import React, { useEffect } from "react";
import { Box, Typography, Stack, Tooltip, Button } from "@mui/material/node";
import Header from "../header/Header";
import "./vendorValidation.css";
import { useSelector, useDispatch } from "react-redux";
import { getConnectStats, getConnectRequest } from "../../redux/dashboardSlice";
import PieChartComponent from "../landingPage/pieChart";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useNavigate } from "react-router";

const VendorValidate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { connectStats, connectRequest } = useSelector(
    (state) => state.dashboardData
  );

  function generateChartData(value) {
    return [
      { value: value > 0, color: "#e2007b" },
      { value: value === 0, color: "rgba(104, 104, 104, 0.2)" },
    ];
  }

  const requestSentTotal = generateChartData(connectStats?.sent?.total);
  const requestSentCompleted = generateChartData(connectStats?.sent?.completed);
  const requestSentScheduled = generateChartData(connectStats?.sent?.scheduled);
  const requestSentDeclined = generateChartData(connectStats?.sent?.declined);

  const requestReceivedTotal = generateChartData(connectStats?.received?.total);
  const requestReceivedCompleted = generateChartData(
    connectStats?.received?.completed
  );
  const requestReceivedScheduled = generateChartData(
    connectStats?.received?.scheduled
  );
  const requestReceivedDeclined = generateChartData(
    connectStats?.received?.declined
  );

  useEffect(() => {
    dispatch(getConnectStats());
    dispatch(getConnectRequest());
  }, []);

  const handleBackToSearch = () => {
    navigate("/landing_page");
  };

  const formatDate = (inputDate) => {
    const options = { year: "2-digit", month: "short", day: "2-digit" };
    const dateObj = new Date(inputDate);
    const formattedDate = dateObj
      .toLocaleDateString("en-GB", options)
      .replace(/ /g, "-");

    return formattedDate;
  };

  return (
    <>
      <Box className="validate">
        <Header />
        <Box className="vendorValidate_wrapper">
          <Stack className="rfiBackButton">
            <Tooltip
              title="Back To Landing Page"
              arrow
              placement="bottom"
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "black",
                    color: "#e2007b",
                    padding: "10px",
                    lineHeight: "24px",
                    border: "0.5px solid #e2007b",
                    "& .MuiTooltip-arrow": {
                      color: "#e2007b",
                    },
                    typography: {
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontWeight: "400px",
                      lineHeight: "18px",
                    },
                  },
                },
              }}
            >
              <Button onClick={() => handleBackToSearch()} className="backBtn">
                <KeyboardArrowLeftIcon sx={{ color: "#AE0770" }} />
              </Button>
            </Tooltip>
            <Typography className="vendorValidateHeading">
              Vendor Validation
            </Typography>
          </Stack>
          <Box className="requestContainer">
            <Box className="validateVendorRequest">
              <Typography className="requestHeading">Requests Sent</Typography>
              <Box className="validationChartContainer">
                <Stack className="requestSentChart">
                  <Stack className="validateChartDataBox">
                    <Stack className="chartInfo">
                      <Typography className="chartData">Sent</Typography>
                    </Stack>
                    <PieChartComponent
                      data={requestSentTotal}
                      value={connectStats?.sent?.total}
                    />
                  </Stack>
                  <Stack className="validateChartDataBox">
                    <Stack className="chartInfo">
                      <Typography className="chartData">Completed</Typography>
                    </Stack>
                    <PieChartComponent
                      data={requestSentCompleted}
                      value={connectStats?.sent?.completed}
                    />
                  </Stack>
                </Stack>
                <Stack className="requestSentChart">
                  <Stack className="validateChartDataBox">
                    <Stack className="chartInfo">
                      <Typography className="chartData">Scheduled</Typography>
                    </Stack>
                    <PieChartComponent
                      data={requestSentScheduled}
                      value={connectStats?.sent?.scheduled}
                    />
                  </Stack>
                  <Stack className="validateChartDataBox">
                    <Stack className="chartInfo">
                      <Typography className="chartData">Declined</Typography>
                    </Stack>
                    <PieChartComponent
                      data={requestSentDeclined}
                      value={connectStats?.sent?.declined}
                    />
                  </Stack>
                </Stack>
              </Box>
              <Box className="requestDataBox">
                <Stack className="requestTableHeading">
                  <Stack className="requestTableRow">
                    <Typography className="rfiCategorHeading">Date</Typography>
                  </Stack>
                  <Stack className="requestTableRow">
                    <Typography className="rfiCategorHeading">
                      {`Company<>Contact`}
                    </Typography>
                  </Stack>
                  <Stack className="requestTableRow">
                    <Typography className="rfiCategorHeading">
                      Status
                    </Typography>
                  </Stack>
                </Stack>
                <Stack className="validationList">
                  {connectRequest?.sent?.map((item, index) => (
                    <Stack className="requestTableData" key={index}>
                      <Stack className="requestTableRow">
                        <Typography className="vendorData">
                          {formatDate(item?.created_at)}
                        </Typography>
                      </Stack>
                      <Stack className="requestTableRow">
                        <Typography className="vendorData">
                          {item?.description}
                        </Typography>
                      </Stack>
                      <Stack className="requestTableRow">
                        <Typography className="vendorData">
                          {item?.status}
                        </Typography>
                      </Stack>
                    </Stack>
                  ))}
                </Stack>
              </Box>
            </Box>
            <Box className="validateVendorRequest">
              <Typography className="requestHeading">
                Requests Received
              </Typography>
              <Box className="validationChartContainer">
                <Stack className="requestSentChart">
                  <Stack className="validateChartDataBox">
                    <Stack className="chartInfo">
                      <Typography className="chartData">Sent</Typography>
                    </Stack>
                    <PieChartComponent
                      data={requestReceivedTotal}
                      value={connectStats?.received?.total}
                    />
                  </Stack>
                  <Stack className="validateChartDataBox">
                    <Stack className="chartInfo">
                      <Typography className="chartData">Completed</Typography>
                    </Stack>
                    <PieChartComponent
                      data={requestReceivedCompleted}
                      value={connectStats?.received?.completed}
                    />
                  </Stack>
                </Stack>
                <Stack className="requestSentChart">
                  <Stack className="validateChartDataBox">
                    <Stack className="chartInfo">
                      <Typography className="chartData">Scheduled</Typography>
                    </Stack>
                    <PieChartComponent
                      data={requestReceivedScheduled}
                      value={connectStats?.received?.scheduled}
                    />
                  </Stack>
                  <Stack className="validateChartDataBox">
                    <Stack className="chartInfo">
                      <Typography className="chartData">Declined</Typography>
                    </Stack>
                    <PieChartComponent
                      data={requestReceivedDeclined}
                      value={connectStats?.received?.declined}
                    />
                  </Stack>
                </Stack>
              </Box>
              <Box className="requestDataBox">
                <Stack className="requestTableHeading">
                  <Stack className="requestTableRow">
                    <Typography className="rfiCategorHeading">Date</Typography>
                  </Stack>
                  <Stack className="requestTableRow">
                    <Typography className="rfiCategorHeading">
                      {`Company<>Contact`}
                    </Typography>
                  </Stack>
                  <Stack className="requestTableRow">
                    <Typography className="rfiCategorHeading">
                      Status
                    </Typography>
                  </Stack>
                </Stack>
                <Stack className="validationList">
                  {connectRequest?.received?.map((item, index) => (
                    <Stack className="requestTableData" key={index}>
                      <Stack className="requestTableRow">
                        <Typography className="vendorData">
                          {item?.created_at}
                        </Typography>
                      </Stack>
                      <Stack className="requestTableRow">
                        <Typography className="vendorData">
                          {item?.description}
                        </Typography>
                      </Stack>
                      <Stack className="requestTableRow">
                        <Typography className="vendorData">
                          {item?.status}
                        </Typography>
                      </Stack>
                    </Stack>
                  ))}
                </Stack>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default VendorValidate;
