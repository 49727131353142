export const Colors = [
  "#FFFFFF",
  "#CCCCCC",
  "#E6E6E6",
  "#B3B3B3",
  "#999999",
  "#666666",
  "#E2007C",
  "#FFE5F4",
  "#FFCCE8",
  "#FF99D1",
  "#FF66BA",
  "#FF33A3",
  "#CC0070",
  "#990054",
  "#660038",
];

export const Stopwords = [
  "the",
  "in",
  "on",
  "at",
  "for",
  "between",
  "by",
  "with",
  "to",
  "beside",
  "aside",
  "a",
  "an",
  "been",
  "we",
  "they",
  "and",
  "have",
  "has",
  "is",
  "are",
  "of",
  "that",
  "us",
  "would",
  "their",
  "this",
];

export const BuyerRevenue = [
  "Select",
  "1M",
  "1M-10M",
  "10M-50M",
  "50M-100M",
  "100M-500M",
  "500M-1B",
  ">1B",
];

export const BuyerEmployee = [
  "Select",
  "1-10 employees",
  "11-50 employees",
  "51-200 employees",
  "201-500 employees",
  "501-1000 employees",
  "1001-5000 employees",
  "5001-10,000 employees",
  "10,001+ employees",
];

export const JobFunction = [
  "Select",
  "Advertising",
  "Engineering",
  "Finance",
  "Management",
  "Marketing",
  "Procurement",
  "Research",
  "Sales",
  "Strategy",
  "Other",
];

export const ProfileInfo =
  "Please review your profile. Ensure the information is correct.";

export const CompanyProfileInfo = `Tell me about your company. Please provide additional information. This will help me
identify solutions best suited to your profile.`;

export const TechStackInfo = `Please enter your tech stack. This will help me identify vendors who
  are best suited to your current workflow.`;

export const RfISteps = [
  { label: "Company Review", value: 0.7 },
  { label: "Tech Requirements", value: 13.2 },
  { label: "Service Requirements", value: 25.7 },
  { label: "Pricing Structure", value: 38.2 },
  { label: "Security and Financial Requirement", value: 50.7 },
  { label: "Deadlines", value: 63.2 },
  { label: "Deal Breakers", value: 75.7 },
  { label: "Tech Stack & Supporting Files", value: 88.2 },
  { label: "Review & Submit", value: 100 },
];

export const TesterProfilInfo = [
  "Access to over 15,000 vendors across 100+ categories",
  "Customized Vendor Ranking, comparisons",
  "Tech Stack Visualizations",
  "Access to pros & cons, publicly available vendor reviews",
  "Hallucination Free AI Assistance (Beta)",
  "Connect with real users for vendor reference (Beta)",
  "Connect with vendor: submit inquiries, schedule calls (Beta)",
];

export const VendorReqKeys = {
  credit_report: "Credit score/ report",
  financial_statement: "Financial statements",
  SOC2_certification: "SOC 2 certificate",
  ISO27001_certification: "ISO 27001",
  references: "References",
};

export const PricingKeys = {
  detailed_pricing_info: "Detailed pricing data",
  payment_T_and_C: "Payment T & C",
  trial_priod: "Trial period",
};

export const TechstackKeys = {
  share_tech_stack: "Share the current tech stack",
};

export const CriteriaArray = [
  { label: "Description", property: "description" },
  { label: "Revenue", property: "revenue" },
  { label: "G2 Score", property: "G2_Rating" },
  { label: "Market Segment", property: "market_segment" },
  { label: "Price", property: "price" },
  { label: "Customer Support", property: "customer_support" },
  { label: "Customizability", property: "customizability" },
  { label: "Analytics", property: "analytics" },
  { label: "Ease Of Use", property: "ease_of_use" },
  { label: "Features", property: "features" },
  { label: "Reliability", property: "reliability" },
  { label: "Scalability", property: "scalability" },
  { label: "Security", property: "security" },
  { label: "Track Record", property: "track_record" },
];
