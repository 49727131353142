import { Box, Stack, Typography } from "@mui/material/node";
import React from "react";
import BrandIcon from "../../components/search/brandmark-design (16) 2 (1).png";
import "./footer.css";
import { useNavigate } from "react-router";

const Footer = () => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  return (
    <Box className="footerWrapper">
      <Box className="footerRegistraion">
        <Box className="footerContainer">
          <Stack className="footerCard">
            <Stack className="footerCard_address">
              <img src={BrandIcon} alt="" width="160px" height="30px" />
            </Stack>
            <Stack className="footerCard_address">
              <Typography className="address">
                1000 Brickell Ave, Ste 715
                <br /> Miami, FL 33131
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Box>
      <Box className="footer_terms">
        <Box className="footer_box">
          <Typography className="footerBar"> © 2024 AMP AI</Typography>
          <Box className="termsAndCookie_box">
            <Typography className="policy_typography"
              sx={{borderRight: "1px solid rgba(161, 161, 161, 0.3)"}}
              onClick={() => handleNavigate("/privacy")}>Privacy Policy</Typography>
            <Typography className="policy_typography"
              sx={{borderRight: "1px solid rgba(161, 161, 161, 0.3)"}}
              onClick={() => handleNavigate("/cookies")}>Cookies</Typography>
            <Typography className="policy_typography" onClick={() => handleNavigate("/terms")}>Terms</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
