import React, { useEffect } from "react";
import { Box, Typography, Stack } from "@mui/material/node";
import "./category.css";
import { useDispatch, useSelector } from "react-redux";
import { removeVendorInquiry } from "../../../redux/rfiSlice";
import PropTypes from "prop-types";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const Category = ({ openSelectedStep }) => {
  const dispatch = useDispatch();
  const { vendorInquiry } = useSelector((state) => state.rfiData);

  const handleCategoryClick = (index) => {
    openSelectedStep("companyReview", index);
  };
  useEffect(() => {
    const data = {
      vendor_category: vendorInquiry[1]?.vendor_category,
    };
    if (vendorInquiry[1]?.vendor_list === []) {
      dispatch(removeVendorInquiry(data));
    }
  }, [vendorInquiry]);

  return (
    <>
      <Box className="rfi">
        <Box className="category_wrapper">
          <Box className="companyReview_wrapper">
            <Box className="headline_container">
              <Typography className="companyReview_typography">
                Confirm the short-list of companies to send Inquiry
              </Typography>
            </Box>
            {vendorInquiry?.map((item, index) =>
              item?.vendor_list && item?.vendor_list.length > 0 && item?.saved_step !== "submitted" ? (
                <Box
                  className="category_listContainer"
                  key={index}
                  onClick={() => handleCategoryClick(index)}
                >
                  <Box className="rfi_categoryCard">
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px",
                        alignItems: "center",
                      }}
                    >
                      <Typography className="category_itemNo">
                        {index + 1}.
                      </Typography>
                      <Typography className="category_itemName">
                        {item?.vendor_category}
                      </Typography>
                    </Stack>
                    <ArrowForwardIosIcon className="arrowIcon" />
                  </Box>
                </Box>
              ) : null
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Category;

Category.propTypes = {
  openSelectedStep: PropTypes.any,
};
