import React from "react";
import { Select, MenuItem } from "@mui/material";
import PropTypes from "prop-types";

const SelectInput = ({ value, onChange, options, error, helperText }) => {
  return (
    <Select
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      sx={{
        border: "1px solid #686868",
        borderRadius: "8px",
        background: "#191819",
        width: "100%",
        height: "41px",
        color: "#A1A1A1",
        "& .css-1yhq19e": {
          color: "grey",
        },
        "& .css-1636szt": {
          color: "grey",
        },
        "& .css-9zxjqa-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root":
          {
            borderColor: "transparent",
          },
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#e2007b",
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            border: "1px solid var(--stroke, #686868)",
          },
          "&:hover fieldset": {
            borderColor: "#686868",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#E2007B",
            border: "2px solid #E2007B",
          },
        },
      }}
    >
      {options.map((option, index) => (
        <MenuItem key={index} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
};

SelectInput.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

export default SelectInput;
