import React, { useRef, useState } from "react";
import "./dashboardtestimonials.css";
import { useSelector } from "react-redux";
import WordCloud from "react-d3-cloud";
import "react-multi-carousel/lib/styles.css";
import DashboardReviews from "./DashboardReviews";
import { Menu, MenuItem, Box, Button, Typography, Stack } from "@mui/material";
import html2canvas from "html2canvas";
import Jspdf from "jspdf";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import addLogo from "../../Layout/image_amp.png";
import { Colors, Stopwords } from "../../util/constant";

const stopwordsPattern = new RegExp(
  "\\b(" + Stopwords.join("|") + ")\\b",
  "gi"
);
const DashboardTestimonials = () => {
  const graphRef = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [displaySummary, setDisplaySummary] = useState(true);
  const open = Boolean(anchorEl);
  const { SingleVendor, userProfiledata, summary } = useSelector(
    (state) => state.promptdata
  );

  const companyName = userProfiledata.company_name;
  const data1 = SingleVendor?.reviews?.data;
  const ReviewFormat = SingleVendor?.reviews?.qa_format;
  // eslint-disable-next-line no-useless-escape
  const punctuationPattern = /[\.,;:'\(\)\[\]]/g;
  let data2;
  if (!ReviewFormat) {
    data2 = data1
      ?.replace(/\*\*Customer Review \d+\*\*\n\n(.+?)\n\n/g, "$1 ")
      .replace(/\./g, "")
      .replace(stopwordsPattern, "")
      .replace("** Customer Reviews**", "")
      .replace(/"/g, "")
      .replace(/[*"]/g, "")
      .replace(companyName, "")
      .replace(
        new RegExp(SingleVendor.vendor_name.replace(/\s/g, ""), "gi"),
        ""
      )
      .replace(punctuationPattern, "")
      .split(" ");
  } else {
    data2 = data1
      ?.map((innerArray) =>
        innerArray?.map((review) => {
          let replacedText = review?.answer;
          let verndorName = SingleVendor.vendor_name.replaceAll("(", "");
          verndorName = verndorName.replaceAll(")", "");
          verndorName = verndorName.replaceAll(/and/gi, "");
          const vendorNameWords = verndorName.split(/\s+/);
          vendorNameWords.forEach((word) => {
            const lowerCaseWord = word.toLowerCase();
            replacedText = replacedText.replace(
              new RegExp(lowerCaseWord, "gi"),
              ""
            );
          });

          return replacedText;
        })
      )
      .join(" ") // Combine array elements into a single string
      .replace(/\*\*Customer Review \d+\*\*\n\n(.+?)\n\n/g, "$1 ")
      .replace(/\./g, "")
      .replace(stopwordsPattern, "")
      .replace("** Customer Reviews**", "")
      .replace(/"/g, "")
      .replace(/[*"]/g, "")
      .replace(punctuationPattern, "")
      .replace(companyName, "")
      .replace(
        new RegExp(SingleVendor.vendor_name.replace(/\s/g, ""), "gi"),
        ""
      )
      .split(" "); // Split the string back into an array
  }

  const reviewsArray = data2?.filter((reviewText) => reviewText.trim() !== "");
  const wordFrequency = {};

  reviewsArray?.forEach((reviewText) => {
    const words = reviewText?.toLowerCase().split(/\s+/);
    words.forEach((word) => {
      wordFrequency[word] = (wordFrequency[word] || 0) + 1;
    });
  });

  const uniqueWords = Object.keys(wordFrequency)
    .filter((word) => word.length > 3)
    .sort((a, b) => wordFrequency[b] - wordFrequency[a]);

  const sortedReviewsObjectsArray = uniqueWords.map((word) => ({
    text: word,
    value: wordFrequency[word],
  }));

  const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * Colors.length);

    return Colors[randomIndex];
  };

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    e.preventDefault();
    setAnchorEl(null);
  };
  const handleBackdropClick = () => {
    setAnchorEl(null);
  };

  const handleSummary = () => {
    setDisplaySummary(!displaySummary);
  };

  const handleScreenshot = () => {
    setAnchorEl(null);
    if (graphRef.current) {
      html2canvas(graphRef.current, {
        backgroundColor: "#black",
        useCORS: true,
      }).then((canvas) => {
        const image = canvas.toDataURL("image/png");
        const pdf = new Jspdf("l", "mm", "a4");
        pdf.setFillColor("black");
        pdf.rect(
          0,
          0,
          pdf.internal.pageSize.getWidth(),
          pdf.internal.pageSize.getHeight(),
          "F"
        );
        pdf.addImage(addLogo, "PNG", 125, 0, 50, 15);
        pdf.setFontSize(18);
        pdf.setTextColor("#989898"); // Set a different color
        pdf.text(
          `${SingleVendor?.vendor_name}'s Review cloud`,
          pdf.internal.pageSize.getWidth() / 2,
          20,
          { align: "center" }
        );
        const space = 14;
        pdf.addImage(image, "PNG", space, 30, 270, 150);
        pdf.setFontSize(12);
        pdf.setTextColor("#989898");
        const date = new Date();
        let localDate = "N/A";

        if (date && !isNaN(date)) {
          const options = {
            timeZone: "America/New_York",
            year: "numeric",
            month: "long",
            day: "2-digit",
          };
          localDate = date.toLocaleString("en-US", options);
        }
        pdf.text(
          `Created by ${userProfiledata.first_name} on ${localDate}`,
          280,
          190,
          {
            align: "right",
          }
        );
        pdf.save("Reviews download.pdf");
      });
    }
  };

  const handleJpeg = () => {
    setAnchorEl(null);
    if (graphRef.current) {
      html2canvas(graphRef.current, {
        backgroundColor: "black",
      }).then((canvas) => {
        const image = canvas.toDataURL("image/jpeg"); // Specify JPEG format
        const link = document.createElement("a");
        link.href = image;
        link.download = "screenshot.jpeg";
        link.click();
      });
    }
  };
  if (!SingleVendor?.reviews) {
    return null;
  }

  return (
    <Box className="dashboardTestimonials">
      <Box className="dashboardTestimonials_wrapper">
        {sortedReviewsObjectsArray.length > 0 && (
          <Box className="dashboardTestimonials_headingBox">
            <Box className="dashboardTestimonial_heading">
              Testimonials and Reviews
            </Box>
            <Box
              sx={{
                width: "25%",
                display: "flex",
                flexDirection: "row",
                gap: "2%",
              }}
            >
              <Button
                className="export_btn"
                sx={{ width: "130px" }}
                onClick={() => handleSummary()}
              >
                {displaySummary ? (
                  <Typography
                    className="btn_typography"
                    sx={{ color: "#e2007b" }}
                  >
                    Summary
                  </Typography>
                ) : (
                  <Typography
                    className="btn_typography"
                    sx={{ color: "#e2007b" }}
                  >
                    Reviews
                  </Typography>
                )}
              </Button>
              <Button
                className="export_btn"
                sx={{ width: "130px" }}
                onClick={(e) => handleClick(e)}
              >
                <Typography
                  className="btn_typography"
                  sx={{ color: "#e2007b" }}
                >
                  Export as
                </Typography>
                <KeyboardArrowDownIcon sx={{ color: "#e2007b" }} />
              </Button>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleBackdropClick}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    width: "133px",
                    height: "100px",
                    borderRadius: "8px",
                    background: "#000000",
                    padding: "5px",
                    color: "white",
                    border: "1px solid #383838",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 150,
                      height: 150,
                      ml: -0.5,
                      mr: 1,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem className="ExportMenu" onClick={() => handleJpeg()}>
                  JPEG
                </MenuItem>
                <MenuItem
                  className="ExportMenu"
                  onClick={() => handleScreenshot()}
                >
                  PDF
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        )}
      </Box>
      <Box
        className="dashboardTech_wrapper_box"
        ref={(ref) => (graphRef.current = ref)}
      >
        <Box className="dashboardTech_wrapper">
          {sortedReviewsObjectsArray.length > 0 && (
            <Box className="dashboardTech_box">
              <WordCloud
                data={sortedReviewsObjectsArray}
                width={300}
                fontSize={(word) => {
                  const calculatedSize = Math.max(Math.log2(word.value) * 8);
                  if (calculatedSize === 0) {
                    return Math.floor(Math.random() * 10) + 1;
                  } else {
                    return calculatedSize;
                  }
                }}
                height={200}
                fill={() => getRandomColor()}
                font="Arial"
                rotate={0}
              />
            </Box>
          )}
          {displaySummary ? (
            <DashboardReviews />
          ) : (
            <Box className="data">
              <Stack sx={{ width: "80%", display: "flex", flexWrap: "wrap" }}>
                <Typography className="summaryHeading">Summary</Typography>
                <Typography className="summaryData">{summary}</Typography>
              </Stack>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardTestimonials;
