import React, { useEffect } from "react";
import { Box, Typography, Stack } from "@mui/material/node";
import "./dealBreaker.css";
import { RFIslider } from "../slider/rfiSlider";
import RfiFooterButton from "../footer/footerButtonGroup";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { saveVendorInquiry } from "../../../redux/rfiSlice";
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import CustomTextField from "../../otherComponent/customTextfield";

const DealBreaker = ({
  openSelectedStep,
  setSliderValue,
  sliderValue,
  selectedCategory,
}) => {
  const dispatch = useDispatch();
  const { vendorInquiry } = useSelector((state) => state.rfiData);

  const NewProjectSchema = Yup.object().shape({
    dealbreakers: Yup.string(),
    budget: Yup.number()
      .typeError("Please enter only a numerical value greater than 0")
      .min(1, "Please enter only a numerical value greater than 0"),
  });
  const formik = useFormik({
    initialValues: {
      dealbreakers: "",
      budget: 0,
    },
    validationSchema: NewProjectSchema,
    onSubmit: async (values) => {
      const data = {
        saved_step: "deal_breakers",
        vendor_category: vendorInquiry[selectedCategory].vendor_category,
        deal_breakers: values,
      };
      dispatch(saveVendorInquiry(data));
      openSelectedStep("rfiTechStack", selectedCategory);
    },
  });

  useEffect(() => {
    if (vendorInquiry) {
      setFieldValue(
        "dealbreakers",
        vendorInquiry[selectedCategory]?.deal_breakers?.dealbreakers
      );
      setFieldValue(
        "budget",
        vendorInquiry[selectedCategory]?.deal_breakers?.budget
      );
    }
  }, [vendorInquiry]);

  const moveback = () => {
    openSelectedStep("deadline", selectedCategory);
  };

  useEffect(() => {
    setSliderValue(75.7);
  }, []);

  const {
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
    errors,
    touched,
  } = formik;

  return (
    <>
      <Box className="rfi">
        <FormikProvider value={formik}>
          <Form
            className="inquiry_form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <Box className="rfi_wrapper">
              <RFIslider
                sliderValue={sliderValue}
                setSliderValue={setSliderValue}
              />
              <Box className="companyReview_wrapper">
                <Box className="dealBreakerHeadline_container">
                  <Typography className="companyReview_typography">
                    Please enter the deal breakers. This will not be shared with
                    the vendor. However, it will help me to filter out
                    responses.
                  </Typography>
                </Box>
                <Box className="rfi_listContainer">
                  <Box className="dealbreaker_field">
                    <Typography className="rfi_question">
                      Enter the deal breakers, this could be a trail period,
                      SOC2 compliance, lack of specific features.
                    </Typography>
                    <CustomTextField
                      name="dealbreakers"
                      value={values?.dealbreakers}
                      {...getFieldProps("dealbreakers")}
                      width="100%"
                    />
                  </Box>
                  <Box className="rfi_field">
                    <Typography className="rfi_question">
                      What’s your budget? (Annual in USD)
                    </Typography>
                    <CustomTextField
                      name="budget"
                      value={values?.budget}
                      {...getFieldProps("budget")}
                      width="100%"
                      error={Boolean(touched.budget && errors.budget)}
                      helperText={touched.budget && errors.budget}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Stack sx={{ textAlign: "center", width: "70%" }}>
              <RfiFooterButton moveback={moveback} />
            </Stack>
          </Form>
        </FormikProvider>
      </Box>
    </>
  );
};

export default DealBreaker;

DealBreaker.propTypes = {
  openSelectedStep: PropTypes.func,
  setSliderValue: PropTypes.number,
  sliderValue: PropTypes.number,
  selectedCategory: PropTypes.number,
};
