import {
  Box,
  Stack,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./resetPassword.css";
import PropTypes from "prop-types";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router";
import { changePassword } from "../../redux/applicationSlice";
import { useDispatch, useSelector } from "react-redux";

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = window?.location?.href;
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [secret, setSecret] = useState("");
  const { getResetEmail } = useSelector((state) => state.promptdata);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };
  const handlePasswordReset = (data) => {
    if (data.status === "Password reset") {
      toast("Password reset successfully.");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } else {
      toast(data?.response?.data?.detail);
    }
  };

  const handleSubmit = () => {
    const data = {
      email: getResetEmail,
      password: confirmPassword,
    };
    if (password === confirmPassword) {
      dispatch(changePassword(data, handlePasswordReset, secret));
    } else {
      toast("Passwords do not match");
    }
  };

  useEffect(() => {
    const split = params.split("=");
    setSecret(split[1]);
  }, []);

  return (
    <Stack className="login_page_container_wrapper">
      <ToastContainer />
      <Box className="login_page_box">
        <Stack className="resetPassword_elements">
          <Stack className="resetPassword_container">
            <Stack className="logo_brand">
              <img src="/images/logo.png" />
            </Stack>
            <Stack className="reset_header">
              <Typography className="forgotPasswordTypography">
                Change password
              </Typography>
              <Typography className="resetHeadline">
                Set the new password for your account.
              </Typography>
            </Stack>
          </Stack>
          <Stack className="login_form_email" style={{ marginTop: "24px" }}>
            <Typography
              className="login_textfield"
              style={{ marginBottom: "7px" }}
            >
              New Password
            </Typography>
            <TextField
              value={password}
              onChange={handlePasswordChange}
              className="passwordTextfield"
              sx={{
                backgroundColor: "#191819",
                width: "496px",
                border: "1px solid #686868",
                borderRadius: "8px",
                fontFamily: "Poppins",
                fontWeight: 600,
                "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                  color: "#E2007B",
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: "0px solid #E2007B",
                    borderRadius: "8px",
                  },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "#E2007B",
                },
                "& .MuiOutlinedInput-input": {
                  color: "white",
                  padding: "0 0 0 10px",
                  height: "44px",
                },
              }}
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              name="password"
              fullWidth
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityIcon style={{ color: "grey" }} />
                      ) : (
                        <VisibilityOff style={{ color: "grey" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Stack className="login_form_email" style={{ marginTop: "24px" }}>
            <Typography
              className="login_textfield"
              style={{ marginBottom: "7px" }}
            >
              Confirm New Password
            </Typography>
            <TextField
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              sx={{
                backgroundColor: "#191819",
                width: "496px",
                border: "1px solid #686868",
                borderRadius: "8px",
                fontFamily: "Poppins",
                fontWeight: 600,
                "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                  color: "#E2007B",
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: "0px solid #E2007B",
                    borderRadius: "8px",
                  },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "#E2007B",
                },
                "& .MuiOutlinedInput-input": {
                  color: "white",
                  padding: "0 0 0 10px",
                  height: "44px",
                },
              }}
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              name="password"
              fullWidth
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityIcon style={{ color: "grey" }} />
                      ) : (
                        <VisibilityOff style={{ color: "grey" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Stack className="login_button">
            <Button
              className="login_textfield"
              style={{ height: "44px" }}
              onClick={handleSubmit}
            >
              Continue
            </Button>
          </Stack>
          <Typography className="backToLoginTypography">
            Back to{" "}
            <span
              style={{ color: "#e2007b" }}
              onClick={() => navigate("/login")}
            >
              Log in
            </span>
          </Typography>
        </Stack>
      </Box>
    </Stack>
  );
};

export default ResetPassword;

ResetPassword.propTypes = {
  setOnBoarding: PropTypes.any,
};
