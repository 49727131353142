import React, { useState } from "react";
import { Box } from "@mui/material/node";
import Modal from '@mui/material/Modal';
// import Button from '@mui/material/Button';
import "./ChatPage.css";
import Chat from "./Chat";

const ChatPage = () => {
  const [open, setOpen] = useState(false);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    < >
      {/* <Button className="chatOpen_btn" onClick={handleOpen}>
        <img src="/images/openchat.svg" alt="" />
      </Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal_background">
          <Chat onClose={setOpen} />
        </Box>
      </Modal>
    </>
  );
};

export default ChatPage;
