import { Box, Button, Stack } from "@mui/material/node";
import React from "react";
import "./FooterButton.css";
import PropTypes from "prop-types";
import { useLocation } from "react-router";

export const FooterButton = ({
  MoveNext,
  isdisable,
  moveback,
  skipBtn,
  techstack,
  testerProfile,
  isUserInvite,
}) => {
  const { pathname } = useLocation();

  const styles = {
    color: isdisable && "#E2007B",
    opacity: isdisable && "0.2",
  };

  return (
    <Stack>
      <Stack className="Footerbtn_wrapper">
        <Box>
          {pathname === "/onboarding" && techstack && testerProfile ? (
            ""
          ) : pathname === "/onboarding" && isUserInvite ? (
            <Button className="cancel_btn" onClick={skipBtn}> Skip for Now</Button>
          ) : (
            <Button className="cancel_btn" onClick={moveback}>
              Cancel
            </Button>
          )}
        </Box>
        <Box>
          <Button
            className="save_btn"
            onClick={MoveNext}
            disabled={isdisable}
            style={styles}
            type="submit"
          >
            Save
            <img src="/images/material-symbols_arrow-right-alt-rounded.svg" />
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};

export default FooterButton;

FooterButton.propTypes = {
  MoveNext: PropTypes.any,
  isdisable: PropTypes.bool,
  moveback: PropTypes.any,
  skipBtn: PropTypes.func,
  techstack: PropTypes.bool,
  testerProfile: PropTypes.bool,
  isUserInvite: PropTypes.bool,
};
