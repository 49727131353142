import React, { useEffect } from "react";
import { Box, Typography, Stack } from "@mui/material/node";
import "./deadline.css";
import { RFIslider } from "../slider/rfiSlider";
import RfiFooterButton from "../footer/footerButtonGroup";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { saveVendorInquiry } from "../../../redux/rfiSlice";
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

const Deadline = ({
  openSelectedStep,
  setSliderValue,
  sliderValue,
  selectedCategory,
}) => {
  const dispatch = useDispatch();
  const { vendorInquiry } = useSelector((state) => state.rfiData);

  const NewProjectSchema = Yup.object().shape({
    submission_deadline: Yup.date(),
  });
  dayjs.extend(utc);
  dayjs.extend(timezone);

  dayjs.tz.setDefault("America/New_York");
  const formik = useFormik({
    initialValues: {
      submission_deadline: "",
    },
    validationSchema: NewProjectSchema,
    onSubmit: async (values) => {
      const data = {
        saved_step: "deadlines",
        vendor_category: vendorInquiry[selectedCategory].vendor_category,
        deadlines: values,
      };
      dispatch(saveVendorInquiry(data));
      openSelectedStep("dealBreaker", selectedCategory);
    },
  });
  useEffect(() => {
    if (vendorInquiry) {
      setFieldValue(
        "submission_deadline",
        vendorInquiry[selectedCategory].deadlines?.submission_deadline
      );
    }
  }, [vendorInquiry]);

  const moveback = () => {
    openSelectedStep("vendorReq", selectedCategory);
  };

  useEffect(() => {
    setSliderValue(63.2);
  }, []);

  const { handleSubmit, setFieldValue, getFieldProps, values } = formik;

  return (
    <>
      <Box className="rfi">
        <FormikProvider value={formik}>
          <Form
            className="inquiry_form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <Box className="rfi_wrapper">
              <RFIslider
                sliderValue={sliderValue}
                setSliderValue={setSliderValue}
              />
              <Box className="companyReview_wrapper">
                <Box className="headline_container">
                  <Typography className="companyReview_typography">
                    Please select a deadline date.
                  </Typography>
                </Box>
                <Box className="deadline_container">
                  <Box className="deadline_field">
                    <Typography className="rfi_question">
                      Last date for submission
                    </Typography>
                    <div className="datePicker1">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="datePicker"
                          {...getFieldProps("submission_deadline")}
                          value={dayjs(values?.submission_deadline).tz()}
                          onChange={(date) =>
                            setFieldValue(
                              "submission_deadline",
                              dayjs(date).toDate()
                            )
                          }
                          classes={{
                            root: "customDatePicker",
                          }}
                        />
                      </LocalizationProvider>
                    </div>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Stack sx={{ textAlign: "center", width: "70%" }}>
              <RfiFooterButton moveback={moveback} />
            </Stack>
          </Form>
        </FormikProvider>
      </Box>
    </>
  );
};

export default Deadline;

Deadline.propTypes = {
  openSelectedStep: PropTypes.func,
  setSliderValue: PropTypes.number,
  sliderValue: PropTypes.number,
  selectedCategory: PropTypes.number,
};
