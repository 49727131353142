import React, { useEffect } from "react";
import { Box, Typography, Stack } from "@mui/material/node";
import "./service.css";
import { RFIslider } from "../slider/rfiSlider";
import RfiFooterButton from "../footer/footerButtonGroup";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { saveVendorInquiry } from "../../../redux/rfiSlice";
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import CustomTextField from "../../otherComponent/customTextfield";

const Service = ({
  openSelectedStep,
  setSliderValue,
  sliderValue,
  selectedCategory,
}) => {
  const dispatch = useDispatch();
  const { vendorInquiry } = useSelector((state) => state.rfiData);
  const NewProjectSchema = Yup.object().shape({
    post_sales_services: Yup.string().required("Functionalities Required"),
  });

  const formik = useFormik({
    initialValues: {
      post_sales_services: "",
    },
    validationSchema: NewProjectSchema,
    onSubmit: async (values) => {
      const data = {
        saved_step: "service_requirements",
        vendor_category: vendorInquiry[selectedCategory]?.vendor_category,
        service_requirements: values,
      };
      dispatch(saveVendorInquiry(data));
      openSelectedStep("pricing", selectedCategory);
    },
  });

  useEffect(() => {
    if (vendorInquiry) {
      setFieldValue(
        "post_sales_services",
        vendorInquiry[selectedCategory]?.service_requirements
          ?.post_sales_services
      );
    }
  }, [vendorInquiry]);

  const moveback = () => {
    openSelectedStep("techRequirement", selectedCategory);
  };

  useEffect(() => {
    setSliderValue(25.7);
  }, []);

  const { handleSubmit, getFieldProps, setFieldValue, values } = formik;

  return (
    <>
      <Box className="rfi">
        <FormikProvider value={formik}>
          <Form
            className="inquiry_form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <Box className="rfi_wrapper">
              <RFIslider
                sliderValue={sliderValue}
                setSliderValue={setSliderValue}
              />
              <Box className="companyReview_wrapper">
                <Box className="headlineService_container">
                  <Typography className="companyReview_typography">
                    Please enter the service requirements as detailed
                    information as possible. These are considerations to have
                    post product purchase.
                  </Typography>
                  <Typography className="companyReview_typography">
                    I can help you refine the questions once you have provided
                    basic information.
                  </Typography>
                </Box>
                <Box className="service_listContainer">
                  <Box className="rfi_field">
                    <Typography className="rfi_question">
                      Describe the support service required, training, customer
                      support.
                    </Typography>
                    <CustomTextField
                      name="post_sales_services"
                      value={values?.post_sales_services}
                      {...getFieldProps("post_sales_services")}
                      width="100%"
                    />
                  </Box>
                </Box>
                <Box className="refine_box">
                  <Typography className="refineTopography">Refine</Typography>
                </Box>
              </Box>
            </Box>
            <Stack sx={{ textAlign: "center", width: "70%" }}>
              <RfiFooterButton moveback={moveback} />
            </Stack>
          </Form>
        </FormikProvider>
      </Box>
    </>
  );
};

export default Service;

Service.propTypes = {
  setSliderValue: PropTypes.func,
  sliderValue: PropTypes.number,
  selectedCategory: PropTypes.number,
  openSelectedStep: PropTypes.number,
};
