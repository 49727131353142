import { Box, Stack } from "@mui/material/node";
import Typography from "@mui/material/node/Typography";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

const removeSpecialChars = (text) => {
  if (typeof text === "string") {
    return text.replace(
      /Customer Reviews|Customer Review|Overall Rating|[#*"]/g,
      ""
    );
  }

  return text;
};

const DashboardReviews = ({ maxChar = 150, itemsPerPage = 1, maxCharacter = 450}) => {
  const [reviews, setReviews] = useState([]);
  const [expandedItems, setExpandedItems] = useState([]);
  const [expandReview, setExpandReview] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { SingleVendor } = useSelector((state) => state.promptdata);
  const ReviewFormat = SingleVendor.reviews.qa_format;

  useEffect(() => {
    const reviewsData = SingleVendor.reviews?.data;
    if (Array.isArray(reviewsData)) {
      setReviews(reviewsData);
      setExpandedItems(Array(reviewsData.length).fill(false));
    }
  }, []);
  const toggleExpandReview = () => {
    setExpandReview(!expandReview);
  };
  const toggleExpand = (index) => {
    setExpandedItems((prevExpandedItems) => {
      const newExpandedItems = [...prevExpandedItems];
      newExpandedItems[index] = !newExpandedItems[index];

      return newExpandedItems;
    });
  };
  const nonEmptyReviews = reviews.filter((itemGroup) => itemGroup?.length > 0);

  const handlePageChange = (newPage) => {
    const pageCount = Math.ceil(nonEmptyReviews?.length / itemsPerPage);
    const clampedPage = Math.min(Math.max(newPage, 1), pageCount);

    setCurrentPage(clampedPage);
  };

  const renderReviews = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedReviews = nonEmptyReviews.slice(startIndex, endIndex);

    return paginatedReviews.map((itemGroup, groupIndex) => (
      <div key={groupIndex}>
        {itemGroup?.map((item, index) => (
          <Box key={index} sx={{ marginBottom: "4%" }}>
            <Typography sx={{ color: "#e2007b" }}>
              {removeSpecialChars(item.question)}
            </Typography>
            <Typography className="dashboardTestimonaial_data">
              {expandedItems[index]
                ? removeSpecialChars(item.answer)
                : `${removeSpecialChars(item.answer)?.slice(0, maxChar)}${
                  removeSpecialChars(item.answer)?.length > maxChar
                    ? "..."
                    : ""
                }`}
            </Typography>
            {removeSpecialChars(item.answer)?.length > maxChar && (
              <Typography
                style={{ color: "#E2007B", cursor: "pointer" }}
                onClick={() => toggleExpand(index)}
              >
                {expandedItems[index] ? "Show Less" : "Read More"}
              </Typography>
            )}
          </Box>
        ))
        }
      </div>
    ));
  };

  const renderPaginationButtons = () => {
    if (nonEmptyReviews.length > itemsPerPage) {
      const pageCount = Math.ceil(nonEmptyReviews.length / itemsPerPage);
      let startPage = Math.max(1, currentPage - 1);
      let endPage = Math.min(currentPage + 1, pageCount);
      if (endPage - startPage + 1 < 3) {
        if (startPage === 1) {
          endPage = Math.min(3, pageCount);
        } else {
          startPage = Math.max(1, endPage - 2);
        }
      }
      endPage = Math.min(endPage, pageCount);
      const pagesToDisplay = Array.from(
        { length: endPage - startPage + 1 },
        (_, i) => startPage + i
      );

      return (
        <Stack className="Pagination_box">
          <Box className="pagination_number">
            <Stack
              className={`review_paginationBtn ${
                currentPage === 1 ? "disabled" : ""
              }`}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              {"<"}
            </Stack>
            {pagesToDisplay?.map((index) => (
              <Stack
                key={index}
                className={`review_paginationBtnNumber ${
                  index === currentPage
                    ? "review_paginationBtnActive"
                    : ""
                }`}
                onClick={() => handlePageChange(index)}
              >
                {index}
              </Stack>
            ))}
            <Stack
              className={`review_paginationBtn ${
                currentPage === pageCount ? "disabled" : ""
              }`}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              {">"}
            </Stack>
          </Box>
        </Stack>
      );
    }

    return null;
  };

  return (
    <Box className="data">
      <Box style={{ display: "flex", flexWrap: "wrap", width: "80%" }}>
        <Box className={"dashboardTestimonaial_rightData"}>
          <Typography className="dashboardTestimonial_vendorName">
            {SingleVendor.vendor_name}
          </Typography>

          {ReviewFormat ? (
            <>
              {reviews.length > 0 && renderReviews()}
              {renderPaginationButtons()}
            </>
          ) : (
            <>
              <Typography className="dashboardTestimonaial_data">
                {expandReview
                  ? removeSpecialChars(SingleVendor.reviews.data)
                  : `${removeSpecialChars(SingleVendor.reviews.data)?.slice(0, maxCharacter)}${
                    removeSpecialChars(SingleVendor.reviews.data)?.length > maxCharacter
                      ? "..."
                      : ""
                  }`}
              </Typography>
              {removeSpecialChars(SingleVendor.reviews.data)?.length > maxCharacter && (
                <Typography
                  style={{ color: "#E2007B", cursor: "pointer" }}
                  onClick={() => toggleExpandReview()}
                >
                  {expandReview ? "Show Less" : "Read More"}
                </Typography>
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardReviews;

DashboardReviews.propTypes = {
  maxChar: PropTypes.any,
  itemsPerPage: PropTypes.any,
  maxCharacter: PropTypes.any,
};
