import Stack from "@mui/material/node/Stack";
import React from "react";
import Slider from "@mui/material/Slider";
import { Box } from "@mui/material/node";
import Typography from "@mui/material/node/Typography";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import "./sliderComponent.css";

export const SliderComponent = ({ value }) => {
  const marks = [{ value: 0 }, { value: 25 }, { value: 50 },{ value: 75}, {value: 99.5}];

  const PrettoSlider = styled(Slider)(({ theme }) => ({
    color: "#e2007b",
    height: 3,
    width: "800px",
    "& .MuiSlider-track": {
      border: "none",
    },
    "& .MuiSlider-thumb": {
      height: 11.27,
      width: 11.27,
      backgroundColor: "#fff",
      border: "1px solid currentColor",
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "inherit",
      },
      "&:before": {
        display: "none",
      },
    },
    "& .MuiSlider-rail": {
      color: theme.palette.mode === "dark" ? "black" : " #191819",
      opacity: theme.palette.mode === "dark" ? undefined : 1,
      height: 3,
    },
    "& .MuiSlider-mark": {
      backgroundColor: " #191819",
      height: 11.27,
      width: 11.27,
      borderRadius: "50%",
      "&.MuiSlider-markActive": {
        opacity: 1,
        backgroundColor: "currentColor",
      },
    },
  }));

  return (
    <Stack width="900px" marginTop="30px" marginBottom="30px">
      <Box color="#ffffff" display="flex" justifyContent="space-between">
        <Box>
          <Typography className="siderPageValue">Profile</Typography>
        </Box>
        <Box>
          <Typography className="siderPageValue">Company Profile</Typography>
        </Box>
        <Box>
          <Typography className="siderPageValue">Invite</Typography>
        </Box>
        <Box>
          <Typography className="siderPageValue">Company Tech Stack</Typography>
        </Box>
        <Box>
          <Typography className="siderPageValue">Subscription</Typography>
        </Box>
      </Box>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <PrettoSlider
          valueLabelDisplay="auto"
          aria-label="pretto slider"
          defaultValue={20}
          value={value}
          marks={marks}
        />
      </Box>
    </Stack>
  );
};

export default SliderComponent;

SliderComponent.propTypes = {
  value: PropTypes.any,
  setValue: PropTypes.any,
};
