import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import usereducer from './userslice';
import applicationReducer from './applicationSlice';
import dashboardReducer from './dashboardSlice';
import techStackReducer from './techStackSlice';
import decisionTreeReducer from './decisionTreeSlice';
import rfiReducer from "./rfiSlice";

const rootPersistConfig = {
  key: 'root',
  storage,
};
const productPersistConfig = {
  key: 'product',
  storage,
};
const rootReducer = combineReducers({
  promptdata: persistReducer(productPersistConfig, usereducer),
  applicationData: applicationReducer,
  dashboardData: dashboardReducer,
  techStackData: techStackReducer,
  decisionTreeData: decisionTreeReducer,
  rfiData: rfiReducer,
});

export { rootPersistConfig, rootReducer };
