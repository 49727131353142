import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./resendMail.css";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getResetPassword } from "../../redux/applicationSlice";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getResetEmail } = useSelector((state) => state.promptdata);
  const email = getResetEmail;

  const handleMailSend = (email) => {
    if(email.status === "noted"){
      toast("Mail has been sent successfully!");
    } else {
      toast(email?.response?.data?.detail);
    }
  };

  const handleMailResend = () => {
    dispatch(getResetPassword(email, handleMailSend));
  };

  return (
    <Stack className="login_page_container_wrapper">
      <ToastContainer />
      <Box className="resendMail_box">
        <Stack className="resendMail_container">
          <Stack className="logo_brand">
            <img src="/images/logo.png" />
          </Stack>
          <Stack className="forgot_header">
            <Typography className="forgotPasswordTypography">
              Check your email
            </Typography>
            <Typography className="forgotHeadline">
              We have sent you an email. Click the link given in email to reset
              password.
            </Typography>
          </Stack>
          <Stack className="resendEmail">
            <Typography className="resendTypography" onClick={handleMailResend}>
              Resend email</Typography>
            <Typography className="backToLoginTypography">
              Back to{" "}
              <span
                style={{ color: "#e2007b", cursor: "pointer" }}
                onClick={() => navigate("/login")}
              >
                Log in
              </span>
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
};

export default ForgotPassword;
