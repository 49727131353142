import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Stack,
  Checkbox
} from "@mui/material/node";
import "./pricing.css";
import { RFIslider } from "../slider/rfiSlider";
import RfiFooterButton from "../footer/footerButtonGroup";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { saveVendorInquiry } from "../../../redux/rfiSlice";
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import CustomTextField from "../../otherComponent/customTextfield";

const Pricing = ({
  openSelectedStep,
  setSliderValue,
  sliderValue,
  selectedCategory,
}) => {
  const dispatch = useDispatch();
  const { vendorInquiry } = useSelector((state) => state.rfiData);
  const NewProjectSchema = Yup.object().shape({
    detailed_pricing_info: Yup.boolean(),
    payment_T_and_C: Yup.boolean(),
    trial_priod: Yup.boolean(),
    addl_pricing_info: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      detailed_pricing_info: false,
      payment_T_and_C: false,
      trial_priod: false,
      addl_pricing_info: "",
    },
    validationSchema: NewProjectSchema,
    onSubmit: async (values) => {
      const data = {
        saved_step: "pricing_structure",
        vendor_category: vendorInquiry[selectedCategory].vendor_category,
        pricing_structure: values,
      };
      dispatch(saveVendorInquiry(data));
      openSelectedStep("vendorReq", selectedCategory);
    },
  });

  useEffect(() => {
    if (
      vendorInquiry &&
      vendorInquiry[selectedCategory] &&
      vendorInquiry[selectedCategory]?.pricing_structure
    ) {
      setFieldValue(
        "detailed_pricing_info",
        vendorInquiry[selectedCategory]?.pricing_structure
          ?.detailed_pricing_info
      );
      setFieldValue(
        "payment_T_and_C",
        vendorInquiry[selectedCategory]?.pricing_structure
          ?.payment_T_and_C
      );
      setFieldValue(
        "trial_priod",
        vendorInquiry[selectedCategory]?.pricing_structure?.trial_priod
      );
      setFieldValue(
        "addl_pricing_info",
        vendorInquiry[selectedCategory]?.pricing_structure
          ?.addl_pricing_info
      );
    }
  }, [vendorInquiry]);

  const moveback = () => {
    openSelectedStep("service", selectedCategory);
  };

  const handleInputChange = (e) => {
    const { name, type } = e.target;
    const inputValue = type === "checkbox" ? e.target.checked : e.target.value;
    formik.setFieldValue(name, inputValue);
  };

  useEffect(() => {
    setSliderValue(38.2);
  }, []);

  const { handleSubmit, getFieldProps, setFieldValue, values } = formik;

  return (
    <>
      <Box className="rfi">
        <FormikProvider value={formik}>
          <Form
            className="inquiry_form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <Box className="rfi_wrapper">
              <RFIslider
                sliderValue={sliderValue}
                setSliderValue={setSliderValue}
              />
              <Box className="companyReview_wrapper">
                <Box className="headline_container">
                  <Typography className="companyReview_typography">
                    Select the pricing information that is needed.
                  </Typography>
                </Box>
                <Box className="pricing_listContainer">
                  <Box
                    className={
                      formik.values.detailed_pricing_info === true
                        ? "rfi_checkedCheckbox"
                        : "rfi_pricing"
                    }
                  >
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px",
                        alignItems: "center",
                      }}
                    >
                      <Typography className="pricing_detail">
                        Detailed pricing data
                      </Typography>
                    </Stack>
                    <Checkbox
                      className="customCheckbox"
                      name="detailed_pricing_info"
                      checked={formik.values.detailed_pricing_info}
                      onChange={handleInputChange}
                    />
                  </Box>
                  <Box
                    className={
                      formik.values.payment_T_and_C === true
                        ? "rfi_checkedCheckbox"
                        : "rfi_pricing"
                    }
                  >
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px",
                        alignItems: "center",
                      }}
                    >
                      <Typography className="pricing_detail">
                        Payment T & C
                      </Typography>
                    </Stack>
                    <Checkbox
                      className="customCheckbox"
                      name="payment_T_and_C"
                      checked={formik.values.payment_T_and_C}
                      onChange={handleInputChange}
                    />
                  </Box>
                  <Box
                    className={
                      formik.values.trial_priod === true
                        ? "rfi_checkedCheckbox"
                        : "rfi_pricing"
                    }
                  >
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px",
                        alignItems: "center",
                      }}
                    >
                      <Typography className="pricing_detail">
                        Trial period
                      </Typography>
                    </Stack>
                    <Checkbox
                      className="customCheckbox"
                      name="trial_priod"
                      checked={formik.values.trial_priod}
                      onChange={handleInputChange}
                    />
                  </Box>
                  <Box className="rfi_field">
                    <Typography className="rfi_question">
                      Please specify any additional pricing information that is
                      needed.
                    </Typography>
                    <CustomTextField
                      name="addl_pricing_info"
                      value={values?.addl_pricing_info}
                      {...getFieldProps("addl_pricing_info")}
                      width="100%"
                    />
                  </Box>
                </Box>
                <Box className="refine_box">
                  <Typography className="refineTopography">Refine</Typography>
                </Box>
              </Box>
            </Box>
            <Stack sx={{ textAlign: "center", width: "70%" }}>
              <RfiFooterButton moveback={moveback} />
            </Stack>
          </Form>
        </FormikProvider>
      </Box>
    </>
  );
};

export default Pricing;

Pricing.propTypes = {
  openSelectedStep: PropTypes.func,
  setSliderValue: PropTypes.number,
  sliderValue: PropTypes.number,
  selectedCategory: PropTypes.number,
};
