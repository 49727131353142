import { Box, Typography, Stack } from "@mui/material/node";
import React from "react";
import "./feature.css";
import VideoCallOutlinedIcon from '@mui/icons-material/VideoCallOutlined';
import PersonPinOutlinedIcon from '@mui/icons-material/PersonPinOutlined';
import SignalCellularAltOutlinedIcon from '@mui/icons-material/SignalCellularAltOutlined';
import VpnLockOutlinedIcon from '@mui/icons-material/VpnLockOutlined';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import StackedLineChartOutlinedIcon from '@mui/icons-material/StackedLineChartOutlined';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';

const Features = () => {

  return (
    <Box className="featureWrapper">
      <Stack>
      </Stack>
      <Box className="featuresContainer">
        <Box className="featuresCard">
          <Box className="featureCard_header">
            <Typography className="featuresCard_headline">
              Current Features
            </Typography>
            <Typography className="featuresCard_detail">
              The first hyper- personalized AI Native Vendor identified platform
            </Typography>
          </Box>
          <Box className="featureCard-List">
            <Stack className="featureCard_ListItem">
              <Stack className="featurecard_itemIcon">
                <TableRowsOutlinedIcon className="icons" />
              </Stack>
              <Typography className="featurecard_itemName">
                AI generated tech stack visuals
              </Typography>
            </Stack>
            <Stack className="featureCard_ListItem">
              <Stack className="featurecard_itemIcon">
                <SignalCellularAltOutlinedIcon className="icons" />
              </Stack>
              <Typography className="featurecard_itemName">
                AI driven customized vendor ranking
              </Typography>
            </Stack>
            <Stack className="featureCard_ListItem">
              <Stack className="featurecard_itemIcon">
                <SupportAgentOutlinedIcon className="icons" />
              </Stack>
              <Typography className="featurecard_itemName">
                Hallucination Free AI Assistant
              </Typography>
            </Stack>
            <Stack className="featureCard_ListItem">
              <Stack className="featurecard_itemIcon">
                <PersonPinOutlinedIcon className="icons" />
              </Stack>
              <Typography className="featurecard_itemName">
                Talk to current users for vendor feedback
              </Typography>
            </Stack>
            <Stack className="featureCard_ListItem">
              <Stack className="featurecard_itemIcon">
                <DescriptionOutlinedIcon className="icons" />
              </Stack>
              <Typography className="featurecard_itemName">
                AI assisted vendor information inquiry
              </Typography>
            </Stack>
          </Box>
        </Box>
        {/* second card */}
        <Box className="featuresCard">
          <Stack className="featureCard_header">
            <Typography className="featuresCard_headline">
              In the pipeline
            </Typography>
            <Typography className="featuresCard_detail">
              Revolutionizing marketplaces through AI{" "}
            </Typography>
          </Stack>
          <Box className="featureCard-List">
            <Stack className="featureCard_ListItem">
              <Stack className="featurecard_itemIcon">
                <VpnLockOutlinedIcon className="icons" />
              </Stack>
              <Typography className="featurecard_itemName">
                Vendor expansion to over 70,000
              </Typography>
            </Stack>
            <Stack className="featureCard_ListItem">
              <Stack className="featurecard_itemIcon">
                <SpeedOutlinedIcon className="icons" />
              </Stack>
              <Typography className="featurecard_itemName">
                Selection from over 200+ categories
              </Typography>
            </Stack>
            <Stack className="featureCard_ListItem">
              <Stack className="featurecard_itemIcon">
                <StackedLineChartOutlinedIcon className="icons" />
              </Stack>
              <Typography className="featurecard_itemName">
                AI driven tech stack optimization
              </Typography>
            </Stack>
            <Stack className="featureCard_ListItem">
              <Stack className="featurecard_itemIcon">
                <PsychologyOutlinedIcon className="icons" />
              </Stack>
              <Typography className="featurecard_itemName">
              AI assisted solutions generation for business problems
              </Typography>
            </Stack>
            <Stack className="featureCard_ListItem">
              <Stack className="featurecard_itemIcon">
                <VideoCallOutlinedIcon className="icons" />
              </Stack>
              <Typography className="featurecard_itemName">
                Video call with vendors
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Features;
