import {
  Box,
  Stack,
  Typography,
  Checkbox,
  Button,
  Modal,
  Tooltip
} from "@mui/material/node";
import { Menu, MenuItem } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/system";
import { useSelector, useDispatch } from "react-redux";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import "./dashboardRow.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { ToastContainer, toast } from "react-toastify";
import {
  getSingleTargetVendor,
  getmarketAverage,
  setActiveVendor,
  setSearchProfile,
  searchProfile,
  getReviewSummary
} from "../../redux/userslice";
import { getPostedTechStack } from "../../redux/techStackSlice";
import addLogo from "../../Layout/image_amp.png";
import {
  getCriteriaList,
  getRevenueBucket,
  targetvendors,
  getUserConnectList,
  makeConnectRequest
} from "../../redux/dashboardSlice";
import { useNavigate, useLocation } from "react-router";
import "react-multi-carousel/lib/styles.css";
import html2canvas from "html2canvas";
import Jspdf from "jspdf";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import PropTypes from "prop-types";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import IosShareIcon from "@mui/icons-material/IosShare";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CloseIcon from "@mui/icons-material/Close";
import CustomTextField from "../otherComponent/customTextfield";
import { saveVendorInquiry, getVendorInquiries } from "../../redux/rfiSlice";

const PrettoSlider = styled(Slider)(({ theme }) => ({
  color: "#e2007b",
  padding: "0px",
  height: 3,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    position: "relative",
    zIndex: 1,
    height: 8,
    width: 8,
    backgroundColor: "#e2007b",
    border: "1px solid white",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      position: "absolute",
      top: "-14px",
      left: "50%",
      transform: "translateX(-50%) rotate(-90deg)",
      width: 0,
      height: 0,
      borderTop: "7px solid transparent",
      borderBottom: "7px solid transparent",
      borderRight: "10px solid #e2007b",
      cursor: "grab",
      borderRadius: "4px",
    },
  },
  "& ..css-nnhk2u-MuiSlider-root": {
    padding: "0px",
  },
  "& ..css-oh6yyr-MuiSlider-root": {
    padding: "0px",
  },
  "& .MuiSlider-rail": {
    color: theme.palette.mode === "dark" ? "black" : " white",
    border: "1px solid #e2007b",
    opacity: theme.palette.mode === "dark" ? undefined : 1,
    height: 3,
  },
  "& .MuiSlider-mark": {
    backgroundColor: " white",
    border: "1px solid #e2007b",
    height: 3,
    width: 3,
    borderRadius: "50%",
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "white",
    },
  },
}));

const DashboardRow = ({ setIsVisible }) => {
  const graphRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [actives, setActives] = useState(0);
  const [activeTab, setActiveTab] = useState(-1);
  const [activeIndustry, setActiveIndustry] = useState(-1);
  const [selectedCriteria, setSelectedCritera] = useState();
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [clickedItems, setClickedItems] = useState([]);
  const [criteria, setCriteria] = useState({});
  const [sliderValue, setSliderValues] = useState();
  console.log("slider", sliderValue);
  const [openModal, setOpenModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [category, setCategory] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [value, setValue] = useState({});
  const open = Boolean(anchorEl);
  const [textFieldValues, setTextFieldValues] = useState({
    label: "",
  });
  const [openCriteria, setOpenCriteria] = useState(false);
  const [openRevenue, setOpenRevenue] = useState(false);
  const [openCritical, setOpenCritical] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const [openConnect, setOpenConnect] = useState(false);
  const [btnDisable, setBtnDisable] = useState(true);

  const selectedId = localStorage.getItem("savedId");
  const {
    getSearch,
    getChildCategory,
    searchType,
    userProfiledata,
    SingleVendor,
  } = useSelector((state) => state.promptdata);
  const { selectedTechstack } = useSelector((state) => state.techStackData);
  const { vendorInquiry, saveInquiry } = useSelector((state) => state.rfiData);
  const {
    revenueList,
    criteriaList,
    topvendor,
    vendorError,
    vendorErrorMessage,
    dashboardVendors,
    userConnect,
  } = useSelector((state) => state.dashboardData);

  const [requestStatus, setRequestStatus] = useState([]);
  const isSaved = getSearch?.some((item) => item.id === selectedId);
  useEffect(() => {
    if (selectedId) {
      dispatch(searchProfile());
    }
  }, [selectedId]);

  useEffect(() => {
    if (isSaved) {
      const data = getSearch.filter((dt) => selectedId === dt.id)[0];
      setSelectedSizes(data?.sizes || []);
      setSelectedItems(data?.integration || []);
      setCategory(data?.category);
      setCriteria(data?.criteria);
    }
  }, [getSearch]);

  const handleCheckboxChange = (e, item) => {
    if (e.target.checked) {
      setSelectedItems((prevState) => [...prevState, item?.vendor_id]);
    } else {
      setSelectedItems((prevState) =>
        prevState?.filter((id) => id !== item?.vendor_id)
      );
    }
  };

  useEffect(() => {
    const clickedItems1 = selectedTechstack?.filter(
      (obj) => obj.is_critical === true
    );
    setClickedItems(clickedItems1);
  }, [selectedTechstack]);

  const getOnevendor = async (vendor, i) => {
    dispatch(getSingleTargetVendor(vendor.vendor_id));
    setActiveIndustry(i);
    setActiveTab(-1);
    setIsVisible(false);
    setBtnDisable(false);
    dispatch(getmarketAverage());
    dispatch(getReviewSummary(vendor.vendor_id));
    dispatch(getUserConnectList(vendor.vendor_id, getChildCategory));
  };

  const getTarget = async (vendor, i) => {
    dispatch(getSingleTargetVendor(vendor.vendor_id));
    setActiveTab(i);
    setActiveIndustry(-1);
    setIsVisible(false);
    setBtnDisable(false);
    dispatch(getmarketAverage());
    dispatch(getReviewSummary(vendor.vendor_id));
    dispatch(getUserConnectList(vendor.vendor_id, getChildCategory));
  };

  useEffect(() => {
    dispatch(getRevenueBucket());
    dispatch(getCriteriaList());
    dispatch(getPostedTechStack());
  }, []);

  useEffect(() => {
    dispatch(setActiveVendor(actives));
  }, [actives, dispatch]);

  useEffect(() => {
    if (vendorError) {
      toast(vendorErrorMessage);
    }
  }, [vendorError, vendorErrorMessage]);

  const handleTopVendor = (key, value, index) => {
    dispatch(setActiveVendor(index));
    setActives(index);
  };

  let topVendorKeys;
  let initialTopVendorKey;
  let initialTopVendorValue;
  if (dashboardVendors && typeof dashboardVendors === "object") {
    topVendorKeys = Object.keys(dashboardVendors);
    if (topVendorKeys.length > 0) {
      const initialTopVendorKey = topVendorKeys[0];
      initialTopVendorValue = dashboardVendors[initialTopVendorKey];
    }
  }
  const handleSelection = () => {
    setCurrentPage(1);
    const updatedCriteria = { ...criteria };
    criteriaList.forEach((key) => {
      if (!(key in updatedCriteria)) {
        updatedCriteria[key] = "good";
      }
    });
    const payload = {
      sizes: selectedSizes,
      category: getChildCategory,
      criteria: updatedCriteria || criteria,
      integration: selectedItems,
    };
    setSelectedCritera(updatedCriteria || criteria);
    const paramsString = new URLSearchParams(payload).toString();
    const currentURL = window.location.href;
    const newURL = `${currentURL}?${paramsString}`;
    window.history.replaceState(null, null, newURL);
    dispatch(targetvendors(payload));
  };

  const getUrlParams = () => {
    const params = new URLSearchParams(window.location.search);
    const paramsObject = {};
    for (const [key, value] of params) {
      paramsObject[key] = value;
    }

    return paramsObject;
  };
  window.addEventListener("load", () => {
    const urlParams = getUrlParams();
    setCurrentPage(urlParams.currentPage || 1);
    setSelectedSizes(urlParams.sizes || []);
    handleSelection();
  });

  const handleReset = () => {
    setCriteria([]);
    setSelectedItems([]);
    setSliderValues();
    setValue({});
    const dynamicCriteria = {};
    criteriaList.forEach((criteriaItem) => {
      dynamicCriteria[criteriaItem] = "good";
    });
    if (criteriaList.length > 0) {
      dispatch(
        targetvendors({
          sizes: ["large", "small", "medium"],
          category: getChildCategory,
          criteria: dynamicCriteria,
          integration: [],
        })
      );
    }
  };

  const handleCreiteriaValues = (criteria) => {
    let updated = {};
    Object.keys(criteria).forEach((obj) => {
      switch (criteria[obj]) {
      case "better":
        updated = { ...updated, [obj]: 49 };

        return;

      case "best":
        updated = { ...updated, [obj]: 99 };

        return;

      default:
        updated = { ...updated, [obj]: 0 };
      }
    });
    setValue(updated);
  };
  const dynamicCriteria = {};
  criteriaList.forEach((criteriaItem) => {
    dynamicCriteria[criteriaItem] = "good";
  });

  useEffect(() => {
    if (isSaved) {
      const data = getSearch.filter((dt) => selectedId === dt.id)[0];
      const updatedCriteria = { ...data?.criteria };
      criteriaList.forEach((key) => {
        if (!(key in updatedCriteria)) {
          updatedCriteria[key] = "good";
        }
      });
      dispatch(
        targetvendors({
          sizes: data?.sizes,
          category: data?.category,
          criteria: updatedCriteria,
          integration: data?.integration,
        })
      );
      handleCreiteriaValues(updatedCriteria);
    } else if (criteriaList.length > 0 && !location?.state?.selection) {
      setSelectedSizes(["small", "medium", "large"]);
      dispatch(
        targetvendors({
          sizes: ["large", "small", "medium"],
          category: getChildCategory,
          criteria: dynamicCriteria,
          integration: selectedItems,
        })
      );
    } else if (location?.state?.selection && criteriaList.length > 0) {
      setActives(location?.state?.activeIndustryOverview);
      const data = location?.state?.selection;
      const updatedCriteria = { ...data?.criteria };
      criteriaList.forEach((key) => {
        if (!(key in updatedCriteria)) {
          updatedCriteria[key] = "good";
        }
      });
      handleCreiteriaValues(updatedCriteria);
      dispatch(
        targetvendors({
          sizes: data?.revenue,
          category: getChildCategory,
          criteria: data?.criteria || dynamicCriteria,
          integration: data?.critical,
        })
      );
      setSelectedSizes(data?.revenue || []);
      setSelectedItems(data?.critical || []);
      setCategory(data?.category);
      setCriteria(data?.criteria);
    }
    handleTopVendor(initialTopVendorKey, initialTopVendorValue, location?.state?.activeIndustryOverview || 0);
  }, [criteriaList]);

  const handeSavedSearch = () => {
    dispatch(
      setSearchProfile({
        label: textFieldValues.label,
        sizes: selectedSizes,
        category: getChildCategory,
        criteria,
        integration: selectedItems,
        action: "add",
      })
    );
    handleCloseModal();
  };

  const handleOpenModal = () => {
    if (isSaved) {
      const data = getSearch.filter((dt) => selectedId === dt.id)[0];
      dispatch(
        setSearchProfile({
          label: data?.label,
          sizes: selectedSizes,
          category: data?.category,
          criteria,
          integration: selectedItems,
          action: "update",
          id: selectedId,
        })
      );
    } else {
      setOpenModal(true);
    }
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    e.preventDefault();
    setAnchorEl(null);
  };

  const handleRevenue = (e, sizeKey) => {
    if (e.target.checked) {
      setSelectedSizes((prevSelectedSizes) => [...prevSelectedSizes, sizeKey]);
    } else {
      setSelectedSizes((prevSelectedSizes) =>
        prevSelectedSizes.filter((size) => size !== sizeKey)
      );
    }
  };

  const handleScreenshot = () => {
    setAnchorEl(null);
    if (graphRef.current) {
      html2canvas(graphRef.current, {
        backgroundColor: "#191819",
        useCORS: true,
      }).then((canvas) => {
        const image = canvas.toDataURL("image/png");
        const pdf = new Jspdf("l", "mm", "a4");
        pdf.setFillColor("black");
        pdf.rect(
          0,
          0,
          pdf.internal.pageSize.getWidth(),
          pdf.internal.pageSize.getHeight(),
          "F"
        );
        pdf.addImage(addLogo, "PNG", 125, 0, 50, 15);
        pdf.setFontSize(18);
        pdf.setTextColor("#989898");
        pdf.text(
          `${userProfiledata.company_name}'s Personalized Dashboard`,
          pdf.internal.pageSize.getWidth() / 2,
          20,
          { align: "center" }
        );
        const category = isSaved ? category : getChildCategory;
        const space = 14;
        const userProfileTextY = 30;
        pdf.addImage(image, "PNG", space, userProfileTextY, 270, 150);
        pdf.setFontSize(12);
        pdf.setTextColor("#989898");
        const date = new Date();
        let localDate = "N/A";
        if (date && !isNaN(date)) {
          const options = {
            timeZone: "America/New_York",
            year: "numeric",
            month: "long",
            day: "2-digit",
          };
          localDate = date.toLocaleString("en-US", options);
        }
        pdf.text(
          `Created by ${userProfiledata.first_name} on ${localDate}`,
          280,
          userProfileTextY + 170,
          {
            align: "right",
          }
        );
        pdf.save("Dashboard download.pdf");
      });
    }
  };

  const handleJpeg = () => {
    setAnchorEl(null);
    if (graphRef.current) {
      html2canvas(graphRef.current, {
        backgroundColor: "black",
      }).then((canvas) => {
        const imageCanvas = document.createElement("canvas");
        const imageContext = imageCanvas.getContext("2d");
        imageCanvas.width = canvas.width;
        imageCanvas.height = canvas.height;
        imageContext.drawImage(canvas, 0, 0);
        // Add text to the new canvas
        const category = isSaved ? category : getChildCategory;
        imageContext.font = "18px Arial";
        imageContext.fillStyle = "#989898"; // Pink color
        imageContext.textAlign = "center";
        // Adjust the Y position based on your canvas height
        const textY = canvas.height - 10;
        const date = new Date();
        let localDate = "N/A";
        if (date && !isNaN(date)) {
          const options = {
            timeZone: "America/New_York",
            year: "numeric",
            month: "long",
            day: "2-digit",
          };
          localDate = date.toLocaleString("en-US", options);
        }
        imageContext.fillText(
          `Created by ${userProfiledata.first_name} on ${localDate}`,
          canvas.width / 2,
          textY
        );
        const imageWithText = imageCanvas.toDataURL("image/jpeg");
        const link = document.createElement("a");
        link.href = imageWithText;
        link.download = "Customized Vendor Ranking.jpeg";
        link.click();
      });
    }
  };
  const marks = [{ value: 0 }, { value: 49 }, { value: 99 }];
  const handleSliderChange = (item, value) => {
    const breakpoint1 = 33;
    const breakpoint2 = 66;
    let sliderValue;
    if (value <= breakpoint1) {
      sliderValue = "good";
    } else if (value <= breakpoint2) {
      sliderValue = "better";
    } else {
      sliderValue = "best";
    }
    setSliderValues((prevSliderValues) => {
      const updatedValues = Array.isArray(prevSliderValues)
        ? [...prevSliderValues]
        : [];
      const index = criteriaList.indexOf(item);
      if (index === -1 || index >= updatedValues.length) {
        updatedValues.push(sliderValue);
      } else {
        updatedValues[index] = sliderValue;
      }
      criteriaList.forEach((key) => {
        const keyIndex = criteriaList.indexOf(key);
        if (keyIndex < updatedValues.length && key !== item) {
          updatedValues[keyIndex] = "good";
        }
      });

      return updatedValues;
    });
    setCriteria((prevCriteria) => {
      const updatedCriteria = { ...prevCriteria };
      updatedCriteria[item] = sliderValue;
      criteriaList.forEach((key) => {
        if (key !== item && !(key in updatedCriteria)) {
          updatedCriteria[key] = "good";
        }
      });

      return updatedCriteria;
    });
    setValue((prevValue) => ({
      ...prevValue,
      [item]: value,
    }));
  };

  const handleTextFieldChange = (fieldName, value) => {
    setTextFieldValues((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleBackToSearch = () => {
    if (searchType.search_pref === "CHAT") {
      navigate("/chat_search");
    } else {
      navigate("/decision_tree");
    }
  };

  const itemsPerPage = 10;
  const totalPages = Math.ceil(
    dashboardVendors?.vendors?.length / itemsPerPage
  );
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedVendors = dashboardVendors?.vendors?.slice(
    startIndex,
    endIndex
  );
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const customOrder = ["top_revenue", "G2", "Gartner", "breakthrough"];

  const handleAddToCompare = () => {
    setShowCheckboxes(true);
  };

  const handleCompare = () => {
    if (selectedCheckboxes.length >= 2) {
      navigate("/vendor", {
        state: {
          selectedCheckboxes,
          selectedSizes,
          selectedItems,
          selectedCriteria,
          actives,
        },
      });
    } else {
      toast("Minimum two vendors are required for comparision.");
    }
  };

  const handleCompareCancel = () => {
    setShowCheckboxes(false);
    setSelectedCheckboxes("");
  };

  const handleCompareCheckbox = (vendor) => {
    const vendorId = vendor.vendor_id;
    if (selectedCheckboxes.includes(vendorId)) {
      setSelectedCheckboxes((prevSelected) =>
        prevSelected.filter((item) => item !== vendorId)
      );
    } else if (selectedCheckboxes.length < 3) {
      setSelectedCheckboxes((prevSelected) => [...prevSelected, vendorId]);
    } else {
      toast("Maximum limit reached. Cannot select more vendors.");
    }
  };

  const handleUserConnect = () => {
    if (userConnect.length === 0) {
      toast("Currently, no user exists.");
    } else {
      setOpenConnect(true);
    }
  };

  const handleCloseConnect = () => setOpenConnect(false);

  useEffect(() => {
    if(userConnect) {
      setRequestStatus(userConnect.map(() => false));
    }
  }, [userConnect]);

  const handleCallback = (data, index) => {
    if(typeof data === 'string'){
      const newstate = requestStatus.map((value, index1) => index1 === index ? true : value);
      setRequestStatus(newstate);
      // toast("Your request to connect has been sent!");
    } else {
      // toast(data?.response?.data?.detail);
    }
  };

  const handleConnectRequest = (item, index) => {
    const data = {
      peer_user: item?.user_id,
      vendor_id: SingleVendor?.vendor_id,
      vendor_category: getChildCategory,
    };
    dispatch(makeConnectRequest(data, handleCallback, index));
  };

  useEffect(() => {
    dispatch(getVendorInquiries());
  }, [saveInquiry]);

  const handleAddToRfi = async () => {
    const existingInquiry = vendorInquiry.find(
      (inquiry) => inquiry.vendor_category === getChildCategory
    );
    let newInquiryData;
    if (existingInquiry) {
      const updatedData = {
        saved_step: "company_review",
        vendor_category: getChildCategory,
        vendor_list: [...existingInquiry.vendor_list, SingleVendor.vendor_id],
      };
      dispatch(saveVendorInquiry(updatedData));
    } else {
      newInquiryData = {
        saved_step: "company_review",
        vendor_category: getChildCategory,
        vendor_list: [SingleVendor.vendor_id],
      };
      dispatch(saveVendorInquiry(newInquiryData));
    }
  };
  const isdisable = btnDisable;
  const styles = {
    color: isdisable && "#E2007B",
    opacity: isdisable && "0.5",
  };

  return (
    <Box
      ref={(ref) => (graphRef.current = ref)}
      width={"100%"}
      height={"1000px"}
      display="flex"
      justifyContent="center"
    >
      <Stack sx={{ width: "90%", height: "100%" }}>
        {vendorError ? <ToastContainer /> : ""}
        <Box className="dashboardRow_topHeader">
          <Stack sx={{ display: "flex", flexDirection: "row" }}>
            <Tooltip
              title="Back To Search"
              arrow
              placement="bottom"
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "black",
                    color: "#e2007b",
                    padding: "10px",
                    lineHeight: "24px",
                    border: "0.5px solid #e2007b",
                    "& .MuiTooltip-arrow": {
                      color: "#e2007b",
                    },
                    typography: {
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontWeight: "400px",
                      lineHeight: "18px",
                    },
                  },
                },
              }}
            >
              <Button
                onClick={handleBackToSearch}
                className="backToSearch_btn"
              >
                <KeyboardArrowLeftIcon sx={{ color: "#AE0770" }} />
              </Button>
            </Tooltip>
          </Stack>
          <Stack className="selectionCategory">
            <Typography className="dashboardRow_categoryName">
              {isSaved ? category : getChildCategory}
            </Typography>
          </Stack>
          <Box className="btnGroup">
            <Button className="exportRow_btn" onClick={(e) => handleClick(e)}>
              <IosShareIcon sx={{ color: "#e2007b" }} />
              <KeyboardArrowDownIcon
                sx={{ color: "#e2007b", height: "18px", width: "18px" }}
              />
            </Button>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  width: "133px",
                  height: "100px",
                  borderRadius: "8px",
                  background: "#000000",
                  padding: "5px",
                  color: "white",
                  border: "1px solid #383838",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 150,
                    height: 150,
                    ml: -0.5,
                    mr: 1,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem className="ExportMenu" onClick={() => handleJpeg()}>
                JPEG
              </MenuItem>
              <MenuItem
                className="ExportMenu"
                onClick={() => handleScreenshot()}
              >
                PDF
              </MenuItem>
            </Menu>
            <Button
              onClick={() => handleOpenModal()}
              className="saveSearch_btn"
            >
              <SaveOutlinedIcon sx={{ color: "white" }} />
            </Button>
          </Box>
          <Box
            className="dashboardRow_headerBtnGrp"
            sx={{ width: showCheckboxes ? "660px" : "571px" }}
          >
            {showCheckboxes ? (
              <>
                <Button
                  className="compareCancel_btn"
                  onClick={handleCompareCancel}
                >
                  Cancel
                </Button>
                <Button className="compareNow_btn" onClick={handleCompare}>
                  Compare now
                </Button>
              </>
            ) : (
              <Button className="Addcompare_btn" onClick={handleAddToCompare}>
                Add To Compare
              </Button>
            )}
            <Button className="connectBtnDasboard"
              onClick={handleUserConnect}
              style={styles}
              disabled={isdisable}>
              <Typography className="connectbtn_dashboard">
              Connect with User
              </Typography>
              <InsertLinkIcon className="connectIcon_dashboard" />
            </Button>
            <Button
              className="addToRfi_btn"
              onClick={handleAddToRfi}
              disabled={isdisable}
              style={styles}
            >
               Inquire with Vendor
            </Button>
          </Box>
        </Box>
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box className="saveModal">
            <Box className="saveSearch_modalBox">
              <Box
                sx={{
                  marginTop: "24px",
                  marginBottom: "24px",
                  marginLeft: "40%",
                }}
              >
                <Typography className="saveSearch_modalTypography">
                  Save Search
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "40%",
                gap: "13%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box className="field">
                <CustomTextField
                  label="Label"
                  width="360px"
                  placeholder="Label"
                  value={textFieldValues.label}
                  onChange={(e) =>
                    handleTextFieldChange("label", e.target.value)
                  }
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                onClick={() => handeSavedSearch()}
                className="save_button"
                sx={{
                  backgroundColor: "#e2007b",

                  color: "white",
                  width: "161px",
                }}
              >
                <Typography className="btn_typographys" sx={{ color: "white" }}>
                  Save
                </Typography>
              </Button>
            </Box>
          </Box>
        </Modal>
        <div>
          <Modal
            open={openConnect}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="connectModal">
              <Box className="connect_box">
                <Stack className="connectBoxHeading">
                  <Typography className="connectHeadingTypography">
                    Current users for {SingleVendor.vendor_name}
                  </Typography>
                  <img src={SingleVendor.logo} className="vendorConnectLogo" />
                  <Button
                    className="closeConnectModal"
                    onClick={() => handleCloseConnect()}
                  >
                    <CloseIcon sx={{ color: "white" }} />
                  </Button>
                </Stack>
              </Box>
              <Box className="connectVendor">
                <Box className="userBox">
                  <Carousel
                    draggable
                    additionalTransfrom={0}
                    autoPlaySpeed={3000}
                    centerMode={false}
                    className="userCarousel"
                    customTransition="container-with-dots"
                    focusOnSelect
                    infinite
                    keyBoardControl
                    minimumTouchDrag={80}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={{
                      desktop: {
                        breakpoint: {
                          max: 3000,
                          min: 1024,
                        },
                        items: 2,
                        partialVisibilityGutter: 40,
                      },
                      mobile: {
                        breakpoint: {
                          max: 464,
                          min: 0,
                        },
                        items: 2,
                        partialVisibilityGutter: 30,
                      },
                      tablet: {
                        breakpoint: {
                          max: 1024,
                          min: 464,
                        },
                        items: 2,
                        partialVisibilityGutter: 30,
                      },
                    }}
                    showDots
                    slidesToSlide={2}
                    arrows={false}
                    swipeable
                    sx={{
                      "& .react-multi-carousel-item": {
                        display: "flex",
                        alignItems: "center",
                      },
                      "& .react-multi-carousel-list": {
                        width: "100%",
                        transition: "transform 0.5s ease-in-out !important",
                      },
                      "& .react-multi-carousel-track": {
                        height: "90%",
                      },
                    }}
                  >
                    {userConnect.map((item, index) => (
                      <Stack className="userRequest" key={index}>
                        <Stack className="connectBrandIcon">{item?.logo}</Stack>
                        <Stack className="userDetailBox">
                          <Stack className="userDetailKey">
                            <Typography className="keyNameTypography">
                              NAME
                            </Typography>
                          </Stack>
                          <Stack className="userDetailValue">
                            {item?.display_name}
                          </Stack>
                        </Stack>
                        <Stack className="userDetailBox">
                          <Stack className="userDetailKey">
                            <Typography className="keyNameTypography">
                              Function
                            </Typography>
                          </Stack>
                          <Stack className="userDetailValue">
                            {item?.job_function}
                          </Stack>
                        </Stack>
                        <Stack className="userDetailBox">
                          <Stack className="userDetailKey">
                            <Typography className="keyNameTypography">
                              Title
                            </Typography>
                          </Stack>
                          <Stack className="userDetailValue">
                            {item?.title}
                          </Stack>
                        </Stack>
                        <Stack className="userDetailBox">
                          <Stack className="userDetailKey">
                            <Typography className="keyNameTypography">
                              Company Rev.
                            </Typography>
                          </Stack>
                          <Stack className="userDetailValue">
                            {item?.company?.revenue}
                          </Stack>
                        </Stack>
                        <Stack className="userDetailBox">
                          <Stack className="userDetailKey">
                            <Typography className="keyNameTypography">
                              # of employees
                            </Typography>
                          </Stack>
                          <Stack className="userDetailValue">
                            {item?.company?.employees}
                          </Stack>
                        </Stack>
                        <Stack className="userDetailBox">
                          <Stack className="userDetailKey">
                            <Typography className="keyNameTypography">
                              Industry
                            </Typography>
                          </Stack>
                          <Stack className="userDetailValue">
                            {item?.industry}
                          </Stack>
                        </Stack>
                        <Stack className="userDescriptionBox">
                          <Stack className="userDetailKey">
                            <Typography className="keyNameTypography">
                              Description
                            </Typography>
                          </Stack>
                          <Stack className="userDetailValue">
                            {item?.company?.description}
                          </Stack>
                        </Stack>
                        <Button
                          className={
                            requestStatus[index]
                              ? "requestSent_btn"
                              : "requestConnect_btn"
                          }
                          onClick={() => handleConnectRequest(item, index)}
                        >
                          {requestStatus[index] ? (
                            <Typography className="connectTypography">
                              Request Sent
                            </Typography>
                          ) : (
                            <Typography className="connectTypography">
                              Request to connect
                            </Typography>
                          )}
                        </Button>
                      </Stack>
                    ))}
                  </Carousel>
                </Box>
              </Box>
            </Box>
          </Modal>
        </div>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
            height: "90%",
            gap: "5%",
          }}
        >
          <Box sx={{ width: "20%", height: "100%" }}>
            <Box
              className={
                openRevenue || openCriteria || openCritical
                  ? "selectionCriteria_sideBarOpen"
                  : "selectionCriteria_sideBar"
              }
            >
              <Box
                className={
                  openRevenue ? "companyRevenue_divOpen" : "companyRevenue_div"
                }
              >
                <Typography className="companyRevenue_Typography">
                  Vendor Rev
                </Typography>
                {openRevenue ? (
                  <KeyboardArrowUpIcon
                    sx={{ color: "rgba(104, 104, 104, 1)" }}
                    onClick={() => setOpenRevenue(false)}
                  />
                ) : (
                  <KeyboardArrowDownIcon
                    sx={{ color: "rgba(104, 104, 104, 1)" }}
                    onClick={() => {
                      setOpenCritical(false);
                      setOpenRevenue(true);
                      setOpenCriteria(false);
                    }}
                  />
                )}
              </Box>
              {openRevenue ? (
                <Box className="selectionCriteria_openSubmenu">
                  {Object.keys(revenueList).map((sizeKey) => {
                    const { label } = revenueList[sizeKey];
                    const isSelected = selectedSizes?.includes(sizeKey);

                    return (
                      <Stack
                        key={sizeKey}
                        className={isSelected ? "active_checkbox" : ""}
                        sx={{
                          width: "100%",
                          height: "32px",
                          padding: "5px, 49px, 5px, 5px",
                          borderRadius: "4px",
                        }}
                      >
                        <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            height: "32px",
                            marginLeft: "5px",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Checkbox
                            className="customCheckbox"
                            checked={selectedSizes?.includes(sizeKey)}
                            onChange={(e) => handleRevenue(e, sizeKey)}
                          />
                          <Typography
                            sx={{
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "21px",
                              letterSpacing: "0em",
                              textAlign: "left",
                            }}
                          >
                            {label}
                          </Typography>
                        </Stack>
                      </Stack>
                    );
                  })}
                </Box>
              ) : (
                ""
              )}
              <Box
                className={
                  openCritical ? "companyRevenue_divOpen" : "companyRevenue_div"
                }
                sx={{
                  borderTop: "1px solid #191819",
                }}
              >
                <Typography className="companyRevenue_Typography">
                  Critical Integration
                </Typography>
                {openCritical ? (
                  <KeyboardArrowUpIcon
                    sx={{ color: "rgba(104, 104, 104, 1)" }}
                    onClick={() => setOpenCritical(false)}
                  />
                ) : (
                  <KeyboardArrowDownIcon
                    sx={{ color: "rgba(104, 104, 104, 1)" }}
                    onClick={() => {
                      setOpenCritical(true);
                      setOpenRevenue(false);
                      setOpenCriteria(false);
                    }}
                  />
                )}
              </Box>
              {openCritical ? (
                <Box className="selectionCriteria_openSubmenu">
                  {clickedItems.map((item, index) => {
                    return (
                      <Stack
                        className={
                          selectedItems?.includes(item?.vendor_id)
                            ? "active_checkbox"
                            : ""
                        }
                        key={index}
                        sx={{
                          width: "100%",
                          height: "32px",
                          padding: "5px, 49px, 5px, 5px",
                          borderRadius: "4px",
                        }}
                      >
                        <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            height: "32px",
                            marginLeft: "5px",
                            gap: "10px",
                          }}
                        >
                          <Checkbox
                            className="customCheckbox"
                            checked={selectedItems?.includes(item?.vendor_id)}
                            onChange={(e) => handleCheckboxChange(e, item)}
                          />
                          <Typography
                            sx={{
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "21px",
                              letterSpacing: "0em",
                              textAlign: "left",
                            }}
                          >
                            {item?.name}
                          </Typography>
                        </Stack>
                      </Stack>
                    );
                  })}
                </Box>
              ) : (
                ""
              )}
              <Box
                className={
                  openCritical || openRevenue
                    ? "sideBar_SelectionOpen"
                    : openCriteria
                      ? "sideBar_criteria"
                      : "sideBar_CriteriaOpen"
                }
              >
                {criteriaList?.slice(0, 9).map((item, i) => (
                  <Box
                    key={i}
                    sx={{
                      width: "96%",
                      height: "9%",
                      marginBottom: "5%",
                    }}
                  >
                    <Typography className="CriteraList_ItemName">
                      {item}
                    </Typography>
                    <PrettoSlider
                      aria-label="pretto slider"
                      value={value[item] || 0}
                      step={49}
                      min={0}
                      max={99}
                      marks={marks}
                      onChange={(event, value) =>
                        handleSliderChange(item, value)
                      }
                    />
                    <Box
                      color="#ffffff"
                      display="flex"
                      justifyContent="space-between"
                      width="98%"
                      height="18px"
                    >
                      <Typography className="Criteria_sliderValue">
                        Good
                      </Typography>
                      <Typography className="Criteria_sliderValue">
                        Better
                      </Typography>
                      <Typography className="Criteria_sliderValue">
                        Best
                      </Typography>
                    </Box>
                  </Box>
                ))}
                {openCriteria ? (
                  <Box className="Criteria_openSubmenu">
                    <Box sx={{ height: "95%", width: "96%" }}>
                      {criteriaList?.slice(9, 10).map((item, i) => (
                        <Box
                          key={i}
                          sx={{
                            width: "100%",
                            marginBottom: "5%",
                          }}
                        >
                          <Typography className="CriteraList_ItemName">
                            {item}
                          </Typography>
                          <PrettoSlider
                            aria-label="pretto slider"
                            value={value[item] || 0}
                            step={49}
                            min={0}
                            max={99}
                            marks={marks}
                            onChange={(event, value) =>
                              handleSliderChange(item, value)
                            }
                          />
                          <Box
                            color="#ffffff"
                            display="flex"
                            justifyContent="space-between"
                            width="98%"
                            height="18px"
                          >
                            <Typography className="Criteria_sliderValue">
                              Good
                            </Typography>
                            <Typography className="Criteria_sliderValue">
                              Better
                            </Typography>
                            <Typography className="Criteria_sliderValue">
                              Best
                            </Typography>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                ) : (
                  ""
                )}
                {openCriteria ? (
                  <KeyboardArrowUpIcon
                    sx={{ color: "rgba(104, 104, 104, 1)" }}
                    onClick={() => setOpenCriteria(false)}
                  />
                ) : (
                  <KeyboardArrowDownIcon
                    sx={{ color: "rgba(104, 104, 104, 1)" }}
                    onClick={() => {
                      setOpenCritical(false);
                      setOpenRevenue(false);
                      setOpenCriteria(true);
                    }}
                  />
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  height: "6%",
                  gap: "10px",
                  justifyContent: "space-betweeen",
                  alignItems: "end",
                }}
              >
                <Button
                  className="searchResetBtn"
                  sx={{
                    width: "12%",
                    border: "1px solid #A1A1A1",
                  }}
                  onClick={(e) => handleReset(e)}
                >
                  <RestartAltIcon
                    sx={{ color: "#A1A1A1", height: "118px", width: "18px" }}
                  />
                </Button>
                <Button
                  className="searchResetBtn"
                  sx={{ width: "85%" }}
                  onClick={(e) => handleSelection(e)}
                >
                  <Typography
                    className="search_btnTypography"
                    sx={{ color: "#e2007b" }}
                  >
                    Generate Recommendations
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
          <Box className="vendor_list_card">
            <Typography className="vendorList_Heading">
              Personalized Recommendation
            </Typography>
            <Stack className="vendorList">
              <Stack sx={{ width: "100%" }}>
                {displayedVendors?.length > 0 ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      color: "white",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Typography
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      Page {currentPage} of {totalPages}
                    </Typography>
                    <Stack
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        width: "64px",
                        justifyContent: "space-between",
                      }}
                    >
                      {totalPages > 1 && (
                        <Stack
                          className={
                            currentPage === 1
                              ? "vendor_paginationBtnDisabled"
                              : "vendor_paginationBtn"
                          }
                          onClick={() => handlePageChange(currentPage - 1)}
                        >
                          {"<"}
                        </Stack>
                      )}
                      {totalPages > 1 && (
                        <Stack
                          className={
                            currentPage === 3
                              ? "vendor_paginationBtnDisabled"
                              : "vendor_paginationBtn"
                          }
                          onClick={() => handlePageChange(currentPage + 1)}
                        >
                          {">"}
                        </Stack>
                      )}
                    </Stack>
                  </Box>
                ) : (
                  ""
                )}
              </Stack>
            </Stack>
            <Stack sx={{ height: "86.5%", marginTop: "2%", gap: "3%" }}>
              {displayedVendors?.length > 0 ? (
                displayedVendors.map((vendors, index) => {
                  const vendorNumber =
                    index + 1 + (currentPage - 1) * itemsPerPage;

                  return (
                    <Box
                      key={index}
                      className={
                        activeTab === vendorNumber
                          ? "active_dashboard"
                          : selectedCheckboxes.includes(vendors.vendor_id)
                            ? "compareVendor_box"
                            : "dashboardRow_box"
                      }
                      onClick={() => {
                        if (showCheckboxes) {
                          handleCompareCheckbox(vendors);
                        } else {
                          getTarget(vendors, vendorNumber);
                        }
                      }}
                      onChange={(e) => e.preventDefault()}
                    >
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "16px",
                        }}
                      >
                        {showCheckboxes ? (
                          <Checkbox
                            className="customCheckbox"
                            checked={selectedCheckboxes.includes(
                              vendors.vendor_id
                            )}
                          />
                        ) : (
                          ""
                        )}
                        <Typography className="vendorList_ItemNo">
                          {vendorNumber}.
                        </Typography>
                        <Typography className="vendorList_ItemName">
                          {vendors.vendor_name}{" "}
                        </Typography>
                      </Stack>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <img
                          className="vendorLise_ItemLogo"
                          src={
                            vendors.logo === ""
                              ? "/images/iconamoon_search-light.svg"
                              : vendors.logo
                          }
                          alt=""
                        />
                      </Box>
                    </Box>
                  );
                })
              ) : (
                <Typography
                  sx={{
                    textAlign: "center",
                    marginTop: "16px",
                    color: "white",
                  }}
                >
                  No match found.
                </Typography>
              )}
            </Stack>
          </Box>
          <Box className="vendor_list_card">
            <Typography className="vendorList_Heading">
              Industry Overview
            </Typography>
            <Stack className="vendorList">
              {topvendor !== undefined &&
                customOrder.map((key, index) => {
                  const value = topvendor[key];

                  return (
                    <Stack key={index}>
                      <Typography
                        className={
                          actives === index && value !== ""
                            ? "active_topVendor"
                            : "topVendor"
                        }
                        onClick={() => {
                          if (value !== "") {
                            handleTopVendor(key, value, index);
                          }
                        }}
                        sx={{
                          cursor: value !== "" ? "pointer" : "not-allowed",
                        }}
                      >
                        {key === "top_revenue"
                          ? "Revenue"
                          : key
                            .split(" ")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(" ")}
                        {key === "breakthrough" && (
                          <Tooltip
                            title="Pioneering enterprises leveraging cutting-edge
                           technologies to redefine conventional business norms and drive innovation."
                            arrow
                            placement="bottom"
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  width: "260px",
                                  height: "100px",
                                  bgcolor: "black",
                                  color: "#e2007b",
                                  padding: "10px",
                                  lineHeight: "24px",
                                  border: "0.5px solid #e2007b",
                                  "& .MuiTooltip-arrow": {
                                    color: "#e2007b",
                                  },
                                  typography: {
                                    fontFamily: "Poppins",
                                    fontSize: "12px",
                                    fontWeight: "400px",
                                    lineHeight: "18px",
                                  },
                                },
                              },
                            }}
                          >
                            <Typography className="newIcon">new</Typography>
                          </Tooltip>
                        )}
                      </Typography>
                    </Stack>
                  );
                })}
              ;
            </Stack>
            <Stack sx={{ height: "86.5%", marginTop: "2%", gap: "3%" }}>
              {topvendor !== undefined &&
                topvendor[customOrder[actives]]?.map((vendors, i) => (
                  <>
                    <Box
                      key={i}
                      className={
                        activeIndustry === i
                          ? "active_dashboard"
                          : selectedCheckboxes.includes(vendors.vendor_id)
                            ? "compareVendor_box"
                            : "dashboardRow_box"
                      }
                      onClick={() => {
                        if (showCheckboxes) {
                          handleCompareCheckbox(vendors);
                        } else {
                          getOnevendor(vendors, i);
                        }
                      }}
                      onChange={(e) => e.preventDefault()}
                    >
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "16px",
                        }}
                      >
                        {showCheckboxes ? (
                          <Checkbox
                            className="customCheckbox"
                            checked={selectedCheckboxes.includes(
                              vendors.vendor_id
                            )}
                          />
                        ) : (
                          ""
                        )}
                        <Typography className="vendorList_ItemNo">
                          {i + 1}.
                        </Typography>
                        <Typography className="vendorList_ItemName">
                          {vendors.vendor_name}{" "}
                        </Typography>
                      </Stack>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <img
                          className="vendorLise_ItemLogo"
                          src={
                            vendors.logo === ""
                              ? "/images/iconamoon_search-light.svg"
                              : vendors.logo
                          }
                          alt=""
                        />
                      </Box>
                    </Box>
                  </>
                ))}
            </Stack>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default DashboardRow;

DashboardRow.propTypes = {
  setIsVisible: PropTypes.any,
};
