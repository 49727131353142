import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import "./onboardingComplete.css";

const OnboardingComplete = () => {

  return (
    <>
      <Stack
        className="profile_page_container"
        style={{
          height: "100%",
        }}
      >
        <Stack className="header_wrapper">
          <Box marginLeft="36px">
            <img src="/images/logo.png" alt="" />
          </Box>
        </Stack>
        <Box className="onboardingCompleteBox">
          <Box className="">
            <Typography className="onboardingCompleteHeading">
            Your Onboarding is complete.
            </Typography>
            <Stack className="onboardingComplete_content">
              <Typography className="onboardingCompleteData">
              Kindly refresh your company profile or the tech stack periodically.              </Typography>
              <Typography className="onboardingCompleteData2">
              Welcome to Amp AI
              </Typography>
            </Stack>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "116px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "center",
            }}>
          </Box>
        </Box>
      </Stack>
    </>
  );
};

export default OnboardingComplete;
