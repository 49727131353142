import React from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

const CustomTextField = ({
  label,
  name,
  value,
  onChange,
  placeholder,
  error,
  helperText,
  readOnly,
  width,
  type,
  InputProps,
}) => {

  return (
    <div className="profileBox_rowField">
      <div style={{ width: width }}>
        <Typography className="title_field">{label}</Typography>
        <TextField
          className="textfieldata"
          placeholder={placeholder}
          value={value}
          name={name}
          onChange={onChange}
          error={Boolean(error)}
          helperText={helperText}
          readOnly={readOnly}
          type={type}
          InputProps={InputProps}
          sx={{
            "& input": {
              color: "#A1A1A1",
              border: "1px solid var(--stroke, #686868)",
              borderRadius: "8px",
              background: "#191819",
              padding: "9.6px",
            },
            "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
              fontSize: "14px",
            },
            "& .MuiFormHelperText-root": {
              marginLeft: "0px",
            },
            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #E2007B",
              borderRadius: "8px",
            },
          }}
        />
      </div>
      <div></div>
    </div>
  );
};

CustomTextField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  readOnly: PropTypes.bool,
  width: PropTypes.any,
  type: PropTypes.string,
  InputProps: PropTypes.object,
};

export default CustomTextField;
