import { Box, Button, Stack } from "@mui/material/node";
import React from "react";
import "./reviewSubmit.css";
import PropTypes from "prop-types";

export const ReviewFooterButton = ({ MoveNext, moveback, confirmSubmit, isdisable }) => {

  const styles = {
    backgroundColor: isdisable && "#E2007B",
    color: isdisable && "white",
    opacity: isdisable && "0.2",
  };

  return (
    <Stack>
      <Stack className="Footerbtn_wrapper">
        <Box>
          <Button className="back_btn" onClick={moveback}>
            Back
          </Button>
        </Box>
        <Box>
          <Button className="reviewConfirm" onClick={confirmSubmit}>
            Confirm
          </Button>
        </Box>
        <Box>
          <Button
            className="reviewSubmitBtn"
            onClick={MoveNext}
            disabled={isdisable}
            style={styles}
            type="submit"
          >
            Submit
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};

export default ReviewFooterButton;

ReviewFooterButton.propTypes = {
  MoveNext: PropTypes.any,
  isdisable: PropTypes.bool,
  moveback: PropTypes.any,
  confirmSubmit: PropTypes.any,
};
