import { Menu, MenuItem } from "@mui/material";
import { Box, Stack, Typography } from "@mui/material/node";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import MenuIcon from "../otherComponent/profileIcon";
import "./header.css";

const Header = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { vendorInquiry } = useSelector((state) => state.rfiData);
  const { userProfiledata } = useSelector((state) => state.promptdata);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const firstCharacter = userProfiledata.first_name
    ? userProfiledata.first_name[0]
    : "";
  const secondCharacter = userProfiledata.last_name
    ? userProfiledata.last_name[0]
    : "";

  const handleClose = (e, data) => {
    e.preventDefault();
    if (data !== "backdropClick") {
      navigate(`/${data}`);
    }
    setAnchorEl(null);
  };

  const handleLogout = () => {
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    localStorage.removeItem("persist:root");
    localStorage.removeItem("onboarding");
    navigate("/login");
  };

  const handleSubMenuClose = (e, menuItem) => {
    e.preventDefault();
    if (menuItem !== "backdropClick") {
      navigate(`/${menuItem}`);
    }
  };

  let totalCount = 0;
  vendorInquiry.forEach((obj) => {
    if (
      obj.hasOwnProperty("vendor_list") &&
      Array.isArray(obj.vendor_list) &&
      obj.saved_step !== "submitted"
    ) {
      totalCount += obj.vendor_list.length;
    }
  });

  return (
    <Stack className="header">
      <Stack className="header_wrapper">
        <Box>
          <img src="/images/logo.png" alt="" />
        </Box>
        <Box className="header_Container">
          {pathname === "/dashboard" && "/rfi_process" && totalCount > 0 && (
            <Box className="cartIcon">
              <Stack className="inquiryCount">{totalCount}</Stack>
              <img
                onClick={() => navigate("/rfi_process")}
                src="/images/iconBill.png"
                style={{ width: "26px", height: "26px" }}
              />
            </Box>
          )}
          {pathname !== "/onboarding" && (
            <MenuIcon
              handleClick={(e) => handleClick(e)}
              firstCharacter={firstCharacter}
              secondCharacter={secondCharacter}
            />
          )}
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              width: "210px",
              height: "295px",
              borderRadius: "8px",
              background: "#191819",
              color: "white",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 150,
                height: 150,
                ml: -0.5,
                mr: 1,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem
            className="header_MenuItems"
            onClick={(e) => handleClose(e, "profile")}
          >
            <Typography className="menuItem">User Profile</Typography>
          </MenuItem>
          <MenuItem className="header_MenuItemsCompany">
            <Stack className="companyMenu_InnerBox">
              <Typography className="companyTypography">My Company</Typography>
              <Box className="companyMenu_Box">
                <Stack className="company_menu">
                  <Typography
                    className="header_CompanyItems"
                    onClick={(e) => handleSubMenuClose(e, "company_profile")}
                  >
                    Profile
                  </Typography>
                  <Typography
                    className="header_CompanyItems"
                    onClick={(e) => handleSubMenuClose(e, "userInvite")}
                  >
                    Invite Users
                  </Typography>
                  <Typography
                    className="header_CompanyItems"
                    onClick={(e) => handleSubMenuClose(e, "tech_stack")}
                  >
                    {" "}
                    Tech Stack
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          </MenuItem>
          <MenuItem
            className="header_MenuItems"
            onClick={(e) => handleClose(e, "landing_page")}
          >
            <Typography className="menuItem">My Dashboard</Typography>
          </MenuItem>
          <MenuItem className="header_MenuItems" onClick={handleLogout}>
            <Typography className="menuItem">Logout</Typography>
          </MenuItem>
        </Menu>
      </Stack>
    </Stack>
  );
};

export default Header;
