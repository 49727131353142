import { Box, Button, Stack } from "@mui/material/node";
import React from "react";
import "./footerButtonGroup.css";
import PropTypes from "prop-types";

export const RfiFooterButton = ({ MoveNext, isdisable, moveback }) => {
  const styles = {
    color: isdisable && "#E2007B",
    opacity: isdisable && "0.2",
  };

  return (
    <Stack>
      <Stack className="Footerbtn_wrapper">
        <Box>
          <Button className="cancel_btn" onClick={moveback}>
            Back
          </Button>
        </Box>
        <Box>
          <Button
            className="rfiSave"
            onClick={MoveNext}
            disabled={isdisable}
            style={styles}
            type="submit"
          >
            Save & Next
            <img src="/images/material-symbols_arrow-right-alt-rounded.svg" />
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};

export default RfiFooterButton;

RfiFooterButton.propTypes = {
  MoveNext: PropTypes.any,
  isdisable: PropTypes.bool,
  moveback: PropTypes.any,
  techstack: PropTypes.any,
};
