import React, { useEffect, useState } from "react";
import { Box, Checkbox, Stack, Typography } from "@mui/material";
import Header from "../header/Header";
import "./subScription.css";
import PropTypes from "prop-types";
import SliderComponent from "../sliderComponent/sliderComponent";
import FooterButton from "../footerButton/FooterButton";
import { setuserProfile } from "../../redux/userslice";
import { TesterProfilInfo } from "../../util/constant";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useDispatch, useSelector } from "react-redux";

const TesterProfile = ({
  setValue,
  value,
  openSelectedStep,
  testerProfile,
}) => {
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);
  const { userProfiledata } = useSelector((state) => state.promptdata);
  const MoveNext = () => {
    openSelectedStep("onboardingComplete");
    const data = {
      ...userProfiledata,
      onboarding: "Complete",
    };
    localStorage.setItem("onboarding", "Complete");
    dispatch(setuserProfile(data));
  };
  const moveback = () => {
    openSelectedStep("techstack");
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    setValue(100);
  }, []);

  const isButtonDisabled = !isChecked;

  return (
    <>
      <Box className="main">
        <Header />
        <Stack className="testerProfile" id="bg-image">
          <SliderComponent setValue={setValue} value={value} />
          <Box className="testerProfile_wrapper">
            <Stack className="containerCard">
              <Stack className="headingContainer">
                <Typography className="testerHeadline">
                  Early Adopter{" "}
                </Typography>
                <Stack className="freeTag">Free</Stack>
              </Stack>
              <Stack className="detailBox">
                {TesterProfilInfo.map((item, index) => (
                  <Stack key={index} className="testerProfile_listItem">
                    <CheckCircleIcon className="tickIcon" />
                    <Typography className="infoTypography">{item}</Typography>
                  </Stack>
                ))}
              </Stack>
              <Stack className="selectBox">
                <Box className="selectContainer">
                  <Checkbox
                    className="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <Typography className="selectTypography">Select</Typography>
                </Box>
              </Stack>
            </Stack>
          </Box>
          <Stack width="963px" style={{ marginTop: "20px" }}>
            <FooterButton
              MoveNext={MoveNext}
              testerProfile={testerProfile}
              isdisable={isButtonDisabled}
              moveback={moveback}
            />
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default TesterProfile;

TesterProfile.propTypes = {
  value: PropTypes.any,
  setValue: PropTypes.any,
  openSelectedStep: PropTypes.function,
  testerProfile: PropTypes.bool,
};
