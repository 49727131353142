import {
  Box,
  Button,
  Checkbox,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Login, resetLogin } from "../../redux/applicationSlice";
import { useNavigate } from "react-router";
import { Oval } from "react-loader-spinner";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "./login.css";
import PropTypes from "prop-types";
import CustomTextField from "../otherComponent/customTextfield";

const LoginPage = ({ setOnBoarding }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function getCookie(cookieName) {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(cookieName + "=")) {
        return cookie.substring(cookieName.length + 1);
      }
    }

    return null;
  }

  const cookieName = "token";
  const cookieValue = getCookie(cookieName);

  const {
    isLoading,
    successLogin,
    successMessage,
    errorLogin,
    errorMessage,
    status,
  } = useSelector((state) => state.applicationData);

  const [userAcess, setUserAcess] = useState({
    login: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [isFocused, setIsFocused] = useState(false);
  const handleFocused = () => setIsFocused(true);

  const HandleChange = (e) => {
    const details = { ...userAcess, [e.target.name]: e.target.value };
    setUserAcess(details);
  };

  const HandleSubmit = (e) => {
    e.preventDefault();
    dispatch(Login(userAcess));
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      HandleSubmit(event);
    }
  };

  const HandleSignup = () => {
    navigate("/signup");
  };

  useEffect(() => {
    if (successLogin) {
      localStorage.setItem("onboarding", status.onboarding);
      setTimeout(() => {
        if (
          status.status === "verified" ||
          (status.onboarding !== "Complete" && status.status === "logged in")
        ) {
          setOnBoarding(true);
          navigate("/onboarding");
        } else {
          setOnBoarding(false);
          navigate("/landing_page");
        }
      }, 2000);
    }
    dispatch(resetLogin());
  }, [successLogin, status, successMessage, errorMessage, errorLogin]);
  const onboardingLs = localStorage.getItem("onboarding");
  useEffect(() => {
    if (cookieValue !== null) {
      if ((status.status === "logged in" && status.onboarding !== "Complete") || onboardingLs !== 'Complete'){
        navigate("/onboarding");
      } else {
        navigate("/profile");
      }
    }
  }, []);

  const inputStyle = {
    border: !isFocused ? "1px solid white" : "1px solid #E2007B",
    outline: "none",
  };

  return (
    <Stack className="login_page_container_wrapper">
      {isLoading ? (
        <Box className="login_page_container">
          <Oval
            height={80}
            width={100}
            color="#E75480"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#7a0d66"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </Box>
      ) : (
        <Box className="login_page_box">
          <Stack className="login_page_elements">
            <Stack className="login_page_element_container">
              <Stack className="logo_brand">
                <img src="/images/logo.png" />
              </Stack>
              <Stack className="login_header">
                <Typography className="welcome_textfield">
                  Welcome Back!
                </Typography>
                <Typography className="new_ampi_textField">
                  New to AMP AI ?
                  <span
                    style={{ color: "#E2007B", cursor: "pointer" }}
                    className="new_ampi_textField"
                    onClick={HandleSignup}
                  >
                    {" "}
                    Create an account
                  </span>
                </Typography>
              </Stack>
            </Stack>
            <Stack className="login-input_block">
              <Stack className="login_page_form">
                <Stack className="login_form_email">
                  <CustomTextField
                    label="Email"
                    name="login"
                    onChange={HandleChange}
                    placeholder="Enter your email"
                    height="44px"
                  />
                </Stack>
                <Stack
                  className="login_form_email"
                  style={{ marginTop: "24px" }}
                >
                  <Typography
                    className="login_textfield"
                    style={{ marginBottom: "7px" }}
                  >
                    {" "}
                    Password
                  </Typography>
                  <TextField
                    onKeyDown={handleKeyPress}
                    sx={{
                      backgroundColor: "#191819",
                      width: "496px",
                      border: "1px solid #686868",
                      borderRadius: "8px",
                      fontFamily: "Poppins",
                      fontWeight: 600,
                      "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                        color: "#E2007B",
                      },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          border: "0px solid #E2007B",
                          borderRadius: "8px",
                        },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#E2007B",
                      },
                      "& .MuiOutlinedInput-input": {
                        color: "white",
                        padding: "0 0 0 10px",
                        height: "41px",
                      },
                    }}
                    style={inputStyle}
                    onFocus={handleFocused}
                    placeholder="Password"
                    type={showPassword ? "text" : "password"}
                    onChange={HandleChange}
                    name="password"
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleTogglePasswordVisibility}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityIcon style={{ color: "grey" }} />
                            ) : (
                              <VisibilityOff style={{ color: "grey" }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
              </Stack>
              <Box className="login_form_remember">
                <Box className="checkbox_wrapper">
                  <Checkbox className="checkbox" style={{ color: "#E2007B" }} />
                  <Typography
                    marginLeft="10px"
                    marginTop="2px"
                    className="new_ampi_textField"
                  >
                    Remember me
                  </Typography>
                </Box>
                <Box display={"flex"}>
                  <Typography
                    className="forgotPassword_textfield"
                    onClick={() => navigate("/forgotPassword")}
                  >
                    Forgot password?
                  </Typography>
                </Box>
              </Box>
              <Stack className="login_button">
                <Button
                  className="login_textfield"
                  style={{ height: "44px" }}
                  onClick={(e) => HandleSubmit(e)}
                >
                  Continue
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      )}
    </Stack>
  );
};

export default LoginPage;

LoginPage.propTypes = {
  setOnBoarding: PropTypes.any,
};
