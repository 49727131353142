import { Box, Typography } from "@mui/material/node";
import React, { useState, useEffect } from "react";
import "./about.css";
import Stack from "@mui/material/node/Stack";
import BackgroundFrame from "../images/Frame 2.png";
import { useInView } from "react-intersection-observer";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const VendorSection = () => {
  const targetNumber = 15782;
  const duration = 3000;
  const midNumber = 1000;
  const [inViewRef, inView] = useInView();
  const [currentNumber, setCurrentNumber] = useState(0);

  useEffect(() => {
    if (inView) {
      const startCount = Date.now();
      const interval = setInterval(() => {
        const currentTime = Date.now();
        const progress = (currentTime - startCount) / duration;
        if (progress < 1) {
          const newNumber = Math.floor(progress * (targetNumber - midNumber)) + midNumber;
          setCurrentNumber(newNumber);
        } else {
          setCurrentNumber(targetNumber);
          clearInterval(interval);
        }
      }, 10);

      return () => clearInterval(interval);
    }
  }, [inView, targetNumber, duration, midNumber]);

  return (
    <Box
      ref={inViewRef}
      className="about"
      sx={{
        width: "100%",
        height: "600px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "65px",
        backgroundImage: `url(${BackgroundFrame})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Box className="noOfVendor">
        <Typography className="sectionHeading">Number of Vendors</Typography>
        <Typography className="vendorNumber">
          {currentNumber.toLocaleString()}
        </Typography>
      </Box>
      <Box className="vendorCards_container">
        <Box className="aboutCards">
          <Box className="aboutCard_headline">
            <Stack sx={{ display: "flex", flexDirection: "row" }}>
              <Typography className="highlightedData"> 04</Typography>
              <Typography className="dataDetail"> weeks</Typography>
            </Stack>
            <ArrowForwardIcon sx={{color: "#e2007b", width: "20px", height: "14pxx"}}/>
            <Stack sx={{ display: "flex", flexDirection: "row" }}>
              <Typography className="highlightedData"> 07</Typography>
              <Typography className="dataDetail"> mins</Typography>
            </Stack>
          </Box>
          <Box>
            <Typography className="dataDetail">
              Avg. time to identified right solution
            </Typography>
          </Box>
        </Box>
        <Box className="aboutCards">
          <Box sx={{ display: "flex", flexDirection: "row", gap: "80px" }}>
            <Typography className="highlightedData"> 15x</Typography>
          </Box>
          <Box>
            <Typography className="dataDetail">
              Average Rate of Return
            </Typography>
          </Box>
        </Box>
        <Box className="aboutCards">
          <Box sx={{ display: "flex", flexDirection: "row", gap: "80px" }}>
            <Typography className="highlightedData">Priceless!!</Typography>
          </Box>
          <Box>
            <Typography className="dataDetail">
              Avoiding buying wrong solution
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default VendorSection;
