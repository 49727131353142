import React, { useEffect } from "react";
import { Box, Typography, Stack } from "@mui/material/node";
import "./companyReview.css";
import { RFIslider } from "../slider/rfiSlider";
import RfiFooterButton from "../footer/footerButtonGroup";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";
import { Oval } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import {
  getVendorInquiries,
  saveVendorInquiry,
  removeVendorInquiry
} from "../../../redux/rfiSlice";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const CompanyReview = ({
  openSelectedStep,
  setSliderValue,
  sliderValue,
  selectedCategory,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { vendorInquiry } = useSelector((state) => state.rfiData);
  const { isLoading } = useSelector((state) => state.applicationData);
  const MoveNext = () => {
    openSelectedStep("techRequirement", selectedCategory);
  };

  useEffect(() => {
    if (vendorInquiry.length > 0) {
      vendorInquiry.map((obj) => {
        if (obj.vendor_list.length === 0) {
          const data = {
            vendor_category: obj.vendor_category,
          };
          dispatch(removeVendorInquiry(data));
        }
      });
    }
  }, [vendorInquiry]);

  const moveback = () => {
    if (vendorInquiry.length > 1) {
      openSelectedStep("category", selectedCategory);
    } else {
      navigate("/dashboard");
    }
  };

  const handleDeleteInquiry = (selectedItem, vendorId) => () => {
    const updatedVendorList = selectedItem?.vendor_list.filter(
      (id) => id !== vendorId
    );
    const updatedItem = {
      saved_step: "company_review",
      vendor_category: selectedItem.vendor_category,
      vendor_list: updatedVendorList,
    };

    dispatch(saveVendorInquiry(updatedItem)).then(() =>
      dispatch(getVendorInquiries())
    );
  };

  useEffect(() => {
    setSliderValue(0.7);
  }, []);

  return (
    <>
      <Box className="rfi">
        {isLoading ? (
          <Box className="login_page_container">
            <Oval
              height={80}
              width={100}
              color="#E75480"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#7a0d66"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </Box>
        ) : (
          <>
            <Box className="rfi_wrapper">
              <RFIslider
                sliderValue={sliderValue}
                setSliderValue={setSliderValue}
              />
              <Box className="companyReview_wrapper">
                <Box className="headline_container">
                  <Typography className="companyReview_typography">
                    Confirm the short-list of companies to send Inquiry
                  </Typography>
                </Box>
                {vendorInquiry?.map(
                  (item, index) =>
                    index === selectedCategory && (
                      <Box className="rfi_listContainer" key={index}>
                        {item?.vendor_list?.map((vendorId, i) => (
                          <Box className="rfi_companyCard" key={vendorId}>
                            <Stack className="rfiVendorList">
                              <Typography className="companyCard_itemNo">
                                {i + 1}.
                              </Typography>
                              <Typography className="companyCard_itemName">
                                {item?.vendor_info?.[vendorId]?.vendor_name}
                              </Typography>
                            </Stack>
                            <Box className="logoImageContainer">
                              <img
                                src={item?.vendor_info?.[vendorId]?.logo}
                                alt=""
                                className="logoImage"
                              />
                            </Box>
                            <Typography className="companyReview_description">
                              Company description
                            </Typography>
                            <DeleteOutlineOutlinedIcon
                              className="delIcon"
                              onClick={handleDeleteInquiry(item, vendorId)}
                            />
                          </Box>
                        ))}
                      </Box>
                    )
                )}
              </Box>
            </Box>
            <Stack sx={{ textAlign: "center", width: "70%" }}>
              <RfiFooterButton MoveNext={MoveNext} moveback={moveback} />
            </Stack>
          </>
        )}
      </Box>
    </>
  );
};

export default CompanyReview;

CompanyReview.propTypes = {
  setSliderValue: PropTypes.func,
  sliderValue: PropTypes.number,
  selectedCategory: PropTypes.number,
  openSelectedStep: PropTypes.number,
};
