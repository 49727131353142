import { createSlice } from "@reduxjs/toolkit";
import { api } from "../axiosinterceptor/axiosInterceptor";
import { startLoading, stopLoading } from "./applicationSlice";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";

const initialState = {
  vendorInquiry: [],
  saveInquiry: {},
  deleteInquiry: {},
  fileData: [],
  inquiryStats: {},
};

const rfiSlice = createSlice({
  name: "rfiData",
  initialState,
  reducers: {
    retrieveVendorInquiry(state, action) {
      state.vendorInquiry = action.payload;
    },
    setInquiry(state, action) {
      state.saveInquiry = action.payload;
    },
    removeInquiry(state, action) {
      state.deleteInquiry = action.payload;
    },
    fileUploaded(state, action) {
      state.fileData = action.payload;
    },
    vendorInquiryStats(state, action) {
      state.inquiryStats = action.payload;
    },
  },
});

export default rfiSlice.reducer;

export function getVendorInquiries() {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await api.get("/vendor_inquiries/");
      dispatch(rfiSlice.actions.retrieveVendorInquiry(response));
      dispatch(stopLoading());
    } catch (error) {
      toast(error);
    }
  };
}

export function saveVendorInquiry(data) {
  return async (dispatch) => {
    try {
      const response = await api.post("/save_vendor_inquiry/", data);
      dispatch(rfiSlice.actions.setInquiry(response));
    } catch (error) {
      toast(error);
    }
  };
}

export function removeVendorInquiry(data) {
  return async (dispatch) => {
    try {
      const response = await api.post("/remove_vendor_inquiry/", data);
      dispatch(rfiSlice.actions.removeInquiry(response));
    } catch (error) {
      toast(error);
    }
  };
}

export function UploadInquiryFile(category, file) {
  const formData = new FormData();
  formData.append("file", file);

  return async (dispatch) => {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    const encodedCategory = encodeURIComponent(category);
    try {
      const response = await api.post(
        `/upload_vendor_inquiry_file/${encodedCategory}/`,
        formData,
        {
          headers,
        }
      );
      dispatch(rfiSlice.actions.fileUploaded(response));
    } catch (error) {
      toast("error", error);
    }
  };
}

export function getInquiryfiles(category, file_name) {
  return async () => {
    const headersdata = {
      headers: {
        "Content-Type": "multipart/form-data",
        responseType: "arraybuffer",
      },
    };
    try {
      const response = await api.get(
        `/download_vendor_inquiry_file/${category}/${file_name}/`,
        headersdata
      );
      const blob = new Blob([response], { type: "application/pdf" });
      saveAs(blob, "test.pdf");
    } catch (error) {
      toast(error);
    }
  };
}

export function removeUploadedFile(category, file_name) {
  return async () => {
    try {
      await api.post(`/remove_vendor_inquiry_file/${category}/${file_name}/`);
    } catch (error) {
      toast(error);
    }
  };
}

export function getVendorInquiryStats() {
  return async (dispatch) => {
    try {
      const response = await api.get("/vendor_inquiry_stats/");
      dispatch(rfiSlice.actions.vendorInquiryStats(response));
    } catch (error) {
      toast(error);
    }
  };
}
