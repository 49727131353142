import { Box, Typography, Tooltip, Button } from "@mui/material/node";
import React from "react";
import "./cookies.css";
import WebsiteHeader from "../WebsiteHeader/WebsiteHeader";
import { useNavigate } from "react-router";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

const Privacy = () => {
  const navigate = useNavigate();

  return (
    <Box className="cookies_wrapper">
      <WebsiteHeader />
      <Box className="cookieContainer">
        <Box className="cookieHeadingBox">
          <Tooltip
            title="Back"
            arrow
            placement="bottom"
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "black",
                  color: "#e2007b",
                  padding: "10px",
                  lineHeight: "24px",
                  border: "0.5px solid #e2007b",
                  "& .MuiTooltip-arrow": {
                    color: "#e2007b",
                  },
                  typography: {
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    fontWeight: "400px",
                    lineHeight: "18px",
                  },
                },
              },
            }}
          >
            <Button onClick={() => navigate(-1)} className="backToWebsite">
              <KeyboardArrowLeftIcon sx={{ color: "#AE0770" }} />
            </Button>
          </Tooltip>
          <Typography className="cookieHeading">PRIVACY NOTICE</Typography>
          <Typography className="cookie_updated">
            Last updated March 04, 2024
          </Typography>
        </Box>
        <Box className="cookie_Info">
          <p className="cookieData">
            This privacy notice for Amp AI Inc (doing business as Amp AI) (
            {`"we," "us," or "our" `}), describes how and why we might collect,
            store, use, and/or share ({`"process" `}) your information when you
            use our services ({`"Services" `}), such as when you:
          </p>
          <ul style={{ color: "white", listStyle: "square" }}>
            <li className="cookiData">
              Visit our website at{" "}
              <a
                href="https://www.ampai.ai/"
                style={{ color: "#e2007b", cursor: "pointer" }}
                target="_blank"
                rel="noreferrer"
              >
                <u>http://www.ampai.ai</u>
              </a>
              , or any website of ours that links to this privacy notice
            </li>
            <li className="cookiData">
              Engage with us in other related ways, including any sales,
              marketing, or events
            </li>
          </ul>
          <p className="cookieData">
            <b>Questions or concerns?</b> Reading this privacy notice will help
            you understand your privacy rights and choices. If you do not agree
            with our policies and practices, please do not use our Services. If
            you still have any questions or concerns, please contact us at
            privacy@ampai.ai.
          </p>
          <h2 className="policy_questions">SUMMARY OF KEY POINTS</h2>
          <p className="cookieData">
            <b>
              This summary provides key points from our privacy notice, but you
              can find out more details about any of these topics by clicking
              the link following each key point or by using our{" "}
              <a
                href="#tableofContent"
                style={{ color: "#e2007b", cursor: "pointer" }}
              >
                table of contents
              </a>{" "}
              below to find the section you are looking for.
            </b>
          </p>
          <p className="cookieData">
            <b> What personal information do we process?</b> When you visit,
            use, or navigate our Services, we may process personal information
            depending on how you interact with us and the Services, the choices
            you make, and the products and features you use. Learn more about
            <a
              href="#personalInfo"
              style={{ color: "#e2007b", cursor: "pointer" }}
            >
              <u> personal information you disclose to us</u>
            </a>
            .
          </p>
          <p className="cookieData">
            <b> Do we process any sensitive personal information?</b> We do not
            process sensitive personal information.
          </p>
          <p className="cookieData">
            <b> Do we receive any information from third parties? </b> We do not
            receive any information from third parties.
          </p>
          <p className="cookieData">
            <b>How do we process your information?</b> We process your
            information to provide, improve, and administer our Services,
            communicate with you, for security and fraud prevention, and to
            comply with law. We may also process your information for other
            purposes with your consent. We process your information only when we
            have a valid legal reason to do so. Learn more about
            <a
              href="#question2"
              style={{ color: "#e2007b", cursor: "pointer" }}
            >
              <u> how we process your information.</u>
            </a>
          </p>
          <p className="cookieData">
            <b>
              In what situations and with which parties do we share personal
              information?
            </b>
            We may share information in specific situations and with specific
            third parties. Learn more about
            <a
              href="#question4"
              style={{ color: "#e2007b", cursor: "pointer" }}
            >
              <u>when and with whom we share your personal information.</u>{" "}
            </a>
          </p>
          <p className="cookieData">
            <b> How do we keep your information safe? </b>We have organizational
            and technical processes and procedures in place to protect your
            personal information. However, no electronic transmission over the
            internet or information storage technology can be guaranteed to be
            100% secure, so we cannot promise or guarantee that hackers,
            cybercriminals, or other unauthorized third parties will not be able
            to defeat our security and improperly collect, access, steal, or
            modify your information. Learn more about{" "}
            <a
              href="#question7"
              style={{ color: "#e2007b", cursor: "pointer" }}
            >
              <u> how we keep your information safe.</u>
            </a>
          </p>
          <p className="cookieData">
            <b> What are your rights? </b> Depending on where you are located
            geographically, the applicable privacy law may mean you have certain
            rights regarding your personal information. Learn more about
            <a
              href="#question9"
              style={{ color: "#e2007b", cursor: "pointer" }}
            >
              <u> your privacy rights.</u>
            </a>
          </p>
          <p className="cookieData">
            <b> How do you exercise your rights? </b> The easiest way to
            exercise your rights is by submitting a data subject access request,
            or by contacting us. We will consider and act upon any request in
            accordance with applicable data protection laws.
          </p>
          <p className="cookieData">
            Want to learn more about what we do with any information we collect?
            <a
              href="#tableofContent"
              style={{ color: "#e2007b", cursor: "pointer" }}
            >
              <u>Review the privacy notice in full.</u>
            </a>
          </p>
          <h2 className="policy_questions" id="tableofContent">
            TABLE OF CONTENTS
          </h2>
          <ol style={{ color: "white", listStyle: "number" }}>
            <li className="cookiData">
              <a
                href="#question1"
                style={{ color: "#e2007b", cursor: "pointer" }}
              >
                <u> WHAT INFORMATION DO WE COLLECT?</u>
              </a>
            </li>
            <li className="cookiData">
              <a
                href="#question2"
                style={{ color: "#e2007b", cursor: "pointer" }}
              >
                <u> HOW DO WE PROCESS YOUR INFORMATION?</u>
              </a>
            </li>
            <li className="cookiData">
              <a
                href="#question3"
                style={{ color: "#e2007b", cursor: "pointer" }}
              >
                <u>
                  WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                  INFORMATION?
                </u>{" "}
              </a>
            </li>
            <li className="cookiData">
              <a
                href="#question4"
                style={{ color: "#e2007b", cursor: "pointer" }}
              >
                <u>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</u>
              </a>
            </li>
            <li className="cookiData">
              <a
                href="#question5"
                style={{ color: "#e2007b", cursor: "pointer" }}
              >
                <u>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</u>
              </a>
            </li>
            <li className="cookiData">
              <a
                href="#question6"
                style={{ color: "#e2007b", cursor: "pointer" }}
              >
                <u>HOW LONG DO WE KEEP YOUR INFORMATION?</u>
              </a>
            </li>
            <li className="cookiData">
              <a
                href="#question7"
                style={{ color: "#e2007b", cursor: "pointer" }}
              >
                <u>HOW DO WE KEEP YOUR INFORMATION SAFE?</u>
              </a>
            </li>
            <li className="cookiData">
              <a
                href="#question8"
                style={{ color: "#e2007b", cursor: "pointer" }}
              >
                <u>DO WE COLLECT INFORMATION FROM MINORS?</u>
              </a>
            </li>
            <li className="cookiData">
              <a
                href="#question9"
                style={{ color: "#e2007b", cursor: "pointer" }}
              >
                <u>WHAT ARE YOUR PRIVACY RIGHTS? </u>
              </a>
            </li>
            <li className="cookiData">
              <a
                href="#question10"
                style={{ color: "#e2007b", cursor: "pointer" }}
              >
                <u>CONTROLS FOR DO-NOT-TRACK FEATURES</u>
              </a>
            </li>
            <li className="cookiData">
              <a
                href="#question11"
                style={{ color: "#e2007b", cursor: "pointer" }}
              >
                <u> DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</u>
              </a>
            </li>
            <li className="cookiData">
              <a
                href="#question12"
                style={{ color: "#e2007b", cursor: "pointer" }}
              >
                <u>DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS? </u>
              </a>
            </li>
            <li className="cookiData">
              <a
                href="#question13"
                style={{ color: "#e2007b", cursor: "pointer" }}
              >
                <u>DO WE MAKE UPDATES TO THIS NOTICE?</u>
              </a>
            </li>
            <li className="cookiData">
              <a
                href="#question14"
                style={{ color: "#e2007b", cursor: "pointer" }}
              >
                <u>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</u>
              </a>
            </li>
            <li className="cookiData">
              <a
                href="#question15"
                style={{ color: "#e2007b", cursor: "pointer" }}
              >
                <u>
                  HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
                  YOU?
                </u>
              </a>
            </li>
          </ol>
          <h2 className="policy_questions" id="question1">
            1. WHAT INFORMATION DO WE COLLECT?
          </h2>
          <h3 className="policy_questions" id="personalInfo">
            Personal information you disclose to us
          </h3>
          <p className="cookieData">
            <b> In Short:</b> We collect personal information that you provide
            to us.
          </p>
          <p className="cookieData">
            We collect personal information that you voluntarily provide to us
            when you register on the Services, express an interest in obtaining
            information about us or our products and Services, when you
            participate in activities on the Services, or otherwise when you
            contact us.
          </p>
          <p className="cookieData">
            <b>Personal Information Provided by You.</b>The personal information
            that we collect depends on the context of your interactions with us
            and the Services, the choices you make, and the products and
            features you use. The personal information we collect may include
            the following:
          </p>
          <ul style={{ color: "white", listStyle: "square" }}>
            <li className="cookieData">names</li>
            <li className="cookieData">email addresses</li>
            <li className="cookieData">job titles</li>
            <li className="cookieData">usernames</li>
            <li className="cookieData">passwords</li>
            <li className="cookieData">billing addresses</li>
            <li className="cookieData">debit/credit card numbers</li>
            <li className="cookieData">contact or authentication data</li>
            <li className="cookieData">contact preferences</li>
            <li className="cookieData">websites</li>
            <li className="cookieData">industry classification</li>
          </ul>
          <p className="cookieData">
            <b> Sensitive Information. </b> We do not process sensitive
            information.
          </p>
          <p className="cookieData">
            <b>Payment Data.</b>We may collect data necessary to process your
            payment if you make purchases, such as your payment instrument
            number, and the security code associated with your payment
            instrument. All payment data is stored by __________. You may find
            their privacy notice link(s) here: __________.
          </p>
          <p className="cookieData">
            All personal information that you provide to us must be true,
            complete, and accurate, and you must notify us of any changes to
            such personal information.
          </p>
          <h3 className="policy_questions">
            Information automatically collected
          </h3>
          <p className="cookieData">
            <b> In Short:</b> Some information — such as your Internet Protocol
            (IP) address and/or browser and device characteristics — is
            collected automatically when you visit our Services.
          </p>
          <p className="cookieData">
            We automatically collect certain information when you visit, use, or
            navigate the Services. This information does not reveal your
            specific identity (like your name or contact information) but may
            include device and usage information, such as your IP address,
            browser and device characteristics, operating system, language
            preferences, referring URLs, device name, country, location,
            information about how and when you use our Services, and other
            technical information. This information is primarily needed to
            maintain the security and operation of our Services, and for our
            internal analytics and reporting purposes.
          </p>
          <p className="cookieData">
            Like many businesses, we also collect information through cookies
            and similar technologies. You can find out more about this in our
            Cookie Notice:
            <a
              href="https://www.ampai.ai/"
              style={{ color: "#e2007b", cursor: "pointer" }}
              target="_blank"
              rel="noreferrer"
            >
              <u> http://www.ampai.ai/cookies</u>
            </a>
            .
          </p>
          <p className="cookieData">The information we collect includes:</p>
          <ul style={{ color: "white", listStyle: "square" }}>
            <li className="cookieData">
              Log and Usage Data. Log and usage data is service-related,
              diagnostic, usage, and performance information our servers
              automatically collect when you access or use our Services and
              which we record in log files. Depending on how you interact with
              us, this log data may include your IP address, device information,
              browser type, and settings and information about your activity in
              the Services (such as the date/time stamps associated with your
              usage, pages and files viewed, searches, and other actions you
              take such as which features you use), device event information
              (such as system activity, error reports (sometimes called `
              {"crash dumps"}`), and hardware settings).
            </li>
          </ul>
          <h2 className="policy_questions" id="question2">
            2. HOW DO WE PROCESS YOUR INFORMATION?
          </h2>
          <p className="cookieData">
            <b> In Short:</b> We process your information to provide, improve,
            and administer our Services, communicate with you, for security and
            fraud prevention, and to comply with law. We may also process your
            information for other purposes with your consent.
          </p>
          <p className="cookieData">
            <b>
              We process your personal information for a variety of reasons,
              depending on how you interact with our Services, including:
            </b>
          </p>
          <ul style={{ color: "white", listStyle: "square" }}>
            <li className="cookieData">
              <b>
                To facilitate account creation and authentication and otherwise
                manage user accounts.
              </b>
              We may process your information so you can create and log in to
              your account, as well as keep your account in working order.
            </li>
            <li className="cookieData">
              <b>To save or protect an individual&apos;s vital interest.</b>
              We may process your information when necessary to save or protect
              an individual’s vital interest, such as to prevent harm.
            </li>
          </ul>
          <h2 className="policy_questions" id="question3">
            3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
          </h2>
          <p className="cookieData">
            <b> In Short:</b> We only process your personal information when we
            believe it is necessary and we have a valid legal reason (i.e.,
            legal basis) to do so under applicable law, like with your consent,
            to comply with laws, to provide you with services to enter into or
            fulfill our contractual obligations, to protect your rights, or to
            fulfill our legitimate business interests.
          </p>
          <p className="cookieData">
            <b>
              <u>
                If you are located in the EU or UK, this section applies to you.
              </u>
            </b>
          </p>
          <p className="cookieData">
            The General Data Protection Regulation (GDPR) and UK GDPR require us
            to explain the valid legal bases we rely on in order to process your
            personal information. As such, we may rely on the following legal
            bases to process your personal information:
          </p>
          <ul style={{ color: "white", listStyle: "square" }}>
            <li className="cookieData">
              <b>Consent. </b>
              We may process your information if you have given us permission
              (i.e., consent) to use your personal information for a specific
              purpose. You can withdraw your consent at any time. Learn more
              about
              <a
                href="#withdrawConsent"
                style={{ color: "#e2007b", cursor: "pointer" }}
              >
                <u> withdrawing your consent</u>
              </a>
              .
            </li>
            <li className="cookieData">
              <b>Legal Obligations. </b>
              We may process your information where we believe it is necessary
              for compliance with our legal obligations, such as to cooperate
              with a law enforcement body or regulatory agency, exercise or
              defend our legal rights, or disclose your information as evidence
              in litigation in which we are involved.
            </li>
            <li className="cookieData">
              <b>Vital Interests. </b>
              We may process your information where we believe it is necessary
              to protect your vital interests or the vital interests of a third
              party, such as situations involving potential threats to the
              safety of any person.
            </li>
          </ul>
          <p className="cookieData">
            <b>
              <u>If you are located in Canada, this section applies to you.</u>
            </b>
          </p>
          <p className="cookieData">
            We may process your information if you have given us specific
            permission (i.e., express consent) to use your personal information
            for a specific purpose, or in situations where your permission can
            be inferred (i.e., implied consent). You can withdraw your consent
            at any time.
          </p>
          <p className="cookieData">
            In some exceptional cases, we may be legally permitted under
            applicable law to process your information without your consent,
            including, for example:
          </p>
          <ul style={{ color: "white", listStyle: "square" }}>
            <li className="cookieData">
              If collection is clearly in the interests of an individual and
              consent cannot be obtained in a timely way
            </li>
            <li className="cookieData">
              For investigations and fraud detection and prevention
            </li>
            <li className="cookieData">
              For business transactions provided certain conditions are met
            </li>
            <li className="cookieData">
              If it is contained in a witness statement and the collection is
              necessary to assess, process, or settle an insurance claim
            </li>
            <li className="cookieData">
              For identifying injured, ill, or deceased persons and
              communicating with next of kin
            </li>
            <li className="cookieData">
              If we have reasonable grounds to believe an individual has been,
              is, or may be victim of financial abuse
            </li>
            <li className="cookieData">
              If it is reasonable to expect collection and use with consent
              would compromise the availability or the accuracy of the
              information and the collection is reasonable for purposes related
              to investigating a breach of an agreement or a contravention of
              the laws of Canada or a province
            </li>
            <li className="cookieData">
              If disclosure is required to comply with a subpoena, warrant,
              court order, or rules of the court relating to the production of
              records
            </li>
            <li className="cookieData">
              If it was produced by an individual in the course of their
              employment, business, or profession and the collection is
              consistent with the purposes for which the information was
              produced
            </li>
            <li className="cookieData">
              If the collection is solely for journalistic, artistic, or
              literary purposes
            </li>
            <li className="cookieData">
              If the information is publicly available and is specified by the
              regulations
            </li>
          </ul>
          <h2 className="policy_questions" id="question4">
            4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </h2>
          <p className="cookieData">
            <b> In Short: </b>We may share information in specific situations
            described in this section and/or with the following third parties.
          </p>
          <p className="cookieData">
            We may need to share your personal information in the following
            situations:
          </p>
          <ul style={{ color: "white", listStyle: "square" }}>
            <li className="cookieData">
              <b>Business Transfers. </b>
              We may share or transfer your information in connection with, or
              during negotiations of, any merger, sale of company assets,
              financing, or acquisition of all or a portion of our business to
              another company.
            </li>
            <li className="cookieData">
              <b>Affiliates. </b>
              We may share your information with our affiliates, in which case
              we will require those affiliates to honor this privacy notice.
              Affiliates include our parent company and any subsidiaries, joint
              venture partners, or other companies that we control or that are
              under common control with us.
            </li>
            <li className="cookieData">
              <b>Business Partners. </b>
              We may share your information with our business partners to offer
              you certain products, services, or promotions.
            </li>
          </ul>
          <h2 className="policy_questions" id="question5">
            5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
          </h2>
          <p className="cookieData">
            <b> In Short: </b> We may use cookies and other tracking
            technologies to collect and store your information.
          </p>
          <p className="cookieData">
            We may use cookies and similar tracking technologies (like web
            beacons and pixels) to access or store information. Specific
            information about how we use such technologies and how you can
            refuse certain cookies is set out in our Cookie Notice:
            http://www.ampai.ai/cookies.
          </p>
          <h2 className="policy_questions" id="question6">
            6. HOW LONG DO WE KEEP YOUR INFORMATION?
          </h2>
          <p className="cookieData">
            <b> In Short: </b>We keep your information for as long as necessary
            to fulfill the purposes outlined in this privacy notice unless
            otherwise required by law.
          </p>
          <p className="cookieData">
            We will only keep your personal information for as long as it is
            necessary for the purposes set out in this privacy notice, unless a
            longer retention period is required or permitted by law (such as
            tax, accounting, or other legal requirements). No purpose in this
            notice will require us keeping your personal information for longer
            than six (6) months past the termination of the user&apos;s account.
          </p>
          <p className="cookieData">
            When we have no ongoing legitimate business need to process your
            personal information, we will either delete or anonymize such
            information, or, if this is not possible (for example, because your
            personal information has been stored in backup archives), then we
            will securely store your personal information and isolate it from
            any further processing until deletion is possible.
          </p>
          <h2 className="policy_questions" id="question7">
            7. HOW DO WE KEEP YOUR INFORMATION SAFE?
          </h2>
          <p className="cookieData">
            <b> In Short: </b> We aim to protect your personal information
            through a system of organizational and technical security measures.
          </p>
          <p className="cookieData">
            We have implemented appropriate and reasonable technical and
            organizational security measures designed to protect the security of
            any personal information we process. However, despite our safeguards
            and efforts to secure your information, no electronic transmission
            over the Internet or information storage technology can be
            guaranteed to be 100% secure, so we cannot promise or guarantee that
            hackers, cybercriminals, or other unauthorized third parties will
            not be able to defeat our security and improperly collect, access,
            steal, or modify your information. Although we will do our best to
            protect your personal information, transmission of personal
            information to and from our Services is at your own risk. You should
            only access the Services within a secure environment.
          </p>
          <h2 className="policy_questions" id="question8">
            8. DO WE COLLECT INFORMATION FROM MINORS?
          </h2>
          <p className="cookieData">
            <b> In Short: </b> We do not knowingly collect data from or market
            to children under 18 years of age.
          </p>
          <p className="cookieData">
            We do not knowingly solicit data from or market to children under 18
            years of age. By using the Services, you represent that you are at
            least 18 or that you are the parent or guardian of such a minor and
            consent to such minor dependent’s use of the Services. If we learn
            that personal information from users less than 18 years of age has
            been collected, we will deactivate the account and take reasonable
            measures to promptly delete such data from our records. If you
            become aware of any data we may have collected from children under
            age 18, please contact us at __________.
          </p>
          <h2 className="policy_questions" id="question9">
            9. WHAT ARE YOUR PRIVACY RIGHTS?
          </h2>
          <p className="cookieData">
            <b> In Short: </b> In some regions, such as the European Economic
            Area (EEA), United Kingdom (UK), Switzerland, and Canada, you have
            rights that allow you greater access to and control over your
            personal information. You may review, change, or terminate your
            account at any time.
          </p>
          <p className="cookieData">
            In some regions (like the EEA, UK, Switzerland, and Canada), you
            have certain rights under applicable data protection laws. These may
            include the right (i) to request access and obtain a copy of your
            personal information, (ii) to request rectification or erasure;
            (iii) to restrict the processing of your personal information; (iv)
            if applicable, to data portability; and (v) not to be subject to
            automated decision-making. In certain circumstances, you may also
            have the right to object to the processing of your personal
            information. You can make such a request by contacting us by using
            the contact details provided in the section
            {`"HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"`} below.
          </p>
          <p className="cookieData">
            We will consider and act upon any request in accordance with
            applicable data protection laws.
          </p>
          <p className="cookieData">
            If you are located in the EEA or UK and you believe we are
            unlawfully processing your personal information, you also have the
            right to complain to your Member State data protection authority or
            UK data protection authority.
          </p>
          <p className="cookieData">
            If you are located in Switzerland, you may contact the Federal Data
            Protection and Information Commissioner.
          </p>
          <p className="cookieData" id="withdrawConsent">
            <b>Withdrawing your consent: </b>If we are relying on your consent
            to process your personal information, which may be express and/or
            implied consent depending on the applicable law, you have the right
            to withdraw your consent at any time. You can withdraw your consent
            at any time by contacting us by using the contact details provided
            in the section {` "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" `}{" "}
            below.
          </p>
          <p className="cookieData">
            However, please note that this will not affect the lawfulness of the
            processing before its withdrawal nor, when applicable law allows,
            will it affect the processing of your personal information conducted
            in reliance on lawful processing grounds other than consent.
          </p>
          <h3 className="policy_questions">Account Information</h3>
          <p className="cookieData">
            If you would at any time like to review or change the information in
            your account or terminate your account, you can:
          </p>
          <ul style={{ color: "white", listStyle: "square" }}>
            <li className="cookieData">
              Log in to your account settings and update your user account.
            </li>
            <li className="cookieData">
              Contact us using the contact information provided.
            </li>
          </ul>
          <p className="cookieData">
            Upon your request to terminate your account, we will deactivate or
            delete your account and information from our active databases.
            However, we may retain some information in our files to prevent
            fraud, troubleshoot problems, assist with any investigations,
            enforce our legal terms and/or comply with applicable legal
            requirements.
          </p>
          <p className="cookieData">
            <b>
              <u>Cookies and similar technologies:</u>
            </b>{" "}
            Most Web browsers are set to accept cookies by default. If you
            prefer, you can usually choose to set your browser to remove cookies
            and to reject cookies. If you choose to remove cookies or reject
            cookies, this could affect certain features or services of our
            Services. For further information, please see our Cookie Notice:
            http://www.ampai.ai/cookies.
          </p>
          <p className="cookieData">
            If you have questions or comments about your privacy rights, you may
            email us at privacy@ampai.ai.
          </p>
          <h2 className="policy_questions" id="question10">
            10. CONTROLS FOR DO-NOT-TRACK FEATURES
          </h2>
          <p className="cookieData">
            Most web browsers and some mobile operating systems and mobile
            applications include a Do-Not-Track ({`"DNT"`}) feature or setting
            you can activate to signal your privacy preference not to have data
            about your online browsing activities monitored and collected. At
            this stage no uniform technology standard for recognizing and
            implementing DNT signals has been finalized. As such, we do not
            currently respond to DNT browser signals or any other mechanism that
            automatically communicates your choice not to be tracked online. If
            a standard for online tracking is adopted that we must follow in the
            future, we will inform you about that practice in a revised version
            of this privacy notice.
          </p>
          <h2 className="policy_questions" id="question11">
            11. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
          </h2>
          <p className="cookieData">
            <b> In Short: </b> If you are a resident of California, Colorado,
            Connecticut, Utah or Virginia, you are granted specific rights
            regarding access to your personal information.
          </p>
          <p className="cookieData">
            <b>What categories of personal information do we collect?</b>
          </p>
          <p className="cookieData">
            We have collected the following categories of personal information
            in the past twelve (12) months:
          </p>
          <div>
            <table className="tableBorder" style={{ width: "90%" }}>
              <tr>
                <th className="cookieData">Category</th>
                <th className="cookieData">Examples</th>
                <th className="cookieData">Collected</th>
              </tr>
              <tr style={{ height: "100px" }}>
                <td
                  className="cookieData"
                  style={{ width: "20%", paddingLeft: "10px" }}
                >
                  A. Identifiers
                </td>
                <td
                  className="cookieData"
                  style={{ width: "70%", paddingLeft: "10px" }}
                >
                  Contact details, such as real name, alias, postal address,
                  telephone or mobile contact number, unique personal
                  identifier, online identifier, Internet Protocol address,
                  email address, and account name
                </td>
                <td className="cookieData" style={{ textAlign: "center" }}>
                  NO
                </td>
              </tr>
              <tr style={{ height: "100px" }}>
                <td
                  className="cookieData"
                  style={{ width: "20%", paddingLeft: "10px" }}
                >
                  B. Personal information as defined in the California Customer
                  Records statute
                </td>
                <td
                  className="cookieData"
                  style={{ width: "70%", paddingLeft: "10px" }}
                >
                  Name, contact information, education, employment, employment
                  history, and financial information
                </td>
                <td className="cookieData" style={{ textAlign: "center" }}>
                  NO
                </td>
              </tr>
              <tr style={{ height: "100px" }}>
                <td
                  className="cookieData"
                  style={{ width: "20%", paddingLeft: "10px" }}
                >
                  C. Protected classification characteristics under state or
                  federal law
                </td>
                <td
                  className="cookieData"
                  style={{ width: "70%", paddingLeft: "10px" }}
                >
                  Gender and date of birth
                </td>
                <td className="cookieData" style={{ textAlign: "center" }}>
                  NO
                </td>
              </tr>
              <tr style={{ height: "100px" }}>
                <td
                  className="cookieData"
                  style={{ width: "20%", paddingLeft: "10px" }}
                >
                  D. Commercial information
                </td>
                <td
                  className="cookieData"
                  style={{ width: "70%", paddingLeft: "10px" }}
                >
                  Transaction information, purchase history, financial details,
                  and payment information
                </td>
                <td className="cookieData" style={{ textAlign: "center" }}>
                  NO
                </td>
              </tr>
              <tr style={{ height: "100px" }}>
                <td
                  className="cookieData"
                  style={{ width: "20%", paddingLeft: "10px" }}
                >
                  E. Biometric information
                </td>
                <td
                  className="cookieData"
                  style={{ width: "70%", paddingLeft: "10px" }}
                >
                  Fingerprints and voiceprints
                </td>
                <td className="cookieData" style={{ textAlign: "center" }}>
                  NO
                </td>
              </tr>
              <tr style={{ height: "100px" }}>
                <td
                  className="cookieData"
                  style={{ width: "20%", paddingLeft: "10px" }}
                >
                  F. Internet or other similar network activity
                </td>
                <td
                  className="cookieData"
                  style={{ width: "70%", paddingLeft: "10px" }}
                >
                  Browsing history, search history, online behavior, interest
                  data, and interactions with our and other websites,
                  applications, systems, and advertisements
                </td>
                <td className="cookieData" style={{ textAlign: "center" }}>
                  NO
                </td>
              </tr>
              <tr style={{ height: "100px" }}>
                <td
                  className="cookieData"
                  style={{ width: "20%", paddingLeft: "10px" }}
                >
                  G. Geolocation data
                </td>
                <td
                  className="cookieData"
                  style={{ width: "70%", paddingLeft: "10px" }}
                >
                  Device location
                </td>
                <td className="cookieData" style={{ textAlign: "center" }}>
                  NO
                </td>
              </tr>
              <tr style={{ height: "100px" }}>
                <td
                  className="cookieData"
                  style={{ width: "20%", paddingLeft: "10px" }}
                >
                  H. Audio, electronic, visual, thermal, olfactory, or similar
                  information
                </td>
                <td
                  className="cookieData"
                  style={{ width: "70%", paddingLeft: "10px" }}
                >
                  Images and audio, video or call recordings created in
                  connection with our business activities
                </td>
                <td className="cookieData" style={{ textAlign: "center" }}>
                  NO
                </td>
              </tr>
              <tr style={{ height: "100px" }}>
                <td
                  className="cookieData"
                  style={{ width: "20%", paddingLeft: "10px" }}
                >
                  I. Professional or employment-related information
                </td>
                <td
                  className="cookieData"
                  style={{ width: "70%", paddingLeft: "10px" }}
                >
                  Business contact details in order to provide you our Services
                  at a business level or job title, work history, and
                  professional qualifications if you apply for a job with us
                </td>
                <td className="cookieData" style={{ textAlign: "center" }}>
                  NO
                </td>
              </tr>
              <tr style={{ height: "100px" }}>
                <td
                  className="cookieData"
                  style={{ width: "20%", paddingLeft: "10px" }}
                >
                  J. Education Information
                </td>
                <td
                  className="cookieData"
                  style={{ width: "70%", paddingLeft: "10px" }}
                >
                  Student records and directory information
                </td>
                <td className="cookieData" style={{ textAlign: "center" }}>
                  NO
                </td>
              </tr>
              <tr style={{ height: "100px" }}>
                <td
                  className="cookieData"
                  style={{ width: "20%", paddingLeft: "10px" }}
                >
                  K. Inferences drawn from collected personal information
                </td>
                <td
                  className="cookieData"
                  style={{ width: "70%", paddingLeft: "10px" }}
                >
                  Inferences drawn from any of the collected personal
                  information listed above to create a profile or summary about,
                  for example, an individual’s preferences and characteristics
                </td>
                <td className="cookieData" style={{ textAlign: "center" }}>
                  NO
                </td>
              </tr>
              <tr style={{ height: "100px" }}>
                <td
                  className="cookieData"
                  style={{ width: "20%", paddingLeft: "10px" }}
                >
                  L. Sensitive personal Information{" "}
                </td>
                <td
                  className="cookieData"
                  style={{ width: "70%", paddingLeft: "10px" }}
                ></td>
                <td className="cookieData" style={{ textAlign: "center" }}>
                  NO
                </td>
              </tr>
            </table>
          </div>
          <p className="cookieData">
            We will use and retain the collected personal information as needed
            to provide the Services or for:
          </p>
          <ul style={{ color: "white", listStyle: "square" }}>
            <li className="cookieData">
              Category B - As long as the user has an account with us
            </li>
          </ul>
          <p className="cookieData">
            We may also collect other personal information outside of these
            categories through instances where you interact with us in person,
            online, or by phone or mail in the context of:
          </p>
          <ul style={{ color: "white", listStyle: "square" }}>
            <li className="cookieData">
              Receiving help through our customer support channels;
            </li>
            <li className="cookieData">
              Participation in customer surveys or contests; and
            </li>
            <li className="cookieData">
              Facilitation in the delivery of our Services and to respond to
              your inquiries.
            </li>
          </ul>
          <h2 className="policy_questions" id="question12">
            12. DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?
          </h2>
          <p className="cookieData">
            <b> In Short: </b> You may have additional rights based on the
            country you reside in.
          </p>
          <h3 className="policy_questions">Australia and New Zealand</h3>
          <p className="cookieData">
            We collect and process your personal information under the
            obligations and conditions set by Australia&apos;s Privacy Act 1988
            and New Zealand&apos;s Privacy Act 2020 (Privacy Act).
          </p>
          <p className="cookieData">
            This privacy notice satisfies the notice requirements defined in
            both Privacy Acts, in particular: what personal information we
            collect from you, from which sources, for which purposes, and other
            recipients of your personal information.
          </p>
          <p className="cookieData">
            If you do not wish to provide the personal information necessary to
            fulfill their applicable purpose, it may affect our ability to
            provide our services, in particular:
          </p>
          <ul style={{ color: "white", listStyle: "square" }}>
            <li className="cookieData">
              offer you the products or services that you want
            </li>
            <li className="cookieData">
              respond to or help with your requests
            </li>
            <li className="cookieData">manage your account with us</li>
            <li className="cookieData">
              confirm your identity and protect your account
            </li>
          </ul>
          <p className="cookieData">
            At any time, you have the right to request access to or correction
            of your personal information. You can make such a request by
            contacting us by using the contact details provided in the section
            {` "HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?"  `}
            .
          </p>
          <p className="cookieData">
            If you believe we are unlawfully processing your personal
            information, you have the right to submit a complaint about a breach
            of the Australian Privacy Principles to the Office of the Australian
            Information Commissioner and a breach of New Zealand&apos;s Privacy
            Principles to the Office of New Zealand Privacy Commissioner.
          </p>
          <h3 className="policy_questions">Republic of South Africa</h3>
          <p className="cookieData">
            At any time, you have the right to request access to or correction
            of your personal information. You can make such a request by
            contacting us by using the contact details provided in the section
            {` "HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?"  `}
            .
          </p>
          <p className="cookieData">
            If you are unsatisfied with the manner in which we address any
            complaint with regard to our processing of personal information, you
            can contact the office of the regulator, the details of which are:
          </p>
          <p className="cookieData">
            The Information Regulator (South Africa)
            <br />
            General enquiries: enquiries@inforegulator.org.za
            <br />
            Complaints (complete POPIA/PAIA form 5):
            PAIAComplaints@inforegulator.org.za &
            POPIAComplaints@inforegulator.org.za
          </p>
          <h2 className="policy_questions" id="question13">
            13. DO WE MAKE UPDATES TO THIS NOTICE?
          </h2>
          <p className="cookieData">
            <b> In Short: </b> Yes, we will update this notice as necessary to
            stay compliant with relevant laws.
          </p>
          <p className="cookieData">
            We may update this privacy notice from time to time. The updated
            version will be indicated by an updated {`"Revised"`} date and the
            updated version will be effective as soon as it is accessible. If we
            make material changes to this privacy notice, we may notify you
            either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this
            privacy notice frequently to be informed of how we are protecting
            your information.
          </p>
          <h2 className="policy_questions" id="question14">
            14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          </h2>
          <p className="cookieData">
            If you have questions or comments about this notice, you may contact
            our Data Protection Officer (DPO) by email at __________, or contact
            us by post at:
          </p>
          <p className="cookieData">
            {" "}
            Amp AI Inc <br />
            1000 Brickell Ave, Suite 715
            <br /> Miami,FL 33131
            <br /> United States
          </p>
          <h2 className="policy_questions" id="question15">
            15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?
          </h2>
          <p className="cookieData">
            Based on the applicable laws of your country, you may have the right
            to request access to the personal information we collect from you,
            change that information, or delete it. To request to review, update,
            or delete your personal information, please fill out and submit a
            data subject access request.
          </p>
        </Box>
      </Box>
    </Box>
  );
};

export default Privacy;
