import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Stack,
  Checkbox
} from "@mui/material/node";
import "./vendorRequest.css";
import { RFIslider } from "../slider/rfiSlider";
import RfiFooterButton from "../footer/footerButtonGroup";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {saveVendorInquiry} from "../../../redux/rfiSlice";
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import CustomTextField from "../../otherComponent/customTextfield";

const VendorRequest = ({
  openSelectedStep,
  setSliderValue,
  sliderValue,
  selectedCategory,
}) => {
  const dispatch = useDispatch();
  const { vendorInquiry } = useSelector((state) => state.rfiData);

  const NewProjectSchema = Yup.object().shape({
    credit_report: Yup.boolean(),
    financial_statement: Yup.boolean(),
    SOC2_certification: Yup.boolean(),
    ISO27001_certification: Yup.boolean(),
    references: Yup.boolean(),
    addl_requirement_info: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      credit_report: false,
      financial_statement: false,
      SOC2_certification: false,
      ISO27001_certification: false,
      references: false,
      addl_requirement_info: "",
    },
    validationSchema: NewProjectSchema,
    onSubmit: async (values) => {
      const data = {
        saved_step: "vendor_requirement",
        vendor_category: vendorInquiry[selectedCategory].vendor_category,
        vendor_requirement: values,
      };
      dispatch(saveVendorInquiry(data));
      openSelectedStep("deadline", selectedCategory);
    },
  });

  useEffect(() => {
    if (
      vendorInquiry &&
      vendorInquiry[selectedCategory] &&
      vendorInquiry[selectedCategory]?.vendor_requirement
    ) {
      setFieldValue(
        "credit_report",
        vendorInquiry[selectedCategory]?.vendor_requirement?.credit_report
      );
      setFieldValue(
        "financial_statement",
        vendorInquiry[selectedCategory]?.vendor_requirement?.financial_statement
      );
      setFieldValue(
        "SOC2_certification",
        vendorInquiry[selectedCategory]?.vendor_requirement?.SOC2_certification
      );
      setFieldValue(
        "ISO27001_certification",
        vendorInquiry[selectedCategory]?.vendor_requirement?.ISO27001_certification
      );
      setFieldValue(
        "references",
        vendorInquiry[selectedCategory]?.vendor_requirement?.references
      );
      setFieldValue(
        "addl_requirement_info",
        vendorInquiry[selectedCategory]?.vendor_requirement?.addl_requirement_info
      );
    }
  }, [vendorInquiry]);

  const moveback = () => {
    openSelectedStep("pricing", selectedCategory);
  };

  const handleInputChange = (e) => {
    const { name, type } = e.target;
    const inputValue = type === "checkbox" ? e.target.checked : e.target.value;
    formik.setFieldValue(name, inputValue);
  };

  useEffect(() => {
    setSliderValue(50.7);
  }, []);

  const { handleSubmit, getFieldProps, setFieldValue, values } = formik;

  return (
    <>
      <Box className="rfi">
        <FormikProvider value={formik}>
          <Form
            className="inquiry_form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <Box className="rfi_wrapper">
              <RFIslider
                sliderValue={sliderValue}
                setSliderValue={setSliderValue}
              />
              <Box className="companyReview_wrapper">
                <Box className="headlineVendorReq_container">
                  <Typography className="companyReview_typography">
                    What specific information about the Vendor is required?
                    Check only those is a must, remember not all companies can/
                    willing to provide.{" "}
                  </Typography>
                </Box>
                <Box className="rfi_listContainer">
                  <Box
                    className={formik.values.credit_report === true ? "rfi_checkedCheckbox"
                      : "rfi_pricing"}>
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px",
                        alignItems: "center",
                      }}
                    >
                      <Typography className="pricing_detail">
                        Credit score/ report
                      </Typography>
                    </Stack>
                    <Checkbox
                      className="customCheckbox"
                      name="credit_report"
                      checked={formik.values.credit_report}
                      onChange={handleInputChange}
                    />
                  </Box>
                  <Box
                    className={formik.values.financial_statement === true ? "rfi_checkedCheckbox"
                      : "rfi_pricing"}>
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px",
                        alignItems: "center",
                      }}
                    >
                      <Typography className="pricing_detail">
                        Financial statements{" "}
                      </Typography>
                    </Stack>
                    <Checkbox
                      className="customCheckbox"
                      name="financial_statement"
                      checked={formik.values.financial_statement}
                      onChange={handleInputChange}
                    />
                  </Box>
                  <Box
                    className={formik.values.SOC2_certification === true ? "rfi_checkedCheckbox"
                      : "rfi_pricing"}>
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px",
                        alignItems: "center",
                      }}
                    >
                      <Typography className="pricing_detail">
                        SOC 2 certificate{" "}
                      </Typography>
                    </Stack>
                    <Checkbox
                      className="customCheckbox"
                      name="SOC2_certification"
                      checked={formik.values.SOC2_certification}
                      onChange={handleInputChange}
                    />
                  </Box>
                  <Box
                    className={formik.values.ISO27001_certification === true ? "rfi_checkedCheckbox"
                      : "rfi_pricing"}>
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px",
                        alignItems: "center",
                      }}
                    >
                      <Typography className="pricing_detail">
                        ISO 27001{" "}
                      </Typography>
                    </Stack>
                    <Checkbox
                      className="customCheckbox"
                      name="ISO27001_certification"
                      checked={formik.values.ISO27001_certification}
                      onChange={handleInputChange}
                    />
                  </Box>
                  <Box
                    className={formik.values.references === true ? "rfi_checkedCheckbox"
                      : "rfi_pricing"}>
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px",
                        alignItems: "center",
                      }}
                    >
                      <Typography className="pricing_detail">
                        References{" "}
                      </Typography>
                    </Stack>
                    <Checkbox
                      className="customCheckbox"
                      name="references"
                      checked={formik.values.references}
                      onChange={handleInputChange}
                    />
                  </Box>
                  <Box className="rfi_field">
                    <Typography className="rfi_question">
                      Please specify any additional pricing information that is
                      needed.
                    </Typography>
                    <CustomTextField
                      name="addl_requirement_info"
                      value={values?.addl_requirement_info}
                      {...getFieldProps("addl_requirement_info")}
                      width="100%"
                    />
                  </Box>
                </Box>
                <Box className="refine_box">
                  <Typography className="refineTopography">Refine</Typography>
                </Box>
              </Box>
            </Box>
            <Stack sx={{ textAlign: "center", width: "70%" }}>
              <RfiFooterButton moveback={moveback} />
            </Stack>
          </Form>
        </FormikProvider>
      </Box>
    </>
  );
};

export default VendorRequest;

VendorRequest.propTypes = {
  openSelectedStep: PropTypes.func,
  setSliderValue: PropTypes.number,
  sliderValue: PropTypes.number,
  selectedCategory: PropTypes.number,
};
