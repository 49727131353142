import React, { useEffect, useState } from "react";
import { Box, Typography, Stack, Checkbox } from "@mui/material/node";
import "./rfiTechStack.css";
import { RFIslider } from "../slider/rfiSlider";
import RfiFooterButton from "../footer/footerButtonGroup";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  saveVendorInquiry,
  UploadInquiryFile,
  getInquiryfiles
} from "../../../redux/rfiSlice";
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";

const RfiTechstack = ({
  openSelectedStep,
  setSliderValue,
  sliderValue,
  selectedCategory,
}) => {
  const dispatch = useDispatch();
  const { vendorInquiry, fileData } = useSelector((state) => state.rfiData);
  const [selectedFile, setSelectedFile] = useState(null);
  const category = vendorInquiry[selectedCategory].vendor_category;

  const NewProjectSchema = Yup.object().shape({
    share_tech_stack: Yup.boolean(),
    files: Yup.array()
      .of(
        Yup.object().shape({
          display_name: Yup.string().required("dd"),
          file_name: Yup.string().required("dd"),
          content_type: Yup.string().required("rr"),
        })
      )
      .min(1, "At least one file is required"),
  });

  const formik = useFormik({
    initialValues: {
      share_tech_stack: false,
      files: [],
    },
    validationSchema: NewProjectSchema,
    onSubmit: async (values) => {
      const data = {
        saved_step: "tech_stack_and_files",
        vendor_category: vendorInquiry[selectedCategory].vendor_category,
        tech_stack_and_files: values,
      };
      dispatch(saveVendorInquiry(data));
      openSelectedStep("reviewSubmit", selectedCategory);
    },
  });

  useEffect(() => {
    if (vendorInquiry) {
      setFieldValue(
        "share_tech_stack",
        vendorInquiry[selectedCategory]?.tech_stack_and_files?.share_tech_stack
      );
      setFieldValue(
        "files",
        vendorInquiry[selectedCategory]?.tech_stack_and_files?.files
      );
      setSelectedFile(
        vendorInquiry[selectedCategory]?.tech_stack_and_files?.files[0]
      );
    }
  }, [vendorInquiry]);

  useEffect(() => {
    if (Object.keys(fileData)?.length > 0) {
      setFieldValue("files", [fileData]);
    }
  }, [fileData]);

  const moveback = () => {
    openSelectedStep("dealBreaker", selectedCategory);
  };

  useEffect(() => {
    setSliderValue(88.2);
  }, []);

  const handleInputChange = (e) => {
    const { name, type } = e.target;
    const inputValue = type === "checkbox" ? e.target.checked : e.target.value;
    formik.setFieldValue(name, inputValue);
  };

  const handleFileChange = (e) => {
    const file = e?.target?.files[0];
    if (file) {
      setSelectedFile(file);
      dispatch(UploadInquiryFile(category, file));
    } else if (
      vendorInquiry[selectedCategory]?.tech_stack_and_files?.files[0]
    ) {
      setSelectedFile(
        vendorInquiry[selectedCategory]?.tech_stack_and_files?.files[0]
      );
    }
  };

  const handleRemove = () => {
    setSelectedFile(null);
  };

  const { handleSubmit, setFieldValue } = formik;

  return (
    <>
      <Box className="rfi">
        <FormikProvider value={formik}>
          <Form
            className="inquiry_form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <Box className="rfi_wrapper">
              <RFIslider
                sliderValue={sliderValue}
                setSliderValue={setSliderValue}
              />
              <Box className="companyReview_wrapper">
                <Box className="rfiTechStack_headline">
                  <Typography className="companyReview_typography">
                    Sharing your tech stack, will help the vendor answer the
                    requirements better. This will help reduce multiple back and
                    forth between the tech teams and increase process
                    efficiency.
                  </Typography>
                </Box>
                <Box className="rfi_listContainer">
                  <Box
                    className={
                      formik.values.share_tech_stack === true
                        ? "rfi_checkedCheckbox"
                        : "rfi_pricing"
                    }
                  >
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px",
                        alignItems: "center",
                      }}
                    >
                      <Typography className="pricing_detail">
                        Share the current tech stack
                      </Typography>
                    </Stack>
                    <Checkbox
                      className="customCheckbox"
                      name="share_tech_stack"
                      checked={formik.values.share_tech_stack}
                      onChange={handleInputChange}
                    />
                  </Box>
                  <Box className="rfi_uploadfield">
                    <Typography className="companyReview_typography">
                      Upload additional files that could help the vendor team to
                      understand your requirements better. This could be a
                      snapshot of a portion of your tech stack.
                    </Typography>
                    <Box className="upload">
                      {!selectedFile && (
                        <input
                          className="rfi_template"
                          type="file"
                          name="files"
                          accept="application/pdf, application/vnd.ms-excel"
                          onChange={handleFileChange}
                          style={{ display: "none" }}
                          id="fileInput"
                        />
                      )}
                      <label
                        htmlFor="fileInput"
                        className={
                          selectedFile ? "rfi_uploadTemplate" : "rfi_template"
                        }
                      >
                        {selectedFile ? (
                          <>
                            <Box className="uploadDocument">
                              <img
                                src="/images/tabler_file-filled.svg"
                                style={{ height: "20px", width: "20px" }}
                              />

                              <span
                                className="filename"
                                onClick={() =>
                                  dispatch(
                                    getInquiryfiles(
                                      category,
                                      selectedFile?.file_name
                                    )
                                  )
                                }
                              >
                                {selectedFile?.name ||
                                  selectedFile?.display_name}
                              </span>
                            </Box>
                            <Box className="deselectIcon">
                              <img
                                style={{ height: "18px", width: "18px" }}
                                src="/images/group_image_icon.svg"
                                onClick={handleRemove}
                              />
                            </Box>
                          </>
                        ) : (
                          <>
                            <img
                              src="/images/material-symbols_cloud-upload.png"
                              alt="Upload"
                            />
                            <span>Upload</span>
                          </>
                        )}
                      </label>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Stack sx={{ textAlign: "center", width: "70%" }}>
              <RfiFooterButton moveback={moveback} />
            </Stack>
          </Form>
        </FormikProvider>
      </Box>
    </>
  );
};

export default RfiTechstack;

RfiTechstack.propTypes = {
  openSelectedStep: PropTypes.func,
  setSliderValue: PropTypes.number,
  sliderValue: PropTypes.number,
  selectedCategory: PropTypes.number,
};
