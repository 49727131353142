/* eslint-disable no-debugger */
import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { saveAs } from "file-saver";
import { api } from "../axiosinterceptor/axiosInterceptor";
import { toast } from "react-toastify";
import {startLoading, stopLoading} from "./applicationSlice";

const userslice = createSlice({
  name: "promptdata",
  initialState: {
    data: [],
    message: "",
    message1: "",
    loader2: false,
    success: false,
    successMessage: "",
    error: false,
    errorMessage: "",
    ampData: undefined,
    userProfiledata: {},
    companyInformation: {},
    ndafile: {},
    rfpfile: {},
    SingleVendor: {},
    showdashboard: false,
    marketAverage: {},
    active: -1,
    selectCriteria: [],
    vendorid: "",
    integrated: "",
    getChat: [],
    loader3: false,
    status: [],
    getConversation: [],
    setConversation: [],
    InitMessage: [],
    LandingPage: [],
    getAnswer: "",
    verifysuccess: false,
    verifyfail: false,
    searchType: "",
    setSearch: {},
    getSearch: [],
    criteriaChild: "",
    targetVendor: [],
    getChildCategory: "",
    summary: "",
    companyData: {},
    getResetEmail: {},
  },
  reducers: {
    setCategory(state, action) {
      state.criteriaChild = action.payload;
    },
    success(state, action) {
      state.loader = false;
      state.data = action.payload;
    },
    resetInitMessage(state) {
      state.InitMessage = [];
    },
    startSecondLoader(state) {
      state.loader2 = true;
    },
    chatboatloader3(state) {
      state.loader3 = true;
    },

    signupsuccess(state) {
      state.success = true;
      state.successMessage = "Signup Successfully";
    },
    userSuccess(state) {
      state.success = true;
      state.successMessage = "User Saved successfully";
    },
    verifyingEmail(state) {
      state.verifysuccess = true;
    },
    getuserProfileSuccess(state, action) {
      state.userProfiledata = action.payload;
    },
    getCompanyInfo(state, action){
      state.companyInformation = action.payload;
    },
    setCompanyInfo(state, action){
      state.companyData = action.payload;
    },
    getUploadfileSucessnda(state, action) {
      state.ndafile = action.payload;
    },
    getUploadfileSucessRfp(state, action) {
      state.rfpfile = action.payload;
    },
    addtechstackSuccess(state) {
      state.success = true;
    },
    getSingleTargetVendorSucess(state, action) {
      state.SingleVendor = action.payload;
      state.integrated = action.payload.integrated;
      state.showdashboard = true;
      state.loader2 = false;
    },
    resetSingleTargetVendor(state) {
      state.SingleVendor = {};
    },

    setActiveVendor(state, action) {
      state.active = action.payload;
    },
    getmarketAverage(state) {
      // eslint-disable-next-line camelcase
      function get_market_criteria_scores() {
        // eslint-disable-next-line camelcase
        const market_criteria_scores = {};
        for (const cr of state.selectCriteria) {
          const scores = state.dashboardVendors
            ?.map((v) => v.criteria_scores[cr])
            .filter((s) => s != null && s > 0);
          // eslint-disable-next-line camelcase
          market_criteria_scores[cr] =
            scores?.reduce((acc, c) => acc + c, 0) / (scores.length || 1);
        }
        // eslint-disable-next-line camelcase
        // eslint-disable-next-line camelcase
        state.marketAverage = market_criteria_scores;
      }
      get_market_criteria_scores();
    },
    setVnedorid(state, action) {
      state.vendorid = action.payload;
    },
    resetchat(state) {
      state.getChat = [];
    },
    getSummary(state, action) {
      state.summary = action.payload;
    },
    getChatConversation(state, action) {
      const sortedArray = [...action.payload].sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
      state.getConversation = sortedArray;
    },
    getChatApi(state, action) {
      if (typeof action.payload === "object") {
        state.getChat = action.payload[0];
        state.status = action.payload[1];
      } else {
        state.getChat = action.payload;
        state.status = "";
      }
      state.loader3 = false;
    },
    setChatConversationData(state, action) {
      state.setConversation = action.payload;
    },
    resetData(state) {
      state.verifyfail = false;
      state.verifysuccess = false;
      state.success = false;
      state.successMessage = "";
      state.error = "";
      state.errorMessage = "";
      state.ampData = undefined;
      state.showdashboard = false;
    },
    hasErrorinVerifyng(state) {
      state.verifyfail = true;
    },

    hasError(state, action) {
      state.error = true;
      state.errorMessage = action.payload;
      state.loader2 = true;
    },

    resetNda(state) {
      state.ndafile = {};
    },

    resetRFP(state) {
      state.rfpfile = {};
    },
    setLandingPage(state, action) {
      state.LandingPage = action.payload;
      state.InitMessage = [...state.InitMessage, action.payload.message];
      state.getAnswer = action.payload.message;
      state.loader3 = false;
      if (action.payload.state === "TERMINATE") {
        if (action.payload.redirect === "search") {
          setTimeout(() => {
            window.location.replace("/decision_tree");
          }, 6000);
        } else if (action.payload.redirect === "dashboard") {
          state.getChildCategory = state.LandingPage?.data?.category;

          setTimeout(() => {
            window.location.replace(`/${action.payload.redirect}`);
          }, 5000);
        }
      }
    },
    setSearchProfile(state, action) {
      state.setSearch = action.payload;
    },
    resetLandingPage(state) {
      state.getAnswer = "";
    },
    searchTypeSelection(state, action) {
      state.searchType = action.payload;
    },
    getSearchProfile(state, action) {
      state.getSearch = action.payload;
    },
    getvendor(state, action) {
      state.targetVendor = action.payload;
    },
    getCategory(state, action) {
      state.getChildCategory = action.payload;
    },
    getEmail(state, action) {
      state.getResetEmail = action.payload;
    },
  },
});

export const {
  inc,
  resetData,
  resetNda,
  resetRFP,
  resetSingleTargetVendor,
  setActiveVendor,
  getmarketAverage,
  resetSelectedCriteria,
  startSecondLoader,
  getChatApi,
  chatboatloader3,
  setChatConversationData,
  resetchat,
  resetLandingPage,
  resetInitMessage,
  setCategory,
  getCategory,
  getEmail,
} = userslice.actions;

export default userslice.reducer;

export function UploadFile(file, filetype) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("file_type", filetype);

  return async () => {
    const headers = {
      "Content-Type": "multipart/form-data",
    };

    try {
      await api.post(`/upload/${filetype}/`, formData, { headers });
    } catch (error) {
      toast(error);
    }
  };
}

export function signUpUser(userData) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      await api.post("/open/new_profile/", userData);
      dispatch(userslice.actions.signupsuccess());
      dispatch(stopLoading());
    } catch (error) {
      if (error.response?.data?.error) {
        dispatch(userslice.actions.hasError(error?.response?.data?.error));
        dispatch(stopLoading());
      } else {
        dispatch(userslice.actions.hasError(error?.response?.data?.detail));
        dispatch(stopLoading());
      }
    }
  };
}

export function setuserProfile(userdata) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      await api.post("/profile/", userdata);
      dispatch(userslice.actions.userSuccess());
      dispatch(stopLoading());
    } catch (error) {
      toast(error);
    }
  };
}

export function getuserProfile() {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const res = await api.get("/profile/");
      dispatch(userslice.actions.getuserProfileSuccess(res));
      dispatch(stopLoading());
    } catch (error) {
      toast(error);
    }
  };
}

export function setCompanyProfile(companyData) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const respone = await api.post("/company_name/", companyData);
      dispatch(userslice.actions.setCompanyInfo(respone));
      dispatch(stopLoading());
    } catch (error) {
      toast(error);
    }
  };
}

export function getCompanyProfile() {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const res = await api.get("/company_profile/");
      dispatch(userslice.actions.getCompanyInfo(res));
      dispatch(stopLoading());
    } catch (error) {
      toast(error);
    }
  };
}

export function searchProfile() {
  return async (dispatch) => {
    try {
      const response = await api.get("/search_profiles/");
      dispatch(userslice.actions.getSearchProfile(response));
    } catch (error) {
      toast(error);
    }
  };
}

export function getUploadedfilesNda(filetype) {
  return async (dispatch) => {
    try {
      const response = await api.get(`/uploaded/${filetype}/`);
      if (filetype === "NDA") {
        dispatch(userslice.actions.getUploadfileSucessnda(response));
      } else {
        dispatch(userslice.actions.getUploadfileSucessRfp(response));
      }
    } catch (error) {
      toast(error);
    }
  };
}

export function downloadUploadedFiles(filetype, fileName) {
  return async () => {
    const headersdata = {
      headers: {
        "Content-Type": "multipart/form-data",
        responseType: "arraybuffer",
      },
    };
    try {
      const response = await api.get(
        `uploaded/${filetype}/${fileName}/`,
        headersdata
      );
      const blob = new Blob([response], { type: "application/pdf" });
      saveAs(blob, "test.pdf");
    } catch (error) {
      toast(error);
    }
  };
}

export function resetAlluserData() {
  return async () => {
    try {
      await axios({
        method: "get",
        url: `/api/demo_reset/`,
      });
    } catch (error) {
      toast(error);
    }
  };
}

export function getDecisiontree() {
  return async () => {
    try {
      await api.get("/decision_tree/");
    } catch (error) {
      toast(error);
    }
  };
}

export function getReviewSummary(vendorId) {
  return async (dispatch) => {
    try {
      const response = await api.get(`/review_summary/${vendorId}/`);
      dispatch(userslice.actions.getSummary(response));
    } catch (error) {
      toast(error);
    }
  };
}

export function setDecisiontreeRevenues(data) {
  return async () => {
    try {
      await api.post("/company_revenues/", data);
    } catch (error) {
      toast(error);
    }
  };
}

export function getSingleTargetVendor(id) {
  return async (dispatch) => {
    dispatch(userslice.actions.startSecondLoader());
    dispatch(userslice.actions.setVnedorid(id));
    try {
      const response = await api.get(`/vendor/${id}/`);
      dispatch(userslice.actions.getSingleTargetVendorSucess(response));
    } catch (error) {
      dispatch(userslice.actions.hasError(error?.response?.data));
    }
  };
}

export function chatApi(data) {
  return async (dispatch) => {
    dispatch(userslice.actions.chatboatloader3());
    try {
      const response = await api.post("/chat/", data);
      dispatch(userslice.actions.getChatApi(response));
    } catch (error) {
      if (error?.response.status === 422) {
        dispatch(
          userslice.actions.getChatApi([
            "Something went wrong! Please Try again",
            error?.response.status,
          ])
        );
      }
      toast(error);
    }
  };
}

export function ChatConversation() {
  return async (dispatch) => {
    try {
      const response = await api.get("/conversations/");
      dispatch(userslice.actions.getChatConversation(response));
    } catch (error) {
      toast(error);
    }
  };
}

export function setChatConversation(id) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await api.post("/conversation/", id);
      dispatch(userslice.actions.setChatConversationData(response));
      dispatch(stopLoading());
    } catch (error) {
      toast(error);
    }
  };
}

export function landingFLow(state, input) {
  return async (dispatch) => {
    dispatch(userslice.actions.chatboatloader3());
    try {
      const response = await api.post("/landing_flow/", state, input);
      dispatch(userslice.actions.setLandingPage(response));
    } catch (error) {
      toast(error);
    }
  };
}

export function EmailVerification(secret) {
  return async (dispatch) => {
    try {
      const response = await api.get(`/open/email_verification/${secret}`);
      dispatch(userslice.actions.verifyingEmail(response));
    } catch (error) {
      if (error?.response?.status === 422) {
        dispatch(
          userslice.actions.hasErrorinVerifyng(error?.response?.data?.detail)
        );
      } else {
        dispatch(userslice.actions.hasErrorinVerifyng("Signup Failed"));
      }
    }
  };
}

export function searchSelection(pref, data) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await api.post(`/search_pref/${pref}/`, data);
      dispatch(userslice.actions.searchTypeSelection(response));
      dispatch(stopLoading());
    } catch (error) {
      toast(error);
    }
  };
}

export function setSearchProfile(data) {
  return async (dispatch) => {
    try {
      const response = await api.post("/search_profile/", data);
      dispatch(userslice.actions.setSearchProfile(response));
    } catch (error) {
      toast(error);
    }
  };
}
