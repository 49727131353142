import { createSlice } from "@reduxjs/toolkit";
import { api } from "../axiosinterceptor/axiosInterceptor";
import { toast } from "react-toastify";
import { setShowToast, startLoading, stopLoading } from "./applicationSlice";

const initialState = {
  revenueList: {},
  criteriaList: [],
  topvendor: {},
  vendorError: false,
  vendorErrorMessage: "",
  dashboardVendors: {},
  comparision: {},
  connectStats: {},
  userConnect: [],
  connectRequest: {},
  errorConnect: false,
};

const dashboardSlice = createSlice({
  name: "dashboardData",
  initialState,
  reducers: {
    revenuelistSuccess(state, action) {
      state.revenueList = action.payload;
    },
    criteriListSuccess(state, action) {
      const filteredData = action.payload.filter(
        (item) => !item.startsWith("_")
      );
      state.criteriaList = filteredData;
    },
    getTopVendor(state, action) {
      state.topvendor = action.payload;
      state.vendorError = false;
    },
    getTopVendorError(state, action) {
      state.vendorErrorMessage = action.payload;
      state.vendorError = true;
    },
    getTargetvendorsSuccess(state, action) {
      state.dashboardVendors = action.payload;
    },
    getVendorComparion(state, action) {
      state.comparision = action.payload;
    },
    connectStatsData(state, action) {
      state.connectStats = action.payload;
    },
    userConnectList(state, action) {
      state.userConnect = action.payload;
    },
    connectRequestList(state, action) {
      state.connectRequest = action.payload;
    },
  },
});

export const { revenuelistSuccess, criteriListSuccess, isError, resetSuccess } =
  dashboardSlice.actions;

export default dashboardSlice.reducer;

export function getRevenueBucket() {
  return async (dispatch) => {
    try {
      const response = await api.get("/revenue_buckets/");
      dispatch(dashboardSlice.actions.revenuelistSuccess(response));
    } catch (error) {
      toast(error);
    }
  };
}

export function getCriteriaList() {
  return async (dispatch) => {
    try {
      const response = await api.get("/criteria_list/");
      dispatch(dashboardSlice.actions.criteriListSuccess(response));
    } catch (error) {
      toast(error);
    }
  };
}

export function getPublishedTopVendor(category) {
  return async (dispatch) => {
    try {
      const encodedCategory = encodeURIComponent(
        category.replaceAll("/", "__")
      );
      const response = await api.get(
        `/published_top_vendors/${encodedCategory}/`
      );
      dispatch(dashboardSlice.actions.getTopVendor(response));
    } catch (vendorError) {
      if (vendorError?.response?.status === 404) {
        dispatch(
          dashboardSlice.actions.getTopVendorError(
            vendorError?.response?.data?.detail
          )
        );
      }
    }
  };
}

export function targetvendors(data) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await api.post("/target_vendors/", data);
      dispatch(dashboardSlice.actions.getTargetvendorsSuccess(response));
      dispatch(stopLoading());
    } catch (error) {
      toast(error);
    }
  };
}

export function vendorComparision(data) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await api.post("vendor_comparison/", data);
      dispatch(dashboardSlice.actions.getVendorComparion(response));
      dispatch(stopLoading());
    } catch (error) {
      toast(error);
    }
  };
}

export function getConnectStats() {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await api.get("/connect_request_stats/");
      dispatch(dashboardSlice.actions.connectStatsData(response));
      dispatch(stopLoading());
    } catch (error) {
      toast(error);
    }
  };
}

export function getUserConnectList(vendor_id, category) {
  return async (dispatch) => {
    try {
      const vendor_category = encodeURIComponent(
        category.replaceAll("/", "__")
      );
      const response = await api.get("/user_connect_list/", {
        params: { vendor_id, vendor_category },
      });
      dispatch(dashboardSlice.actions.userConnectList(response));
    } catch (error) {
      toast(error);
    }
  };
}

export function getConnectRequest() {
  return async (dispatch) => {
    try {
      const response = await api.get("/connect_requests/");
      dispatch(dashboardSlice.actions.connectRequestList(response));
    } catch (error) {
      toast(error);
    }
  };
}

export function makeConnectRequest(data, callback, index) {
  return async (dispatch) => {
    try {
      const response = await api.post("/make_connect_request/", data);
      dispatch(setShowToast({show: true, toastMessage: "Your request to connect has been sent!"}));
      if (callback) callback(response, index);
    } catch (errorConnect) {
      if (callback) callback(errorConnect, index);
      dispatch(setShowToast({show: true, toastMessage: errorConnect?.response?.data?.detail}));
    }
  };
}
