import React, { useState } from "react";
import LoginPage from "./components/login/LoginPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./components/dashboard/Dashboard";
import Profile from "./components/profile/Profile";
import { ProtectedRoutes } from "./Layout/ProtectedRoutes";
import TechStack from "./components/techstack/techStack";
import DecisionTree from "./components/decisionTree/Decision_Tree";
import Tech3dgraph from "./Layout/Tech3dgraph";
import SignUp from "./components/signup/SignUp";
import SearchPage from "./components/search/SearchPage";
import SearchType from "./components/search/searchtype";
import Verification from "./components/signup/Verification";
import Activate from "./components/signup/Activate";
import WelcomePage from "./components/landingPage/welcomePage";
import CompanyProfile from "./components/profile/Company_profile";
import Website from "./WebsiteComponent/Website/Website";
import Cookies from "./WebsiteComponent/Policies/cookies";
import Privacy from "./WebsiteComponent/Policies/privacy";
import Terms from "./WebsiteComponent/Policies/terms";
import Onboarding from "./components/onboarding/onboarding";
import RFI from "./components/rfi/rfiProcess";
import CompareVendor from "./components/DashboardComponent/compareVendor";
import UserInvite from "./components/userInvite/userInvite";
import RfiDashboard from "./components/rfi/rfiDashboard";
import VendorValidate from "./components/vendorValidation/vendorValidation";
import ForgotPassword from "./components/login/forgotPassword";
import ResetPassword from "./components/login/resetPassword";
import Toast from "./components/otherComponent/toast";
import { useSelector } from "react-redux";

function App() {
  const [value, setValue] = React.useState(0);
  const [onBoarding, setOnBoarding] = useState(false);
  const { showToast } = useSelector((state) => state.applicationData);

  return (
    <BrowserRouter>
      {showToast && <Toast />}
      <Routes>
        <Route path="/" element={<Website />} />
        <Route
          path="/login"
          element={
            <LoginPage onBoarding={onBoarding} setOnBoarding={setOnBoarding} />
          }
        />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/activate" element={<Activate />} />
        <Route path="/verification" element={<Verification />} />
        <Route path="/reset_password" element={<ResetPassword />} />
        <Route path="/cookies" element={<Cookies />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route
          path="/searchType"
          element={<ProtectedRoutes Component={SearchType}></ProtectedRoutes>}
        />
        <Route
          path="/landing_page"
          element={<ProtectedRoutes Component={WelcomePage}></ProtectedRoutes>}
        />
        <Route
          path="/onboarding"
          element={
            <ProtectedRoutes
              setValue={setValue}
              value={value}
              setOnBoarding={setOnBoarding}
              Component={Onboarding}
            ></ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/profile"
          element={
            <ProtectedRoutes
              setValue={setValue}
              value={value}
              Component={Profile}
            ></ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/userInvite"
          element={
            <ProtectedRoutes
              setValue={setValue}
              value={value}
              Component={UserInvite}
            ></ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/company_profile"
          element={
            <ProtectedRoutes
              setValue={setValue}
              value={value}
              Component={CompanyProfile}
            ></ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/tech_stack"
          element={
            <ProtectedRoutes
              setValue={setValue}
              value={value}
              Component={TechStack}
            ></ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/decision_tree"
          element={<ProtectedRoutes Component={DecisionTree}></ProtectedRoutes>}
        />
        <Route
          exact
          path="/chat_search"
          element={<ProtectedRoutes Component={SearchPage}></ProtectedRoutes>}
        />
        <Route
          exact
          path="/dashboard"
          element={<ProtectedRoutes Component={Dashboard}></ProtectedRoutes>}
        />
        <Route
          exact
          path="/vendor"
          element={
            <ProtectedRoutes Component={CompareVendor}></ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/rfi_process"
          element={<ProtectedRoutes Component={RFI}></ProtectedRoutes>}
        />
        <Route
          exact
          path="/rfi_dashboard"
          element={<ProtectedRoutes Component={RfiDashboard}></ProtectedRoutes>}
        />
        <Route
          exact
          path="/vendorValidate"
          element={
            <ProtectedRoutes Component={VendorValidate}></ProtectedRoutes>
          }
        />
        <Route
          path="*"
          element={<ProtectedRoutes Component={Profile}></ProtectedRoutes>}
        />
        <Route path="graph" element={<Tech3dgraph />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
