import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Stack,
  Checkbox
} from "@mui/material/node";
import "./reviewSubmit.css";
import { RFIslider } from "../slider/rfiSlider";
import ReviewFooterButton from "./reviewFooterButton";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import PropTypes from "prop-types";
import {
  getVendorInquiries,
  removeVendorInquiry,
  saveVendorInquiry
} from "../../../redux/rfiSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  VendorReqKeys,
  PricingKeys,
  TechstackKeys
} from "../../../util/constant";
import CustomTextField from "../../otherComponent/customTextfield";

const ReviewSubmit = ({
  openSelectedStep,
  setSliderValue,
  sliderValue,
  selectedCategory,
}) => {
  const dispatch = useDispatch();
  const [fileName, setFileName] = useState();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const { vendorInquiry } = useSelector((state) => state.rfiData);

  const data = {
    saved_step: "submitted",
    vendor_category: vendorInquiry[selectedCategory].vendor_category,
  };

  const MoveNext = () => {
    dispatch(saveVendorInquiry(data));
    openSelectedStep("rfiSuccess", selectedCategory);
  };

  const moveback = () => {
    openSelectedStep("rfiTechStack", selectedCategory);
  };

  const confirmSubmit = () => {
    setIsSubmitDisabled(!isSubmitDisabled);
  };

  const isdisable = isSubmitDisabled;
  useEffect(() => {
    dispatch(getVendorInquiries());
    setSliderValue(100);
    setFileName(
      vendorInquiry[selectedCategory]?.tech_stack_and_files?.files[0]
        ?.display_name
    );
  }, []);

  return (
    <>
      <Box className="reviewSubmit">
        <Box className="reviewWrapper">
          <RFIslider
            sliderValue={sliderValue}
            setSliderValue={setSliderValue}
          />
          <Box className="reviewSubmit_container">
            <Box className="review_headline">
              <Typography className="companyReview_typography">
                Let’s review what we have. Feel free to go back and make
                changes. Hit confirm and then submit..
              </Typography>
            </Box>
            <Box className="reviewBox">
              <Box className="review_CompanyReview">
                <Typography className="fieldTitle">Company Review</Typography>
                <Box>
                  <Typography className="customerReview_question">
                    Please ensure confirm the short-list of companies to send
                    the RFI.
                  </Typography>
                  {vendorInquiry?.map(
                    (item, index) =>
                      index === selectedCategory && (
                        <Box className="review_listContainer" key={index}>
                          {item?.vendor_list?.map((vendorId, i) => (
                            <Box className="rfi_companyCard" key={vendorId}>
                              <Stack
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "16px",
                                  alignItems: "center",
                                }}
                              >
                                <Typography className="companyCard_itemNo">
                                  {i + 1}.
                                </Typography>
                                <Typography className="vendorlist_itemName">
                                  {item?.vendor_info?.[vendorId]?.vendor_name}
                                </Typography>
                              </Stack>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  width: "96px",
                                }}
                              >
                                <img
                                  src={item?.vendor_info?.[vendorId]?.logo}
                                  alt=""
                                  style={{
                                    maxHeight: "30px",
                                    maxWidth: "100%",
                                  }}
                                />
                              </Box>
                              <Typography className="companyCard_description">
                                Company description
                              </Typography>
                              <DeleteOutlineOutlinedIcon
                                className="delIcon"
                                onClick={() =>
                                  dispatch(removeVendorInquiry({ id: item.id }))
                                }
                              />
                            </Box>
                          ))}
                        </Box>
                      )
                  )}
                </Box>
              </Box>
              <Box className="review_TechRequirement">
                <Typography className="fieldTitle">Tech Requirement</Typography>
                <Box className="review_listContainer">
                  <Box className="review_TechField">
                    <Typography className="rfi_question">
                      Describe the specific functionalities and features the
                      software must have.
                    </Typography>
                    <CustomTextField
                      value={
                        vendorInquiry[selectedCategory]?.tech_requirements
                          ?.functionalities
                      }
                      width="100%"
                    />
                  </Box>
                  <Box className="review_TechField">
                    <Typography className="rfi_question">
                      Detail the technical specifications required, like
                      security, scalability.
                    </Typography>
                    <CustomTextField
                      value={
                        vendorInquiry[selectedCategory]?.tech_requirements
                          ?.specifications
                      }
                      width="100%"
                    />
                  </Box>
                  <Box className="review_TechField">
                    <Typography className="rfi_question">
                      Describe the customization and specific integration
                      capabilities that’s needed.
                    </Typography>
                    <CustomTextField
                      value={
                        vendorInquiry[selectedCategory]?.tech_requirements
                          ?.customizations
                      }
                      width="100%"
                    />
                  </Box>
                </Box>
              </Box>
              <Box className="review_Service">
                <Typography className="fieldTitle">
                  Service & Additional
                </Typography>
                <Box className="review_listContainer">
                  <Box className="review_TechField">
                    <Typography className="rfi_question">
                      Describe the support service required, training, customer
                      support.
                    </Typography>
                    <CustomTextField
                      value={
                        vendorInquiry[selectedCategory]?.service_requirements
                          ?.post_sales_services
                      }
                      width="100%"
                    />
                  </Box>
                </Box>
              </Box>
              <Box className="review_Pricing">
                <Typography className="fieldTitle">
                  Pricing Structure
                </Typography>
                <Box sx={{ marginBottom: "18px" }}>
                  <Typography className="customerReview_question">
                    Select the pricing information that is needed.
                  </Typography>
                  {Object.keys(
                    vendorInquiry[selectedCategory]?.pricing_structure || {}
                  )
                    .filter(
                      (key) =>
                        vendorInquiry[selectedCategory]?.pricing_structure[key]
                    )
                    .map((key) => {
                      const isChecked =
                        vendorInquiry[selectedCategory]?.pricing_structure[key];
                      const UiPricingKeys = PricingKeys[key];
                      if (
                        vendorInquiry[selectedCategory]?.pricing_structure[
                          key
                        ] === true
                      ) {
                        return (
                          <Box
                            key={key}
                            className={
                              isChecked
                                ? "rfiReview_checkedCheckbox"
                                : "rfiReview_pricing"
                            }
                          >
                            <Stack
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "16px",
                                alignItems: "center",
                              }}
                            >
                              <Typography className="pricing_detail">
                                {UiPricingKeys}
                              </Typography>
                            </Stack>
                            <Checkbox
                              className="customCheckbox"
                              checked={isChecked}
                            />
                          </Box>
                        );
                      }
                    })}
                </Box>
                <Box className="review_TechField">
                  <Typography className="rfi_question">
                    Describe the support service required, training, customer
                    support.
                  </Typography>
                  <CustomTextField
                    value={
                      vendorInquiry[selectedCategory]?.pricing_structure
                        ?.addl_pricing_info
                    }
                    width="100%"
                  />
                </Box>
              </Box>
              <Box className="review_vendorReq">
                <Typography className="fieldTitle">Vendor Req.</Typography>
                <Box className="review_VendorField">
                  <Typography className="customerReview_question">
                    What specific information about the Vendor is required?
                    Check only those is a must, remember not all companies can/
                    willing to provide.{" "}
                  </Typography>
                  {Object.keys(
                    vendorInquiry[selectedCategory]?.vendor_requirement || {}
                  )
                    .filter(
                      (key) =>
                        vendorInquiry[selectedCategory]?.vendor_requirement[
                          key
                        ]
                    )
                    .map((key) => {
                      const isChecked =
                        vendorInquiry[selectedCategory]?.vendor_requirement[
                          key
                        ];
                      const uiKeyName = VendorReqKeys[key];
                      if (
                        vendorInquiry[selectedCategory]?.vendor_requirement[
                          key
                        ] === true
                      ) {
                        return (
                          <Box
                            key={key}
                            className={
                              isChecked
                                ? "rfiReview_checkedCheckbox"
                                : "rfiReview_pricing"
                            }
                          >
                            <Stack
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "16px",
                                alignItems: "center",
                              }}
                            >
                              <Typography className="pricing_detail">
                                {uiKeyName}
                              </Typography>
                            </Stack>
                            <Checkbox
                              className="customCheckbox"
                              checked={isChecked}
                            />
                          </Box>
                        );
                      }
                    })}
                </Box>
                <Box className="review_TechField">
                  <Typography className="rfi_question">
                    Please specify any additional information you need.
                  </Typography>
                  <CustomTextField
                    value={
                      vendorInquiry[selectedCategory]?.vendor_requirement
                        ?.addl_requirement_info
                    }
                    width="100%"
                  />
                </Box>
              </Box>
              <Box className="review_Deadline">
                <Typography className="fieldTitle">Deadlines</Typography>
                <Box sx={{ marginBottom: "18px" }}>
                  <Typography className="customerReview_question">
                    Please select a deadline date.
                  </Typography>
                  <Box className="rfi_field">
                    <Typography className="rfi_question">
                      Last date for submission
                    </Typography>
                    <CustomTextField
                      value={vendorInquiry[
                        selectedCategory
                      ]?.deadlines?.submission_deadline.slice(0, 10)}
                      width="100%"
                    />
                  </Box>
                </Box>
              </Box>
              <Box className="review_DealBreaker">
                <Typography className="fieldTitle">Deal Breakers</Typography>
                <Box sx={{ marginBottom: "14px" }}>
                  <Typography className="customerReview_question">
                    Please enter the deal breakers. This will not be shared with
                    the vendor. However, it will help me to filter out
                    responses.
                  </Typography>
                </Box>
                <Box className="review_TechField" sx={{ marginBottom: "10px" }}>
                  <Typography className="rfi_question">
                    Enter the deal breakers, this could be a trail period, SOC2
                    compliance, lack of specific features.{" "}
                  </Typography>
                  <CustomTextField
                    value={
                      vendorInquiry[selectedCategory]?.deal_breakers
                        ?.dealbreakers
                    }
                    width="100%"
                  />
                </Box>
                <Box className="review_TechField">
                  <Typography className="rfi_question">
                    What’s your budget? (Annual in USD)
                  </Typography>
                  <CustomTextField
                    value={
                      vendorInquiry[selectedCategory]?.deal_breakers?.budget
                    }
                    width="100%"
                  />
                </Box>
              </Box>
              <Box className="review_TechStack">
                <Typography className="fieldTitle">
                  Tech Stack & Supporting Files
                </Typography>
                <Box className="rfitechStack_continer">
                  <Typography className="customerReview_question">
                    Sharing your tech stack, will help the vendor answer the
                    requirements better. This will help reduce multiple back and
                    forth between the tech teams and increase process
                    efficiency.
                  </Typography>
                  {Object.keys(
                    vendorInquiry[selectedCategory]?.tech_stack_and_files || {}
                  )
                    .filter(
                      (key) =>
                        vendorInquiry[selectedCategory]?.tech_stack_and_files[
                          key
                        ]
                    )
                    .map((key) => {
                      const isChecked =
                        vendorInquiry[selectedCategory]?.tech_stack_and_files[
                          key
                        ];
                      const UiTechstackKeys = TechstackKeys[key];
                      if (
                        vendorInquiry[selectedCategory]?.tech_stack_and_files[
                          key
                        ] === true
                      ) {
                        return (
                          <Box
                            key={key}
                            className={
                              isChecked
                                ? "rfiReview_checkedCheckbox"
                                : "rfiReview_pricing"
                            }
                          >
                            <Stack
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "16px",
                                alignItems: "center",
                              }}
                            >
                              <Typography className="pricing_detail">
                                {UiTechstackKeys}
                              </Typography>
                            </Stack>
                            <Checkbox
                              className="customCheckbox"
                              checked={isChecked}
                            />
                          </Box>
                        );
                      }
                    })}
                </Box>
                <Box className="review_uploadTemplate">
                  <Box className="reviewUpload">
                    <img
                      src="/images/tabler_file-filled.svg"
                      style={{ height: "20px", width: "20px" }}
                    />
                    <span className="filename">{fileName}</span>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Stack width="963px" sx={{ textAlign: "center" }}>
          <ReviewFooterButton
            MoveNext={MoveNext}
            moveback={moveback}
            isdisable={isdisable}
            confirmSubmit={confirmSubmit}
          />
        </Stack>
      </Box>
    </>
  );
};

export default ReviewSubmit;

ReviewSubmit.propTypes = {
  setSliderValue: PropTypes.any,
  sliderValue: PropTypes.any,
  openSelectedStep: PropTypes.any,
  selectedCategory: PropTypes.any,
};
