import { Box, Typography } from "@mui/material/node";
import React, { useEffect, useRef } from "react";
import "./category.css";
import BrandIcon from "../../components/search/brandmark-design (16) 2 (1).png";
import CategoryVideo from "./category.mp4";

const Category = () => {
  const videoRef = useRef(null);
  useEffect(() => {
    if (videoRef.current) {
      const handleEnded = () => {
        if (videoRef.current) {
          videoRef.current.currentTime = 0;
          videoRef.current.play();
        }
      };

      videoRef.current.addEventListener("ended", handleEnded);
      videoRef.current.play();

      return () => {
        videoRef.current?.removeEventListener("ended", handleEnded);
      };
    }
  }, []);

  return (
    <Box className="categoryContainer">
      <Box className="categoryHeadingBox">
        <img
          src={BrandIcon}
          alt=""
          style={{ height: "25px", width: "140px" }}
        />
        <Typography className="categoryHeadline">
          Helping you to find the
          <span className="highlightWord"> RIGHT</span> solutions in
        </Typography>
      </Box>
      <Box className="categoryVideoContainer">
        <video autoPlay muted loop ref={videoRef}>
          <source src={CategoryVideo} type="video/mp4" />
        </video>
      </Box>
    </Box>
  );
};

export default Category;
