import { Box, Button, Stack } from "@mui/material/node";
import Typography from "@mui/material/node/Typography";
import React, { useEffect, useRef, useState } from "react";
import "./dashboardInformation.css";
import { useDispatch, useSelector } from "react-redux";
import { saveVendorInquiry, getVendorInquiries } from "../../redux/rfiSlice";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import Modal from "@mui/material/node/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { ToastContainer, toast } from "react-toastify";
import {
  makeConnectRequest
} from "../../redux/dashboardSlice";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const DashboardInformation = () => {
  const dispatch = useDispatch();
  const [openConnect, setOpenConnect] = useState(false);

  const { SingleVendor, getChildCategory } = useSelector(
    (state) => state.promptdata
  );
  const { vendorInquiry, saveInquiry } = useSelector((state) => state.rfiData);
  const { userConnect } =
    useSelector((state) => state.dashboardData);

  const [requestStatus, setRequestStatus] = useState([]);
  const handleClose = () => setOpenConnect(false);

  const infoRef = useRef(null);
  useEffect(() => {
    infoRef.current.scrollIntoView({ behavior: "smooth" });
  }, [infoRef.current]);

  useEffect(() => {
    if(userConnect) {
      setRequestStatus(userConnect.map(() => false));
    }
  }, [userConnect]);

  useEffect(() => {
    dispatch(getVendorInquiries());
  }, [saveInquiry]);

  const handleAddToRfi = async () => {
    const existingInquiry = vendorInquiry.find(
      (inquiry) => inquiry.vendor_category === getChildCategory
    );
    let newInquiryData;
    if (existingInquiry) {
      const updatedData = {
        saved_step: "company_review",
        vendor_category: getChildCategory,
        vendor_list: [...existingInquiry.vendor_list, SingleVendor.vendor_id],
      };
      dispatch(saveVendorInquiry(updatedData));
    } else {
      newInquiryData = {
        saved_step: "company_review",
        vendor_category: getChildCategory,
        vendor_list: [SingleVendor.vendor_id],
      };
      dispatch(saveVendorInquiry(newInquiryData));
    }
  };

  const handleUserConnect = () => {
    if (userConnect.length === 0) {
      toast("Currently, no user exists.");
    } else {
      setOpenConnect(true);
    }
  };
  const handleCallback = (data, index) => {
    if(typeof data === 'string'){
      const newstate = requestStatus.map((value, index1) => index1 === index ? true : value);
      setRequestStatus(newstate);
      // toast("Your request to connect has been sent!");
    } else {
      // toast(data?.response?.data?.detail);
    }
  };

  const handleConnectRequest = (item, index) => {
    const data = {
      peer_user: item?.user_id,
      vendor_id: SingleVendor?.vendor_id,
      vendor_category: getChildCategory,
    };
    dispatch(makeConnectRequest(data, handleCallback, index));
  };

  return (
    <Box display="flex" flexDirection="column" width="90%" ref={infoRef}>
      <ToastContainer />
      <Box className="dashboardInfo_wrapper">
        <Box className="dashboardInfo_heading">Company Information</Box>
        <Box className="dashboardInfo_btnGroup">
          <Button className="connectBtn" onClick={handleUserConnect}>
            <Typography
              className="connectbtn_typography"
              sx={{ color: "#e2007b" }}
            >
              Connect with User
            </Typography>
            <InsertLinkIcon className="connectIcon" />
          </Button>
          <Button
            className="dashboardInfo_connect_box"
            onClick={handleAddToRfi}
          >
            Inquire with Vendor
          </Button>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" width="100%">
        <Box className="dashboardInfo_heading_box">
          <Box className="dashboardInfo_heading_innerbox">
            <Box color="white" style={{ width: "50%" }}>
              <Typography style={{ marginLeft: "7%" }}>Description</Typography>
            </Box>
            <Box className="dashboardInfo_column_heading">
              <Typography>Additional Information</Typography>
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" width="100%"></Box>
        <Box className="dashboardInfo_content_box">
          <Box className="dashboardInfo_content_inner_box">
            <Box color="white" width="50%">
              <Typography style={{ marginLeft: "7%" }}>
                {SingleVendor?.description}
              </Typography>
            </Box>
            <Box className="dashboardInfo_list">
              <Box style={{ marginLeft: "16%" }}>
                <li>
                  <Typography>
                    G2 score : {SingleVendor?.G2_Rating}/5
                  </Typography>
                </li>
                <li>
                  <Typography>
                    average revenue: {SingleVendor?.revenue}
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Number of employees: {SingleVendor?.employees}
                  </Typography>
                </li>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <div>
        <Modal
          open={openConnect}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="connectModal">
            <Box className="connect_box">
              <Stack className="connectBoxHeading">
                <Typography className="connectHeadingTypography">
                  Current users for {SingleVendor.vendor_name}
                </Typography>
                <img src={SingleVendor.logo} className="vendorConnectLogo" />
                <Button
                  className="closeConnectModal"
                  onClick={() => handleClose()}
                >
                  <CloseIcon sx={{ color: "white" }} />
                </Button>
              </Stack>
            </Box>
            <Box className="connectVendor">
              <Box className="userBox">
                <Carousel
                  draggable
                  additionalTransfrom={0}
                  autoPlaySpeed={3000}
                  centerMode={false}
                  className="userCarousel"
                  customTransition="container-with-dots"
                  focusOnSelect
                  infinite
                  keyBoardControl
                  minimumTouchDrag={80}
                  renderButtonGroupOutside={false}
                  renderDotsOutside={false}
                  responsive={{
                    desktop: {
                      breakpoint: {
                        max: 3000,
                        min: 1024,
                      },
                      items: 2,
                      partialVisibilityGutter: 40,
                    },
                    mobile: {
                      breakpoint: {
                        max: 464,
                        min: 0,
                      },
                      items: 2,
                      partialVisibilityGutter: 30,
                    },
                    tablet: {
                      breakpoint: {
                        max: 1024,
                        min: 464,
                      },
                      items: 2,
                      partialVisibilityGutter: 30,
                    },
                  }}
                  showDots
                  slidesToSlide={2}
                  arrows={false}
                  swipeable={false}
                  autoPlay={false}
                  sx={{
                    "& .react-multi-carousel-item": {
                      display: "flex",
                      alignItems: "center",
                    },
                    "& .react-multi-carousel-list": {
                      width: "100%",
                      transition: "transform 0.5s ease-in-out !important",
                    },
                    "& .react-multi-carousel-track": {
                      height: "90%",
                    },
                  }}
                >
                  {userConnect.map((item, index) => (
                    <Stack className="userRequest" key={index}>
                      <Stack className="connectBrandIcon">{item?.logo}</Stack>
                      <Stack className="userDetailBox">
                        <Stack className="userDetailKey">
                          <Typography className="keyNameTypography">
                            NAME
                          </Typography>
                        </Stack>
                        <Stack className="userDetailValue">
                          {item?.display_name}
                        </Stack>
                      </Stack>
                      <Stack className="userDetailBox">
                        <Stack className="userDetailKey">
                          <Typography className="keyNameTypography">
                            Function
                          </Typography>
                        </Stack>
                        <Stack className="userDetailValue">
                          {item?.job_function}
                        </Stack>
                      </Stack>
                      <Stack className="userDetailBox">
                        <Stack className="userDetailKey">
                          <Typography className="keyNameTypography">
                            Title
                          </Typography>
                        </Stack>
                        <Stack className="userDetailValue">{item?.title}</Stack>
                      </Stack>
                      <Stack className="userDetailBox">
                        <Stack className="userDetailKey">
                          <Typography className="keyNameTypography">
                            Company Rev.
                          </Typography>
                        </Stack>
                        <Stack className="userDetailValue">
                          {item?.company?.revenue}
                        </Stack>
                      </Stack>
                      <Stack className="userDetailBox">
                        <Stack className="userDetailKey">
                          <Typography className="keyNameTypography">
                            # of employees
                          </Typography>
                        </Stack>
                        <Stack className="userDetailValue">
                          {item?.company?.employees}
                        </Stack>
                      </Stack>
                      <Stack className="userDetailBox">
                        <Stack className="userDetailKey">
                          <Typography className="keyNameTypography">
                            Industry
                          </Typography>
                        </Stack>
                        <Stack className="userDetailValue">
                          {item?.industry}
                        </Stack>
                      </Stack>
                      <Stack className="userDescriptionBox">
                        <Stack className="userDetailKey">
                          <Typography className="keyNameTypography">
                            Description
                          </Typography>
                        </Stack>
                        <Stack className="userDetailValue">
                          {item?.company?.description}
                        </Stack>
                      </Stack>
                      <Button
                        className={
                          requestStatus[index]
                            ? "requestSent_btn"
                            : "requestConnect_btn"
                        }
                        onClick={() => handleConnectRequest(item, index)}
                      >
                        {requestStatus[index] === true ? (
                          <Typography className="connectTypography">
                            Request Sent
                          </Typography>
                        ) : (
                          <Typography className="connectTypography">
                            Request to connect
                          </Typography>
                        )}
                      </Button>
                    </Stack>
                  ))}
                </Carousel>
              </Box>
            </Box>
          </Box>
        </Modal>
      </div>
    </Box>
  );
};

export default DashboardInformation;
