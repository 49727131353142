import { createSlice } from "@reduxjs/toolkit";
import { api } from "../axiosinterceptor/axiosInterceptor";

const initialState = {
  isLoading: false,
  successLogin: false,
  successMessage: "",
  errorLogin: false,
  errorMessage: "",
  status: "",
  success: false,
  inviteMessage: "",
  error: false,
  inviteErrorMessage: "",
  getPassword: {},
  successReset: false,
  showToast: false,
  toastMessage: "",
  // type: "",
};

const applicationSlice = createSlice({
  name: "applicationData",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    loginsuccess(state, action) {
      state.isLoading = false;
      state.successLogin = true;
      state.successMessage = "Logged in Successfully";
      state.status = action.payload;
    },
    resetLogin(state) {
      state.isLoading = false;
      state.successLogin = false;
      state.successMessage = "";
      state.errorLogin = false;
      state.errorMessage = "";
    },

    loginError(state, action) {
      state.errorLogin = true;
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    inviteSuccess(state) {
      state.success = true;
      state.inviteMessage = "Invite has been sent";
    },
    errorRespone(state, action) {
      state.error = true;
      state.errorMessage = action.payload;
    },
    resetPassword(state, action) {
      state.getPassword = action.payload;
      state.success = true;
    },
    setShowToast(state, action) {
      state.showToast = action.payload.show;
      state.toastMessage = action.payload.toastMessage;
      state.type = action.payload.type;
    },
  },
});

export const { startLoading, resetLogin, stopLoading, setShowToast } =
  applicationSlice.actions;

export default applicationSlice.reducer;

export function Login(userdata) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await api.post("/open/login/", userdata);
      dispatch(applicationSlice.actions.loginsuccess(response));
      dispatch(setShowToast({show: true, toastMessage: "Logged in Successfully"}));
    } catch (errorLogin) {
      if (errorLogin?.response?.status === 401) {
        dispatch(
          applicationSlice.actions.loginError(
            errorLogin?.response?.data?.detail
          )
        );
        dispatch(setShowToast({show: true, toastMessage: errorLogin?.response?.data?.detail}));
      } else if (errorLogin?.response.status === 422) {
        dispatch(
          applicationSlice.actions.loginError(
            errorLogin?.response?.data?.detail[0]?.msg
          )
        );
      }
    }
  };
}

export function getResetPassword(email, callback) {
  return async (dispatch) => {
    try {
      const response = await api.get("/open/reset_password_request", {
        params: { email },
      });
      dispatch(applicationSlice.actions.resetPassword(response));
      if (callback) callback(response);
    } catch (error) {
      dispatch(
        applicationSlice.actions.errorRespone(error.response.data.detail)
      );
      if (callback) callback(error);
    }
  };
}

export function sendInvite(data) {
  return async (dispatch) => {
    try {
      await api.post("/send_invitation/", data);
      dispatch(setShowToast({show: true, toastMessage: "Invite has been sent"}));
    } catch (error) {
      if(error?.response.status === 422){
        console.log("hii", error?.response?.data?.detail);
        dispatch(setShowToast({show: true, toastMessage: error?.response?.data?.detail}));
      } else {
        dispatch(setShowToast({show: true, toastMessage: error?.response?.data?.detail}));
      }
    }
  };
}

export function changePassword(data, callback, secret) {
  return async (dispatch) => {
    try {
      const response = await api.post(`/open/reset_password/${secret}/`, data);
      if (callback) callback(response);
    } catch (error) {
      dispatch(
        applicationSlice.actions.errorRespone(error?.response?.data?.detail)
      );
      if (callback) callback(error);
    }
  };
}
