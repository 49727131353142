import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import "./rfiSuccess.css";

const RfiSuccess = () => {
  return (
    <>
      <Stack
        className="profile_page_container"
        style={{
          height: "100%",
        }}
      >
        <Box className="successContainer">
          <Typography className="successHeading">
            Success!
          </Typography>
          <Stack className="successContent">
            <Typography className="successMessage">
              Your inquiries have been submitted to the selected vendors.
            </Typography>
          </Stack>
        </Box>
      </Stack>
    </>
  );
};

export default RfiSuccess;
