import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import { rootPersistConfig, rootReducer } from "./rootreducer";

const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
});
const persistor = persistStore(store);

export { store, persistor };
