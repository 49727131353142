import {
  Stack,
  Box,
  Typography,
  Checkbox,
  Tooltip,
  tooltipClasses,
  Button
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import styled from "styled-components";
import StarIcon from "@mui/icons-material/Star";
import CloseIcon from "@mui/icons-material/Close";
import { resetData, setuserProfile } from "../../redux/userslice";
import {
  getVendors,
  getPostedTechStack,
  SetCriticalVendor,
  UnsetCriticalVendor,
  removeTechStack,
  addTechStack
} from "../../redux/techStackSlice";
import { startLoading } from "../../redux/applicationSlice";
import FooterButton from "../footerButton/FooterButton";
import Header from "../header/Header";
import { Oval } from "react-loader-spinner";
import Modal from "@mui/material/node/Modal";
import TechstacknodeGraph from "../../Layout/TechstacknodeGraph";
import Tech3dgraph from "../../Layout/Tech3dgraph";
import "./techstack.css";
import PropTypes from "prop-types";
import InfoIcon from "@mui/icons-material/Info";
import SliderComponent from "../sliderComponent/sliderComponent";
import { TechStackInfo } from "../../util/constant";
import CustomTextField from "../otherComponent/customTextfield";

const label = { inputProps: { "aria-label": "Checkbox demo" } };
const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  height: "80%",
  padding: "0px",
  bgcolor: "#191819",
  justifyContent: "center",
};
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#0a0a0a",
    fontSize: "1",
    border: "1px solid #E2007B ",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#E2007B",
  },
}));
const CriticalTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#e2007b",
    fontSize: "1",
    border: "1px solid white ",
    color: "white",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#E2007B",
  },
}));

const TechStack = ({ setValue, value, openSelectedStep, techstack }) => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [checkedItems, setCheckedItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showgraph, setshowgraph] = useState(false);
  const [stopmoving, setstopmoving] = useState(false);
  const [clickedItems, setClickedItems] = useState([]);
  const [text, setText] = useState("");
  const [typingIndex, setTypingIndex] = useState(0);
  const handleClose = () => setOpenModal(false);
  const { techStack, selectedTechstack, techStacksuccess } = useSelector(
    (state) => state.techStackData
  );
  const { userProfiledata } = useSelector((state) => state.promptdata);
  const { isLoading } = useSelector((state) => state.applicationData);
  const [vendorDropdown, setvendorDropdown] = useState();

  useEffect(() => {
    if (typingIndex !== TechStackInfo.length && !isLoading) {
      setText((prevText) => prevText + TechStackInfo.charAt(typingIndex));
      setTypingIndex((prevIndex) => prevIndex + 1);
    }
  }, [typingIndex, isLoading]);

  useEffect(() => {
    dispatch(resetData());
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const MoveNext = async () => {
    const data = {
      ...userProfiledata,
      onboarding: "Plan",
    };
    localStorage.setItem("onboarding", "Plan");
    dispatch(setuserProfile(data));
    dispatch(startLoading());
    setstopmoving(true);
    dispatch(addTechStack(checkedItems));
  };

  const handleSearch = (e) => {
    setOpen(true);
    setSearchQuery(e.target.value);
  };
  const moveback = () => {
    if (pathname === "/onboarding") {
      openSelectedStep("invite");
    } else {
      navigate("/company_profile");
    }
  };

  const handleOpen = (event) => {
    setOpenModal(true);
    if (event === "visualisation") {
      setshowgraph(false);
    } else if (event === "dynamic") {
      setshowgraph(true);
    }
  };

  const getTechdata = (item) => {
    if (
      checkedItems.some(
        (checkedItem) => checkedItem.vendor_id === item.vendor_id
      )
    ) {
      setCheckedItems(
        checkedItems.filter(
          (checkedItem) => checkedItem.vendor_id !== item.vendor_id
        )
      );
    } else {
      setCheckedItems([...checkedItems, item]);
    }
  };

  const isButtonDisabled = checkedItems.length < 1;

  const deleteselectVendor = (item) => {
    dispatch(removeTechStack(item?.vendor_id, item));
    const updatecheckeditems = checkedItems.filter((vendor) => vendor !== item);
    setCheckedItems(updatecheckeditems);
  };
  const handleClick = () => {
    setstopmoving(false);
    dispatch(addTechStack(checkedItems));
    setOpen(false);
  };

  useEffect(() => {
    if (techStacksuccess && stopmoving) {
      toast("tech stack added Succesfully");
      if (pathname === "/onboarding") {
        openSelectedStep("testerProfile");
      } else {
        navigate("/dashboard");
      }
    }
  }, [techStacksuccess, stopmoving]);

  useEffect(() => {
    setValue(75.5);
    dispatch(getVendors());
    dispatch(getPostedTechStack());
  }, []);

  useEffect(() => {
    const data = [...techStack];
    setvendorDropdown(data);
  }, [techStack]);

  useEffect(() => {
    if (selectedTechstack.length > 0) {
      const tempdata = [...selectedTechstack];
      setCheckedItems(tempdata);
    }
  }, [selectedTechstack]);

  const handleLinkClick = (event, url) => {
    event.preventDefault();
    window.open(`https://${url}`, "_blank");
  };

  const handleBoxClick = (item) => {
    if (
      clickedItems.filter((obj) => obj.vendor_id === item.vendor_id).length > 0
    ) {
      dispatch(UnsetCriticalVendor(item.vendor_id));
    } else if (
      clickedItems.filter((obj) => obj.is_critical === true).length < 3
    ) {
      dispatch(SetCriticalVendor(item.vendor_id));
    } else {
      toast("You cannot select more than 3 critical vendor");
    }
  };

  useEffect(() => {
    const clickedItems1 = selectedTechstack?.filter(
      (obj) => obj.is_critical === true
    );
    setClickedItems(clickedItems1);
  }, [selectedTechstack]);

  return (
    <Stack className="main">
      <ToastContainer />
      <Header />
      <Stack className="techstack_wrapper" id="bg-image">
        {isLoading ? (
          <Stack className="page_loading">
            <Oval
              height={80}
              width={100}
              color="#E75480"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#7a0d66"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </Stack>
        ) : (
          <>
            {pathname === "/onboarding" && (
              <SliderComponent setValue={setValue} value={value} />
            )}
            <Stack className="container">
              {pathname.includes("onboarding") && (
                <>
                  <Box className="infoIcon_wrapper">
                    <InfoIcon
                      sx={{ color: "#e2007b", textAlign: "right" }}
                    />
                    <Typography className="infoIconData">{text}</Typography>
                  </Box>
                </>
              )}
              <Stack className="vendor-box">
                <Stack className="vendors_marchtch_input">
                  <Typography className="techstack_heading">
                    TECH STACK
                  </Typography>
                  <Typography className="myTypography">
                    Choose your current Martech stack vendors.
                    <br /> Select up to three critical vendors for new company
                    integration.
                  </Typography>
                  <CustomTextField
                    name="last_name"
                    width="640px"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={handleSearch}
                  />
                  <Stack
                    className="techstack_selected_item_Box"
                    style={{
                      backgroundColor: !open ? "#191819" : "transparent",
                    }}
                  >
                    <Stack className="vendor-checkbox">
                      {!open && (
                        <Stack className="vender_selection_wrapper">
                          {checkedItems?.map((item) => (
                            <Stack
                              key={item.vendor_id}
                              className={
                                clickedItems.filter(
                                  (obj) => obj.vendor_id === item.vendor_id
                                ).length > 0
                                  ? "vender_selection_activebox"
                                  : "vender_selection_box"
                              }
                              onClick={() => handleBoxClick(item)}
                            >
                              <HtmlTooltip
                                wrapperStyle={{
                                  backgroundColor: "#4A4A4A",
                                  height: "88px",
                                  width: "215px",
                                }}
                                className="example"
                                title={
                                  <div>
                                    <span
                                      style={{
                                        font: "Poppins",
                                        fontWeight: "700px",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {item.vendor_name || item.name}
                                    </span>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        float: "right",
                                      }}
                                    >
                                      <img
                                        src={item.logo || item.logo_favicon}
                                        alt="Clearbit Logo"
                                        className="ToolTiplogo"
                                      />
                                    </div>
                                    <br />
                                    <span
                                      style={{
                                        font: "Poppins",
                                        fontWeight: "300px",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {" "}
                                      {item.child_category_name ||
                                        item.parent_category}
                                    </span>

                                    <br />
                                    <a
                                      style={{
                                        font: "Poppins",
                                        fontWeight: "400px",
                                        fontSize: "12px",
                                      }}
                                      href={item.url || item.company_url}
                                      onClick={(event) =>
                                        handleLinkClick(
                                          event,
                                          item.url || item.company_url
                                        )
                                      }
                                    >
                                      {item.url || item.company_url}
                                    </a>
                                  </div>
                                }
                                arrow
                                placement="top"
                              >
                                <Stack>
                                  <img
                                    src={item.logo || item.logo_favicon}
                                    className="logo"
                                  />
                                </Stack>
                              </HtmlTooltip>
                              <Stack
                                style={{
                                  position: "absolute",
                                  top: "7px",
                                  right: "5px",
                                  width: "12px",
                                  height: "12px",
                                }}
                              >
                                <CloseIcon
                                  className={
                                    clickedItems.filter(
                                      (obj) => obj.vendor_id === item.vendor_id
                                    ).length > 0
                                      ? "image-activeSelection"
                                      : "image-selection"
                                  }
                                  onClick={() => deleteselectVendor(item)}
                                />
                              </Stack>
                              <CriticalTooltip
                                key={item.vendor_id}
                                wrapperStyle={{
                                  backgroundColor: "#4A4A4A",
                                  height: "88px",
                                  width: "215px",
                                }}
                                className="example"
                                title="Critical Vendor"
                                arrow
                                placement="top"
                              >
                                <Stack
                                  className="hoveredCriticalVendor"
                                  style={{
                                    position: "absolute",
                                    top: "52.5px",
                                    right: "5px",
                                    width: "18px",
                                    height: "18px",
                                    backgroundColor: "#212021",
                                    borderRadius: "4px",
                                    boxShadow: "0px 0px 4px 0px #000000",
                                  }}
                                >
                                  <StarIcon
                                    className={
                                      clickedItems.filter(
                                        (obj) =>
                                          obj.vendor_id === item.vendor_id
                                      ).length > 0
                                        ? "image-activeSelection"
                                        : "image-selection"
                                    }
                                  />
                                </Stack>
                              </CriticalTooltip>
                            </Stack>
                          ))}
                        </Stack>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
                {open && (
                  <Box className="vendor_selection_box_wrapper " ref={ref}>
                    <Box className="vendor_Selection1">
                      {(vendorDropdown || [])
                        .filter((val) =>
                          val.vendor_name
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase())
                        )
                        ?.slice(0, 100)
                        .map((item, i) => (
                          <>
                            <Box
                              key={item.id}
                              className="vendor_items"
                              onClick={() => getTechdata(item)}
                            >
                              <Box className="vendorImage_box">
                                <img
                                  className="vendor_image"
                                  src={item.logo || item.logo_favicon}
                                />
                                <Box key={i}>
                                  <Typography className="myTypography">
                                    {item.vendor_name}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                style={{
                                  justifyContent: "flex-end",
                                  display: "flex",
                                }}
                              >
                                <Checkbox
                                  className="checkbox"
                                  checked={checkedItems.some(
                                    (checkedItem) =>
                                      checkedItem.vendor_id === item.vendor_id
                                  )}
                                  {...label}
                                  sx={{
                                    color: "#e2007b",
                                    "&.Mui-checked": {
                                      color: "#E2007B",
                                    },
                                  }}
                                />
                              </Box>
                            </Box>
                          </>
                        ))}
                    </Box>
                    <Box className="btn-box">
                      <Button className="done-btn" onClick={handleClick}>
                        Done
                      </Button>
                    </Box>
                  </Box>
                )}
              </Stack>
            </Stack>
          </>
        )}
        <Stack
          className="graph_btn"
        >
          {!isLoading && (
            <>
              <Box>
                <Button
                  className="Visualization_btn"
                  onClick={() => handleOpen("visualisation")}
                >
                  See Visualisation
                </Button>
                <Button
                  className="dynamicData_btn"
                  onClick={() => handleOpen("dynamic")}
                >
                  Dynamic data
                </Button>
              </Box>
            </>
          )}
          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="modal_background">
              <Box>
                {" "}
                <Header />
              </Box>
              <Box sx={style2}>
                {!showgraph ? (
                  <TechstacknodeGraph onClose={setOpenModal} />
                ) : (
                  <Tech3dgraph onClose={setOpenModal} />
                )}
              </Box>
            </Box>
          </Modal>
          {!isLoading && (
            <FooterButton
              MoveNext={MoveNext}
              isdisable={isButtonDisabled}
              moveback={moveback}
              techstack={techstack}
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default TechStack;

TechStack.propTypes = {
  value: PropTypes.any,
  setValue: PropTypes.any,
  openSelectedStep: PropTypes.any,
  techstack: PropTypes.any,
};
