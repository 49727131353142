import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import {
  Box,
  Stack,
  Typography,
  Modal,
  Tooltip,
  Button
} from "@mui/material/node";
import "./decision_tree.css";
import { Oval } from "react-loader-spinner";
import { resetData, getCategory } from "../../redux/userslice";
import {
  getAllpromptCategories,
  getCategoryDescription
} from "../../redux/decisionTreeSlice";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useDispatch, useSelector } from "react-redux";
import FooterButton from "../footerButton/FooterButton";
import { useNavigate } from "react-router";
import Checkbox from "@mui/material/node/Checkbox";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";

const DecisionTree = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [hovered, setHovered] = useState({});
  const [description, setDescription] = useState();
  const [loadingDescription, setLoadingDescription] = useState(false);
  const [criteria, setCriteria] = useState({
    parent: "",
    child: "",
  });

  const { categoryInfo, categories } = useSelector(
    (state) => state.decisionTreeData
  );
  const { isLoading } = useSelector((state) => state.applicationData);

  const handleOpenModal = async (item) => {
    setOpenModal(true);
    setLoadingDescription(true);
    await dispatch(getCategoryDescription(item));
    setLoadingDescription(false);
    setDescription(item);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  useEffect(() => {
    dispatch(resetData());
  }, []);
  const moveback = () => {
    navigate("/profile");
  };

  const data = async () => {
    dispatch(getCategory(criteria.child));
    navigate("/dashboard");
  };
  const isButtonDisabled =
    criteria?.child?.length < 1 || criteria?.child == null;

  const handleKeyClick = (key) => {
    if (criteria.parent === key) {
      setCriteria({ parent: null, child: "" });
    } else {
      setCriteria({ parent: "", child: "" });
    }
  };
  const handleMouseEnter = (obj) => {
    setHovered(obj);
  };

  const handleMouseLeave = () => {
    setHovered({});
  };

  const handleCheckboxClick = async (event) => {
    const checkboxValue = event.target.value;
    setCriteria((prevCriteria) => ({
      ...prevCriteria,
      parent: prevCriteria.child === checkboxValue ? null : hovered?.key,
      child: prevCriteria?.child === checkboxValue ? null : checkboxValue,
    }));
  };
  useEffect(() => {
    dispatch(getAllpromptCategories());
    dispatch(resetData());
  }, []);

  return (
    <>
      <Stack className="guidedSearch_page">
        <Header />
        <Stack className="guidedSearch_wrapper">
          {isLoading ? (
            <Stack
              sx={{
                alignItems: "center",
                justifyContent: "center",
                height: "80vh",
              }}
            >
              <Oval
                height={80}
                width={100}
                color="#E75480"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#7a0d66"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </Stack>
          ) : (
            <>
              <Tooltip
                title="Back To Dashboard"
                arrow
                placement="bottom"
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "black",
                      color: "#e2007b",
                      padding: "10px",
                      lineHeight: "24px",
                      border: "0.5px solid #e2007b",
                      "& .MuiTooltip-arrow": {
                        color: "#e2007b",
                      },
                      typography: {
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        fontWeight: "400px",
                        lineHeight: "18px",
                      },
                    },
                  },
                }}
              >
                <Button
                  onClick={() => navigate("/landing_page")}
                  className="bachFrom_guided"
                >
                  <KeyboardArrowLeftIcon sx={{ color: "#AE0770" }} />
                </Button>
              </Tooltip>
              <Box className="decisiontree_wrapper">
                <Typography className="decisiontree_heading">
                  Please select a category you are looking for.
                </Typography>
                <Box className="guidedSearch_container">
                  <Stack className="decisiontree_parentCriteria">
                    {Object?.entries(categories)?.map(([key, value], index) => (
                      <Stack className="guidedSearch_categoryList" key={index}>
                        <Stack
                          onMouseEnter={() => handleMouseEnter({ key, value })}
                          onMouseLeave={() => handleMouseLeave}
                          sx={{ height: "100%" }}
                          onClick={() => handleKeyClick(key)}
                          className={`parentCriteria_box ${
                            criteria?.parent === key
                              ? "parentCriteria_activebox"
                              : hovered?.key === key
                                ? "hoveredBox"
                                : ""
                          }`}
                        >
                          <Typography className="parentCategory_nameTypography">
                            {key}
                          </Typography>
                        </Stack>
                        {hovered?.key === key && (
                          <Stack className="hover_customArrow">
                            <Stack className="hovered_arrowPart1"></Stack>
                            <Stack className="hovered_arrowPart2"></Stack>
                            <Stack className="hovered_arrowPart3"></Stack>
                          </Stack>
                        )}
                      </Stack>
                    ))}
                  </Stack>
                  <Stack className="guidedSearch_childContainer">
                    {Object?.keys(hovered)?.length > 0 && (
                      <Stack className="childContainer">
                        {(hovered?.value ? [...hovered.value] : [])
                          ?.sort((a, b) => a.localeCompare(b)) // Sort the array alphabetically
                          ?.map((item, itemIndex) => (
                            <Stack
                              className={
                                criteria?.child?.includes(item)
                                  ? "active_childCategory"
                                  : ""
                              }
                              key={itemIndex}
                              sx={{
                                height: "28px",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                paddingLeft: "5px",
                              }}
                            >
                              <Checkbox
                                className="customCheckboxes"
                                value={item}
                                checked={criteria?.child?.includes(item)}
                                onChange={(event) => {
                                  handleCheckboxClick(event, item);
                                }}
                              />
                              <Typography className="childCategory_nameTypography">
                                {item}
                              </Typography>
                              <InfoIcon
                                className="descriptionIcon"
                                onClick={() => handleOpenModal(item)}
                              />
                            </Stack>
                          ))}
                      </Stack>
                    )}
                  </Stack>
                </Box>
                <Modal
                  open={openModal}
                  onClose={handleCloseModal}
                  aria-labelledby="child-modal-title"
                  aria-describedby="child-modal-description"
                >
                  <Box className="descriptionModal">
                    <Box className="description_container">
                      <Box className="descriptionHeading">
                        {!loadingDescription && (
                          <Typography className="modal_InfoHeading">
                            What is {description}?
                          </Typography>
                        )}
                      </Box>
                      <Stack
                        className="description_close"
                        onClick={handleCloseModal}
                      >
                        <CloseIcon className="descriptionClose_icon" />
                      </Stack>
                    </Box>
                    <Box className="description_dataBox">
                      <Stack>
                        {loadingDescription ? (
                          <Typography className="modal_InfoDescription">
                            Loading...
                          </Typography>
                        ) : (
                          <Typography className="modal_InfoDescription">
                            {categoryInfo}
                          </Typography>
                        )}
                      </Stack>
                    </Box>
                  </Box>
                </Modal>
              </Box>
            </>
          )}
        </Stack>
        <Stack
          style={{
            marginTop: "34px",
            width: "83%",
          }}
          className="lower_button"
        >
          {!isLoading && (
            <FooterButton
              MoveNext={data}
              isdisable={isButtonDisabled}
              moveback={moveback}
            />
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default DecisionTree;
