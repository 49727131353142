import {
  Box,
  Stack,
  TextField,
  Typography,
  Button,
  IconButton,
  InputAdornment,
  Checkbox
} from "@mui/material";
import React, { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Form, FormikProvider, useFormik } from "formik";
import { signUpUser, resetData } from "../../redux/userslice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Oval } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import CustomTextField from "../otherComponent/customTextfield";
import "../profile/profile.css";
import * as Yup from "yup";
import "./signUp.css";

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const { success, isloading, error, errorMessage } = useSelector(
    (state) => state.promptdata
  );

  const NewProjectSchema = Yup.object().shape({
    password: Yup.string().required("Password Required"),
    first_name: Yup.string().required("First Name Required"),
    title: Yup.string().required("Title Required"),
    company_name: Yup.string().required("Company Name Required"),
    last_name: Yup.string().required("Last Name Required"),
    email: Yup.string().test(
      "domain",
      "Email domain must match website URL",
      function (value) {
        const websiteUrl = values.website;
        if (!websiteUrl || websiteUrl === "") {

          return true;
        }
        const emailDomain = value.split("@")[1];

        return websiteUrl.includes(emailDomain) || emailDomain.includes(websiteUrl);
      }
    ),
    industry: Yup.string(),
    website: Yup.string().required("Website URL Required").test("website", "Invalid URL", (value) => {
      if (!value || value === "") {
        return true;
      }

      return /^.*\..+$/.test(value);
    }),
  });

  useEffect(() => {
    if (success) {
      navigate("/verification");
    } else if (error) {
      toast(errorMessage);
    }
    dispatch(resetData());
  }, [success, error]);

  const formik = useFormik({
    initialValues: {
      password: "",
      first_name: "",
      title: "",
      company_name: "",
      last_name: "",
      email: "",
      industry: "",
      website: "",
    },
    validationSchema: NewProjectSchema,
    onSubmit: async (values) => {
      const userAccess = {
        password: values?.password,
        profile: {
          first_name: values?.first_name,
          last_name: values?.last_name,
          title: values?.title,
          company_name: values?.company_name,
          industry: values?.industry,
          email: values?.email,
          website: values?.website,
        },
      };
      dispatch(signUpUser(userAccess));
    },
  });

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const isDisable = !isChecked;

  const styles = {
    color: isDisable && "#E2007B",
    opacity: isDisable && "0.2",
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const moveback = () => {
    navigate("/login");
  };

  const { handleSubmit, getFieldProps, touched, errors, handleChange, values } =
    formik;

  return (
    <Stack sx={{ height: "100%" }}>
      <Stack className="signUp_header">
        <Box marginLeft="36px">
          <img src="/images/logo.png" alt="" />
        </Box>
      </Stack>
      <FormikProvider value={formik}>
        <Form className="login-form" noValidate autoComplete="off">
          <Stack className="signUp_wrapper">
            <ToastContainer />
            {isloading ? (
              <Stack className="page_loading">
                <Oval
                  height={80}
                  width={100}
                  color="#E75480"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#7a0d66"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </Stack>
            ) : (
              <Box className="signUp_container">
                <Stack sx={{ width: "80%" }}>
                  <Typography className="letStarted_typography">
                    Let’s Get Started
                  </Typography>
                </Stack>
                <Box className="signUp_fieldBox">
                  <Box className="profile_box_field_wrapper">
                    <CustomTextField
                      label="Email"
                      placeholder="Enter your email"
                      name="email"
                      width="360px"
                      value={values?.email}
                      {...getFieldProps("email")}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                    <Box>
                      <Typography className="title_field">Password</Typography>
                      <TextField
                        onChange={handleChange}
                        placeholder="Password"
                        value={values.password}
                        {...getFieldProps("password")}
                        sx={{
                          backgroundColor: "#191819",
                          width: "360px",
                          border: "1px solid #686868",
                          borderRadius: "8px",
                          fontFamily: "Poppins",
                          fontWeight: 600,
                          "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root":
                            {
                              color: "#E2007B",
                            },
                          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              border: "1px solid #E2007B",
                              borderRadius: "8px",
                            },
                          "& .MuiFormLabel-root.Mui-focused": {
                            color: "#E2007B",
                          },
                          "& .MuiOutlinedInput-input": {
                            color: "white",
                            padding: "0 0 0 10px",
                            height: "41px",
                          },
                        }}
                        type={showPassword ? "text" : "password"}
                        name="password"
                        fullWidth
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleTogglePasswordVisibility}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityIcon style={{ color: "grey" }} />
                                ) : (
                                  <VisibilityOff style={{ color: "grey" }} />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    className="profile_box_field_wrapper"
                    style={{ marginTop: "22px" }}
                  >
                    <CustomTextField
                      label="First Name"
                      placeholder="First Name"
                      name="first_name"
                      width="360px"
                      value={values?.first_name}
                      {...getFieldProps("first_name")}
                      error={Boolean(touched.first_name && errors.first_name)}
                      helperText={touched.first_name && errors.first_name}
                    />
                    <CustomTextField
                      label="Last Name"
                      name="last_name"
                      placeholder="Last Name"
                      width="360px"
                      value={values?.last_name}
                      {...getFieldProps("last_name")}
                      error={Boolean(touched.last_name && errors.last_name)}
                      helperText={touched.last_name && errors.last_name}
                    />
                  </Box>
                  <Box
                    className="profile_box_field_wrapper"
                    style={{ marginTop: "22px" }}
                  >
                    <CustomTextField
                      label="Title"
                      name="title"
                      width="360px"
                      placeholder="Title"
                      value={values?.title}
                      {...getFieldProps("title")}
                      error={Boolean(touched.title && errors.title)}
                      helperText={touched.title && errors.title}
                    />
                    <CustomTextField
                      label="Name of the Company"
                      name="company_name"
                      placeholder="Company Name"
                      width="360px"
                      value={values?.company_name}
                      {...getFieldProps("company_name")}
                      error={Boolean(
                        touched.company_name && errors.company_name
                      )}
                      helperText={touched.company_name && errors.company_name}
                    />
                  </Box>
                  <Box
                    className="profile_box_field_wrapper"
                    style={{ marginTop: "22px" }}
                  >
                    <CustomTextField
                      label="Industry"
                      name="industry"
                      width="360px"
                      placeholder="Industry"
                      value={values?.industry}
                      {...getFieldProps("industry")}
                      error={Boolean(touched.industry && errors.industry)}
                      helperText={touched.industry && errors.industry}
                    />
                    <CustomTextField
                      label="Website"
                      placeholder="Website"
                      name="website"
                      width="360px"
                      value={values?.website}
                      {...getFieldProps("website")}
                      error={Boolean(touched.website && errors.website)}
                      helperText={touched.website && errors.website}
                    />
                  </Box>
                </Box>
                <Box className="acceptCondition_conatiner">
                  <Checkbox
                    className="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <Typography className="accept_typography">
                    I accept all{" "}
                    <span
                      style={{ color: "#e2007b", cursor: "pointer" }}
                      onClick={() => navigate("/privacy")}
                    >
                      <u>Privacy policy</u>
                    </span>{" "}
                    and{" "}
                    <span
                      style={{ color: "#e2007b", cursor: "pointer" }}
                      onClick={() => navigate("/terms")}
                    >
                      <u>Terms & conditions</u>
                    </span>
                    .
                  </Typography>
                </Box>
              </Box>
            )}
            <Stack width="963px" style={{ marginTop: "20px" }}>
              {!isloading && (
                <Stack className="Footerbtn_wrapper">
                  <Box>
                    <Button className="cancel_btn" onClick={moveback}>
                      Cancel
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      className="createBtn"
                      onClick={handleSubmit}
                      disabled={isDisable}
                      style={styles}
                      type="submit"
                    >
                      Create Profile
                      <img src="/images/material-symbols_arrow-right-alt-rounded.svg" />
                    </Button>
                  </Box>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Form>
      </FormikProvider>
    </Stack>
  );
};

export default SignUp;
