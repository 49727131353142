import React, { useState, useRef } from "react";
import { Box, Button, Tooltip } from "@mui/material/node";
import "./dashboardGraph.css";
import { ForceGraph2D } from "react-force-graph";
import { useSelector } from "react-redux";
import Typography from "@mui/material/node/Typography";
import { Menu, MenuItem } from "@mui/material";
import html2canvas from "html2canvas";
import Jspdf from "jspdf";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import addLogo from "../../Layout/image_amp.png";

const colorCodes = ["#46145b", "#e2007b", "#ae0770", "#7a0d66", "#fcfcfc"];
const DashboardGraph = () => {
  const graphRef = useRef(null);
  const {
    vendorid,
    integrated,
    userProfiledata,
    SingleVendor,
  } = useSelector((state) => state.promptdata);
  const {topvendor, dashboardVendors} = useSelector((state) => state.dashboardData);
  const {selectedTechstack} = useSelector((state) => state.techStackData);
  const [disableZoomOnScroll, setDisableZoomOnScroll] = useState(false);
  const [showgraph, setshowgraph] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleNodeClick = () => {
    setDisableZoomOnScroll(!disableZoomOnScroll);
  };

  const findid = dashboardVendors?.vendors?.find((item) => item.vendor_id === vendorid);
  const publishedVendor = Object.values(topvendor)?.flat()?.find((item) => item.vendor_id === vendorid);
  const combinedVendor = findid || publishedVendor;
  const expandfind = { ...combinedVendor, integrated};
  const alldata = [...selectedTechstack, expandfind];

  let nodes = [];
  let links = [];
  let nodesdata = [];
  let links2 = [];

  alldata?.forEach((techStack) => {
    nodes = !showgraph && [
      ...nodes,
      {
        id: techStack.vendor_id,
        name: techStack.name || techStack.vendor_name,
        group: Math.floor(Math.random() * 10) + 1,
        color: techStack.vendor_name ? colorCodes[1] : colorCodes[4],
      },
    ];

    links = !showgraph && [
      ...links,
      ...techStack.integrated.map((id) => {
        return {
          source: techStack.vendor_id,
          target: id,
          value: Math.floor(Math.random() * 10) + 1,
        };
      }),
    ];
  });
  links2 = showgraph && [
    ...links2,
    ...integrated.map((id) => {
      return {
        source: vendorid,
        target: id,
        value: Math.floor(Math.random() * 10) + 1,
      };
    }),
  ];

  nodesdata = showgraph && [
    {
      id: SingleVendor.vendor_id,
      name: SingleVendor.vendor_name || SingleVendor.name,
      group: Math.floor(Math.random() * 10) + 1,
      color: "#FF99D1",
    },
    ...SingleVendor.integrated.map((id) => {
      const integratedVendor = alldata.find((vendor) => vendor.vendor_id === id);

      return {
        id: integratedVendor.vendor_id,
        name: integratedVendor.vendor_name || integratedVendor.name,
        group: Math.floor(Math.random() * 10) + 1,
        color: "#FF99D1", // Change color as needed
      };
    }),
  ];

  const data1 = { nodes, links };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    e.preventDefault();
    setAnchorEl(null);
  };

  const handleScreenshot = () => {
    setAnchorEl(null);
    if (graphRef.current) {
      html2canvas(graphRef.current, {
        backgroundColor: "#191819",
        useCORS: true,
      }).then((canvas) => {
        const image = canvas.toDataURL("image/png");
        const pdf = new Jspdf("l", "mm", "a4");
        pdf.setFillColor("black");
        pdf.rect(
          0,
          0,
          pdf.internal.pageSize.getWidth(),
          pdf.internal.pageSize.getHeight(),
          "F"
        );
        pdf.addImage(addLogo, "PNG", 125, 0, 50, 15);
        pdf.setFontSize(18);
        pdf.setTextColor("#989898");
        pdf.text(
          `${userProfiledata.company_name}'s Martech Stack`,
          pdf.internal.pageSize.getWidth() / 2,
          20,
          { align: "center" }
        );
        const space = 14;
        pdf.addImage(image, "PNG", space, 30, 270, 150);
        pdf.setFontSize(12);
        pdf.setTextColor("#989898");
        const date = new Date();
        let localDate = "N/A";

        if (date && !isNaN(date)) {
          const options = {
            timeZone: "America/New_York",
            year: "numeric",
            month: "long",
            day: "2-digit",
          };
          localDate = date.toLocaleString("en-US", options);
        }
        pdf.text(
          `Created by ${userProfiledata.first_name} on ${localDate}`,
          280,
          190,
          {
            align: "right",
          }
        );
        pdf.save("Martech Stack download.pdf");
      });
    }
  };

  const handleJpeg = () => {
    setAnchorEl(null);
    if (graphRef.current) {
      html2canvas(graphRef.current, {
        backgroundColor: "black",
      }).then((canvas) => {
        const image = canvas.toDataURL("image/jpeg"); // Specify JPEG format
        const link = document.createElement("a");
        link.href = image;
        link.download = "screenshot.jpeg";
        link.click();
      });
    }
  };

  return (
    <>
      <Box className="dashboardGraph">
        <Typography className="dashboardGraph_heading">
          Tech Stack Visualisation
        </Typography>
        <Box
          sx={{
            width: "25%",
            height: "40px",
            display: "flex",
            flexDirection: "row",
            gap: "2% !important",
            alignItems: "center",
          }}
        >
          <Tooltip
            title="Kindly scale the tech stack image to fit the screen."
            arrow
            placement="top"
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "black",
                  color: "#e2007b",
                  border: "0.5px solid #e2007b",
                  "& .MuiTooltip-arrow": {
                    color: "common.black",
                  },
                },
              },
            }}
          >
            <Button
              className="export_btns"
              sx={{ width: "133px" }}
              onClick={(e) => handleClick(e)}
            >
              <Typography className="btn_typography" sx={{ color: "#e2007b" }}>
                Export as
              </Typography>
              <KeyboardArrowDownIcon
                sx={{ color: "#e2007b", height: "18px", width: "18px" }}
              />
            </Button>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                width: "133px",
                height: "100px",
                borderRadius: "8px",
                background: "#000000",
                padding: "5px",
                color: "white",
                border: "1px solid #383838",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 150,
                  height: 150,
                  ml: -0.5,
                  mr: 1,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem className="ExportMenu" onClick={() => handleJpeg()}>
              JPEG
            </MenuItem>
            <MenuItem className="ExportMenu" onClick={() => handleScreenshot()}>
              PDF
            </MenuItem>
          </Menu>
          <Button
            className="alternativeView_btn"
            onClick={() => {
              setshowgraph(!showgraph);
            }}
          >
            <Typography className="btn_typography" sx={{ color: "white" }}>
              Alternative View
            </Typography>
          </Button>
        </Box>
      </Box>
      {!showgraph && (
        <Box
          className="dashboardGraph_wrapper"
          ref={(ref) => (graphRef.current = ref)}
        >
          <ForceGraph2D
            linkAutoColorBy=""
            graphData={data1}
            enableZoomInteraction={disableZoomOnScroll}
            nodeCanvasObject={(node, ctx, globalScale) => {
              const label = node.name;
              const fontSize = 10 / globalScale;
              ctx.font = `${fontSize}px Sans-Serif`;
              const textWidth = ctx.measureText(label).width;
              const bckgDimensions = [textWidth, fontSize].map(
                (n) => n + fontSize * 0.2
              );
              ctx.textAlign = "center";
              ctx.textBaseline = "middle";

              ctx.fillStyle = node.color;
              ctx.fillText(label, node.x, node.y);

              node.__bckgDimensions = bckgDimensions;
            }}
            linkDirectionalArrowColor={() => {}}
            minZoom={3}
            onNodeClick={handleNodeClick}
            height={800}
            width={1200}
          />
        </Box>
      )}

      {showgraph && (
        <Box
          className="dashboardGraph_wrapper"
          ref={(ref) => (graphRef.current = ref)}
        >
          <ForceGraph2D
            linkAutoColorBy="#e2007b"
            graphData={{ nodes: nodesdata, links: links2 }}
            enableZoomInteraction={disableZoomOnScroll}
            linkColor={() => "grey"}
            nodeCanvasObject={(node, ctx, globalScale) => {
              const label = node.name;
              const fontSize = 13 / globalScale;
              ctx.font = `${fontSize}px Sans-Serif`;
              const textWidth = ctx.measureText(label).width;
              const bckgDimensions = [textWidth, fontSize].map(
                (n) => n + fontSize * 0.2
              );

              ctx.textAlign = "center";
              ctx.textBaseline = "middle";

              ctx.fillStyle = node.color;
              ctx.fillText(label, node.x, node.y);

              node.__bckgDimensions = bckgDimensions;
            }}
            linkDirectionalArrowColor={() => {}}
            minZoom={5}
            onNodeClick={handleNodeClick}
            height={800}
            width={1200}
          />
        </Box>
      )}
    </>
  );
};

export default DashboardGraph;
