import React, { useEffect, useState } from "react";
import { Stack, Box, Typography } from "@mui/material";
import Header from "../header/Header";
import "./rfiProcess.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CompanyReview from "../rfiComponent/companyReview/compnayReview";
import TechRequirement from "../rfiComponent/techRequirement/techRequirement";
import Service from "../rfiComponent/service&additional/service";
import Pricing from "../rfiComponent/pricing/pricing";
import VendorReq from "../rfiComponent/vendorReq/vendorRequest";
import Deadline from "../rfiComponent/deadline/deadline";
import DealBreaker from "../rfiComponent/dealBreakers/dealBreaker";
import RfiTechstack from "../rfiComponent/techstack/rfiTechStack";
import ReviewSubmit from "../rfiComponent/review&submit/reviewSubmit";
import Category from "../rfiComponent/category/category";
import RfiSuccess from "../rfiComponent/success/rfiSuccess";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const RFI = () => {
  const navigate = useNavigate();
  const [sliderValue, setSliderValue] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { vendorInquiry } = useSelector((state) => state.rfiData);

  const sliderKeyValue = {
    category: false,
    companyReview: false,
    techRequirement: false,
    service: false,
    pricing: false,
    vendorReq: false,
    deadline: false,
    dealBreaker: false,
    rfiTechStack: false,
    reviewSubmit: false,
    rfiSuccess: false,
  };

  const [sliderSteps, setSliderSteps] = useState(sliderKeyValue);

  useEffect(() => {
    if (!sliderSteps.reviewSubmit) {
      if (vendorInquiry.length > 1) {
        updateSliderSteps("category", true);
      } else {
        updateSliderSteps("companyReview", true);
      }
    }
  }, [vendorInquiry]);

  const updateSliderSteps = (name, keyValue) => {
    setSliderSteps(sliderKeyValue);
    setSliderSteps((prevState) => ({ ...prevState, [name]: keyValue }));
  };

  const openSelectedStep = (step, categoryIndex) => {
    updateSliderSteps(step, true);
    setSelectedCategory(categoryIndex);
    if (step === "rfiSuccess") {
      setTimeout(() => {
        navigate("/dashboard");
      }, [3000]);
    }
  };
  const date = new Date(vendorInquiry[selectedCategory || 0]?.created_at);

  const options = {
    timeZone: "America/New_York",
    year: "numeric",
    month: "long",
    day: "2-digit",
  };

  const formattedDate = date.toLocaleString("en-US", options);

  return (
    <>
      <Stack
        className="profile_page_container"
        style={{
          height: "100%",
        }}
      >
        <Header />
        <Box className="rfiDashboard_label">
          <ArrowForwardIosIcon />
          Vendor Inquiry
          <ArrowBackIosNewIcon />
        </Box>
        {!sliderSteps.rfiSuccess && !sliderSteps.category && (
          <Box className="rfi_headingContainer">
            <Typography className="rfi_heading">
              {vendorInquiry[selectedCategory || 0]?.vendor_category}{" "}
              {formattedDate}
            </Typography>
          </Box>
        )}
        {sliderSteps.category && (
          <Category openSelectedStep={openSelectedStep} />
        )}
        {sliderSteps.companyReview && (
          <CompanyReview
            openSelectedStep={openSelectedStep}
            selectedCategory={selectedCategory || 0}
            setSliderValue={setSliderValue}
            sliderValue={sliderValue}
          />
        )}
        {sliderSteps.techRequirement && (
          <TechRequirement
            openSelectedStep={openSelectedStep}
            selectedCategory={selectedCategory}
            setSliderValue={setSliderValue}
            sliderValue={sliderValue}
          />
        )}
        {sliderSteps.service && (
          <Service
            openSelectedStep={openSelectedStep}
            selectedCategory={selectedCategory}
            setSliderValue={setSliderValue}
            sliderValue={sliderValue}
          />
        )}
        {sliderSteps.pricing && (
          <Pricing
            openSelectedStep={openSelectedStep}
            selectedCategory={selectedCategory}
            setSliderValue={setSliderValue}
            sliderValue={sliderValue}
          />
        )}
        {sliderSteps.vendorReq && (
          <VendorReq
            openSelectedStep={openSelectedStep}
            setSliderValue={setSliderValue}
            sliderValue={sliderValue}
            selectedCategory={selectedCategory}
          />
        )}
        {sliderSteps.deadline && (
          <Deadline
            openSelectedStep={openSelectedStep}
            setSliderValue={setSliderValue}
            sliderValue={sliderValue}
            selectedCategory={selectedCategory}
          />
        )}
        {sliderSteps.dealBreaker && (
          <DealBreaker
            openSelectedStep={openSelectedStep}
            setSliderValue={setSliderValue}
            sliderValue={sliderValue}
            selectedCategory={selectedCategory}
          />
        )}
        {sliderSteps.rfiTechStack && (
          <RfiTechstack
            openSelectedStep={openSelectedStep}
            setSliderValue={setSliderValue}
            sliderValue={sliderValue}
            selectedCategory={selectedCategory}
          />
        )}
        {sliderSteps.reviewSubmit && (
          <ReviewSubmit
            openSelectedStep={openSelectedStep}
            reviewSubmit={sliderSteps.reviewSubmit}
            setSliderValue={setSliderValue}
            sliderValue={sliderValue}
            selectedCategory={selectedCategory}
          />
        )}
        {sliderSteps.rfiSuccess && <RfiSuccess />}
      </Stack>
    </>
  );
};

export default RFI;
