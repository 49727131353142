import React, { useEffect } from "react";
import { Box, Stack, Typography } from "@mui/material/node";
import "./toast.css";
import { useDispatch, useSelector } from "react-redux";
import { setShowToast } from "../../redux/applicationSlice";
import ClearIcon from "@mui/icons-material/Clear";

const Toast = () => {
  const dispatch = useDispatch();
  const { showToast, toastMessage } = useSelector(
    (state) => state.applicationData
  );
  useEffect(() => {
    if (showToast) {
      setTimeout(() => {
        dispatch(setShowToast({ show: false, toastMessage: "" }));
      }, 2000);
    }
  }, [showToast]);

  return (
    <>
      <Box className="toast">
        <Box className="toast_wrapper">
          <Stack className="toastClose">
            <ClearIcon className="toast_closeIcon" />
          </Stack>
          <Stack className="toastMessage_container">
            <Typography className="toastMessage_typography">{toastMessage}</Typography>
          </Stack>
          <Stack className="toast_bottom"></Stack>
        </Box>
      </Box>
    </>
  );
};

export default Toast;
