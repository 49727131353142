import React, { useEffect, useState } from "react";
import { Box, Stack, Typography, Button } from "@mui/material";
import Header from "../header/Header";
import "./userInvite.css";
import PropTypes from "prop-types";
import SliderComponent from "../sliderComponent/sliderComponent";
import FooterButton from "../footerButton/FooterButton";
import { sendInvite } from "../../redux/applicationSlice";
import { setuserProfile } from "../../redux/userslice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import ClearIcon from "@mui/icons-material/Clear";

const UserInvite = ({ setValue, value, openSelectedStep, isUserInvite }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [emailName, setEmailName] = useState("");
  const [addEmail, setAddEmail] = useState("");
  const [splitEmail, setSplitEmail] = useState([]);
  const { userProfiledata } = useSelector((state) => state.promptdata);

  const MoveNext = () => {
    if (pathname === "/onboarding") {
      openSelectedStep("techstack");
      const data = {
        ...userProfiledata,
        onboarding: "Tech Stack",
      };
      localStorage.setItem("onboarding", "Tech Stack");
      dispatch(setuserProfile(data));
    } else {
      navigate("/landing_page");
    }
  };

  const skipBtn = () => {
    openSelectedStep("techstack");
  };

  useEffect(() => {
    setValue(50.4);
  }, []);

  const extractDomain = (email) => {
    return email.slice(email.lastIndexOf("@") + 1);
  };

  const handlleSendInvite = () => {
    const userProfileDomain = extractDomain(userProfiledata.email);
    const emailDomain = extractDomain(emailName);

    if (userProfileDomain === emailDomain) {
      const data = {
        emails: emailName,
      };
      dispatch(sendInvite(data));
    } else {
      toast("Email domains do not match.");
    }
  };

  const handleEmailRequest = (name) => {
    if (name !== " ") {
      setAddEmail(name.trim());
    }
  };

  const handleUpdateName = (event) => {
    if (event.key == " ") {
      let updatedEmailName;
      if (emailName) {
        updatedEmailName = emailName + "," + addEmail;
      } else {
        updatedEmailName = addEmail;
      }
      setEmailName(updatedEmailName);
      setAddEmail("");
    }
  };

  const splitEmailName = () => {
    const spiltNames = emailName.split(",");
    setSplitEmail(spiltNames);
  };

  useEffect(() => {
    if (emailName.length > 0) {
      splitEmailName();
    }
  }, [emailName]);

  const handleDelete = (ind) => {
    const updatedEmails = splitEmail.filter((obj, index) => index !== ind);
    const updatedNames = updatedEmails.join(" ");
    setEmailName(updatedNames);
    setSplitEmail(updatedEmails);
  };

  const isDisable = emailName ? false : true;

  const styles = {
    color: isDisable && "#fff",
    opacity: isDisable && "0.2",
  };

  return (
    <>
      <Box className="wrapper">
        <Header />
        <ToastContainer />
        <Stack className="testerProfile" id="bg-image">
          {pathname === "/onboarding" && (
            <SliderComponent setValue={setValue} value={value} />
          )}
          <Box className="invite_wrapper">
            <Box className="inviteBox">
              <Typography className="inviteHeading">
                Invite Your Colleagues
              </Typography>
              <Stack className="inviteContainer">
                <Box className="inviteInputContainer">
                  {splitEmail.length > 0 &&
                    splitEmail.map((item, index) => {
                      return (
                        <Stack className="emailContainer" key={index}>
                          <Stack className="emailLogo">A</Stack>
                          <Typography>{item}</Typography>
                          <Stack sx={{ display: "flex", alignItems: "center" }}>
                            <ClearIcon
                              className="clearIcon"
                              onClick={() => handleDelete(index)}
                            />
                          </Stack>
                        </Stack>
                      );
                    })}
                  <input
                    type="text"
                    id="to"
                    className="inviteInput"
                    placeholder="Enter Email"
                    required
                    value={addEmail}
                    onChange={(e) => handleEmailRequest(e.target.value)}
                    onKeyDown={(e) =>
                      addEmail.length > 0 && handleUpdateName(e)
                    }
                  />
                </Box>
                <Button
                  className="sendInviteBtn"
                  onClick={handlleSendInvite}
                  disabled={isDisable}
                  style={styles}
                >
                  Send Invite
                </Button>
              </Stack>
              <Typography className="noteTypography">
                Note: Please enter the email id and press the space key.
              </Typography>
            </Box>
          </Box>
          <Stack width="963px" style={{ marginTop: "20px" }}>
            <FooterButton
              MoveNext={MoveNext}
              skipBtn={skipBtn}
              isUserInvite={isUserInvite}
            />
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default UserInvite;

UserInvite.propTypes = {
  value: PropTypes.number,
  setValue: PropTypes.number,
  openSelectedStep: PropTypes.func,
  isUserInvite: PropTypes.bool,
};
