import { Box, Stack } from "@mui/material/node";
import React from "react";
// import "./websiteHeader.css";
import VideoBanner from "../images/Frame 6356760 (1).png";

const Header = () => {

  return (
    <Box className="websiteHeader_wrapper">
      <Stack>
        <img src={VideoBanner} alt="" width= "100%" height="100%" />
      </Stack>
    </Box>
  );
};

export default Header;
