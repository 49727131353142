import { Box, Typography, Tooltip, Button } from "@mui/material/node";
import React from "react";
import "./cookies.css";
import WebsiteHeader from "../WebsiteHeader/WebsiteHeader";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useNavigate } from "react-router";

const Cookies = () => {
  const navigate = useNavigate();

  return (
    <Box className="cookies_wrapper">
      <WebsiteHeader />
      <Box className="cookieContainer">
        <Box className="cookieHeadingBox">
          <Tooltip
            title="Back"
            arrow
            placement="bottom"
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "black",
                  color: "#e2007b",
                  padding: "10px",
                  lineHeight: "24px",
                  border: "0.5px solid #e2007b",
                  "& .MuiTooltip-arrow": {
                    color: "#e2007b",
                  },
                  typography: {
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    fontWeight: "400px",
                    lineHeight: "18px",
                  },
                },
              },
            }}
          >
            <Button
              onClick={() => navigate("/")}
              className="backToWebsite"
            >
              <KeyboardArrowLeftIcon sx={{ color: "#AE0770" }} />
            </Button>
          </Tooltip>
          <Typography className="cookieHeading">Cookie Policy</Typography>
          <Typography className="cookie_updated">
            Last updated January 02, 2024
          </Typography>
        </Box>
        <Box className="cookie_Info">
          <p className="cookieData">
            This Cookie Policy explains how Amp AI Inc (
            {` "Company," "we," "us," and "our" `}) uses cookies and similar
            technologies to recognize you when you visit our website at
            <a
              href="https://support.wix.com/en/article/cookies-and-your-wix-site"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#e2007b", cursor: "pointer" }}
            >
              <u> http://www.ampai.ai</u>
            </a>{" "}
            ({`"Website" `}). It explains what these technologies are and why we
            use them, as well as your rights to control our use of them.
          </p>
          <p className="cookieData">
            In some cases we may use cookies to collect personal information, or
            that becomes personal information if we combine it with other
            information.
          </p>
          <h2 className="policy_questions">What are cookies?</h2>
          <p className="cookieData">
            Cookies are small data files that are placed on your computer or
            mobile device when you visit a website. Cookies are widely used by
            website owners in order to make their websites work, or to work more
            efficiently, as well as to provide reporting information.
          </p>
          <p className="cookieData">
            Cookies set by the website owner (in this case, Amp AI Inc) are
            called `{"first-party cookies."}` Cookies set by parties other than
            the website owner are called `{"third-party cookies."}` Third-party
            cookies enable third-party features or functionality to be provided
            on or through the website (e.g., advertising, interactive content,
            and analytics). The parties that set these third-party cookies can
            recognize your computer both when it visits the website in question
            and also when it visits certain other websites.
          </p>
          <h2 className="policy_questions">Why do we use cookies?</h2>
          <p className="cookieData">
            We use first- and third-party cookies for several reasons. Some
            cookies are required for technical reasons in order for our Website
            to operate, and we refer to these as `{"essential"}` or `
            {"strictly necessary"}` cookies. Other cookies also enable us to
            track and target the interests of our users to enhance the
            experience on our Online Properties. Third parties serve cookies
            through our Website for advertising, analytics, and other purposes.
            This is described in more detail below.
          </p>
          <h2 className="policy_questions">How can I control cookies?</h2>
          <p className="cookieData">
            You have the right to decide whether to accept or reject cookies.
            You can exercise your cookie rights by setting your preferences in
            the Cookie Consent Manager. The Cookie Consent Manager allows you to
            select which categories of cookies you accept or reject. Essential
            cookies cannot be rejected as they are strictly necessary to provide
            you with services.
          </p>
          <p className="cookieData">
            The Cookie Consent Manager can be found in the notification banner
            and on our website. If you choose to reject cookies, you may still
            use our website though your access to some functionality and areas
            of our website may be restricted. You may also set or amend your web
            browser controls to accept or refuse cookies.
          </p>
          <p className="cookieData">
            The specific types of first- and third-party cookies served through
            our Website and the purposes they perform are described in the table
            below (please note that the specific cookies served may vary
            depending on the specific Online Properties you visit):
          </p>
          <h2 className="policy_questions">
            <u>Essential website cookies:</u>
          </h2>
          <p className="cookieData">
            These cookies are strictly necessary to provide you with services
            available through our Website and to use some of its features, such
            as access to secure areas.
          </p>
          <div className="tableDiv">
            <table
              style={{
                border: "1px solid grey",
                width: "100%",
                padding: "30px 0px",
              }}
            >
              <tbody>
                <tr className="table_tr">
                  <td className="table_tdFirst">Name:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">hs</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Purpose:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">
                      Used for security reasons
                    </span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Provider:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">.www.ampai.ai</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Service:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">
                      Wix{" "}
                      <a
                        href="https://support.wix.com/en/article/cookies-and-your-wix-site"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#e2007b", cursor: "pointer" }}
                      >
                        <u> View Service Privacy Policy</u>
                      </a>
                    </span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Country:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">United States</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Type:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">server_cookie</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Expires in:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">session</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              style={{
                border: "1px solid grey",
                width: "100%",
                padding: "30px 0px",
              }}
            >
              <tbody>
                <tr className="table_tr">
                  <td className="table_tdFirst">Name:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">svSession</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Purpose:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">
                      Used in connection with user login
                    </span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Provider:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">.www.ampai.ai</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Service:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">
                      Wix{" "}
                      <a
                        href="https://support.wix.com/en/article/cookies-and-your-wix-site"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#e2007b", cursor: "pointer" }}
                      >
                        <u> View Service Privacy Policy</u>
                      </a>
                    </span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Country:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">United States</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Type:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">server_cookie</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Expires in:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">2 years</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h2 className="policy_questions">
            <u>Performance and functionality cookies:</u>
          </h2>
          <p className="cookieData">
            These cookies are used to enhance the performance and functionality
            of our Website but are non-essential to their use. However, without
            these cookies, certain functionality (like videos) may become
            unavailable.
          </p>
          <div className="tableDiv">
            <table
              style={{
                border: "1px solid grey",
                width: "100%",
                padding: "30px 0px",
              }}
            >
              <tbody>
                <tr className="table_tr">
                  <td className="table_tdFirst">Name:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">XSRF-TOKEN</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Purpose:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">
                      This cookie is written to help with site security in
                      preventing Cross-Site Request Forgery attacks.
                    </span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Provider:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">.www.ampai.ai</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Service:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">
                      Advertisers website domain
                      <a
                        href="https://support.wix.com/en/article/cookies-and-your-wix-site"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#e2007b", cursor: "pointer" }}
                      >
                        <u> View Service Privacy Policy</u>
                      </a>
                    </span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Country:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">United States</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Type:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">server_cookie</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Expires in:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">session</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              style={{
                border: "1px solid grey",
                width: "100%",
                padding: "30px 0px",
              }}
            >
              <tbody>
                <tr className="table_tr">
                  <td className="table_tdFirst">Name:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">XSRF-TOKEN</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Purpose:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">
                      This cookie is written to help with site security in
                      preventing Cross-Site Request Forgery attacks.
                    </span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Provider:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">.wix.com</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Service:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">
                      Advertisers website domain
                      <a
                        href="https://support.wix.com/en/article/cookies-and-your-wix-site"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#e2007b", cursor: "pointer" }}
                      >
                        <u> View Service Privacy Policy</u>
                      </a>
                    </span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Country:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">United States</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Type:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">server_cookie</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Expires in:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">session</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              style={{
                border: "1px solid grey",
                width: "100%",
                padding: "30px 0px",
              }}
            >
              <tbody>
                <tr className="table_tr">
                  <td className="table_tdFirst">Name:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">XSRF-TOKEN</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Purpose:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">
                      This cookie is written to help with site security in
                      preventing Cross-Site Request Forgery attacks.
                    </span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Provider:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">.engage.wixapps.net</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Service:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">
                      Advertisers website domain
                      <a
                        href="https://support.wix.com/en/article/cookies-and-your-wix-site"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#e2007b", cursor: "pointer" }}
                      >
                        <u> View Service Privacy Policy</u>
                      </a>
                    </span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Country:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">United States</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Type:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">server_cookie</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Expires in:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">session</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              style={{
                border: "1px solid grey",
                width: "100%",
                padding: "30px 0px",
              }}
            >
              <tbody>
                <tr className="table_tr">
                  <td className="table_tdFirst">Name:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">fedops.logger.sessionId</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Purpose:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">
                      Used for stability/effectiveness measurement
                    </span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Provider:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">.www.ampai.ai</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Service:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">
                      Wix{" "}
                      <a
                        href="https://support.wix.com/en/article/cookies-and-your-wix-site"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#e2007b", cursor: "pointer" }}
                      >
                        <u> View Service Privacy Policy</u>
                      </a>
                    </span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Country:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">United States</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Type:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">html_local_storage</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Expires in:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">persistent</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h2 className="policy_questions">
            <u>Unclassified cookies:</u>
          </h2>
          <p className="cookieData">
            These are cookies that have not yet been categorized. We are in the
            process of classifying these cookies with the help of their
            providers.
          </p>
          <div className="tableDiv">
            <table
              style={{
                border: "1px solid grey",
                width: "100%",
                padding: "30px 0px",
              }}
            >
              <tbody>
                <tr className="table_tr">
                  <td className="table_tdFirst">Name:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">bSession</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Purpose:</td>
                  <td className="table_tdSecond">
                    <span className="table_info" style={{ whiteSpace: "pre" }}>
                      <u> </u>
                    </span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Provider:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">.www.ampai.ai</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Service:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">__________</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Country:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">United States</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Type:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">http_cookie</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Expires in:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">30 minutes</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              style={{
                border: "1px solid grey",
                width: "100%",
                padding: "30px 0px",
              }}
            >
              <tbody>
                <tr className="table_tr">
                  <td className="table_tdFirst">Name:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">bSession</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Purpose:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">__________</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Provider:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">.engage.wixapps.net</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Service:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">__________</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Country:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">United States</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Type:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">http_cookie</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Expires in:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">30 minutes</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              style={{
                border: "1px solid grey",
                width: "100%",
                padding: "30px 0px",
              }}
            >
              <tbody>
                <tr className="table_tr">
                  <td className="table_tdFirst">Name:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">ssr-caching</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Purpose:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">__________</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Provider:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">www.ampai.ai</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Service:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">__________</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Country:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">United States</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Type:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">server_cookie</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Expires in:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">less than 1 minute</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              style={{
                border: "1px solid grey",
                width: "100%",
                padding: "30px 0px",
              }}
            >
              <tbody>
                <tr className="table_tr">
                  <td className="table_tdFirst">Name:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">
                      firebase:host:wix-engage-visitors-prod-0.firebaseio.com
                    </span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Purpose:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">__________</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Provider:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">engage.wixapps.net</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Service:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">__________</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Country:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">United States</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Type:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">html_local_storage</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Expires in:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">persistent</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              style={{
                border: "1px solid grey",
                width: "100%",
                padding: "30px 0px",
              }}
            >
              <tbody>
                <tr className="table_tr">
                  <td className="table_tdFirst">Name:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">
                      _wixAB3|a53d4c10-f0e1-48f4-86b8-4f8f7f3dcdfc
                    </span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Purpose:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">__________</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Provider:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">.wix.com</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Service:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">__________</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Country:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">United States</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Type:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">server_cookie</span>
                  </td>
                </tr>
                <tr className="table_tr">
                  <td className="table_tdFirst">Expires in:</td>
                  <td className="table_tdSecond">
                    <span className="table_info">4 hours</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h2 className="policy_questions">
            How can I control cookies on my browser?
          </h2>
          <p className="cookieData">
            As the means by which you can refuse cookies through your web
            browser controls vary from browser to browser, you should visit your
            browsers help menu for more information. The following is
            information about how to manage cookies on the most popular
            browsers:
          </p>
          <ul style={{ color: "white", listStyle: "square" }}>
            <li className="cookiData">
              <a
                href="https://support.google.com/chrome/answer/95647#zippy=%2Callow-or-block-cookies"
                style={{ color: "#e2007b", cursor: "pointer" }}
                target="_blank"
                rel="noreferrer"
              >
                <u>Chrome</u>
              </a>
            </li>
            <li className="cookiData">
              <a
                // eslint-disable-next-line max-len
                href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                style={{ color: "#e2007b", cursor: "pointer" }}
                target="_blank"
                rel="noreferrer"
              >
                <u>Internet Explorer</u>
              </a>
            </li>
            <li className="cookiData">
              <a
                // eslint-disable-next-line max-len
                href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US"
                style={{ color: "#e2007b", cursor: "pointer" }}
                target="_blank"
                rel="noreferrer"
              >
                <u>Firefox</u>
              </a>
            </li>
            <li className="cookiData">
              <a
                href="https://support.apple.com/en-ie/guide/safari/sfri11471/mac"
                style={{ color: "#e2007b", cursor: "pointer" }}
                target="_blank"
                rel="noreferrer"
              >
                <u>Safari</u>
              </a>
            </li>
            <li className="cookiData">
              <a
                // eslint-disable-next-line max-len
                href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                style={{ color: "#e2007b", cursor: "pointer" }}
                target="_blank"
                rel="noreferrer"
              >
                <u>Edge</u>
              </a>
            </li>
            <li className="cookiData">
              <a
                href="https://help.opera.com/en/latest/web-preferences/"
                style={{ color: "#e2007b", cursor: "pointer" }}
                target="_blank"
                rel="noreferrer"
              >
                <u>Opera</u>
              </a>
            </li>
          </ul>
          <p className="cookieData">
            In addition, most advertising networks offer you a way to opt out of
            targeted advertising. If you would like to find out more
            information, please visit:
          </p>
          <ul style={{ color: "white", listStyle: "square" }}>
            <li className="cookiData">
              <a
                href="https://optout.aboutads.info/?c=2&lang=EN"
                style={{ color: "#e2007b", cursor: "pointer" }}
                target="_blank"
                rel="noreferrer"
              >
                <u>Digital Advertising Alliance</u>
              </a>
            </li>
            <li className="cookiData">
              <a
                href="https://youradchoices.ca/"
                style={{ color: "#e2007b", cursor: "pointer" }}
                target="_blank"
                rel="noreferrer"
              >
                <u>Digital Advertising Alliance of Canada</u>
              </a>
            </li>
            <li className="cookiData">
              <a
                href="https://www.youronlinechoices.com/"
                style={{ color: "#e2007b", cursor: "pointer" }}
                target="_blank"
                rel="noreferrer"
              >
                <u>European Interactive Digital Advertising Alliance</u>
              </a>
            </li>
          </ul>
          <h2 className="policy_questions">
            What about other tracking technologies, like web beacons?
          </h2>
          <p className="cookieData">
            Cookies are not the only way to recognize or track visitors to a
            website. We may use other, similar technologies from time to time,
            like web beacons (sometimes called `{"tracking pixels"}` or `
            {"clear gifs"}`). These are tiny graphics files that contain a
            unique identifier that enables us to recognize when someone has
            visited our Website or opened an email including them. This allows
            us, for example, to monitor the traffic patterns of users from one
            page within a website to another, to deliver or communicate with
            cookies, to understand whether you have come to the website from an
            online advertisement displayed on a third-party website, to improve
            site performance, and to measure the success of email marketing
            campaigns. In many instances, these technologies are reliant on
            cookies to function properly, and so declining cookies will impair
            their functioning.
          </p>
          <h2 className="policy_questions">
            Do you use Flash cookies or Local Shared Objects?
          </h2>
          <p className="cookieData">
            Websites may also use so-called `{"Flash Cookies"}` (also known as
            Local Shared Objects or `{"LSOs"}`) to, among other things, collect
            and store information about your use of our services, fraud
            prevention, and for other site operations.
          </p>
          <p className="cookieData">
            If you do not want Flash Cookies stored on your computer, you can
            adjust the settings of your Flash player to block Flash Cookies
            storage using the tools contained in the
            <a
              href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
              style={{ color: "#e2007b", cursor: "pointer" }}
              target="_blank"
              rel="noreferrer"
            >
              <u> Website Storage Settings Panel</u>
            </a>
            . You can also control Flash Cookies by going to the
            <a
              href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager03.html"
              style={{ color: "#e2007b", cursor: "pointer" }}
              target="_blank"
              rel="noreferrer"
            >
              <u> Global Storage Settings Panel </u>
            </a>{" "}
            and following the instructions (which may include instructions that
            explain, for example, how to delete existing Flash Cookies (referred
            to `{"information"}` on the Macromedia site), how to prevent Flash
            LSOs from being placed on your computer without your being asked,
            and (for Flash Player 8 and later) how to block Flash Cookies that
            are not being delivered by the operator of the page you are on at
            the time).
          </p>
          <p className="cookieData">
            Please note that setting the Flash Player to restrict or limit
            acceptance of Flash Cookies may reduce or impede the functionality
            of some Flash applications, including, potentially, Flash
            applications used in connection with our services or online content.
          </p>
          <h2 className="policy_questions">
            Do you serve targeted advertising?
          </h2>
          <p className="cookieData">
            Third parties may serve cookies on your computer or mobile device to
            serve advertising through our Website. These companies may use
            information about your visits to this and other websites in order to
            provide relevant advertisements about goods and services that you
            may be interested in. They may also employ technology that is used
            to measure the effectiveness of advertisements. They can accomplish
            this by using cookies or web beacons to collect information about
            your visits to this and other sites in order to provide relevant
            advertisements about goods and services of potential interest to
            you. The information collected through this process does not enable
            us or them to identify your name, contact details, or other details
            that directly identify you unless you choose to provide these.
          </p>
          <h2 className="policy_questions">
            How often will you update this Cookie Policy?
          </h2>
          <p className="cookieData">
            We may update this Cookie Policy from time to time in order to
            reflect, for example, changes to the cookies we use or for other
            operational, legal, or regulatory reasons. Please therefore revisit
            this Cookie Policy regularly to stay informed about our use of
            cookies and related technologies.
          </p>
          <p className="cookieData">
            The date at the top of this Cookie Policy indicates when it was last
            updated.
          </p>
          <h2 className="policy_questions">
            Where can I get further information?
          </h2>
          <p className="cookieData">
            If you have any questions about our use of cookies or other
            technologies, please contact us at:
          </p>
          <p className="cookieData">
            {" "}
            Amp AI Inc <br />
            1000 Brickell Ave,
            <br /> Suite 715 Miami,
            <br /> FL 33131 United States
          </p>
        </Box>
      </Box>
    </Box>
  );
};

export default Cookies;
