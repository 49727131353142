import { Box, Stack, Typography, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./forgotPassword.css";
import PropTypes from "prop-types";
import { getResetPassword } from "../../redux/applicationSlice";
import { useDispatch, useSelector } from "react-redux";
import { getEmail } from "../../redux/userslice";
import { useNavigate } from "react-router";
import CustomTextField from "../otherComponent/customTextfield";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import CheckMail from "./resendMail";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [resendMail, setResendMail] = useState(false);

  const { error, errorMessage, success } = useSelector(
    (state) => state.applicationData
  );

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setEmail(inputValue);
  };

  useEffect(() => {
    if (error) {
      toast(errorMessage);
    } else if (success) {
      setResendMail(true);
    }
  }, [error, success]);

  const handleMailSend = (email) => {
    if (email.status === "noted") {
      toast("Mail has been sent successfully!");
    } else {
      toast(email?.response?.data?.detail);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(getEmail(email));
    dispatch(getResetPassword(email, handleMailSend));
  };

  return (
    <Stack className="login_page_container_wrapper">
      <ToastContainer />
      {!resendMail ? (
        <Box className="forgot_page_box">
          <Stack className="backToLogin" onClick={() => navigate("/login")}>
            <KeyboardArrowLeftIcon className="backToLogin_icon" />
          </Stack>
          <Stack className="forgotPassword_elements">
            <Stack className="forgotPassword_container">
              <Stack className="logo_brand">
                <img src="/images/logo.png" />
              </Stack>
              <Stack className="forgot_header">
                <Typography className="forgotPasswordTypography">
                  Forgot Password
                </Typography>
                <Typography className="forgotHeadline">
                  Enter your email for verification process. We’ll send a
                  recovery link to your email.
                </Typography>
              </Stack>
            </Stack>
            <Stack className="login_form_email">
              <CustomTextField
                label="Email"
                name="login"
                onChange={handleInputChange}
                placeholder="Enter your email"
              />
            </Stack>
            <Stack className="login_button">
              <Button
                className="login_textfield"
                style={{ height: "44px" }}
                onClick={(e) => handleSubmit(e)}
              >
                Continue
              </Button>
            </Stack>
          </Stack>
        </Box>
      ) : (
        <CheckMail />
      )}
    </Stack>
  );
};

export default ForgotPassword;

ForgotPassword.propTypes = {
  setOnBoarding: PropTypes.any,
};
