import { createSlice } from "@reduxjs/toolkit";
import { api } from "../axiosinterceptor/axiosInterceptor";
import { toast } from "react-toastify";
import {startLoading, stopLoading} from "./applicationSlice";

const initialState = {
  categoryInfo: "",
  categories: [],
  subcategories: [],
};

const decisionTreeSlice = createSlice({
  name: "decisionTreeData",
  initialState,
  reducers: {
    getDescription(state, action) {
      state.categoryInfo = action.payload;
    },
    getAllpromptCategoriesSuccess(state, action) {
      const data = Object.keys(action.payload);
      state.subcategories = data;
      state.categories = action.payload;
    },
  },
});

export const { selectionLoading } = decisionTreeSlice.actions;

export default decisionTreeSlice.reducer;

export function getAllpromptCategories() {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await api.get("/vendor_categories/");
      dispatch(decisionTreeSlice.actions.getAllpromptCategoriesSuccess(response));
      dispatch(stopLoading());
    } catch (error) {
      toast(error);
    }
  };
}

export function getCategoryDescription(category) {
  return async (dispatch) => {
    try {
      const encodedCategory = encodeURIComponent(category.replaceAll('/', '__'));
      const response = await api.get(`/category_description/${encodedCategory}/`);
      dispatch(decisionTreeSlice.actions.getDescription(response));
    } catch (error) {
      toast(error);
    }
  };
}
