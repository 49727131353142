import React, { useEffect, useState } from "react";
import { Box, Stack, Typography, Button } from "@mui/material";
import "./onboarding.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Profile from "../profile/Profile";
import PropTypes from "prop-types";
import CompanyProfile from "../profile/Company_profile";
import Invite from "../userInvite/userInvite";
import TechStack from "../techstack/techStack";
import TesterProfile from "../subscription/subScription";
import OnboardingComplete from "./onboardingComplete";
import OnboardingPage from "./onboardingPage";
import { setuserProfile } from "../../redux/userslice";

const Onboarding = ({ setValue, value }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onboardingKeyValue = {
    onboardingIncomplete: false,
    intro: false,
    profile: false,
    companyprofile: false,
    invite: false,
    techstack: false,
    testerProfile: false,
    onboardingComplete: false,
  };
  const [onboardingStep, setOnboardingStep] = useState(onboardingKeyValue);
  const onboardingMark = localStorage.getItem("onboarding");

  const { status } = useSelector((state) => state.applicationData);
  const { userProfiledata } = useSelector((state) => state.promptdata);

  const updateSliderSteps = (name, keyValue) => {
    setOnboardingStep(onboardingKeyValue);
    setOnboardingStep((prevState) => ({ ...prevState, [name]: keyValue }));
  };
  useEffect(() => {
    updateSliderSteps("intro", true);
  }, []);

  useEffect(() => {
    if (status.status === "verified" && status.onboarding === null &&
      onboardingMark === "null") {
      updateSliderSteps("intro", true);
    } else if (status.status === "verified" && status.onboarding !== null &&
      onboardingMark !== "null") {
      updateSliderSteps("onboardingIncomplete", true);
    } else if (
      (status.status === "logged in" && status.onboarding !== "Complete") ||
      onboardingMark !== "Complete"
    ) {
      updateSliderSteps("onboardingIncomplete", true);
    }
  }, []);

  const openSelectedStep = (step) => {
    updateSliderSteps(step, true);
    if (step === "onboardingComplete") {
      setTimeout(() => {
        navigate("/landing_page");
      }, [3000]);
    }
  };

  const handleProceed = () => {
    openSelectedStep("profile");
    const data = {
      ...userProfiledata,
      onboarding: "User Profile",
    };
    localStorage.setItem("onboarding", "User Profile");
    dispatch(setuserProfile(data));
  };

  return (
    <>
      <Stack
        className="profile_page_container"
        style={{
          height: "100%",
        }}
      >
        {onboardingStep.intro && (
          <>
            <Stack className="header_wrapper">
              <Box marginLeft="36px">
                <img src="/images/logo.png" alt="" />
              </Box>
            </Stack>
            <Box className="onboardingBox">
              <Box className="onBoardingContainer">
                <Typography className="onboardingHeading">
                  Welcome to the age of AI
                </Typography>
                <Stack className="onBoarding_content">
                  <Typography className="onboardingData">
                    Let AI drive your vendor discovery.
                  </Typography>
                  <Typography className="onboardingData">
                    Before we get started, help me understand you.
                  </Typography>
                </Stack>
              </Box>
              <Box className="proceedBtnContainer">
                <Button className="proceedBtn" onClick={handleProceed}>
                  <Link className="linkText">Proceed</Link>
                </Button>
              </Box>
            </Box>
          </>
        )}
        {onboardingStep.onboardingIncomplete && (
          <OnboardingPage openSelectedStep={openSelectedStep} />
        )}
        {onboardingStep.profile && (
          <Profile
            setValue={setValue}
            value={value}
            openSelectedStep={openSelectedStep}
          />
        )}
        {onboardingStep.companyprofile && (
          <CompanyProfile
            setValue={setValue}
            value={value}
            openSelectedStep={openSelectedStep}
          />
        )}
        {onboardingStep.invite && (
          <Invite
            setValue={setValue}
            value={value}
            openSelectedStep={openSelectedStep}
            isUserInvite={onboardingStep.invite}
          />
        )}
        {onboardingStep.techstack && (
          <TechStack
            setValue={setValue}
            value={value}
            openSelectedStep={openSelectedStep}
          />
        )}
        {onboardingStep.testerProfile && (
          <TesterProfile
            setValue={setValue}
            value={value}
            openSelectedStep={openSelectedStep}
          />
        )}
        {onboardingStep.onboardingComplete && <OnboardingComplete />}
      </Stack>
    </>
  );
};

export default Onboarding;

Onboarding.propTypes = {
  value: PropTypes.any,
  setValue: PropTypes.any,
  setOnBoarding: PropTypes.any,
};
