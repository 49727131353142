import { Box, Stack, Chip, Tooltip, tooltipClasses } from "@mui/material/node";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Menu, MenuItem } from "@mui/material";
import "./search.css";
import SendIcon from "@mui/icons-material/Send";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { landingFLow, resetLandingPage } from "../../redux/userslice";
import Typingdots from "./typing.gif";
import styled from "styled-components";
import MenuIcon from "../otherComponent/profileIcon";

const SearchPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const msgRef = useRef(null);
  const lastMessageRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { LandingPage, InitMessage, getAnswer, loader3, userProfiledata } =
    useSelector((state) => state.promptdata);

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#0a0a0a",

      border: "1px solid #E2007B ",
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: "#E2007B",
    },
  }));

  const [item, setItem] = useState("");
  const [type, Settype] = useState("");
  const [data, setData] = useState([]);
  const [start, setstart] = useState(false);
  const [selected, setSelected] = useState([]);
  const [firstMsg, setFirstMsg] = useState("");
  const initialText = InitMessage[0];

  const firstCharacter = userProfiledata.first_name
    ? userProfiledata.first_name[0]
    : "";
  const secondCharacter = userProfiledata.last_name
    ? userProfiledata.last_name[0]
    : "";

  useEffect(() => {
    scrollMessage();
  }, [data]);

  function scrollMessage() {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  useEffect(() => {
    if (InitMessage.length > 0) {
      let currentIndex = 0;
      const intervalId = setInterval(() => {
        if (currentIndex < initialText.length) {
          setFirstMsg(initialText.slice(0, currentIndex + 1));
          currentIndex++;
        } else {
          clearInterval(intervalId);
        }
      }, 1);

      return () => clearInterval(intervalId);
    }
  }, [initialText]);

  const addData = (event) => {
    setItem(event.target.value);
  };

  const handleClick = (e) => {
    e.preventDefault();
    setstart(true);
    const obj = {
      input: item,
    };
    setData((prevState) => [...prevState, obj]);
    setItem("");
    dispatch(
      landingFLow({
        state: LandingPage.state,
        input: item,
      })
    );
  };

  const scrollToLatestMessage = () => {
    if (msgRef.current) {
      msgRef.current.scrollIntoView({ behavior: "smooth" });
      msgRef.current.scrollTop = msgRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    let currentIndex = 0;
    let optionIndex = 0;
    let optionsInterval = null;
    const displayOptions = () => {
      if (optionIndex < LandingPage?.options?.length) {
        setData((prevText) => {
          const updatedText = [...prevText];
          const index = updatedText.length - 1;
          updatedText[index].options = LandingPage?.options.slice(
            0,
            optionIndex + 1
          );
          scrollToLatestMessage();
          optionIndex++;

          return updatedText;
        });
      } else {
        clearInterval(optionsInterval);
      }
    };
    if (getAnswer?.length > 0 && start) {
      Settype(LandingPage.type);
      const typingEffectInterval = setInterval(() => {
        if (currentIndex <= getAnswer.length) {
          setData((prevText) => {
            const updatedText = [...prevText];
            const index = updatedText.length - 1;
            updatedText[index].answer = getAnswer.slice(0, currentIndex);
            scrollToLatestMessage();
            currentIndex++;

            return updatedText;
          });
        } else {
          clearInterval(typingEffectInterval);
          optionsInterval = setInterval(displayOptions, 200);
        }
      }, 1);

      return () => {
        clearInterval(typingEffectInterval);
        clearInterval(optionsInterval);
      };
    }
    dispatch(resetLandingPage());
  }, [getAnswer]);

  const handleClickPrompt = async (items) => {
    const obj = {
      input: items,
      answer: "Your selection is saved. We are redirecting you to dashboard.",
    };
    setData((prevState) => [...prevState, obj]);
    dispatch(
      landingFLow({
        state: LandingPage.state,
        input: items,
      })
    );
  };

  useEffect(() => {
    dispatch(
      landingFLow({
        state: "Init",
        input: "",
      })
    );
  }, []);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e, data) => {
    e.preventDefault();
    if (data !== "backdropClick") {
      navigate(`/${data}`);
    }
    setAnchorEl(null);
  };
  const handleLogout = () => {
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    navigate("/login");
  };

  const handleClear = () => {
    dispatch(resetLandingPage());
    setData([]);
    setSelected([]);
    setstart(false);
    Settype("");
    dispatch(
      landingFLow({
        state: "Init",
        input: "",
      })
    );
  };

  const handleSubMenuClose = (e, menuItem) => {
    e.preventDefault();
    if (menuItem !== "backdropClick") {
      navigate(`/${menuItem}`);
    }
  };

  return (
    <Box sx={{ height: "100vh" }}>
      <Box sx={{ width: "100%", height: "91%", display: "flex" }}>
        <Box sx={{ width: "100%" }}>
          <Box className="search_Header">
            <Button
              className="cross_btn"
              onClick={() => {
                handleClear();
              }}
            >
              <ArrowBackIcon className="close_chat" />
            </Button>
            <Box sx={{ float: "left", display: "flex" }}>
              <MenuIcon
                handleClick={(e) => handleMenu(e)}
                firstCharacter={firstCharacter}
                secondCharacter={secondCharacter}
              />
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    width: "210px",
                    height: "295px",
                    borderRadius: "8px",
                    background: "#191819",
                    color: "white",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 150,
                      height: 150,
                      ml: -0.5,
                      mr: 1,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem
                  className="header_MenuItems"
                  onClick={(e) => handleClose(e, "profile")}
                >
                  <Typography className="menuItem">User Profile</Typography>
                </MenuItem>
                <MenuItem className="header_MenuItemsCompany">
                  <Stack className="companyMenu_InnerBox">
                    <Typography className="companyTypography">
                      My Company
                    </Typography>
                    <Box className="companyMenu_Box">
                      <Stack className="company_menu">
                        <Typography
                          className="header_CompanyItems"
                          onClick={(e) =>
                            handleSubMenuClose(e, "company_profile")
                          }
                        >
                          Profile
                        </Typography>
                        <Typography
                          className="header_CompanyItems"
                          onClick={(e) => handleSubMenuClose(e, "userInvite")}
                        >
                          Invite Users
                        </Typography>
                        <Typography
                          className="header_CompanyItems"
                          onClick={(e) => handleSubMenuClose(e, "tech_stack")}
                        >
                          {" "}
                          Tech Stack
                        </Typography>
                      </Stack>
                    </Box>
                  </Stack>
                </MenuItem>
                <MenuItem
                  className="header_MenuItems"
                  onClick={(e) => handleClose(e, "dashboard")}
                >
                  <Typography className="menuItem">My Dashboard</Typography>
                </MenuItem>
                <MenuItem className="header_MenuItems" onClick={handleLogout}>
                  <Typography className="menuItem">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
          <Box className="SearchPage_logo">
            <img src="/images/logo.png" style={{ opacity: "0.6" }} alt="" />
          </Box>
          <Box className="SearchPage_logo">
            <Typography
              sx={{
                color: "white",
                fontFamily: "Poppins",
                fontWeight: "400px",
                fontSize: "16px",
              }}
            >
              Hallucination Free AI assistant
            </Typography>
          </Box>
          <Box className="searchConversation" ref={msgRef}>
            <Box>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "24px",
                  fontWeight: "600",
                  lineHeight: "36px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  color: "#e2007b",
                  marginLeft: "10px",
                }}
              >
                Hello again!
              </Typography>
              {InitMessage.length > 0 && (
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "30px",
                    letterSpacing: "0em",
                    textAlign: "justified",
                    color: "white",
                    margin: "20px 10px",
                  }}
                  dangerouslySetInnerHTML={{ __html: firstMsg }}
                ></Typography>
              )}
              {data?.map((item, index) => {
                return (
                  <div key={index}>
                    <Stack
                      className="chatbot_question_box"
                      ref={lastMessageRef}
                    >
                      <Typography id="categories" className="search_user">
                        {item.input}
                      </Typography>
                      <Box className="ampai_logo">A</Box>
                    </Stack>

                    <Stack>
                      {index === data.length - 1 && loader3 ? (
                        <img
                          src={Typingdots}
                          height="46px"
                          width="50px"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        />
                      ) : (
                        <>
                          <Typography
                            sx={{
                              fontFamily: "Poppins",
                              fontSize: "16px",
                              fontWeight: "400",
                              lineHeight: "30px",
                              letterSpacing: "0em",
                              textAlign: "justified",
                              color: "white",
                              margin: "40px 5px",
                            }}
                            dangerouslySetInnerHTML={{ __html: item.answer }}
                          ></Typography>
                          <Box>
                            {item?.options &&
                              item?.options.map((option, optionIndex) => {
                                return (
                                  <HtmlTooltip
                                    key={optionIndex}
                                    wrapperStyle={{
                                      backgroundColor: "#4A4A4A",
                                    }}
                                    className="example"
                                    title={
                                      <div>
                                        <span style={{ fontSize: "16px" }}>
                                          {option}
                                        </span>
                                      </div>
                                    }
                                    arrow
                                    placement="top"
                                  >
                                    <Chip
                                      sx={{
                                        marginLeft: "20px",
                                        marginBottom: "10px",
                                      }}
                                      key={optionIndex}
                                      label={option}
                                      color="primary"
                                      variant="outlined"
                                      className={
                                        selected.includes(option)
                                          ? "active_chip "
                                          : "Prompt_selection_value"
                                      }
                                      onClick={() => handleClickPrompt(option)}
                                    />
                                  </HtmlTooltip>
                                );
                              })}
                          </Box>
                        </>
                      )}
                    </Stack>
                  </div>
                );
              })}
            </Box>
          </Box>
          <Box sx={{ height: "6%" }}>
            <form onSubmit={(e) => handleClick(e)}>
              <Box className="search_input">
                <Box className="input_box">
                  <input
                    type="text"
                    id="message-input"
                    placeholder="Enter a prompt here"
                    value={item || selected.join(", ")}
                    onChange={addData}
                    disabled={type === "SINGLE_CHOICE"}
                  />
                </Box>
                <Box className="send_btn">
                  <Button
                    type="submit"
                    onClick={(e) => handleClick(e)}
                    sx={{
                      height: "100%",
                      width: "100%",
                      color: "#E2007B",
                      padding: "0px",
                    }}
                  >
                    <SendIcon sx={{ width: "50px", height: "35px" }} />
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SearchPage;
