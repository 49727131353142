import { Button, Stack, Menu, MenuItem } from "@mui/material/node";
import React, { useState, useRef, useCallback } from "react";
import { ForceGraph2D, ForceGraph3D } from "react-force-graph";
import { useSelector } from "react-redux";
import Typography from "@mui/material/node/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import html2canvas from "html2canvas";
import Jspdf from "jspdf";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import addLogo from "./image_amp.png";
import PropTypes from "prop-types";

const Tech3dgraph = ({ onClose }) => {
  const fgRef = useRef();
  const graphRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { userProfiledata } = useSelector(
    (state) => state.promptdata
  );
  const {selectedTechstack} = useSelector((state) => state.techStackData);

  const [singledata, setsingledata] = useState({
    nodes: [],
    links: [],
  });
  const [showsecondGraph, setShowSecondgraph] = useState(false);
  let nodes = [];
  let links = [];

  selectedTechstack?.forEach((techStack) => {
    const isCritical = techStack?.is_critical;
    nodes = [
      ...nodes,
      {
        id: techStack.vendor_id,
        name: techStack.name,
        group: Math.floor(Math.random() * 10) + 1,
        color: isCritical ? "#FF99D1" : "#fcfcfc",
      },
    ];
    links = [
      ...links,
      ...techStack.integrated.map((id) => {
        return {
          source: techStack.vendor_id,
          target: id,
          value: Math.floor(Math.random() * 10) + 1,
        };
      }),
    ];
  });
  const staticData = {
    nodes,
    links,
  };

  const handleClick = (node) => {
    const mainnode = selectedTechstack?.find(
      (item) => item.vendor_id === node.id
    );
    const othernodes = selectedTechstack?.filter((test) =>
      mainnode?.integrated?.includes(test.vendor_id)
    );
    const maindata = [...othernodes, mainnode];
    setsingledata((prev) => {
      const updatedSingleNode = maindata.map((item) => ({
        id: item.vendor_id,
        name: item.name,
        group: Math.floor(Math.random() * 10) + 1,
        color: "#FF99D1",
        // mainnode.vendor_id === item.vendor_id ? colorCodes[1] : colorCodes[4]
      }));

      return { ...prev, nodes: updatedSingleNode };
    });
    setsingledata((prev) => {
      const updatedSingleLink = maindata.map((item) => ({
        source: mainnode.vendor_id,
        target: item.vendor_id,
      }));

      return { ...prev, links: updatedSingleLink };
    });
    setShowSecondgraph(true);
  };
  const handleClicked = useCallback(
    (node, callback) => {
      const distance = 40;
      const distRatio = 1 + distance / Math.hypot(node.x, node.y, node.z);

      fgRef.current.cameraPosition(
        { x: node.x * distRatio, y: node.y * distRatio, z: node.z * distRatio },
        node,
        3000
      );
      setTimeout(() => {
        if (typeof callback === "function") {
          callback(node);
        }
      }, 3000);
    },
    [fgRef]
  );

  const handleExport = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    e.preventDefault();
    setAnchorEl(null);
  };
  const handleBackdropClick = () => {
    setAnchorEl(null);
  };

  const handleScreenshot = () => {
    if (graphRef.current) {
      html2canvas(graphRef.current, {
        backgroundColor: "#191819",
        useCORS: true,
      }).then((canvas) => {
        const image = canvas.toDataURL("image/png");
        const pdf = new Jspdf("l", "mm", "a4");
        pdf.setFillColor("black");
        pdf.rect(
          0,
          0,
          pdf.internal.pageSize.getWidth(),
          pdf.internal.pageSize.getHeight(),
          "F"
        );
        pdf.addImage(addLogo, "PNG", 125, 0, 50, 15);
        pdf.setFontSize(18);
        pdf.setTextColor("#989898"); // Set a different color
        pdf.text(
          `${userProfiledata.company_name}'s Tech stack`,
          pdf.internal.pageSize.getWidth() / 2,
          20,
          { align: "center" }
        );
        const space = 14;
        pdf.addImage(image, "PNG", space, 30, 270, 150);
        pdf.setFontSize(12);
        pdf.setFontSize(12);
        pdf.setTextColor("#989898");
        const date = new Date();
        let localDate = "N/A";

        if (date && !isNaN(date)) {
          const options = {
            timeZone: "America/New_York",
            year: "numeric",
            month: "long",
            day: "2-digit",
          };
          localDate = date.toLocaleString("en-US", options);
        }
        pdf.text(
          `Created by ${userProfiledata.first_name} on ${localDate}`,
          280,
          190,
          {
            align: "right",
          }
        );
        pdf.save("Tech stack download.pdf");
      });
    }
  };

  const handleJpeg = () => {
    if (graphRef.current) {
      html2canvas(graphRef.current, {
        backgroundColor: "black",
      }).then((canvas) => {
        const image = canvas.toDataURL("image/jpeg"); // Specify JPEG format
        const link = document.createElement("a");
        link.href = image;
        link.download = "screenshot.jpeg";
        link.click();
      });
    }
  };

  return (
    <>
      {!showsecondGraph && (
        <>
          <Typography
            style={{
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: "400",
              lineHeight: "30px",
              letterSpacing: "0px",
              textAlign: "left",
              color: "white",
              paddingLeft: "10px",
            }}
          >
            Dynamic
          </Typography>
          <ForceGraph3D
            ref={fgRef}
            enableNodeDrag={false}
            onNodeClick={(node) => handleClicked(node, handleClick)}
            graphData={staticData}
            height={565}
            width={884}
            backgroundColor="#191819"
            linkColor={() => "#e2007b"}
            zoomToFit={(100, 50, () => true)}
          />
          <Button
            onClick={() => onClose(false)}
            sx={{
              marginTop: "20px",
              padding: "10px 16px",
              color: "#E2007B",
              border: "1px solid #E2007B",
              marginRight: "5px",

              fontSize: "16px",
              fontFamily: "Poppins",
              lineHeight: "24px",
              fontWeight: "400",
              borderRadius: "8px",
              textTransform: "none",
            }}
          >
            <ArrowBackIcon style={{ color: "#e2007b" }} />
            Back
          </Button>
        </>
      )}
      {showsecondGraph &&
        singledata.nodes.length > 0 &&
        singledata.links.length > 0 && (
        <>
          <Stack
            ref={(ref) => (graphRef.current = ref)}
            sx={{ height: "100%" }}
          >
            <ForceGraph2D
              linkAutoColorBy="#e2007b"
              backgroundColor="transparent"
              graphData={singledata}
              nodeAutoColorBy={(d) => d.group}
              linkColor={() => "grey"}
              nodeCanvasObject={(node, ctx, globalScale) => {
                const label = node.name;
                const fontSize = 10 / globalScale;
                ctx.font = `${fontSize}px Sans-Serif`;
                const textWidth = ctx.measureText(label).width;
                const bckgDimensions = [textWidth, fontSize].map(
                  (n) => n + fontSize * 0.2
                );

                ctx.textAlign = "center";
                ctx.textBaseline = "middle";

                ctx.fillStyle = node.color;
                ctx.fillText(label, node.x, node.y);

                node.__bckgDimensions = bckgDimensions;
              }}
              linkDirectionalArrowColor={() => {}}
              minZoom={5}
              height={565}
              width={884}
            />
          </Stack>
          <Stack sx={{ display: "flex", flexDirection: "row" }}>
            <Button
              onClick={() => setShowSecondgraph(false)}
              sx={{
                width: "130px",
                height: "40px",
                padding: "10px 16px",
                color: "#E2007B",
                border: "1px solid #E2007B",
                marginRight: "5px",
                fontSize: "16px",
                fontFamily: "Poppins",
                lineHeight: "24px",
                fontWeight: "400",
                borderRadius: "8px",
                textTransform: "none",
              }}
            >
              <ArrowBackIcon style={{ color: "#e2007b" }} />
                Go Back
            </Button>
            <Button
              sx={{
                width: "150px",
                height: "40px",
                padding: "10px 16px",
                color: "#E2007B",
                border: "1px solid #E2007B",
                marginRight: "5px",
                fontSize: "16px",
                fontFamily: "Poppins",
                lineHeight: "24px",
                fontWeight: "400",
                borderRadius: "8px",
                textTransform: "none",
              }}
              onClick={(e) => handleExport(e)}
            >
                Export as
              <KeyboardArrowDownIcon sx={{ color: "#e2007b" }} />
            </Button>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleBackdropClick}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  width: "133px",
                  height: "100px",
                  borderRadius: "8px",
                  background: "#000000",
                  padding: "5px",
                  color: "white",
                  border: "1px solid #383838",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 150,
                    height: 150,
                    ml: -0.5,
                    mr: 1,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem className="ExportMenu" onClick={() => handleJpeg()}>
                  JPEG
              </MenuItem>
              <MenuItem
                className="ExportMenu"
                onClick={() => handleScreenshot()}
              >
                  PDF
              </MenuItem>
            </Menu>
          </Stack>
        </>
      )}
    </>
  );
};

export default Tech3dgraph;

Tech3dgraph.propTypes = {
  onClose: PropTypes.any,
};
