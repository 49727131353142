import { Box, Stack } from "@mui/material/node";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import "./ChatPage.css";
import "./Chat.css";
import AddIcon from "@mui/icons-material/Add";
import SendIcon from "@mui/icons-material/Send";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import sliderClose from "./lucide_sidebar-open.svg";
import sliderOpen from "./Group.svg";
import { Oval } from "react-loader-spinner";
import Typingdots from "../../search/typing.gif";
import PropTypes from "prop-types";
import {
  ChatConversation,
  chatApi,
  resetchat,
  setChatConversation,
  setChatConversationData
} from "../../../redux/userslice";

const Chat = ({ onClose }) => {
  const dispatch = useDispatch();
  const { SingleVendor, setConversation, isloading, loader3, getConversation, userProfiledata } =
    useSelector((state) => state.promptdata);

  const [item, setItem] = useState("");
  const [text, setText] = useState([]);
  const [showPrevioushistory, setpreviousHistory] = useState(false);
  const lastMessageRef = useRef(null);
  const msgRef = useRef(null);
  const addData = (event) => {
    setItem(event.target.value);
  };

  const handleClick = (e) => {
    e.preventDefault();
    const obj = {
      question: item,
    };
    if (obj.question !== "") {
      setText((prevState) => [...prevState, obj]);
      setItem("");
      typingEffect();
    } else {
      console.log("Message is empty. Not sending API request.");
    }
  };

  useEffect(() => {
    scrollMessage();
    scrollToLatestMessage();
  }, [text]);

  function scrollMessage() {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  const typingEffect = () => {
    fetch("/api/chat/", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        question: item,
        reset: false,
        end: false,
        title: "",
      }),
    })
      .then((response) => {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        reader.read().then(function processResult(result) {
          setText((prevText) => {
            const updatedText = [...prevText];
            const index = updatedText.length - 1;
            const data = decoder?.decode(result?.value);
            updatedText[index].answer += data;
            scrollToLatestMessage();

            return updatedText;
          });
          if (result.done) return;
          reader.read().then(processResult);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    dispatch(ChatConversation({ title: SingleVendor.vendor_name }));
    // dispatch(getChatConversation());
  }, []);

  const scrollToLatestMessage = () => {
    if (msgRef.current) {
      msgRef.current.scrollIntoView({ behavior: "smooth" });
      msgRef.current.scrollTop = msgRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (Object.keys(setConversation).length > 0) {
      const particularChat = [...setConversation.dialogs];

      setText(particularChat);
    }
  }, [setConversation]);

  const postchatCoversation = (e, item) => {
    dispatch(resetchat());
    dispatch(setChatConversation({ id: item.id }));
  };
  const NewChat = () => {
    dispatch(resetchat());
    setText([]);
  };

  return (
    <>
      <Box sx={{ width: "100%", height: "100%", display: "flex" }}>
        <Box
          sx={{ width: !showPrevioushistory ? "100%" : "75%", height: "100%" }}
        >
          <Box className="chat_Header">
            <Button
              className="back_btn"
              onClick={() => {
                onClose(false);
                dispatch(chatApi({ end: true }));
                dispatch(setChatConversationData([]));
              }}
            >
              <ArrowBackIcon className="close_chat" />
            </Button>
            <Typography className="chat_together">Back to Dashboard</Typography>
          </Box>
          <Box className="ChatPage_logo">
            <img src="/images/logo.png" style={{ opacity: "0.6" }} alt="" />
          </Box>
          <Box className="ChatPage_logo">
            <Typography
              sx={{
                color: "white",
                fontFamily: "Poppins",
                fontWeight: "400px",
                fontSize: "16px",
              }}
            >
              Hallucination Free AI assistant
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <Box className="chatConversation" ref={msgRef}>
              <Box>
                <Box className="chatbot_answer_box">
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: "4.2%",
                      marginTop: "1%",
                    }}
                  >
                    <div>
                      <img id="brandicon" src="/images/brandicon.png" />
                    </div>
                  </Box>
                  <Stack className="chatbot_reply">
                    <div
                      id="categories"
                      style={{
                        whiteSpace: "pre-line",
                        color: "white",
                        textAlign: "justify",
                      }}
                    >
                      Hey <b> {userProfiledata.first_name}</b>,
                       I am your AI assistant. Ask me about a product. I may not all the answers,
                       but I will try to help.
                    </div>
                  </Stack>
                </Box>
                {isloading ? (
                  <Box
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Oval
                      height={80}
                      width={100}
                      color="#E75480"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#7a0d66"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  </Box>
                ) : (
                  text.map((item, index) => {
                    return (
                      <>
                        <Stack
                          className="chatbot_question_box"
                          key={index}
                          ref={lastMessageRef}
                        >
                          <Typography id="categories" className="chatBot_user">
                            {item.question}
                          </Typography>
                          <Box className="ampai_logo">A</Box>
                        </Stack>
                        <Box className="chatbot_answer_box">
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              width: "4.2%",
                              marginTop: "1%",
                            }}
                          >
                            <div>
                              <img id="brandicon" src="/images/brandicon.png" />
                            </div>
                          </Box>
                          <Stack className="chatbot_reply">
                            {index === text.length - 1 && loader3 ? (
                              <img
                                src={Typingdots}
                                height="46px"
                                width="50px"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              />
                            ) : (
                              <>
                                <div
                                  id="categories"
                                  style={{
                                    whiteSpace: "pre-line",
                                    color: "white",
                                    textAlign: "justify",
                                  }}
                                >
                                  {item?.answer?.replace("undefined", "")}
                                </div>
                              </>
                            )}
                          </Stack>
                        </Box>
                      </>
                    );
                  })
                )}
              </Box>
            </Box>

            <form onSubmit={handleClick}>
              <Box className="chat_input">
                <Box className="input">
                  <input
                    type="text"
                    id="message-input"
                    value={item}
                    placeholder="Enter a prompt here"
                    onChange={addData}
                  />
                </Box>
                <Box className="send">
                  <Button
                    type="submit"
                    onClick={handleClick}
                    sx={{
                      height: "100%",
                      color: "#E2007B",
                      padding: "0px",
                    }}
                  >
                    <SendIcon sx={{ width: "50px", height: "35px" }} />
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
        {/* sidebar open */}
        {!showPrevioushistory && (
          <>
            <Box className="open_sideBar_btn">
              <img
                src={sliderOpen}
                style={{ height: "33px", width: "33px" }}
                onClick={() => setpreviousHistory(true)}
              />
            </Box>
            <Box
              sx={{
                width: "21px",
                height: "100%",
                background: "#151517",
                display: "flex",
              }}
            ></Box>
          </>
        )}

        {showPrevioushistory && (
          <Box
            sx={{
              height: "100%",
              width: "25%",
              background: "#151517",
              color: "white",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "55px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "57.9%",
                marginTop: "9px",
              }}
            >
              <Typography
                sx={{
                  fonyFamily: "Poppins",
                  fontSize: "18px",
                  fontWeight: "600",
                  lineHeight: "27px",
                  display: "flex",
                  alignItems: "center",
                  color: "#e2007b",
                }}
              >
                {" "}
                History
              </Typography>
              <Box
                sx={{
                  width: "55px",
                  height: "55px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={sliderClose}
                  style={{ height: "33px", width: "33px" }}
                  onClick={() => setpreviousHistory(false)}
                />
              </Box>
            </Box>
            <Box
              sx={{
                overflowY: "scroll",
                height: "84%",
              }}
            >
              {getConversation?.map((item, i) => {
                if (item.title && item.title.trim() !== "") {
                  const date = item.date ? new Date(item.date) : null;
                  let localDate = "N/A";

                  if (date && !isNaN(date)) {
                    const options = {
                      timeZone: "America/New_York",
                      year: "numeric",
                      month: "long",
                      day: "2-digit",
                    };
                    localDate = date.toLocaleString("en-US", options);
                  }

                  return (
                    <Box
                      className="sidebar_date-titleContainer"
                      key={i}
                      onClick={(e) => postchatCoversation(e, item)}
                    >
                      <Typography className="chatSideBar_date">
                        {localDate}
                      </Typography>
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "20px",
                        }}
                      >
                        <ChatBubbleOutlineIcon
                          sx={{ height: "20px", width: "20px", color: "grey" }}
                        />
                        <Typography className="chatSidebar_title">
                          {item?.title}
                        </Typography>
                      </Stack>
                    </Box>
                  );
                }

                return null;
              })}
            </Box>
            <Box className="chatSideBar_footer">
              <Box className="newChat_btnBox">
                <Button className="newChat_btn" onClick={() => NewChat()}>
                  <AddIcon />
                  New Chat
                </Button>
              </Box>
            </Box>
          </Box>
        )}
        {/* sidebar close */}
      </Box>
    </>
  );
};

export default Chat;

Chat.propTypes = {
  onClose: PropTypes.any,
};

