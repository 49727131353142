import { Box, Typography, Button, Stack } from "@mui/material/node";
import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "./cta.css";
import { useNavigate } from "react-router";

const CallToAction = () => {
  const navigate = useNavigate();

  return (
    <Box className="callToAction">
      <Box className="cta_container">
        <Typography className="cta_headline">
          Experience Pain-free Search. Talk to Existing Users.<br/> Save Time.
        </Typography>
        <Button className="joinWaitlistBtn" onClick={() => navigate("/signup")}>
          <Stack className="btnText">
            <Typography className="movingText">
              SIGN UP
            </Typography>
          </Stack>
          <ArrowForwardIcon sx={{ width: "26px" }} />
        </Button>
      </Box>
    </Box>
  );
};

export default CallToAction;
