import React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { useDrawingArea } from "@mui/x-charts/hooks";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import "./welcomePage.css";

const size = {
  width: 100,
  height: 100,
};

const StyledText = styled("text")(() => ({
  fill: "#e2007b",
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fontSize: 20,
  fontWeight: 700,
}));

function PieCenterLabel({ children }) {
  const { width, height, left, top } = useDrawingArea();

  return (
    <StyledText x={(left + width ) * 10 + 5} y={top + height / 2}>
      {children}
    </StyledText>
  );
}

export default function PieChartWithCenterLabel({ data, value }) {

  return (
    <PieChart
      series={[{ data, innerRadius: 32, outerRadius: 42 }]}
      {...size}
      sx={{
        "& .css-1mhcdve-MuiPieArc-root": {
          stroke: "none",
          fill: "#e2007b",
        },
        "& .css-1b95g7z": {
          stroke: "none",
          fill: "#e2007b",
        },
        "& > g": {
          transform: "translate(50%, 0%)",
        },
        position: "relative",
      }}
    >
      <PieCenterLabel>{value}</PieCenterLabel>
    </PieChart>
  );
}

PieCenterLabel.propTypes = {
  children: PropTypes.any,
};

PieChartWithCenterLabel.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired,
    })
  ).isRequired,
  value: PropTypes.number,
};
