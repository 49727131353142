import { Box } from "@mui/material";
import React from "react";
import Carousel from "react-multi-carousel";
import { useSelector } from "react-redux";
import "react-multi-carousel/lib/styles.css";
import "./dashboardtestimonials.css";

const DashboardLogo = () => {
  const { SingleVendor } = useSelector((state) => state.promptdata);

  return (
    <>
      <Box sx={{display: "flex",flexDirection: "column", width: "90%" }}>
        <Box className="dashboardInfo_heading" sx={{marginBottom: "2%"}}>Past and Present Companies</Box>
        <Box
          sx={{ width: "100%" }}
          style={{
            borderRadius: "8px",
            border: "1px solid rgba(255, 255, 255, 0.08)",
            background: "#191819",
          }}
        >
          <Carousel
            additionalTransfrom={0}
            arrows={false}
            autoPlay
            autoPlaySpeed={1}
            centerMode={false}
            className=""
            customTransition="all 6s linear"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024,
                },
                items: 5,
                partialVisibilityGutter: 40,
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0,
                },
                items: 1,
                partialVisibilityGutter: 30,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464,
                },
                items: 1,
                partialVisibilityGutter: 30,
              },
            }}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay={true}
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
            transitionDuration={3000}
            sx={{
              "& .react-multi-carousel-item": {
                display: "flex",
                alignItems: "center",
              },
            }}
          >
            {Object.keys(SingleVendor.customers).length === 0 ? (
              <div></div>
            ) : (
              Object.entries(SingleVendor.customers).map(([name, url]) => (
                <div
                  key={name}
                  style={{
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {url === null ? (
                    <div
                      style={{
                        background: "#e2007b",
                        border: "1px solid black",
                        minWidth: "200px",
                      }}
                    >
                      <h3
                        style={{
                          color: "black",
                          textAlign: "center",
                          textWrap: "nowrap",
                        }}
                      >
                        {name}
                      </h3>
                    </div>
                  ) : (
                    <img
                      src={url}
                      alt={name}
                      className="image-car"
                      loading="lazy"
                    />
                  )}
                </div>
              ))
            )}
          </Carousel>
        </Box>
      </Box>
    </>
  );
};

export default DashboardLogo;
