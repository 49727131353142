import React from "react";
import { Box } from "@mui/material/node";
import PropTypes from "prop-types";
import "../header/header.css";

const MenuIcon = ({ handleClick,firstCharacter, secondCharacter }) => {
  return (
    <Box className="header_menuIcon" onClick={(e) => handleClick(e)}>
      {firstCharacter}
      {secondCharacter}
    </Box>
  );
};

export default MenuIcon;

MenuIcon.propTypes = {
  firstCharacter: PropTypes.string,
  secondCharacter: PropTypes.string,
  handleClick: PropTypes.any,
};
