import Stack from "@mui/material/node/Stack";
import React from "react";
import Slider from "@mui/material/Slider";
import { Box, Typography } from "@mui/material/node";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import "./rfiSlider.css";
import { RfISteps } from "../../../util/constant";

export const RFIslider = ({ sliderValue }) => {
  const marks = [
    { value: 0, isActive: false },
    { value: 12.5, isActive: false },
    { value: 25, isActive: false },
    { value: 37.5, isActive: false },
    { value: 50, isActive: false },
    { value: 62.5, isActive: false },
    { value: 75, isActive: false },
    { value: 87.5, isActive: false },
    { value: 99.5, isActive: false },
  ];

  const updatedMarks = RfISteps.map((step) => ({
    ...step,
    isActive: step.value === sliderValue,
  }));

  const PrettoSlider = styled(Slider)(({ theme }) => ({
    color: "#e2007b",
    width: 3,
    height: "578px",
    "& .MuiSlider-track": {
      border: "none",
    },
    "& .MuiSlider-thumb": {
      height: 11.27,
      width: 11.27,
      backgroundColor: "#fff",
      border: "1px solid currentColor",
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "inherit",
      },
      "&:before": {
        display: "none",
      },
    },
    "& .MuiSlider-rail": {
      color: theme.palette.mode === "dark" ? "black" : " #191819",
      opacity: theme.palette.mode === "dark" ? undefined : 1,
    },
    "& .MuiSlider-mark": {
      backgroundColor: " #191819",
      height: 11.27,
      width: 11.27,
      borderRadius: "50%",
      "&.MuiSlider-markActive": {
        opacity: 1,
        backgroundColor: "currentColor",
      },
    },
  }));

  return (
    <Stack className="rfiSlider_wrapper" marginLeft="6%" height="592px">
      <Box
        color="#ffffff"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        {updatedMarks.map((item, index) => (
          <Box key={index}>
            <Typography
              className={item.isActive ? "activeSlider" : "siderPageValue"}
            >
              {item.label}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box className="sliderContainer">
        <PrettoSlider
          orientation="vertical"
          className="inverted-slider"
          valueLabelDisplay="auto"
          aria-label="pretto slider"
          marks={marks}
          value={sliderValue}
        />
      </Box>
    </Stack>
  );
};

export default RFIslider;

RFIslider.propTypes = {
  sliderValue: PropTypes.any,
};
