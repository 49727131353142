import React, { useEffect, useState } from "react";
import { Box, Typography, Stack, Button, Tooltip } from "@mui/material/node";
import Header from "../header/Header";
import "./rfiDashboard.css";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSelector, useDispatch } from "react-redux";
import { getVendorInquiryStats } from "../../redux/rfiSlice";
import { useNavigate } from "react-router";
import PieChartComponent from "../landingPage/pieChart";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

const RfiDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [inquiryCategory, setInquiryCategory] = useState("");
  const { vendorInquiry, inquiryStats } = useSelector((state) => state.rfiData);

  function generateChartData(value) {
    return [
      { value: value > 0, color: "#e2007b" },
      { value: value === 0, color: "rgba(104, 104, 104, 0.2)" },
    ];
  }

  const submittedData = generateChartData(inquiryStats.submitted + inquiryStats.wait_to_submit);
  const receivedData = generateChartData(inquiryStats.completed);
  const declinedData = generateChartData(inquiryStats.declined);
  const overDue = generateChartData(inquiryStats.scheduled);

  const handleChange = (event) => {
    setInquiryCategory(event.target.value);
  };

  useEffect(() => {
    dispatch(getVendorInquiryStats());
  }, []);
  const isdisable = true;
  const styles = {
    color: isdisable && "#E2007B",
    opacity: isdisable && "0.2",
  };

  const handleBackToSearch = () => {
    navigate("/landing_page");
  };

  return (
    <>
      <Box className="rfiDashboard">
        <Header />
        <Box className="Rfidashboard_wrapper">
          <Stack className="rfiBackButton">
            <Tooltip
              title="Back To Landing Page"
              arrow
              placement="bottom"
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "black",
                    color: "#e2007b",
                    padding: "10px",
                    lineHeight: "24px",
                    border: "0.5px solid #e2007b",
                    "& .MuiTooltip-arrow": {
                      color: "#e2007b",
                    },
                    typography: {
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontWeight: "400px",
                      lineHeight: "18px",
                    },
                  },
                },
              }}
            >
              <Button
                onClick={() => handleBackToSearch()}
                className="backButton"
              >
                <KeyboardArrowLeftIcon sx={{ color: "#AE0770" }} />
              </Button>
            </Tooltip>
            <Typography className="dashboardHeading">Vendor Inquiry</Typography>
          </Stack>
          <Box className="dashboardContainer">
            <Box className="dashboardChart">
              <Stack className="dashboardChartDataBox">
                <Stack className="chartInfo">
                  <Typography className="chartData">Submitted</Typography>
                </Stack>
                <PieChartComponent
                  data={submittedData}
                  value={inquiryStats.submitted + inquiryStats.wait_to_submit}
                />
              </Stack>
              <Stack className="dashboardChartDataBox">
                <Stack className="chartInfo">
                  <Typography className="chartData">Received</Typography>
                </Stack>
                <PieChartComponent
                  data={receivedData}
                  value={inquiryStats.completed}
                />
              </Stack>
              <Stack className="dashboardChartDataBox">
                <Stack className="chartInfo">
                  <Typography className="chartData">Declined</Typography>
                </Stack>
                <PieChartComponent
                  value={inquiryStats.declined}
                  data={declinedData}
                />
              </Stack>
              <Stack className="dashboardChartDataBox">
                <Stack className="chartInfo">
                  <Typography className="chartData">Overdue</Typography>
                </Stack>
                <PieChartComponent
                  data={overDue}
                  value={inquiryStats.scheduled}
                />
              </Stack>
            </Box>
          </Box>
          <Box className="inquirySelect">
            <div className="dashboardInput">
              <FormControl sx={{ m: 0, minWidth: 600, height: 40 }}>
                <Select
                  value={inquiryCategory}
                  onChange={(e) => handleChange(e)}
                  className="userInput"
                  displayEmpty
                  inputProps={{ "Poppins": "Without label" }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        backgroundColor: "black",
                        border: "1px solid rgba(25, 24, 25, 1)",
                        color: "rgba(104, 104, 104, 1)",
                      },
                    },
                  }}
                  sx={{
                    "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
                      padding: "0px",
                      height: "40px !important",
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "10px",
                    },
                    "& .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper": {
                      backgroundColor: "black !important",
                      border: "1px solid rgba(25, 24, 25, 1)",
                    },
                  }}
                >
                  <MenuItem value="" className="menu_item">
                    <em>Choose Inquiry Name</em>
                  </MenuItem>
                  {vendorInquiry?.map((item, index) =>
                    item?.vendor_list && item?.vendor_list.length > 0 ? (
                      <MenuItem key={index} value={index} className="menu_item">
                        {item?.vendor_category}
                      </MenuItem>
                    ) : null
                  )}
                </Select>
              </FormControl>
            </div>
          </Box>
        </Box>
        {inquiryCategory !== "" && (
          <Box className="rfiVendor">
            <Stack className="tableHeading">
              <Stack className="vendorNoColumn">
                <Typography className="rfiCategorHeading">S.NO</Typography>
              </Stack>
              <Stack className="vendorCompanyNameColumn">
                <Typography className="rfiCategorHeading">Company</Typography>
              </Stack>
              <Stack className="vendorStatusColumn">
                <Typography className="rfiCategorHeading">Status</Typography>
              </Stack>
              <Stack className="vendorDealBreakersColumn">
                <Typography className="rfiCategorHeading">
                  Deal breakers
                </Typography>
              </Stack>
              <Stack className="vendorMatchColumn">
                <Typography className="rfiCategorHeading">Match</Typography>
              </Stack>
              <Stack className="vendorDeatilColumn">
                <Typography className="rfiCategorHeading">Details</Typography>
              </Stack>
            </Stack>
            {vendorInquiry?.map(
              (item, index) =>
                index === inquiryCategory && (
                  <Box className="vendorlist" key={index}>
                    {item?.vendor_list?.map((vendorId, i) => (
                      <Box className="tableData" key={vendorId}>
                        <Stack className="vendorNoColumn">
                          <Typography className="vendorData vendorNoColumn">
                            {i + 1}.
                          </Typography>
                        </Stack>
                        <Stack className="vendorCompanyNameColumn">
                          <Typography className="vendorData vendorNameColumn">
                            {item?.vendor_info?.[vendorId]?.vendor_name}
                          </Typography>
                          <Box>
                            <img
                              src={item?.vendor_info?.[vendorId]?.logo}
                              alt=""
                              className="logoImage"
                            />
                          </Box>
                        </Stack>
                        <Stack className="vendorStatusColumn">
                          <Typography className="vendorData vendorStatusColumn">
                            Awaiting
                          </Typography>
                        </Stack>
                        <Stack className="vendorDealBreakersColumn">
                          <Typography className="vendorData vendorDealBreakersColumn">
                            -
                          </Typography>
                        </Stack>
                        <Stack className="vendorMatchColumn">
                          <Typography className="vendorData vendorDealBreakersColumn">
                            -
                          </Typography>
                        </Stack>
                        <Stack className="vendorDeatilColumn">
                          <Button
                            className="viewBtn"
                            disabled={isdisable}
                            style={styles}
                          >
                            <Typography className="viewTypography">
                              View
                            </Typography>
                          </Button>
                        </Stack>
                      </Box>
                    ))}
                  </Box>
                )
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default RfiDashboard;
