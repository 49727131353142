import { Box, Stack } from "@mui/material/node";
import * as React from "react";
import "./dashboard.css";
import DashboardRow from "../DashboardComponent/DashboardRow";
import Header from "../header/Header";
import DashboardComparision from "../DashboardComponent/DashboardComparision";
import DashboardInformation from "../DashboardComponent/DashboardInformation";
import DashboardTestimonials from "../DashboardComponent/DashboardTestimonials";
import DashboardGraph from "../DashboardComponent/DashboardGraph";
import { useEffect, useState } from "react";
import {resetData} from "../../redux/userslice";
import { getPublishedTopVendor } from "../../redux/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { Oval } from "react-loader-spinner";
import DashboardLogo from "../DashboardComponent/DashboardLogo";
import { ToastContainer, toast } from "react-toastify";
import ChatPage from "../DashboardComponent/Chatboat/ChatPage";

export default function Dashboard() {
  const {
    showdashboard,
    error,
    errorMessage,
    loader2,
    getSearch,
    getChildCategory,
  } = useSelector((state) => state.promptdata);
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const selectedId = localStorage.getItem("savedId");

  useEffect(() => {
    const isSaved = getSearch?.some((item) => item.id === selectedId);
    if (isSaved) {
      const data = getSearch.filter((dt) => selectedId === dt.id)[0];
      dispatch(getPublishedTopVendor(data?.category));
    } else {
      dispatch(getPublishedTopVendor(getChildCategory));
    }
    dispatch(resetData());
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(errorMessage);
    }
    dispatch(resetData());
  }, [error]);

  useEffect(() => {
    const delay = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(delay);
  }, []);

  return (
    <>
      {isLoading ? (
        <Stack
          sx={{
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          <Oval
            height={80}
            width={100}
            color="#E75480"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#7a0d66"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </Stack>
      ) : (
        <Box className="dashboard">
          <Header />
          <Box className="dashboard_label">
            AI powered Search
            <ToastContainer />
          </Box>
          <DashboardRow
            className="dashboard_row"
            isVisible={isVisible}
            setIsVisible={setIsVisible}
          />

          {showdashboard && (
            <>
              {loader2 ? (
                <Box className="vendor_loader">
                  <Oval
                    height={80}
                    width={100}
                    color="#E75480"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#7a0d66"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                </Box>
              ) : (
                <Stack>
                  <Box className="dashboard_chatPage">
                    <ChatPage />
                  </Box>
                  <Box className="dashboard_Information">
                    <DashboardInformation />
                  </Box>

                  <Box className="dashboard_Logo">
                    <DashboardLogo />
                  </Box>
                  <Box className="dashboard_Graph">
                    <DashboardGraph />
                  </Box>

                  <DashboardComparision
                    isVisible={isVisible}
                    setIsVisible={setIsVisible}
                  />

                  <Box sx={{ marginTop: "30px" }}>
                    <DashboardTestimonials />
                  </Box>
                </Stack>
              )}
            </>
          )}
        </Box>
      )}
    </>
  );
}
