import { Box } from "@mui/material";
import Typography from "@mui/material/node/Typography";
import React, { useEffect, useState } from "react";
import "./dashboardComparision.css";
import { useSelector } from "react-redux";
import Modal from "@mui/material/node/Modal";
import { BarChart, Bar, Cell, YAxis } from "recharts";
import { useInView } from "react-intersection-observer";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material/node";
import PropTypes from "prop-types";

const colors = ["#fe0075", "#800a4b", "#8b0064"];
const style = {
  position: "absolute",
  width: 1000,
  height: 540,
  bgcolor: "#191819",
  color: "white",
  padding: "0px",
  overflow: "auto",
};
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  height: 570,
  border: "none",
  color: "white",
  padding: "0px",
  overflow: "auto",
};
const DashboardComparision = () => {
  const getPath = (x, y, width, height) => {
    return `M${x},${y + height}C${x + width / 3},${y + height} ${
      x + width / 2
    },${y + height / 3}
    ${x + width / 2}, ${y}
    C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${
  x + width
}, ${y + height}
    Z`;
  };

  const TriangleBar = ({ fill, x, y, width, height }) => {
    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
  };
  const [open, setOpen] = React.useState(false);
  const [lines, setlines] = useState([]);
  const [barchartone, setbarchartone] = useState([]);
  const [barchartwo, setbarchartwo] = useState([]);
  const handleOpen = (value) => {
    const formattedText = formatText(
      SingleVendor?.[value] && SingleVendor?.[value]
    );
    setlines(formattedText);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);
  const { SingleVendor, selectCriteria } = useSelector(
    (state) => state.promptdata
  );
  const {dashboardVendors } = useSelector(
    (state) => state.dashboardData
  );

  useEffect(() => {
    const vendorScore = dashboardVendors?.vendor_scores;
    setbarchartone([vendorScore]);
    setbarchartwo([dashboardVendors]);
  }, [SingleVendor]);

  const { ref, inView } = useInView();

  function formatText(apiString) {
    if (apiString !== null) {
      const formattedString = apiString.replace(/^\s+/gm, "");
      const lines = formattedString?.split("\n");
      const formattedText = lines.join("\n");
      const formattedTextAraay = formattedText.split("\n");
      const startIndex = formattedTextAraay?.findIndex((item) =>
        item.startsWith("1")
      );
      const alllines = formattedTextAraay.slice(
        startIndex,
        formattedString?.length
      );

      return alllines;
    }
  }
  const images = [
    {
      src: "/images/points.png",
    },
    {
      src: "/images/Frame 23.svg",
    },
    {
      src: "/images/Frame23.png",
    },
  ];

  return (
    <Box className="dashboardComparision">
      <Box className="dashboardInfo_wrapper">
        <Box className="dashboardComparision_heading">
          Pros and Cons Comparision
        </Box>
      </Box>
      <Box className="dashboardComaprision_wrapper_box">
        <Box className="dashboardComaprision_wrapper">
          <Box sx={{ height: "502px", width: "45%" }}>
            <Box className="DashboardComaprision_bar_Wrapper">
              <Box className=" DashboardComaprision_benefits_wrapper" ref={ref}>
                {inView && (
                  <>
                    <Box className="DashboardComaprision_benefits_box">
                      <BarChart
                        width={280}
                        height={300}
                        data={barchartone}
                        margin={{
                          top: 20,
                          right: 30,
                          bottom: 5,
                        }}
                      >
                        <YAxis dataKey="amount" ticks={[2, 5, 7, 10]} />
                        <Bar
                          dataKey={`[${SingleVendor.vendor_id}]`}
                          fill="#8884d8"
                          shape={<TriangleBar />}
                          label={{ position: "top" }}
                          animationBegin={300}
                          animationDuration={2000}
                          animationEasing="ease-in-out"
                        >
                          {barchartone.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={colors[index % 20]}
                            />
                          ))}
                        </Bar>
                      </BarChart>
                    </Box>
                    <Box className="DashboardComaprision_benefits_box">
                      <BarChart
                        width={280}
                        height={300}
                        data={barchartwo}
                        margin={{
                          top: 20,
                          right: 30,
                          bottom: 5,
                        }}
                      >
                        <YAxis dataKey="amount" ticks={[2, 5, 7, 10]} />
                        <Bar
                          dataKey="market_average_score"
                          fill="#8884d8"
                          shape={<TriangleBar />}
                          label={{ position: "top" }}
                          animationBegin={300}
                          animationDuration={2000}
                          animationEasing="ease-in-out"
                        >
                          {barchartwo?.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={colors[index % 20]}
                            />
                          ))}
                        </Bar>
                      </BarChart>
                    </Box>
                  </>
                )}
              </Box>

              <Box className="DashboardComaprision_bar_names">
                <Box className="DashboardComaprision_bar_names01">
                  <Typography>{SingleVendor?.vendor_name}</Typography>
                </Box>
                <Box className="DashboardComaprision_bar_names02">
                  <Typography>Market Average</Typography>
                </Box>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "28px",
                  marginTop: "20px",
                  padding: "28px",
                }}
              >
                {selectCriteria?.map((item, i) => (
                  <Box key={i} className="DashboardComaprision_benefits">
                    <img
                      src={images[i].src}
                      style={{
                        marginRight: "5%",
                      }}
                    />
                    {item}
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
          <Box sx={{width: "55%"}}>
            <Box className="DashboardComaprision_box">
              <Box className="DashboardComaprision_pros">
                <Box className="pros_block">
                  <Box className="pros_heading">
                    <Typography className="pros">Pros</Typography>
                  </Box>
                </Box>
                <Box className="DashboardComaprision_cons_content">
                  <Typography className="cons_content">
                    {SingleVendor?.pros !== null &&
                      formatText(SingleVendor?.pros)
                        .slice(0, 2)
                        .map((item, i) => {
                          return (
                            <ul style={{ lineHeight: "22px" }} key={i}>
                              <li>{item}</li>
                            </ul>
                          );
                        })}
                  </Typography>
                </Box>
              </Box>

              <Box className="DashboardComaprision_box">
                <Box className="DashboardComaprision_cons">
                  <Box className="cons_block">
                    <Box className="cons_heading">
                      <Typography className="pros">Cons</Typography>
                    </Box>
                  </Box>
                  <Box className="DashboardComaprision_cons_content">
                    <Typography className="cons_content">
                      {SingleVendor?.cons !== null &&
                        formatText(SingleVendor?.cons)
                          .slice(0, 2)
                          .map((item, i) => (
                            <ul style={{ lineHeight: "22px" }} key={i}>
                              <li>{item}</li>
                            </ul>
                          ))}
                    </Typography>
                  </Box>
                </Box>
                <Box className="view_more">
                  {SingleVendor?.cons !== null &&
                  SingleVendor?.cons !== undefined ? (
                      <Box
                        style={{ height: "24px" }}
                        onClick={() => handleOpen("pros")}
                      >
                        <span className="view_more_span">View more</span>
                        <ArrowForwardIcon className="arrow_icon" />
                      </Box>
                    ) : (
                      ""
                    )}
                </Box>
              </Box>
            </Box>

            <div>
              <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style1}>
                  <Box className="dashboardComparision_modal">
                    <Button
                      sx={{ float: "right" }}
                      onClick={() => handleClose()}
                    >
                      <CloseIcon sx={{ color: "white" }} />
                    </Button>
                  </Box>
                  <Box sx={style}>
                    <Box className="DashboardComaprision_pros_cons">
                      <Box>
                        <Typography>Pros</Typography>
                      </Box>
                      <Box className="DashboardComaprision_cons_heading">
                        <Typography>Cons</Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Box className="DashboardComaprision_modal">
                        <Box className="DashboardComaprision_pros_modal">
                          <Typography>
                            <ul>
                              {lines?.map((line, index) => (
                                <>
                                  <li key={index}>{line}</li>
                                  <br />
                                </>
                              ))}
                            </ul>
                          </Typography>
                        </Box>

                        <Box className="DashboardComaprision_cons_modal">
                          <Typography>
                            {SingleVendor?.cons !== null &&
                              formatText(SingleVendor?.cons).map((item, i) => (
                                <ul key={i}>
                                  <li>{item}</li>
                                </ul>
                              ))}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Modal>
            </div>
          </Box>
        </Box>
        <Box sx={{ width: "90%" }}>
          <Typography className="note_typography">
              Summarized from the comprehensive analysis of publicly available information,
              including case studies, market researches, and user reviews gathered from
              platforms such as G2 and Capterra. The evaluation employs AI-driven
              scores, computed and normalized through sophisticated neural network models.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardComparision;

DashboardComparision.propTypes = {
  fill: PropTypes.any,
  x: PropTypes.any,
  y: PropTypes.any,
  width: PropTypes.any,
  height: PropTypes.any,
};
