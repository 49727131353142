import React, { useEffect } from "react";
import { Box, Typography, Stack } from "@mui/material";
import { EmailVerification, resetData } from "../../redux/userslice";
import { useDispatch, useSelector } from "react-redux";
import Frame from "./Frame.svg";
import { useNavigate } from "react-router";
import { useSearchParams, Link } from "react-router-dom";
import { Oval } from "react-loader-spinner";

const Activate = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { verifyfail, verifysuccess, isloading } = useSelector(
    (state) => state.promptdata
  );

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    bgcolor: "black",
    color: "white",
  };
  useEffect(() => {
    if (verifyfail) {
      setTimeout(() => {
        navigate("/signup");
      }, 4000);
    } else if (verifysuccess) {
      setTimeout(() => {
        navigate("/login");
      }, 4000);
    }
  }, [verifyfail, verifysuccess]);

  useEffect(() => {
    dispatch(resetData());
    dispatch(EmailVerification(searchParams.get("code")));
  }, []);
  console.log("Input", setSearchParams);

  return (
    <>
      <Box sx={style}>
        <Stack>
          <Stack className="header_wrapper">
            <Box marginLeft="36px">
              <img src="/images/logo.png" alt="" />
            </Box>
          </Stack>
        </Stack>
        {isloading ? (
          <Stack className="page_loading">
            <Oval
              height={80}
              width={100}
              color="#E75480"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#7a0d66"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </Stack>
        ) : (
          <Box
            sx={{
              width: "611px",
              height: "396px",
              borderRadius: "22px",
              backgroundColor: "#191819",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={Frame} width=" 177px" height="138px" />
              {verifyfail && (
                <Box sx={{ width: "481px", height: "92px" }}>
                  <Typography
                    id="modal-modal-description"
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0px",
                      textAlign: "center",
                      textTransform: "uppercase",
                      marginBottom: "20px",
                    }}
                  >
                    Verification link expired. Kindly recreate your profile.
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0px",
                      textAlign: "center",
                    }}
                  >
                    Redirecting to Sign up page...
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0px",
                      textAlign: "center",
                    }}
                  >
                    Kindly{" "}
                    <span>
                      <Link to="/signup" style={{ color: "#e2007b" }}>
                        click here
                      </Link>
                    </span>{" "}
                    if the page does not load automatically
                  </Typography>
                </Box>
              )}
              {verifysuccess && (
                <Box sx={{ width: "481px", height: "92px" }}>
                  <Typography
                    id="modal-modal-description"
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0px",
                      textAlign: "center",
                      textTransform: "uppercase",
                      marginBottom: "20px",
                    }}
                  >
                    Authentication Successful! Your account has been created.
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0px",
                      textAlign: "center",
                    }}
                  >
                    Redirecting to the login page. Kindly re-login.
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0px",
                      textAlign: "center",
                    }}
                  >
                    Kindly
                    <span>
                      <Link to="/login" style={{ color: "#e2007b" }}>
                        click here
                      </Link>
                    </span>{" "}
                    if the page does not load automatically
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Activate;
