import React from "react";
import { Box, Typography, Stack } from "@mui/material";
import Frame from "./Frame.svg";
import { Oval } from "react-loader-spinner";
import { useSelector } from "react-redux";

const Verification = () => {
  const { isloading } = useSelector((state) => state.promptdata);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    bgcolor: "black",
    color: "white",
  };

  return (
    <>
      <Box sx={style}>
        <Stack>
          <Stack className="header_wrapper">
            <Box marginLeft="36px">
              <img src="/images/logo.png" alt="" />
            </Box>
          </Stack>
        </Stack>
        {isloading ? (
          <Stack className="page_loading">
            <Oval
              height={80}
              width={100}
              color="#E75480"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#7a0d66"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </Stack>
        ) : (
          <Box
            sx={{
              width: "611px",
              height: "396px",
              borderRadius: "22px",
              backgroundColor: "#191819",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={Frame} width=" 177px" height="138px" />
              <Box sx={{ width: "481px", height: "92px" }}>
                <Typography
                  id="modal-modal-description"
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "0px",
                    textAlign: "center",
                    textTransform: "uppercase",
                    marginBottom: "20px",
                  }}
                >
                  We have sent you an email. Please click on the link to verify
                  your account.
                </Typography>
                <Typography
                  id="modal-modal-description"
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "0px",
                    textAlign: "center",
                  }}
                >
                  The authentication link expires in 30 minutes.
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Verification;
