import React, { useState } from "react";
import { Box, Typography, Button, Stack, Checkbox } from "@mui/material/node";
import "./searchType.css";
import { Oval } from "react-loader-spinner";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import {
  resetData,
  searchSelection,
  setuserProfile
} from "../../redux/userslice";
import msgIcon from "./fluent_chat-20-regular.png";
import SearchIcon from "@mui/icons-material/Search";
import BrandIcon from "./brandmark-design (16) 2 (1).png";

const SearchType = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isloading } = useSelector((state) => state.promptdata);
  const [isChecked, setIsChecked] = useState(false);

  const handleGuidedSearch = () => {
    navigate("/decision_tree");
    dispatch(resetData());
    if (isChecked === true) {
      dispatch(searchSelection("GUIDED"));
      dispatch(setuserProfile({ search_pref: "GUIDED" }));
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleChatType = () => {
    navigate("/chat_search");
    if (isChecked === true) {
      dispatch(searchSelection("CHAT"));
    }
  };

  return (
    <>
      <Box className="searchType_wrapper">
        {isloading ? (
          <Stack className="page_loading">
            <Oval
              height={80}
              width={100}
              color="#E75480"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#7a0d66"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </Stack>
        ) : (
          <Box className="searchType_Box">
            <img src={BrandIcon} alt="" className="BrandIcon" />
            <Typography className="welcome_textfield1">
              Hallucination Free AI assistant
            </Typography>
            <Box className="btn_box">
              <Button className="chat_btn" onClick={() => handleChatType()}>
                <Stack className="letsChatBtn">
                  <Stack sx={{ height: "24px", width: "24px" }}>
                    <img
                      src={msgIcon}
                      alt=""
                      style={{ height: "100%", width: "100%" }}
                    />
                  </Stack>
                  <Typography className="letsChat">Let’s Chat</Typography>
                </Stack>
              </Button>
              <Button
                className="chat_btn"
                onClick={() => {
                  handleGuidedSearch();
                }}
                sx={{ backgroundColor: "#e2007b", color: "white" }}
              >
                <SearchIcon />
                Guided Search
              </Button>
            </Box>
            <Box
              sx={{ display: "flex", width: "460px", justifyContent: "center" }}
            >
              <Checkbox
                className="checkboxes"
                checked={isChecked}
                onChange={handleCheckboxChange}
                style={{ marginTop: "18px" }}
              />
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "21px",
                  letterSpacing: "0em",
                  textAlign: "left",
                }}
                marginLeft="10px"
                marginTop="20px"
                className="new_ampi_textField"
              >
                Remember my selection next time I login.
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default SearchType;
