import React, { useEffect } from "react";
import { Box, Typography, Stack } from "@mui/material/node";
import "./techRequirement.css";
import { RFIslider } from "../slider/rfiSlider";
import RfiFooterButton from "../footer/footerButtonGroup";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { saveVendorInquiry } from "../../../redux/rfiSlice";
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import CustomTextField from "../../otherComponent/customTextfield";

const TechRequirement = ({
  openSelectedStep,
  setSliderValue,
  sliderValue,
  selectedCategory,
}) => {
  const dispatch = useDispatch();
  const { vendorInquiry } = useSelector((state) => state.rfiData);
  const NewProjectSchema = Yup.object().shape({
    functionalities: Yup.string(),
    specifications: Yup.string(),
    customizations: Yup.string(),
  });
  const formik = useFormik({
    initialValues: {
      functionalities: "",
      specifications: "",
      customizations: "",
    },
    validationSchema: NewProjectSchema,
    onSubmit: async (values) => {
      const data = {
        saved_step: "tech_requirements",
        vendor_category: vendorInquiry[selectedCategory].vendor_category,
        tech_requirements: values,
      };
      dispatch(saveVendorInquiry(data));
      openSelectedStep("service", selectedCategory);
    },
  });

  useEffect(() => {
    if (vendorInquiry) {
      setFieldValue(
        "functionalities",
        vendorInquiry[selectedCategory]?.tech_requirements?.functionalities
      );
      setFieldValue(
        "specifications",
        vendorInquiry[selectedCategory]?.tech_requirements?.specifications
      );
      setFieldValue(
        "customizations",
        vendorInquiry[selectedCategory]?.tech_requirements?.customizations
      );
    }
  }, [vendorInquiry]);

  const moveback = () => {
    openSelectedStep("companyReview", selectedCategory);
  };

  useEffect(() => {
    setSliderValue(13.2);
  }, []);

  const { handleSubmit, getFieldProps, setFieldValue, values } = formik;

  return (
    <>
      <Box className="rfi">
        <FormikProvider value={formik}>
          <Form
            className="inquiry_form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <Box className="rfi_wrapper">
              <RFIslider
                sliderValue={sliderValue}
                setSliderValue={setSliderValue}
              />
              <Box className="companyReview_wrapper">
                <Box className="headlineTechRequirement_container">
                  <Typography className="companyReview_typography">
                    Lets gather the technical requirements. Please provide as
                    detailed information as possible.
                  </Typography>
                  <Typography className="companyReview_typography">
                    I can help you refine the questions once you have provided
                    basic information.
                  </Typography>
                </Box>
                <Box className="techReq_container">
                  <Box className="tech_field">
                    <Typography className="rfi_question">
                      Describe the specific functionalities and features the
                      software must have.
                    </Typography>
                    <CustomTextField
                      name="functionalities"
                      value={values?.functionalities}
                      {...getFieldProps("functionalities")}
                      width="100%"
                    />
                  </Box>
                  <Box className="tech_field1">
                    <Typography className="rfi_question">
                      Detail the technical specifications required, like
                      security, scalability.
                    </Typography>
                    <CustomTextField
                      name="specifications"
                      value={values?.specifications}
                      {...getFieldProps("specifications")}
                      width="100%"
                    />
                  </Box>
                  <Box className="tech_field">
                    <Typography className="rfi_question">
                      Describe the customization and specific integration
                      capabilities that’s needed.
                    </Typography>
                    <CustomTextField
                      name="customizations"
                      value={values?.customizations}
                      {...getFieldProps("customizations")}
                      width="100%"
                    />
                  </Box>
                </Box>
                <Box className="refine_box">
                  <Typography className="refineTopography">Refine</Typography>
                </Box>
              </Box>
            </Box>
            <Stack sx={{ textAlign: "center", width: "70%" }}>
              <RfiFooterButton moveback={moveback} />
            </Stack>
          </Form>
        </FormikProvider>
      </Box>
    </>
  );
};

export default TechRequirement;

TechRequirement.propTypes = {
  openSelectedStep: PropTypes.func,
  setSliderValue: PropTypes.number,
  sliderValue: PropTypes.number,
  selectedCategory: PropTypes.number,
};
