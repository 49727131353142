import React, { useState, useEffect } from "react";
import { Box, Stack, Typography, Button, Modal } from "@mui/material/node";
import "./welcomePage.css";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import {
  resetData,
  searchProfile
} from "../../redux/userslice";
import SearchIcon from "@mui/icons-material/Search";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import vector from "./Vector.png";
import { Oval } from "react-loader-spinner";
import PieChartComponent from "./pieChart";
import { getVendorInquiryStats } from "../../redux/rfiSlice";
import { getConnectStats } from "../../redux/dashboardSlice";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Header from "../header/Header";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

const WelcomePage = () => {
  const [openValidate, setOpenValidate] = useState(true);
  const [openInquire, setOpenInquire] = useState(true);
  const [openEngage, setOpenEngage] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [groupedData, setGroupedData] = useState({});
  const { getSearch, userProfiledata, isLoading } = useSelector(
    (state) => state.promptdata
  );
  const { inquiryStats } = useSelector((state) => state.rfiData);
  const { connectStats } = useSelector((state) => state.dashboardData);

  const handleOpen = () => {
    dispatch(searchProfile());
    setOpen(true);
  };

  function generateChartData(value) {
    return [
      { value: value > 0, color: "#e2007b" },
      { value: value === 0, color: "rgba(104, 104, 104, 0.2)" },
    ];
  }

  const submittedData = generateChartData(inquiryStats.submitted + inquiryStats.wait_to_submit);
  const receivedData = generateChartData(inquiryStats.completed);
  const declined = generateChartData(inquiryStats.declined);
  const overDue = generateChartData(inquiryStats.scheduled);

  const requestSentTotal = generateChartData(connectStats?.sent?.total);
  const requestSentCompleted = generateChartData(connectStats?.sent?.completed);
  const requestSentScheduled = generateChartData(connectStats?.sent?.scheduled);
  const requestSentDeclined = generateChartData(connectStats?.sent?.declined);

  const requestReceivedTotal = generateChartData(connectStats?.received?.total);
  const requestReceivedCompleted = generateChartData(
    connectStats?.received?.completed
  );
  const requestReceivedScheduled = generateChartData(
    connectStats?.received?.scheduled
  );
  const requestReceivedDeclined = generateChartData(
    connectStats?.received?.declined
  );

  const dynamicData = [
    { value: 67, color: "#e2007b" },
    { value: 100 - 67, color: "rgba(104, 104, 104, 0.2)" },
  ];
  const handleClick = (vendorId) => {
    localStorage.setItem("savedId", vendorId);
    navigate("/dashboard");
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getVendorInquiryStats());
    dispatch(getConnectStats());
  }, []);

  useEffect(() => {
    const grouped = {};
    getSearch.forEach((item) => {
      const date = item.date_created ? new Date(item.date_created) : null;
      if (date && !isNaN(date)) {
        const options = {
          timeZone: "America/New_York",
          year: "numeric",
          month: "long",
          day: "2-digit",
        };
        const localDate = date.toLocaleString("en-US", options);

        if (!grouped[localDate]) {
          grouped[localDate] = [];
        }
        grouped[localDate].push({
          id: item.id,
          label: item.label,
        });
      }
    });
    setGroupedData(grouped);
  }, [getSearch]);

  const handleNewSearch = () => {
    if (userProfiledata.search_pref === "GUIDED") {
      navigate("/decision_tree");
    } else if (userProfiledata.search_pref === "CHAT") {
      navigate("/chat_search");
    }
    localStorage.removeItem("savedId");
    dispatch(resetData());
  };

  return (
    <Box className="welcomePage">
      <Header />
      <Box className="welcomePage_Wrapper">
        {isLoading ? (
          <Stack className="page_loading">
            <Oval
              height={80}
              width={100}
              color="#E75480"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#7a0d66"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </Stack>
        ) : (
          <>
            <Box className="landingPageContainer">
              <Stack className="SearchOpen" sx={{ width: "18%" }}>
                <Stack className="cardHeading">
                  <SearchIcon className="headingIcons" />
                  <Typography className="headingTypograohy">Search</Typography>
                </Stack>
                <>
                  <Stack className="cardDescription">
                    <Typography className="descriptionTypography">
                      Personalized AI search | 15k+ vendors
                    </Typography>
                  </Stack>
                  <>
                    {" "}
                    <Box className="Searchbtn_Group">
                      <Button className="chat_btn" onClick={handleOpen}>
                        <Typography className="letsChat">
                          Load Saved Search
                        </Typography>
                      </Button>
                      <Button
                        className="chat_btn"
                        onClick={() => handleNewSearch()}
                        sx={{ backgroundColor: "#E2007B", color: "white" }}
                      >
                        Start New Search
                      </Button>
                    </Box>{" "}
                  </>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                  >
                    <Box className="savedSearchModal">
                      <Box className="searchHeader">
                        <Box className="searchHeadingContainer">
                          <Typography className="searchHeading">
                            Saved Search
                          </Typography>
                        </Box>
                        <Stack
                          className="closeIconContainer"
                          onClick={handleClose}
                        >
                          <CloseIcon className="closeSaveSearch" />
                        </Stack>
                      </Box>
                      <Box className="saveSearchListContainer">
                        {Object.entries(groupedData).map(
                          ([date, labels], i) => (
                            <Box className="searchList" key={i}>
                              <Typography className="dateTypography">
                                {date}
                              </Typography>
                              {labels.map((label, j) => (
                                <Stack
                                  onClick={() => handleClick(label?.id)}
                                  className="labelList"
                                  key={j}
                                >
                                  <img
                                    src={vector}
                                    height="14px"
                                    width="14px"
                                  />
                                  <Typography className="labelTypography">
                                    {label?.label}
                                  </Typography>
                                </Stack>
                              ))}
                            </Box>
                          )
                        )}
                      </Box>
                    </Box>
                  </Modal>
                </>
              </Stack>
              <Stack
                className={openValidate ? "ValidateOpen" : "landingPageCard"}
                sx={{ width: "27.5%" }}
              >
                <Stack className="cardHeading">
                  <img src="./images/Group.png" className="headingIcons" />
                  <Typography className="headingTypograohy">
                    Validate
                  </Typography>
                </Stack>
                <Stack className="cardDescription">
                  <Typography className="descriptionTypography">
                    Talk with current users
                  </Typography>
                </Stack>
                {openValidate ? (
                  <>
                    <Box className="validateBox">
                      <Stack className="validateRequest">
                        <Stack>
                          <Typography className="validateHeading">
                            SENT
                          </Typography>
                          <Box className="chartBlock_container">
                            <Stack className="chartBlock">
                              <PieChartComponent
                                data={requestSentTotal}
                                value={connectStats?.sent?.total}
                              />
                              <Typography className="chartName">
                                Sent
                              </Typography>
                            </Stack>
                            <Stack className="chartBlock">
                              <PieChartComponent
                                data={requestSentCompleted}
                                value={connectStats?.sent?.completed}
                              />
                              <Typography className="chartName">
                                Complete
                              </Typography>
                            </Stack>
                            <Stack className="chartBlock">
                              <PieChartComponent
                                data={requestSentScheduled}
                                value={connectStats?.sent?.scheduled}
                              />
                              <Typography className="chartName">
                                Schedule
                              </Typography>
                            </Stack>
                            <Stack className="chartBlock">
                              <PieChartComponent
                                data={requestSentDeclined}
                                value={connectStats?.sent?.declined}
                              />
                              <Typography className="chartName">
                                Declined
                              </Typography>
                            </Stack>
                          </Box>
                        </Stack>
                      </Stack>
                      <Stack className="validateRequest">
                        <Stack>
                          <Typography className="validateHeading">
                            RECEIVED
                          </Typography>
                          <Box className="chartBlock_container">
                            <Stack className="chartBlock">
                              <PieChartComponent
                                data={requestReceivedTotal}
                                value={connectStats?.received?.total}
                              />
                              <Typography className="chartName">
                                Received
                              </Typography>
                            </Stack>
                            <Stack className="chartBlock">
                              <PieChartComponent
                                data={requestReceivedCompleted}
                                value={connectStats?.received?.completed}
                              />
                              <Typography className="chartName">
                                Complete
                              </Typography>
                            </Stack>
                            <Stack className="chartBlock">
                              <PieChartComponent
                                data={requestReceivedScheduled}
                                value={connectStats?.received?.scheduled}
                              />
                              <Typography className="chartName">
                                Schedule
                              </Typography>
                            </Stack>
                            <Stack className="chartBlock">
                              <PieChartComponent
                                value={connectStats?.received?.declined}
                                data={requestReceivedDeclined}
                              />
                              <Typography className="chartName">
                                Declined
                              </Typography>
                            </Stack>
                          </Box>
                        </Stack>
                      </Stack>
                    </Box>
                    <Box className="viewMore_container">
                      <Button
                        className="ViewMore_btn"
                        onClick={() => navigate("/vendorValidate")}
                      >
                        View More
                      </Button>
                    </Box>
                  </>
                ) : (
                  ""
                )}
                <Stack className="cardDropDownArrow">
                  {openValidate ? (
                    <KeyboardArrowUpIcon
                      className="headingIcons"
                      onClick={() => setOpenValidate(!openValidate)}
                    />
                  ) : (
                    <KeyboardArrowDownIcon
                      className="headingIcons"
                      onClick={() => setOpenValidate(!openValidate)}
                    />
                  )}
                </Stack>
              </Stack>
              <Stack
                className={openInquire ? "ValidateOpen" : "landingPageCard"}
                sx={{ width: "19%" }}
              >
                <Stack className="cardHeading">
                  <DescriptionOutlinedIcon className="headingIcons" />
                  <Typography className="headingTypograohy">Inquire</Typography>
                </Stack>
                <Stack className="cardDescription">
                  <Typography className="descriptionTypography">
                    AI - assessed fit with chosen vendor
                  </Typography>
                </Stack>
                {openInquire ? (
                  <>
                    <Box className="validateBox">
                      <Box className="inquireContainer">
                        <Box className="chartBlock_container">
                          <Stack className="chartBlock">
                            <PieChartComponent
                              data={submittedData}
                              value={inquiryStats.submitted + inquiryStats.wait_to_submit}
                            />
                            <Typography className="chartName">
                              Submitted
                            </Typography>
                          </Stack>
                          <Stack className="chartBlock">
                            <PieChartComponent
                              data={receivedData}
                              value={inquiryStats.completed}
                            />
                            <Typography className="chartName">
                              Received
                            </Typography>
                          </Stack>
                          <Stack className="chartBlock">
                            <PieChartComponent
                              value={inquiryStats.declined}
                              data={declined}
                            />
                            <Typography className="chartName">
                              Declined
                            </Typography>
                          </Stack>
                          <Stack className="chartBlock">
                            <PieChartComponent
                              data={overDue}
                              value={inquiryStats.scheduled}
                            />
                            <Typography className="chartName">
                              Overdue
                            </Typography>
                          </Stack>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="viewMore_container">
                      <Button
                        className="ViewMore_btn"
                        onClick={() => navigate("/rfi_dashboard")}
                      >
                        View More
                      </Button>
                    </Box>
                  </>
                ) : (
                  ""
                )}
                <Stack className="cardDropDownArrow">
                  {openInquire ? (
                    <KeyboardArrowUpIcon
                      className="headingIcons"
                      onClick={() => setOpenInquire(!openInquire)}
                      u
                    />
                  ) : (
                    <KeyboardArrowDownIcon
                      className="headingIcons"
                      onClick={() => setOpenInquire(!openInquire)}
                    />
                  )}
                </Stack>
              </Stack>
              <Stack
                className={openEngage ? "ValidateOpen" : "landingPageCard"}
                sx={{ width: "19%" }}
              >
                <Stack className="cardHeading">
                  <GroupsOutlinedIcon className="headingIcons" />
                  <Typography className="headingTypograohy">Engage</Typography>
                </Stack>
                <Stack className="cardDescription">
                  <Typography className="descriptionTypography">
                    Clarify | Negotiate | Customize
                  </Typography>
                </Stack>
                {openEngage ? (
                  <>
                    <Box className="validateBox">
                      <Box className="engageContainer">
                        <Box className="chartBlock_container">
                          <Stack className="chartBlock">
                            <PieChartComponent data={dynamicData} />
                            <Typography className="chartName">Sent</Typography>
                            <Typography className="countNumber">100</Typography>
                          </Stack>
                          <Stack className="chartBlock">
                            <PieChartComponent data={dynamicData} />
                            <Typography className="chartName">
                              Complete
                            </Typography>
                            <Typography className="countNumber">100</Typography>
                          </Stack>
                          <Stack className="chartBlock">
                            <PieChartComponent data={dynamicData} />
                            <Typography className="chartName">
                              Schedule
                            </Typography>
                            <Typography className="countNumber">100</Typography>
                          </Stack>
                          <Stack className="chartBlock">
                            <PieChartComponent data={dynamicData} />
                            <Typography className="chartName">
                              Declined
                            </Typography>
                            <Typography className="countNumber">100</Typography>
                          </Stack>
                        </Box>
                      </Box>
                      <Box className="underDevelopmentBox">
                        <Stack className="developmentIconContainer">
                          <SettingsOutlinedIcon className="developmentIcon" />
                        </Stack>
                        <Stack>
                          <Typography className="developmentTypography">
                            Under<br/> Development
                          </Typography>
                        </Stack>
                      </Box>
                    </Box>
                    <Box className="viewMore_container">
                      <Button className="ViewMore_btn" sx={{ opacity: "0.08" }}>
                        View More
                      </Button>
                    </Box>
                  </>
                ) : (
                  ""
                )}
                <Stack className="cardDropDownArrow">
                  {openEngage ? (
                    <KeyboardArrowUpIcon
                      className="headingIcons"
                      onClick={() => setOpenEngage(!openEngage)}
                      u
                    />
                  ) : (
                    <KeyboardArrowDownIcon
                      className="headingIcons"
                      onClick={() => setOpenEngage(!openEngage)}
                    />
                  )}
                </Stack>
              </Stack>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default WelcomePage;
