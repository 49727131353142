/* eslint-disable no-undef */
import { Box, Stack, Typography, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import FooterButton from "../footerButton/FooterButton";
import Header from "../header/Header";
import { Form, FormikProvider, useFormik } from "formik";
import Select from "@mui/material/Select";
import SliderComponent from "../sliderComponent/sliderComponent";
import {
  setuserProfile,
  resetData,
  searchSelection
} from "../../redux/userslice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Oval } from "react-loader-spinner";
import "./profile.css";
import * as Yup from "yup";
import PropTypes from "prop-types";
import InfoIcon from "@mui/icons-material/Info";
import { ProfileInfo, JobFunction } from "../../util/constant";
import { ToastContainer, toast } from "react-toastify";
import CustomTextField from "../otherComponent/customTextfield";

const Profile = ({ setValue, value, openSelectedStep }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [text, setText] = useState("");
  const [otherJobFunction, setOtherJobFunction] = useState("");
  const [typingIndex, setTypingIndex] = useState(0);
  const { isloading, userProfiledata, successMessage, success } = useSelector(
    (state) => state.promptdata
  );

  useEffect(() => {
    if (typingIndex !== ProfileInfo.length) {
      setText((prevText) => prevText + ProfileInfo.charAt(typingIndex));
      setTypingIndex((prevIndex) => prevIndex + 1);
    }
  }, [typingIndex]);

  const NewProjectSchema = Yup.object().shape({
    first_name: Yup.string().required("First Name Required"),
    title: Yup.string().required("Title Required"),
    company_name: Yup.string().required("Company Name Required"),
    last_name: Yup.string().required("Last Name Required"),
    email: Yup.string()
      .email("Invalid Email Address")
      .matches(/^[a-zA-Z0-9_.]+@[\w.-]+\.[a-zA-Z]{2,}$/, "Invalid Email Address")
      .max(255)
      .required("Email Required"),
    industry: Yup.string().required("Industry Required"),
    website: Yup.string()
      .test("", (value) => {
        return /^.*\..+$/.test(value);
      })
      .required("Invalid URL"),
    search_pref: Yup.string(),
    job_function: Yup.string().required("Job Function Required"),
  });

  useEffect(() => {
    if (success && pathname === "/profile") {
      toast(successMessage);
    }
    dispatch(resetData());
  }, [success, successMessage]);

  const formik = useFormik({
    initialValues: {
      first_name: "",
      title: "",
      company_name: "",
      last_name: "",
      email: "",
      industry: "",
      website: "",
      search_pref: "",
      job_function: "",
      other_job_function: "",
    },
    validationSchema: NewProjectSchema,
    onSubmit: async (values) => {
      if (values.job_function === "Other") {
        values.job_function = values.other_job_function;
      }
      delete values.other_job_function;
      const data = {
        ...values,
        onboarding: "Company Profile",
      };
      dispatch(searchSelection(values.search_pref));
      if (pathname === "/onboarding") {
        dispatch(setuserProfile(data));
        localStorage.setItem("onboarding", "Company Profile");
        openSelectedStep("companyprofile");
      } else {
        dispatch(setuserProfile(values));
        setTimeout(() => {
          navigate("/landing_page");
        }, 2000);
      }
    },
  });
  const moveback = () => {
    if (pathname === "/onboarding") {
      openSelectedStep("intro");
    } else {
      navigate("/landing_page");
    }
  };

  useEffect(() => {
    if (userProfiledata) {
      setFieldValue("first_name", userProfiledata.first_name);
      setFieldValue("last_name", userProfiledata.last_name);
      setFieldValue("email", userProfiledata.email);
      setFieldValue("industry", userProfiledata.industry);
      setFieldValue("website", userProfiledata.website);
      setFieldValue("title", userProfiledata.title);
      setFieldValue("company_name", userProfiledata.company_name);
      setFieldValue("search_pref", userProfiledata.search_pref);
      if (
        userProfiledata.job_function &&
        !JobFunction.includes(userProfiledata.job_function)
      ) {
        setFieldValue("job_function", "Other");
        setFieldValue("other_job_function", userProfiledata.job_function);
        setOtherJobFunction(userProfiledata.job_function);
      } else {
        setFieldValue("job_function", userProfiledata.job_function);
      }
    }
  }, [userProfiledata]);

  useEffect(() => {
    setValue(0.5);
  }, []);

  const getItemValue = (value) => {
    let ans = '';
    if(value !== 'Select'){
      ans = value;
    }

    return ans;
  };

  const {
    handleSubmit,
    getFieldProps,
    touched,
    errors,
    setFieldValue,
    values,
  } = formik;

  return (
    <Stack
      className="profile_page_container"
      style={{
        height: "100%",
      }}
    >
      <Header />
      <ToastContainer/>
      <FormikProvider value={formik}>
        <Form
          className="login-form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <Stack className="profile_page_box" style={{ marginTop: "2%" }}>
            {isloading ? (
              <Stack className="page_loading">
                <Oval
                  height={80}
                  width={100}
                  color="#E75480"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#7a0d66"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </Stack>
            ) : (
              <>
                {pathname === "/onboarding" && (
                  <SliderComponent setValue={setValue} value={value} />
                )}
                <Box className="profile_box_wrapper">
                  {pathname.includes("onboarding") && (
                    <>
                      <Box className="infoIcon_container">
                        <InfoIcon
                          sx={{ color: "#e2007b", textAlign: "right" }}
                        />
                        <Typography className="infoIconData">{text}</Typography>
                      </Box>
                    </>
                  )}
                  <Box className="profile_box">
                    <Box className="fieldsContainer">
                      <Box className="profile_box_field_wrapper">
                        <CustomTextField
                          label="First Name"
                          name="first_name"
                          width="360px"
                          value={values?.first_name}
                          InputProps={{readOnly: pathname === '/onboarding' ? true : false}}
                          onChange={(e) => setFieldValue("first_name", e.target.value)}
                          error={Boolean(
                            touched.first_name && errors.first_name
                          )}
                          helperText={touched.first_name && errors.first_name}
                        />
                        <CustomTextField
                          label="Last Name"
                          name="last_name"
                          width="360px"
                          value={values?.last_name}
                          InputProps={{readOnly: pathname === '/onboarding' ? true : false}}
                          onChange={(e) => setFieldValue("last_name", e.target.value)}
                          {...getFieldProps("last_name")}
                          error={Boolean(touched.last_name && errors.last_name)}
                          helperText={touched.last_name && errors.last_name}
                        />
                      </Box>
                      <Box
                        className="profile_box_field_wrapper"
                        style={{ marginTop: "22px" }}
                      >
                        <CustomTextField
                          label="Title"
                          name="title"
                          width="360px"
                          value={values?.title}
                          {...getFieldProps("title")}
                          error={Boolean(touched.title && errors.title)}
                          helperText={touched.title && errors.title}
                        />
                        <CustomTextField
                          label="Name of the Company"
                          name="company_name"
                          width="360px"
                          value={values?.company_name}
                          {...getFieldProps("company_name")}
                          error={Boolean(
                            touched.company_name && errors.company_name
                          )}
                          helperText={
                            touched.company_name && errors.company_name
                          }
                        />
                      </Box>
                      <Box
                        className="profile_box_field_wrapper"
                        style={{ marginTop: "22px" }}
                      >
                        <CustomTextField
                          label="Industry"
                          width="360px"
                          name="industry"
                          value={values?.industry}
                          {...getFieldProps("industry")}
                          error={Boolean(touched.industry && errors.industry)}
                          helperText={touched.industry && errors.industry}
                        />
                        <CustomTextField
                          label="Email"
                          name="email"
                          InputProps={{readOnly: pathname === '/onboarding' ? true : false}}
                          onChange={(e) => setFieldValue("email", e.target.value)}
                          width="360px"
                          value={values?.email}
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                        />
                      </Box>
                      <Box
                        className="profile_box_field_wrapper"
                        style={{ marginTop: "22px" }}
                      >
                        <CustomTextField
                          label="Website"
                          name="website"
                          width="360px"
                          InputProps={{readOnly: pathname === '/onboarding' ? true : false}}
                          onChange={(e) => setFieldValue("website", e.target.value)}
                          value={values?.website}
                          error={Boolean(touched.website && errors.website)}
                          helperText={touched.website && errors.website}
                        />
                        <Box>
                          <Typography color="#ffffff" className="title_field">
                            Search Preference
                          </Typography>
                          <Select
                            readOnly
                            value="GUIDED"
                            error={Boolean(
                              touched.search_pref && errors.search_pref
                            )}
                            helperText={
                              touched.search_pref && errors.search_pref
                            }
                            {...getFieldProps("search_pref")}
                            sx={{
                              color: "#A1A1A1",
                              background: "#191819",
                              border: "1px solid var(--stroke, #686868)",
                              borderRadius: "8px",
                              width: "360px",
                              height: "41px",
                              "& .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon": {
                                color: "grey",
                              },
                              "& .css-bpeome-MuiSvgIcon-root-MuiSelect-icon": {
                                color: "grey !important",
                              },
                              "& ..css-1636szt": {
                                color: "grey",
                              },
                            }}
                          >
                            <MenuItem value="">Select</MenuItem>
                            <MenuItem value="CHAT">CHAT</MenuItem>
                            <MenuItem value="GUIDED">GUIDED</MenuItem>
                          </Select>
                        </Box>
                      </Box>
                      <Box
                        className="profile_box_field_wrapper"
                        style={{ marginTop: "22px" }}
                      >
                        <Box>
                          <Typography color="#ffffff" className="title_field">
                            Job Function
                          </Typography>
                          <Select
                            name="job_function"
                            value={values?.job_function || ""}
                            error={Boolean(
                              touched.job_function && errors.job_function
                            )}
                            helperText={
                              touched.job_function && errors.job_function
                            }
                            {...getFieldProps("job_function")}
                            sx={{
                              border: "1px solid #686868",
                              borderRadius: "8px",
                              background: "#191819",
                              width: "360px",
                              height: "41px",
                              color: "#A1A1A1",
                              "& .css-1yhq19e": {
                                color: "grey",
                              },
                              "& .css-1636szt": {
                                color: "grey",
                              },
                              "& .css-9zxjqa-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root":
                                {
                                  borderColor: "transparent",
                                },
                              "& .Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#e2007b",
                                },
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  border: "1px solid var(--stroke, #686868)",
                                },
                                "&:hover fieldset": {
                                  borderColor: "#686868",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#E2007B",
                                  border: "2px solid #E2007B",
                                },
                              },
                            }}
                          >
                            {JobFunction.map((item, index) => (
                              <MenuItem key={index} value={getItemValue(item)}>
                                {item}
                              </MenuItem>
                            ))}
                          </Select>
                        </Box>
                        {values.job_function === "Other" && (
                          <Box>
                            <CustomTextField
                              label="If other, please specify here"
                              name="other_job_function"
                              width="360px"
                              value={otherJobFunction}
                              placeholder="Other"
                              onChange={formik.handleChange}
                              {...getFieldProps("other_job_function")}
                            />
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </>
            )}

            <Stack width="963px" style={{ marginTop: "20px" }}>
              {!isloading && <FooterButton moveback={moveback} />}
            </Stack>
          </Stack>
        </Form>
      </FormikProvider>
    </Stack>
  );
};

export default Profile;

Profile.propTypes = {
  value: PropTypes.any,
  setValue: PropTypes.any,
  openSelectedStep: PropTypes.function,
};
