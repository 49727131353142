import { Button, Stack, Menu, MenuItem, Tooltip } from "@mui/material";
import React, { useRef } from "react";
import { ForceGraph2D } from "react-force-graph";
import { useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import html2canvas from "html2canvas";
import Jspdf from "jspdf";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import addLogo from "./image_amp.png";
import PropTypes from "prop-types";

const TechstacknodeGraph = ({ onClose }) => {
  const graphRef = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { userProfiledata } = useSelector(
    (state) => state.promptdata
  );
  const {selectedTechstack} = useSelector((state) => state.techStackData);
  let nodes = [];
  let links = [];
  selectedTechstack?.forEach((techStack) => {
    const isCritical = techStack?.is_critical;
    nodes = [
      ...nodes,
      {
        id: techStack.vendor_id,
        name: techStack.name,
        group: Math.floor(Math.random() * 10) + 1,
        color: isCritical ? "#FF99D1" : "#ffffff",
      },
    ];
    links = [
      ...links,
      ...techStack.integrated.map((id) => {
        return {
          source: techStack.vendor_id,
          target: id,
          value: Math.floor(Math.random() * 10) + 1,
        };
      }),
    ];
  });
  const data1 = { nodes, links };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    e.preventDefault();
    setAnchorEl(null);
  };
  const handleBackdropClick = () => {
    setAnchorEl(null);
  };

  const handleScreenshot = () => {
    if (graphRef.current) {
      html2canvas(graphRef.current, {
        backgroundColor: "#191819",
        useCORS: true,
      }).then((canvas) => {
        const image = canvas.toDataURL("image/png");
        const pdf = new Jspdf("l", "mm", "a4");
        pdf.setFillColor("black");
        pdf.rect(
          0,
          0,
          pdf.internal.pageSize.getWidth(),
          pdf.internal.pageSize.getHeight(),
          "F"
        );
        pdf.addImage(addLogo, "PNG", 125, 0, 50, 15);
        pdf.setFontSize(18);
        pdf.setTextColor("#989898"); // Set a different color
        pdf.text(
          `${userProfiledata.company_name}'s Tech stack`,
          pdf.internal.pageSize.getWidth() / 2,
          20,
          { align: "center" }
        );
        const space = 14;
        pdf.addImage(image, "PNG", space, 30, 270, 150);
        pdf.setFontSize(12);
        pdf.setTextColor("#989898");
        const date = new Date();
        let localDate = "N/A";

        if (date && !isNaN(date)) {
          const options = {
            timeZone: "America/New_York",
            year: "numeric",
            month: "long",
            day: "2-digit",
          };
          localDate = date.toLocaleString("en-US", options);
        }
        pdf.text(
          `Created by ${userProfiledata.first_name} on ${localDate}`,
          280,
          190,
          {
            align: "right",
          }
        );
        pdf.save("Tech stack download.pdf");
      });
    }
  };

  const handleJpeg = () => {
    if (graphRef.current) {
      html2canvas(graphRef.current, {
        backgroundColor: "black",
      }).then((canvas) => {
        const image = canvas.toDataURL("image/jpeg"); // Specify JPEG format
        const link = document.createElement("a");
        link.href = image;
        link.download = "screenshot.jpeg";
        link.click();
      });
    }
  };

  return (
    <>
      <Stack
        ref={(ref) => (graphRef.current = ref)}
        sx={{ height: "100%", display: "flex", justifyContent: "center" }}
      >
        <ForceGraph2D
          graphData={data1}
          backgroundColor="transparent"
          nodeAutoColorBy="#red"
          linkColor={() => "#e2007b"}
          height={565}
          width={884}
          onNodeDragEnd={(node) => {
            node.fx = node.x;
            node.fy = node.y;
            node.fz = node.z;
          }}
          nodeCanvasObject={(node, ctx, globalScale) => {
            const label = node.name;
            const fontSize = 10 / globalScale;
            ctx.font = `${fontSize}px Sans-Serif`;
            const textWidth = ctx.measureText(label).width;
            const bckgDimensions = [textWidth, fontSize].map(
              (n) => n + fontSize * 0.2
            );

            ctx.textAlign = "center";
            ctx.textBaseline = "middle";

            ctx.fillStyle = node.color;
            ctx.fillText(label, node.x, node.y);

            node.__bckgDimensions = bckgDimensions;
          }}
          minZoom={2.5}
        />
      </Stack>
      <Stack sx={{ display: "flex", flexDirection: "row" }}>
        <Button
          onClick={() => onClose(false)}
          sx={{
            width: "130px",
            height: "40px",
            padding: "10px 16px",
            color: "#E2007B",
            border: "1px solid #E2007B",
            marginRight: "5px",
            fontSize: "16px",
            fontFamily: "Poppins",
            lineHeight: "24px",
            fontWeight: "400",
            borderRadius: "8px",
            textTransform: "none",
          }}
        >
          <ArrowBackIcon style={{ color: "#e2007b" }} />
          Back
        </Button>

        <Tooltip
          title="Kindly scale the tech stack image to fit the screen." // Replace with your actual tooltip content
          arrow
          placement="top"
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "black",
                color: "#e2007b",
                border: "0.5px solid #e2007b",
                "& .MuiTooltip-arrow": {
                  color: "common.black",
                },
              },
            },
          }}
        >
          <Button
            sx={{
              width: "150px",
              height: "40px",
              padding: "10px 16px",
              color: "#E2007B",
              border: "1px solid #E2007B",
              marginRight: "5px",
              fontSize: "16px",
              fontFamily: "Poppins",
              lineHeight: "24px",
              fontWeight: "400",
              borderRadius: "8px",
              textTransform: "none",
              position: "relative",
            }}
            onClick={(e) => handleClick(e)}
          >
            Export as
            <KeyboardArrowDownIcon sx={{ color: "#e2007b" }} />
          </Button>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleBackdropClick}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              width: "133px",
              height: "100px",
              borderRadius: "8px",
              background: "#000000",
              padding: "5px",
              color: "white",
              border: "1px solid #383838",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 150,
                height: 150,
                ml: -0.5,
                mr: 1,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem className="ExportMenu" onClick={() => handleJpeg()}>
            JPEG
          </MenuItem>
          <MenuItem className="ExportMenu" onClick={() => handleScreenshot()}>
            PDF
          </MenuItem>
        </Menu>
      </Stack>
    </>
  );
};

export default TechstacknodeGraph;

TechstacknodeGraph.propTypes = {
  onClose: PropTypes.any,
};
