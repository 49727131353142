import React, { useEffect, useState } from "react";
import { Stack, Box, Typography, TextField } from "@mui/material";
import Header from "../header/Header";
import { getCompanyProfile, setCompanyProfile, setuserProfile } from "../../redux/userslice";
import "./company_profile.css";
import { useDispatch, useSelector } from "react-redux";
import { Form, FormikProvider, useFormik } from "formik";
import { useNavigate, useLocation } from "react-router";
import FooterButton from "../footerButton/FooterButton";
import { Oval } from "react-loader-spinner";
import {
  BuyerEmployee,
  BuyerRevenue,
  CompanyProfileInfo
} from "../../util/constant";
import * as Yup from "yup";
import PropTypes from "prop-types";
import SliderComponent from "../sliderComponent/sliderComponent";
import InfoIcon from "@mui/icons-material/Info";
import SelectInput from "../otherComponent/selectInput";

const CompanyProfile = ({ setValue, value, openSelectedStep }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [text, setText] = useState("");
  const [typingIndex, setTypingIndex] = useState(0);
  const { companyInformation, isLoading, userProfiledata } = useSelector(
    (state) => state.promptdata
  );

  useEffect(() => {
    if (typingIndex !== CompanyProfileInfo.length) {
      setText((prevText) => prevText + CompanyProfileInfo.charAt(typingIndex));
      setTypingIndex((prevIndex) => prevIndex + 1);
    }
  }, [typingIndex]);

  const NewProjectSchema = Yup.object().shape({
    description: Yup.string().required("Description is required"),
    revenue: Yup.string().required("Revenue is required"),
    employees: Yup.string().required("Number of EMployee is required"),
  });

  const formik = useFormik({
    initialValues: {
      description: "",
      revenue: "",
      employees: "",
    },
    validationSchema: NewProjectSchema,
    onSubmit: async (values) => {
      const data = {
        ...userProfiledata,
        onboarding: "Invite Colleagues",
      };
      dispatch(setCompanyProfile(values));
      if (pathname === "/onboarding") {
        openSelectedStep("invite");
        localStorage.setItem("onboarding", "Invite Colleagues");
        dispatch(setuserProfile(data));
      } else {
        navigate("/landing_page");
      }
    },
  });

  useEffect(() => {
    if (companyInformation) {
      setFieldValue("description", companyInformation.description);
      setFieldValue("revenue", companyInformation.revenue);
      setFieldValue("employees", companyInformation.employees);
    }
  }, [companyInformation]);

  useEffect(() => {
    setValue(25.5);
    dispatch(getCompanyProfile());
  }, []);

  const {
    handleSubmit,
    getFieldProps,
    touched,
    errors,
    setFieldValue,
    values,
  } = formik;

  const moveback = () => {
    if (pathname === "/onboarding") {
      openSelectedStep("profile");
    } else {
      navigate("/profile");
    }
  };

  return (
    <Stack
      className="companyProfile_page_container"
      style={{
        height: "100%",
      }}
    >
      <Header />
      <FormikProvider value={formik}>
        <Form
          className="company-form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <Stack
            className="companyProfile_page_box"
            style={{ marginTop: "2%" }}
          >
            {isLoading ? (
              <Stack className="page_loading">
                <Oval
                  height={80}
                  width={100}
                  color="#E75480"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#7a0d66"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </Stack>
            ) : (
              <>
                {pathname === "/onboarding" && (
                  <SliderComponent setValue={setValue} value={value} />
                )}
                <Box className="companyProfile_box_wrapper">
                  {pathname.includes("onboarding") && (
                    <>
                      <Box className="infoIcon_wrapper">
                        <InfoIcon
                          sx={{ color: "#e2007b", textAlign: "right" }}
                        />
                        <Typography className="infoIconData">{text}</Typography>
                      </Box>
                    </>
                  )}
                  <Box className="companyProfile_box">
                    <Box
                      className="companyProfile_box_field_wrapper"
                      style={{ height: "215px" }}
                    >
                      <Typography className="title_field">
                        I have generated a brief description of the company.
                        Kindly check and revise as needed.
                      </Typography>
                      <TextField
                        multiline
                        rows={8}
                        inputProps={{ className: "custom-height-text-field" }}
                        className="Description_textField"
                        placeholder="Description"
                        {...getFieldProps("description")}
                        value={values?.description}
                        error={Boolean(
                          touched.description && errors.description
                        )}
                        helperText={touched.description && errors.description}
                        sx={{
                          color: "#A1A1A1",
                          borderRadius: "8px",
                          background: "#191819",
                          fontSize: "14px",
                          "& .custom-height-text-field": {
                            color: "rgba(161, 161, 161, 1)",
                          },
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "1px solid var(--stroke, #686868)",
                            },
                            "&:hover fieldset": {
                              borderColor: "#686868",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#E2007B",
                              border: "2px solid #E2007B",
                            },
                          },
                        }}
                      />
                    </Box>
                    <Box
                      className="CompanyProfile_box_field_wrapper"
                      style={{ marginTop: "52px" }}
                    >
                      <Box sx={{ width: "46.8%" }}>
                        <Typography color="#ffffff" className="title_field">
                          Revenue of the Company
                        </Typography>
                        <SelectInput
                          value={values.revenue}
                          onChange={(e) => setFieldValue("revenue", e.target.value)}
                          options={BuyerRevenue}
                          error={Boolean(touched.revenue && errors.revenue)}
                          helperText={touched.revenue && errors.revenue}
                        />
                      </Box>
                      <Box sx={{ width: "46.8%" }}>
                        <Typography color="#ffffff" className="title_field">
                          Number of Employees
                        </Typography>
                        <SelectInput
                          value={values.employees}
                          options={BuyerEmployee}
                          error={Boolean(touched.employees && errors.employees)}
                          helperText={touched.employees && errors.employees}
                          onChange={(e) => setFieldValue("employees", e.target.value)}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
            <Stack width="963px" style={{ marginTop: "20px" }}>
              {!isLoading && <FooterButton moveback={moveback} />}
            </Stack>
          </Stack>
        </Form>
      </FormikProvider>
    </Stack>
  );
};

export default CompanyProfile;

CompanyProfile.propTypes = {
  value: PropTypes.any,
  setValue: PropTypes.any,
  openSelectedStep: PropTypes.any,
};
