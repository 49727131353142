import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import { getVendorInquiries } from "../redux/rfiSlice";
import { getuserProfile } from "../redux/userslice";
import { useDispatch } from "react-redux";

export const ProtectedRoutes = ({
  Component,
  setValue,
  value,
  setOnBoarding,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userAuth = document.cookie;
  const onboardingStep = localStorage.getItem("onboarding");

  useEffect(() => {
    if (!userAuth) {
      navigate("/");
    }
    dispatch(getVendorInquiries());
    dispatch(getuserProfile());
  }, [userAuth]);

  useEffect(() => {
    if(onboardingStep !== "Complete" && !window.location.pathname.includes("onboarding")) {
      navigate("/onboarding");
    }
  },[onboardingStep]);

  return (
    <Component
      setValue={setValue}
      value={value}
      setOnBoarding={setOnBoarding}
    />
  );
};

ProtectedRoutes.propTypes = {
  Component: PropTypes.any,
  setValue: PropTypes.number,
  value: PropTypes.number,
  setOnBoarding: PropTypes.any,
};
