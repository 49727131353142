import React from "react";
import { Box, Stack, Typography, Button } from "@mui/material";
import "./onboardingPage.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const OnboardingPage = ({openSelectedStep}) => {
  const { status } = useSelector((state) => state.applicationData);
  const onboardingStep = localStorage.getItem("onboarding");
  const switchCheck = onboardingStep !== null ? onboardingStep : status.onboarding;

  const handleOnboarding = () => {
    switch (switchCheck) {
    case "User Profile":
      openSelectedStep("profile");
      break;
    case "Company Profile":
      openSelectedStep("companyprofile");
      break;
    case "Invite Colleagues":
      openSelectedStep("invite");
      break;
    case "Tech Stack":
      openSelectedStep("techstack");
      break;
    case "Plan":
      openSelectedStep("testerProfile");
      break;
    default:
      openSelectedStep("profile");
    }
  };

  return (
    <>
      <Stack
        style={{
          height: "100vh",
        }}
      >
        <Stack className="onBoardingHeader">
          <img src="/images/logo.png" alt="" className="ampaiLogo" />
        </Stack>
        <Box className="onBoardingPage_wrapper">
          <img src="/images/Frame (2).png" className="onboardingImage" />
          <Typography className="onboardingHeadline">
            Please complete your onboarding process
          </Typography>
          <Button className="proceedBtn" onClick={handleOnboarding}>
            <Link className="linkText">Complete Now</Link>
          </Button>
        </Box>
      </Stack>
    </>
  );
};

export default OnboardingPage;

OnboardingPage.propTypes = {
  openSelectedStep: PropTypes.func,
};
