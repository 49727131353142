import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Stack,
  Tooltip,
  Typography,
  Menu,
  MenuItem
} from "@mui/material/node";
import "./compareVendor.css";
import Header from "../header/Header";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useLocation, useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { vendorComparision } from "../../redux/dashboardSlice";
import { CriteriaArray } from "../../util/constant";
import { Oval } from "react-loader-spinner";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import addLogo from "../../Layout/image_amp.png";
import html2canvas from "html2canvas";
import Jspdf from "jspdf";

const CompareVendor = () => {
  const graphRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [selection, setSelection] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const { getChildCategory, userProfiledata } = useSelector(
    (state) => state.promptdata
  );
  const { comparision } = useSelector((state) => state.dashboardData);
  const { isLoading } = useSelector((state) => state.applicationData);

  const searchSelection = {
    revenue: location?.state?.selectedSizes,
    critical: location?.state?.selectedItems,
    criteria: location?.state?.selectedCriteria,
  };
  const activeIndustryOverview = location?.state?.actives;

  const handleBackToSearch = () => {
    navigate("/dashboard", { state: { selection, activeIndustryOverview} });
  };
  const compare = {
    vendor_category: getChildCategory,
    vendor_list: location?.state?.selectedCheckboxes,
  };

  useEffect(() => {
    dispatch(vendorComparision(compare));
    setSelection(searchSelection);
  }, []);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    e.preventDefault();
    setAnchorEl(null);
  };

  const handleScreenshot = () => {
    setAnchorEl(null);
    if (graphRef.current) {
      html2canvas(graphRef.current, {
        backgroundColor: "#191819",
        useCORS: true,
      }).then((canvas) => {
        const image = canvas.toDataURL("image/png");
        const pdf = new Jspdf("p", "mm", "a4");
        pdf.setFillColor("black");
        pdf.rect(
          0,
          0,
          pdf.internal.pageSize.getWidth(),
          pdf.internal.pageSize.getHeight(),
          "F"
        );
        const logoWidth = 50;
        const logoHeight = 15;
        const logoX = (pdf.internal.pageSize.getWidth() - logoWidth) / 2;
        const logoY = 5;

        pdf.addImage(addLogo, "PNG", logoX, logoY, logoWidth, logoHeight);
        pdf.setFontSize(18);
        pdf.setTextColor("#989898");
        pdf.text(
          `${userProfiledata.company_name}'s Martech Stack`,
          pdf.internal.pageSize.getWidth() / 2,
          25,
          { align: "center" }
        );
        const tableX = 10;
        const tableY = 25;
        const tableWidth = pdf.internal.pageSize.getWidth() - 2 * tableX;
        const tableHeight = pdf.internal.pageSize.getHeight() - 2 * tableY;
        pdf.addImage(image, "PNG", tableX, tableY, tableWidth, tableHeight);
        pdf.setFontSize(12);
        pdf.setTextColor("#989898");
        const date = new Date();
        let localDate = "N/A";

        if (date && !isNaN(date)) {
          const options = {
            timeZone: "America/New_York",
            year: "numeric",
            month: "long",
            day: "2-digit",
          };
          localDate = date.toLocaleString("en-US", options);
        }
        pdf.text(
          `Created by ${userProfiledata.first_name} on ${localDate}`,
          200,
          280,
          {
            align: "right",
          }
        );
        pdf.save("Vendor Compare download.pdf");
      });
    }
  };

  const handleJpeg = () => {
    setAnchorEl(null);
    if (graphRef.current) {
      html2canvas(graphRef.current, {
        backgroundColor: "black",
      }).then((canvas) => {
        const image = canvas.toDataURL("image/jpeg"); // Specify JPEG format
        const link = document.createElement("a");
        link.href = image;
        link.download = "screenshot.jpeg";
        link.click();
      });
    }
  };

  return (
    <>
      <Box className="compareVendor_wrapper">
        <Header />
        <Box className="dashboard_label">
          <ArrowForwardIosIcon />
          Comparison Table
          <ArrowBackIosNewIcon />
        </Box>
        {isLoading ? (
          <Stack className="page_loading">
            <Oval
              height={80}
              width={100}
              color="#E75480"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#7a0d66"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </Stack>
        ) : (
          <Box className="vendor_container">
            <Box className="compare_topHeader">
              <Tooltip
                title="Back To Search"
                arrow
                placement="bottom"
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "black",
                      color: "#e2007b",
                      padding: "10px",
                      lineHeight: "24px",
                      border: "0.5px solid #e2007b",
                      "& .MuiTooltip-arrow": {
                        color: "#e2007b",
                      },
                      typography: {
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        fontWeight: "400px",
                        lineHeight: "18px",
                      },
                    },
                  },
                }}
              >
                <Button
                  onClick={() => handleBackToSearch()}
                  className="backToDashboard_btn"
                >
                  <KeyboardArrowLeftIcon sx={{ color: "#AE0770" }} />
                </Button>
              </Tooltip>
              <Tooltip
                title="Download the vendor comparision."
                arrow
                placement="top"
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "black",
                      color: "#e2007b",
                      padding: "10px",
                      lineHeight: "24px",
                      border: "0.5px solid #e2007b",
                      "& .MuiTooltip-arrow": {
                        color: "#e2007b",
                      },
                      typography: {
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        fontWeight: "400px",
                        lineHeight: "18px",
                      },
                    },
                  },
                }}
              >
                <Button
                  className="export_btns"
                  sx={{ width: "133px" }}
                  onClick={(e) => handleClick(e)}
                >
                  <Typography
                    className="btn_typography"
                    sx={{ color: "#e2007b" }}
                  >
                    Export as
                  </Typography>
                  <KeyboardArrowDownIcon
                    sx={{ color: "#e2007b", height: "18px", width: "18px" }}
                  />
                </Button>
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    width: "133px",
                    height: "100px",
                    borderRadius: "8px",
                    background: "#000000",
                    padding: "5px",
                    color: "white",
                    border: "1px solid #383838",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 150,
                      height: 150,
                      ml: -0.5,
                      mr: 1,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem className="ExportMenu" onClick={() => handleJpeg()}>
                  JPEG
                </MenuItem>
                <MenuItem
                  className="ExportMenu"
                  onClick={() => handleScreenshot()}
                >
                  PDF
                </MenuItem>
              </Menu>
            </Box>
            <Box
              className="compareVendor_completeBox"
              ref={(ref) => (graphRef.current = ref)}
            >
              <Stack className="vendor_header">
                <Stack className="criteriaItem">
                  <Typography className="criteria">CRITERIA</Typography>
                </Stack>
                <Stack className="criteria_Info">
                  {location?.state.selectedCheckboxes.map((checkbox, index) => (
                    <div key={index} className="compareData">
                      <Typography className="criteriaHeading">
                        {comparision?.[checkbox]?.vendor_name}
                      </Typography>
                    </div>
                  ))}
                </Stack>
              </Stack>
              {CriteriaArray.map((criteria, index) => (
                <Stack key={index} className="comparision_detail">
                  <Stack className="criteriaItem">
                    <Typography className="criteria_list">
                      {criteria.label}
                    </Typography>
                  </Stack>
                  <Stack className="criteria_Info">
                    {location?.state.selectedCheckboxes.map(
                      (checkbox, innerIndex) => (
                        <Typography key={innerIndex} className="compareData">
                          {comparision?.[checkbox]?.[criteria.property]}
                        </Typography>
                      )
                    )}
                  </Stack>
                </Stack>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default CompareVendor;
