import axios from "axios";
// import { useNavigate } from "react-router";

// Create an instance of Axios
export const api = axios.create({
  baseURL: "/api",
});

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    // Modify the request config here (e.g., add headers, modify data, etc.)
    // config.headers["Authorization"] = "Bearer your-token";
    return config;
  },
  (error) => {
    // Handle request errors here
    return Promise.reject(error);
  }
);

// Add a response interceptor
api.interceptors.response.use(
  (response) => {
    // Modify the response data here
    return response.data;
  },
  (error) => {
    // const navigate = useNavigate();
    if (error.response.status === 403) {
      document.cookie =
        "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      window.location.replace("/");
      console.log(window.location);
    }

    // console.log("instance error", error.response?.data?.detail === "instance error not a login session");
    // Handle response errors here
    return Promise.reject(error);
  }
);

// Usage example
// api
//   .get("/users")
//   .then((response) => {
//     // Handle successful response here
//     console.log(response);
//   })
//   .catch((error) => {
//     // Handle error here
//     console.error(error);
//   });
