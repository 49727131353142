import { Box, Button, Stack, Typography } from "@mui/material/node";
import React, { useEffect, useState } from "react";
import WebsiteHeader from "../WebsiteHeader/WebsiteHeader";
import Banner from "../Banner/Banner";
import About from "../About/About";
import CallToAction from "../CallToAction/CallToAction";
import Category from "../Category/Category";
import Features from "../Features/Feature";
import Footer from "../Footer/Footer";
import CTA from "../CTA/Cta";
import Modal from "@mui/material/node/Modal";
import "./website.css";

const Website = () => {
  const [open, setOpen] = useState(false);

  function areCookiesEnabled() {
    document.cookie = "testCookie=1";
    var cookieEnabled = document.cookie.indexOf("testCookie") !== -1;
    document.cookie = "testCookie=; expires=Thu, 01 Jan 1970 00:00:00 GMT";

    return cookieEnabled;
  }

  useEffect(() => {
    const cookiesEnabled = areCookiesEnabled();
    if (!cookiesEnabled) {
      setOpen(true);
    }
  }, []);

  const handleAcceptCookies = () => {
    setOpen(false);
    alert("Please enable cookies in your browser settings.");
  };

  return (
    <>
      <Box className="website">
        <Box className="website_banner">
          <WebsiteHeader />
          <Banner />
        </Box>
        <div>
          <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="cookiesPopper">
              <Box className="popUp_wrapper">
                <img src="/images/logo.png" alt="" />
                <Stack sx={{ height: "65%" }}>
                  <Typography className="acceptCookiesHeadline">
                    We use essential cookies to make our site work.
                  </Typography>
                  <Typography className="cookiesTypography">
                    By clicking
                    {`"Accept"`}, you agree to our website&apos;s cookie use as
                    described in our cookie policy.{" "}
                  </Typography>
                </Stack>
                <Stack className="cookies_btnGroup">
                  <Button className="accept_btn" onClick={handleAcceptCookies}>
                    Accept all cookies
                  </Button>
                </Stack>
              </Box>
            </Box>
          </Modal>
        </div>
        <Box className="website_about">
          <About />
        </Box>
        <Box className="website_cta">
          <CallToAction />
        </Box>
        <Box className="website_category">
          <Category />
        </Box>
        <Box>
          <Features className="website_features" />
        </Box>
        <Box className="website_cta">
          <CTA />
        </Box>
        <Box>
          <Footer />
        </Box>
      </Box>
    </>
  );
};

export default Website;
