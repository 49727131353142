import { createSlice } from "@reduxjs/toolkit";
import { api } from "../axiosinterceptor/axiosInterceptor";
import { toast } from "react-toastify";
import {startLoading, stopLoading} from "./applicationSlice";

const initialState = {
  techStack: [],
  selectedTechstack: [],
  techStacksuccess: false,
};

const techStackSlice = createSlice({
  name: "techStackData",
  initialState,
  reducers: {
    getTechStackSuccess(state, action) {
      state.techStack = action.payload;
    },
    getPostedTechStackSuccess(state, action) {
      state.selectedTechstack = action.payload;
    },
    addtechstackSuccess(state) {
      state.techStacksuccess = true;
    },
  },
});

export const { getTechStackSuccess, getPostedTechStackSuccess } =
  techStackSlice.actions;

export default techStackSlice.reducer;

export function getVendors() {
  return async (dispatch) => {
    dispatch(startLoading());
    const data = {
      query: {},
      returns: [
        "vendor_name",
        "url",
        "logo_clearbit",
        "logo_favicon",
        "logo",
        "parent_category_name",
        "child_category_name",
      ],
    };
    try {
      const response = await api.post("/vendors/", data);
      dispatch(techStackSlice.actions.getTechStackSuccess(response));
      dispatch(stopLoading());
    } catch (error) {
      toast(error);
    }
  };
}

export function getPostedTechStack() {
  return async (dispatch) => {
    try {
      const response = await api.get("/tech_stack/");
      dispatch(techStackSlice.actions.getPostedTechStackSuccess(response));
    } catch (error) {
      toast(error);
    }
  };
}

export function SetCriticalVendor(vendorId) {
  return async (dispatch) => {
    try {
      const response = await api.post(`/tech_stack/set_critical/${vendorId}/`);
      dispatch(techStackSlice.actions.getPostedTechStackSuccess(response));
    } catch (error) {
      toast(error);
    }
  };
}

export function UnsetCriticalVendor(vendorId) {
  return async (dispatch) => {
    try {
      const response = await api.post(
        `/tech_stack/unset_critical/${vendorId}/`
      );
      dispatch(techStackSlice.actions.getPostedTechStackSuccess(response));
    } catch (error) {
      toast(error);
    }
  };
}

export function addTechStack(vendor) {
  const vendorids = vendor.map((item) => {
    return item.vendor_id;
  });

  return async (dispatch) => {
    try {
      await api.post(`/tech_stack/set/`, vendorids);
      dispatch(techStackSlice.actions.addtechstackSuccess());
    } catch (error) {
      toast(error);
    }
  };
}

export function removeTechStack(vendorId, data) {
  return async (dispatch) => {
    try {
      const response = await api.post(`/tech_stack/remove/${vendorId}/`, data);
      dispatch(techStackSlice.actions.removeVendor(response));
    } catch (error) {
      toast(error);
    }
  };
}
