//import { Box, Button, Stack } from "@mui/material/node";
import { Box, Stack } from "@mui/material/node";
import React from "react";
import BrandIcon from "../../components/search/brandmark-design (16) 2 (1).png";
import "./websiteHeader.css";
//import { useLocation, useNavigate } from "react-router";
import { useLocation } from "react-router";

const Header = () => {
  //const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <Box className="websiteHeader_wrapper">
      <Box className="website_header">
        <Stack className="websiteHeader_box">
          <img
            src={BrandIcon}
            alt=""
            style={{ height: "25px", width: "140px" }}
          />
          {pathname === "/" && (
            <Stack className="signUp_btnBox">
              {/* <Button className="loginBtn" onClick={() => navigate("/login")}>
                Log In
              </Button> */}
              {/*<Button className="loginBtn" onClick={() => navigate("/signUp")}>
                Sign Up
		</Button> */}
            </Stack>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default Header;
